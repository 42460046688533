import { useState } from 'react';
import { useQueryKeys } from '../../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { DataView } from '../../../../../../../components/Common/DataView';
import { CustomDropDown } from '../../CustomDropdown';
import { formatAssociateName } from '../../../../../utils/utils';

export const Fulfillment = () => {
  const [view, setView] = useState('Fulfillment View');
  const [storeCompleteDlvFulfill] = useQueryKeys([`storeCompleteDlvFulfill`]);
  const [timeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['Fulfillment View'];
  const getData = timeframe => {
    const filterBytimeframe = storeCompleteDlvFulfill.filter(row => row[timeframe + '_FLG'] > 0);
    switch (view) {
      case 'Fulfillment View':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Cancel Date' },
            { name: 'SKU #' },
            { name: 'Cancel Qty' },
            { name: 'Reason Code' }
          ],
          data: filterBytimeframe.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM, 5) },
            { stringValue: data.EXTN_HOST_ORDER_REF },
            { stringValue: data.TRNSFM_DT },
            { numValue: data.EXTN_SKU_CODE },
            { numValue: data.CANCELED_QTY },
            { stringValue: data.REASON_DESC }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case 'Associate View':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'Units Unfulfilled' }, { name: '$ Unfulfilled' }],
          data: filterBytimeframe.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM, 5) },
            { numValue: data.CANCELED_QTY },
            { dollarValue: data.CANCELED_SALES, decimals: 2 }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='my-4'>
      <DataView.Title as='h3'>Fulfillment: Associate Report</DataView.Title>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Fulfillment View', text: 'Fulfillment View', value: 'Fulfillment View' },
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'LM', text: 'LM', value: 'LM' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataView.DataTable data={getData(timeframe)} />
    </DataView>
  );
};

import { Header } from 'semantic-ui-react';
import { DataView } from '../../Common/DataView';
import { DataTable } from '../../Common/DataTable';
import { getCalloutStyle, getThousandDollarString } from '../../Common/TableCellUtils';

export const WTDSales = ({ wtdSalesData, dailySalesData }) => {
  const todayIsMonday = new Date().getDay() === 1;

  const wtdDataObj = {
    data: wtdSalesData.map(row => [
      {
        stringValue: row['ABBR_DAY_NM']
      },
      {
        customStyle: getCalloutStyle(row['WTD_SLS_AMT']),
        stringValue: todayIsMonday
          ? getThousandDollarString(row['LW_WTD_SLS_AMT'], 1)
          : getThousandDollarString(row['WTD_SLS_AMT'], 1)
      },
      {
        customStyle: getCalloutStyle(row['DOL_TO_WTD_PLN']),
        stringValue: getThousandDollarString(row['DOL_TO_WTD_PLN'], 1)
      },
      {
        customStyle: getCalloutStyle(row['PCT_TO_WTD_PLN']),
        decimals: 1,
        pctValue: row['PCT_TO_WTD_PLN']
      },
      {
        customStyle: getCalloutStyle(row['WTD_COMP_PCT']),
        decimals: 1,
        pctValue: row['WTD_COMP_PCT']
      },
      {
        customStyle: getCalloutStyle(row['WTD_PLANNED_COMP_PCT']),
        decimals: 1,
        pctValue: row['WTD_PLANNED_COMP_PCT']
      }
    ]),
    headers: [
      { name: 'As Of' },
      {
        element: (
          <span>
            WTD Sales <br />$ (k)
          </span>
        ),
        name: 'wtd-sales-k'
      },
      {
        element: (
          <span>
            $ vPlan <br />
            WTD (k)
          </span>
        ),
        name: 'vplan-k'
      },
      {
        element: (
          <span>
            % to <br />
            WTD Plan
          </span>
        ),
        name: 'to-wtd-plan'
      },
      {
        element: (
          <span>
            WTD <br />
            Comp %
          </span>
        ),
        name: 'wtd-comp'
      },
      {
        element: (
          <span>
            WTD Planned
            <br />
            Comp %
          </span>
        ),
        name: 'wtd-planned-comp'
      }
    ]
  };

  const dailySalesDataObj = {
    data: dailySalesData.map(row => [
      {
        orderBy: row['DAY_CODE'],
        stringValue: row['ABBR_DAY_NM']
      },
      {
        stringValue: todayIsMonday
          ? getThousandDollarString(row['LW_TY_SLS_AMT'], 1)
          : getThousandDollarString(row['TY_SLS_AMT'], 1)
      },
      {
        customStyle: getCalloutStyle(row['DOL_TO_PLN']),
        stringValue: getThousandDollarString(row['DOL_TO_PLN'], 1)
      },
      {
        customStyle: getCalloutStyle(row['PCT_TO_PLN']),
        decimals: 1,
        pctValue: row['PCT_TO_PLN']
      },
      {
        customStyle: getCalloutStyle(row['COMP_PCT']),
        decimals: 1,
        pctValue: row['COMP_PCT']
      },
      {
        customStyle: getCalloutStyle(row['PLANNED_COMP_PCT']),
        decimals: 1,
        pctValue: row['PLANNED_COMP_PCT']
      }
    ]),
    headers: [
      { name: 'Day' },
      {
        element: (
          <span>
            Daily <br />
            Sales $ (k)
          </span>
        ),
        name: 'daily-sales-k'
      },
      { name: '$ vPlan (k)' },
      {
        element: (
          <span>
            % to <br />
            Daily Plan
          </span>
        ),
        name: 'to-daily-plan'
      },
      { name: 'Comp %' },
      {
        element: (
          <span>
            Planned
            <br />
            Comp %
          </span>
        ),
        name: 'planned-comp'
      }
    ],
    sortable: true,
    sortableDefault: 0,
    sortableColumns: [0]
  };

  return (
    <>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        WTD Sales
      </Header>
      <DataTable data={wtdDataObj} />
      <br />
      <DataView key={'dailySalesView'} testId={'dailySalesView'}>
        <DataView.Title position={'center'}>Daily Sales</DataView.Title>
        <DataView.DataTable data={dailySalesDataObj} />
      </DataView>
    </>
  );
};

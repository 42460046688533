import { DataView } from '../../../Common/DataView';
import { usageApplicationsBuilder } from '../utils/usageApplicationsBuilder';

export const CreditUsage = ({ data = [], timeframe }) => {
  const dataTableData = {
    headers: [{ name: 'Category' }, { name: 'Credit Sales' }, { name: 'Usage' }, { name: 'bps vPlan' }, { name: 'bps vLY' }],
    data: usageApplicationsBuilder(data, timeframe)
  };

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Usage</DataView.Title>
      <DataView.DataTable data={dataTableData} />
    </DataView>
  );
};

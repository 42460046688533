import React, { useState } from 'react'
import { Header, Loader, Grid, Segment, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { useQueryParams } from '../../hooks/useQueryParams'
import { getCompareStyle } from '../Common/TableCellUtils'
import { DataTable } from '../Common/DataTable'
import { DataView } from '../Common/DataView'
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel'
import { useParams } from 'react-router-dom'
import { useFetchLevelName } from '../../hooks/useFetchLevelName'
import { formatNumberWithCommas } from '../../utils/formatter'
import { buildSublevelLinkColumn } from '../../utils/dataTable'

// TODO: Replace this queries with enhanced queries
const QUERIES = {
  store: { // There's a bug where the level stays 'store' when hitting the back button, so this compensates
    query: 'queryLeadsDtlByDst',
  },
  district: {
    query: 'queryLeadsDtlByDst',
  },
  region: {
    query: 'queryLeadsDtlByRgn',
  },
  division: {
    query: 'queryLeadsDtlByDiv',
  }
}


export const LeadsDetailDst = ({ timeframe, settimeframe, fpToggle }) => {
  const { level } = useParams()
  const { dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams()

  const [unit, setUnit] = useState('NOM')
  const [allWebStore, setAllWebStore] = useState('') // All = empty string, Web = '_WEB', Store = '_STR'
  const locNbr = dstNbr || rgnNbr || divNbr
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level, fpToggle, locNbr })
  const leadDetailQuery = useQuery([QUERIES[level]?.query, { locNbr, fpToggle }], getData)

  if (leadDetailQuery.isLoading || isLoadingName) {
    return <Loader active>Loading...</Loader>
  }

  let sumProgNmFilter = leadDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL']
  })

  let totalFilter = leadDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['subMetric']
  })

  const recordByLevel = leadDetailQuery.data.filter(row => row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL'])

  const totalRecords = [...recordByLevel, ...totalFilter]

  const isMonday = new Date().getDay() === 1

  const leadsBySumProgram = {
    headers: [
      {
        name: 'Summary Program Name '
      },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      {
        name: 'Leads '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: sumProgNmFilter.map(row => [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS_TGT']),
        orderBy: row[timeframe + allWebStore + '_LEADS_TGT']
      },
      (isMonday && timeframe === 'WTD') && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_LEADS_TGT']),
        orderBy: row['LW' + allWebStore + '_LEADS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS']),
        orderBy: +row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM' ? {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
        orderBy: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))
    ),
    sortable: true,
    sortableDefault: 0

  }

  const totalLeadsDataObj = {
    headers: [
      { name: 'Org # ' },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target ' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      { name: 'Leads ' },
      { name: unit === 'PCT' ? 'VLY% ' : 'VLY ' },
    ].filter(row => Boolean(row)),
    data: totalRecords.map((row, index) => [
      {
        ...buildSublevelLinkColumn({ level, row, to: '/leads' })
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS_TGT']),
        orderBy: row[timeframe + allWebStore + '_LEADS_TGT']
      },
      (isMonday && timeframe === 'WTD') && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_LEADS_TGT']),
        orderBy: row['LW' + allWebStore + '_LEADS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS']),
        orderBy: row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM' ? {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
        orderBy: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))),
    sortable: true,
    sortableDefault: 0
  }

  const sumProNmView = <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>Units:</Grid.Row>
        <Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <Grid.Row>&nbsp;</Grid.Row>
        <Grid.Row>
          <Menu compact size='small'>
            <Menu.Item
              name='All'
              active={allWebStore === ''}
              content='All'
              onClick={() => {
                setAllWebStore('')
              }}
            />
            <Menu.Item
              name='Web'
              active={allWebStore === '_WEB'}
              content='Web'
              onClick={() => {
                setAllWebStore('_WEB')
              }}
            />
            <Menu.Item
              name='Store'
              active={allWebStore === '_STR'}
              content='Store'
              onClick={() => {
                setAllWebStore('_STR')
              }}
            />
          </Menu>
        </Grid.Row>
      </Grid.Column>
    </Grid>
    {/* Leads overview - aggregated summary programs */}
    <br />
    <DataView testId='totalLeads'>
      <DataView.Title position='center'>
        Total Leads - {level} {locNbr}, {String(currentLevelName).toLowerCase()}
        <Header.Subheader> Data as of {totalRecords[0]?.TRNSFM_DT}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={totalLeadsDataObj} />
    </DataView>
    <p>*Target is reported as the total leads generated last year through the current period. For example, YTD target is all leads generated last year through the upcoming Sunday. Actual Lead counts and vLY are reported through the previous day.</p>
    {/* Leads detail - by individual summary program */}
    <Header textAlign='center'>Leads by Summary Program</Header>
    <DataTable data={leadsBySumProgram} />
  </>

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2o-link' >Back to List</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {isMonday ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>

      <Segment attached='bottom'>
        {sumProNmView}
      </Segment>
    </>
  )
}
import { useEffect, useMemo, useState } from 'react'
import { Segment, Grid, Header, Menu, Image } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { ScannerV2 } from '../Support/ScannerV2'
import { getParamByLevel } from '../../utils/timeFrameUtils'
import { SearchInput } from '../Search/SearchInput'

export const TopMenu = ({ fpToggle, level, setLevel, levelSelection, setLevelSelection, storeNumber, setStoreNumber, storeNumberField, setStoreNumberField, location }) => {
  const active = useMemo(() => location.pathname.substring(location.pathname.lastIndexOf('/') + 1), [location.pathname])
  const [activeItem, setActiveItem] = useState(active ? active : 'overview')
  const paramByLevel = getParamByLevel(level)
  const levelUrlParam = level === 'core' ? '' : `?${paramByLevel}=${storeNumber.padStart(4, '0')}`

  useEffect(() => {
    setActiveItem(active ? active : 'overview')
  }, [active])

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <Image
              src='/hd_logo.jpg'
              size='mini'
              as={Link}
              to={`/${level}/overview${levelUrlParam}`}
              onClick={() => {
                setActiveItem('overview')
                setStoreNumber(storeNumberField.padStart(4, '0'))
                setLevel(levelSelection)
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign='center' width={6}>
            <Header textAlign='center'>Store Pulse</Header>
          </Grid.Column>
          <Grid.Column width={5} textAlign='right'>
            <Link
              to={{ pathname: `https://portal.homedepot.com/sites/myapron_en_us/Departments/Assets0001/Pulse%20Tutorial.pdf` }}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
              target="_blank"
            >Tutorial</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div>&nbsp;</div>
      Select View:
      {/* TODO: Refactor this Menu Items */}
      <Menu fluid attached='top' widths={3} color='orange'>
        <Menu.Item
          as={Link}
          to={`/${level}/overview${levelUrlParam}`}
          name='overview'
          active={activeItem === 'overview'}
          content='Overview'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('overview')
          }}
        />
        <Menu.Item
          active={activeItem === 'intraday'}
          as={Link}
          name='intraday'
          content='Hourly Sales'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('intraday')
          }}
          to={`/${level}/intraday${levelUrlParam}`}
        />
        <Menu.Item
          as={Link} to={`/${level}/daily${levelUrlParam}`}
          name='daily'
          active={activeItem === 'daily'}
          content='Daily Sales'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('daily')
          }}
        />
      </Menu>
      <Menu fluid attached widths={3} color='orange'>
        <Menu.Item
          as={Link} to={`/${level}/orderfulfillment${levelUrlParam}`}
          name='orderfulfillment'
          active={activeItem === 'orderfulfillment'}
          content='Order Fulfillment'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('orderfulfillment')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/credit${levelUrlParam}`}
          name='credit'
          active={activeItem === 'credit'}
          content='Credit'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('credit')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/specialtyprorental${levelUrlParam}`}
          name='specialtyprorental'
          active={activeItem === 'specialtyprorental'}
          content='Spec/Pro/Rental'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('specialtyprorental')
          }}
        />
      </Menu>
      <Menu fluid attached='bottom' widths={3} color='orange'>
        <Menu.Item
          as={Link} to={`/${level}/shrink${levelUrlParam}`}
          name='shrink'
          active={activeItem.includes('shrink')}
          content='Shrink'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('shrink')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/events${levelUrlParam}`}
          name='events'
          active={activeItem.includes('events')}
          content='Events'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('events')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/headlinesinsights${levelUrlParam}`}
          name='headlinesinsights'
          active={activeItem === 'headlinesinsights'}
          content='Headlines & Insights'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setActiveItem('headlinesinsights')
          }}
        />
      </Menu>

      <SearchInput
        active={activeItem}
        fpToggle={fpToggle}
        level={level}
        levelSelection={levelSelection}
        setActiveItem={setActiveItem}
        setLevel={setLevel}
        setLevelSelection={setLevelSelection}
        setStoreNumber={setStoreNumber}
        setStoreNumberField={setStoreNumberField}
        storeNumberField={storeNumberField}
      />
      {/* <Scanner storeNumber={storeNumber} setStoreNumber={setStoreNumber} storeNumberField={storeNumberField} /> */}
      <ScannerV2 storeNumber={storeNumber} setStoreNumber={setStoreNumber} storeNumberField={storeNumberField} />
    </Segment>
  )
}
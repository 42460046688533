import { getBpsString } from '../../../Common/TableCellUtils';

const CATEGORIES = {
  Consumer: 'CONS',
  Commercial: 'COMM',
  Total: 'CRED'
};

export const usageApplicationsBuilder = (data = [], timeframe) => {
  const [item] = data;

  return Object.entries(CATEGORIES).map(([key, value]) => {
    const CATEGORY_NAME = key;
    const CREDIT_SALES = item[`${timeframe}_${value}_SLS_AMT`] || 0;
    const TOTAL_SALES = item[`${timeframe}_TOT_SLS_AMT`] || 0;
    const LY_TOTAL_SALES = item[`${timeframe}_LY_TOT_SLS_AMT`] || 0;
    const TOTAL_SALES_PLANNED = item[`${timeframe}_TOT_SLS_PLN`] || 0;
    const USAGE = item[`${timeframe}_${value}_SLS_AMT`] / TOTAL_SALES || 0;

    let BPS_VLY = 0;
    let BPS_VPLAN = 0;
    switch (CATEGORY_NAME) {
      case 'Consumer':
        BPS_VLY = USAGE - item[`${timeframe}_LY_CONS_SLS_AMT`] / LY_TOTAL_SALES;
        BPS_VPLAN = USAGE - item[`${timeframe}_CONS_CRED_SLS_PLN`] / TOTAL_SALES_PLANNED;
        break;
      case 'Commercial':
        BPS_VLY = USAGE - item[`${timeframe}_LY_COMM_SLS_AMT`] / LY_TOTAL_SALES;
        BPS_VPLAN = USAGE - item[`${timeframe}_COMM_CRED_SLS_PLN`] / TOTAL_SALES_PLANNED;
        break;
      default:
        BPS_VLY = USAGE - item[`${timeframe}_LY_CRED_SLS_AMT`] / LY_TOTAL_SALES;
        BPS_VPLAN = USAGE - item[`${timeframe}_TOT_CRED_SLS_PLN`] / TOTAL_SALES_PLANNED;
        break;
    }

    return [
      { stringValue: CATEGORY_NAME },
      { stringValue: Number(CREDIT_SALES.toFixed(0)).toLocaleString() },
      { decimals: 2, pctValue: USAGE },
      { compareStyle: true, stringValue: getBpsString(BPS_VPLAN) },
      { compareStyle: true, stringValue: getBpsString(BPS_VLY) }
    ];
  });
};

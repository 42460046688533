import { createContext } from 'react';

import { useQueryParams } from '../hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import { OverviewMenu } from '../components/Overviews/Region/OverviewMenu';

export const OverviewContext = createContext({
  metric: 'sales',
  setMetric: () => {}
});

const OverviewContextProvider = ({ fpToggle, levelName, metric, setMetric, ...props }) => {
  const { rgnNbr, divNbr } = useQueryParams();
  const queryParam = rgnNbr || divNbr;
  const { level } = useParams();

  const value = {
    ...props,
    metric,
    setMetric,
    rgnNbr,
    fpToggle,
    level,
    divNbr,
    levelName,
    queryParam
  };

  return (
    <OverviewContext.Provider value={value}>
      <OverviewMenu />
    </OverviewContext.Provider>
  );
};

export default OverviewContextProvider;

import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { DataView } from '../../../../../../../components/Common/DataView';

export const WillCallComplete = () => {
  const { assocCompletePickupData, timeframe, settimeframe } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const showCustomTimeframe = [];

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'Units Unfulfilled' }, { name: '$ Unfulfilled' }],
          data: assocCompletePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.UNFULFILLED_NOM },
            {
              dollarValue: data.UNFULFILLED_AMOUNT,
              prefix: '$ '
            }
          ])
        };
      case 'Cancellations':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Cancel Date' },
            { name: 'SKU #' },
            { name: 'Cancel Qty' },
            { name: 'Reason Code' }
          ],
          data: assocCompletePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.WC_ORDER_NOM },
            { numValue: data.BOPIS_CANCELED_DATE },
            { numValue: data.SKU },
            { numValue: data.CANCEL_QTY },
            { numValue: data.REASON_CODE }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='my-4'>
      <DataView.Title>WILL CALL: Associate Details</DataView.Title>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: 'Cancellations', text: 'Cancellations', value: 'Cancellations' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
};

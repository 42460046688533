import { DataView } from '../../../../../../components/Common/DataView';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';
import { buildBaseDrillLink } from '../../../../../../components/ShrinkContainer/utils/url';
import { getCompareStyle } from '../../../../../../components/Common/TableCellUtils';
import { getRowLevelNm } from "../../../../../../utils/getRowLevelNm";

export const UnmatchedXfersTable = ({ unmatchedXfersData, timeframe }) => {
  const level = 'core';
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const url = `/${baseLevelShrinkUrl}/unmatchedxfers`;
  const dataObj = {
    sortable: true,
    sortableColumns: [0, 1],
    sortableDefault: 0,
    headers: [{ name: 'Division # ' }, { name: 'Value ' }, { name: 'Goal ' }],
    data: unmatchedXfersData.map(data => [
      {
        stringValue: getRowLevelNm({row: data, level}),
        link: {
          to: url,
          params: {
            [METRIC_CONSTANTS[level].urlSubLevelParam]: data[METRIC_CONSTANTS[level].propToRender]
          }
        }
      },
      {
        stringValue:
          data[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] > 0
            ? data[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT']
            : 0,
        customStyle: getCompareStyle(data[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'])
      },
      {
        stringValue: '0'
      }
    ])
  };

  return <DataView.DataTable data={dataObj} />;
};

import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { formatNumberWithCommas } from '../../../../../../../utils/formatter';

export const WillCall = (props) => {
  const { associate, missedSLA } = props
  const { assocOnTimePickupData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const [timeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['# Missed SLA']
  const missedSLAFiltered = missedSLA.filter(row => row[timeframe + '_FLG'] > 0)

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate' },
            { name: 'Pick On Time %' },
            { name: ' # of Orders Missed ' },
          ],
          data: associate.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: formatNumberWithCommas(data.PICK_ON_TIME) },
            { numValue: formatNumberWithCommas(data.MISSED_ORDERS) },
          ])
        };
      case '# Missed SLA':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Order Date' },
            { name: 'Mins To Pick' },
          ],
          data: missedSLAFiltered.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: data.ORDER_NUMBER },
            { numValue: String(data.ORDER_DATE).split('T')[0] },
            { numValue: formatNumberWithCommas(data.AVG_MINS_TO_PICK) },
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        }
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' },
        ]}
      />
      <DataTable
        data={getData()}
      />
    </>
  );
};

import { useContext, useState } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { Dropdown } from 'semantic-ui-react';
import { completeDeliveryData, selectTruckTypeOptions } from '../../../../utils/completeDelivery';
import { TOTAL } from '../../../../utils/consts';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';

export const CompleteDelivery = () => {
  const { level, timeframe, locNbr } = useContext(OrderFulfillmentContext);
  const formatQueryKeys = ['query/queryDeliveryComplete/', 'query/queryCompleteTotalDelivery/'].map(query => query + `${level}/${locNbr}`)
  const [queryDeliveryComplete, completeTotalDelivery] = useQueryKeys(formatQueryKeys)
  const [view, setView] = useState(TOTAL);
  const dataFilter = view !== TOTAL ? queryDeliveryComplete.filter(row => row.TRUCK_TYPE === String(view).toUpperCase()) : completeTotalDelivery
  const data = completeDeliveryData(view, level, dataFilter, timeframe);

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <p className='my-0'>Select Truck Type</p>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={selectTruckTypeOptions}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Delivery Details by {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
};

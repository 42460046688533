import React, { useContext } from 'react';
import { Tab } from 'semantic-ui-react';
import { Boss } from './assoc/Boss';
import { WillCall } from './assoc/WillCall';
import { BopisAssoc } from './assoc/BopisAssoc';
import { Curbside } from './assoc/Curbside';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';

export const OnTimePickupAssoc = () => {

    const { strNbr } = useContext(StoreOrderFulfillmentContext)
    // nonCompliance used for Non-Compliance Orders
    // associateView used for associateView

    const [nonCompliance, associateView, queryMissedSLA] = useQueryKeys(['storeNonComplianceView', `query/queryAssociateView/store/${strNbr}`, `query/queryMissedSLA/store/${strNbr}`])
    const nonComplianceFiltered = nonCompliance.filter(row => row['LW_FLG'] > 0 || row['WTD_FLG'] > 0 || row['MTD_FLG'] > 0)

    const propsToRender = (tab) => ({
        associate: associateView.filter(row => row.CHANNEL === tab),
        nonCompliance: nonComplianceFiltered.filter(row => row.ORDER_TYPE === tab),
        missedSLA: queryMissedSLA.filter(row => row.CHANNEL === tab)
    })
    const tabs = [
        {
            menuItem: 'BOPIS',
            render: () => <BopisAssoc {...propsToRender('BOPIS')} />
        },
        {
            menuItem: 'BOSS',
            render: () => <Boss {...propsToRender('BOSS')} />
        },
        {
            menuItem: 'WILL CALL',
            render: () => <WillCall {...propsToRender('WILL CALL')} />
        },
        {
            menuItem: 'CURBSIDE',
            render: () => <Curbside />
        }
    ]
    return (
        <Tab
            menu={{ compact: true, size: 'small' }}
            panes={tabs}
        />
    );
};

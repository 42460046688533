import { DataView } from '../Common/DataView';
import { TabMenu } from '../Common/TabMenu';
import { DirTasksSection } from './DirTasksSection';
import { capitalizeFirstLetter } from '../../utils/string';
import { buildSublevelLinkColumn } from '../../utils/dataTable';

const getMgrAddHeader = ({ subLevelName }) => [
  { name: `${capitalizeFirstLetter(subLevelName)}s ` },
  { name: 'Ttl Tasks Comp #' },
  { name: 'Ttl Tasks Added #' },
  { name: 'Ttl Tasks Comp %' }
];

const getMgrAddData = ({ directedTaskSummary, level, subLevelName }) => ({
  headers: getMgrAddHeader({ subLevelName }),
  data: Object.entries(directedTaskSummary).map(([_, data]) => {
    return [
      {
        ...buildSublevelLinkColumn({ level, row: data, path: `/osa/sidekickskubay/${subLevelName}` })
      },

      { stringValue: data.mgrComp.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: data.mgrSent.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { pctValue: data.mgrComp / data.mgrSent, decimals: 1 }
    ];
  }),
  sortable: true,
  sortableDefault: 0
});

const getLoggedWkHeader = ({ subLevelName, ltf }) => [
  { name: `${capitalizeFirstLetter(subLevelName)}s ` },
  { name: 'Logged SKUs #' },
  { name: ltf ? `v${ltf} SKU#` : '-' }
];

const getLoggedData = ({ directedTaskSummary, level, subLevelName, ltf }) => ({
  headers: getLoggedWkHeader({ subLevelName, ltf }),
  data: Object.entries(directedTaskSummary).map(([_, data]) => {
    return [
      {
        ...buildSublevelLinkColumn({ level, row: data, path: `/osa/sidekickskubay/${subLevelName}` })
      },
      { stringValue: data.logSkus.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      {
        stringValue: (data.logSkus - data.logSkusLTF).toLocaleString(undefined, { maximumFractionDigits: 0 }),
        compareStyle: true
      }
    ];
  }),
  sortable: true,
  sortableDefault: 0
});

const getPanes = ({ directedTaskSummary, level, subLevelName, subLevelUrlParam, ltf }) => {
  const mgrAddData = getMgrAddData({ directedTaskSummary, level, subLevelName, subLevelUrlParam });
  const loggedData = getLoggedData({ directedTaskSummary, level, subLevelName, subLevelUrlParam, ltf });

  return [
    {
      menuItem: 'DIR TASKS',
      render: () => (
        <DirTasksSection
          directedTaskSummary={directedTaskSummary}
          level={level}
          subLevelName={subLevelName}
          subLevelUrlParam={subLevelUrlParam}
        />
      )
    },
    {
      menuItem: 'MGR ADD',
      render: () => <DataView.DataTable data={mgrAddData} />
    },
    {
      menuItem: 'LOGGED WK',
      render: () => <DataView.DataTable data={loggedData} />
    }
  ];
};

export const SidekickSkuBayTasksCompletion = props => {
  const { directedTaskSummary, level, subLevelName, subLevelUrlParam, ltf } = props;
  const panes = getPanes({ directedTaskSummary, level, subLevelName, subLevelUrlParam, ltf });

  return (
    <DataView className='mt-3'>
      <DataView.Title>Tasks Completion</DataView.Title>

      <TabMenu panes={panes} />
    </DataView>
  );
};

import { DataView } from '../../Common/DataView';
import { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { headers, prokeyMetricsDataRows, prokeyMetricsFooterRow } from '../utils/specialtyProRentalProKeyMetrics';
import { STORE } from '../../../utils/constantsByLevel';

const { levelName: storeLevelName } = STORE;

export const SpecialtyProRentalProKeyMetrics = ({ queryProOpsPlusOneData = [], proOpsData = [] }) => {
  const { level, timeframe } = useSpecialtyProRentalContext();
  const { levelName } = level;
  const dataFiltered = useMemo(
    () => queryProOpsPlusOneData.filter(row => row[level.propToRender]),
    [queryProOpsPlusOneData, level]
  );

  const footer = proOpsData[0];

  if (!queryProOpsPlusOneData.length || !footer)
    return <Header textAlign='center'>No data to show in Pro Key Metrics.</Header>;

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers: headers({ level }),
    footer: levelName !== storeLevelName ? [prokeyMetricsFooterRow(footer, timeframe)] : [],
    data: dataFiltered.map(row => prokeyMetricsDataRows(row, timeframe, level))
  };

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Pro Key Metrics</DataView.Title>

      <DataView.DataTable data={data} />
    </DataView>
  );
};

import { formatNumberWithCommas } from "../../../utils/formatter"
import { divide } from "../../../utils/numbers"

export const onTimePickUpBuilder = ({ bodyRows, totalRow, timeframe }) => {
  return {
    data: bodyRows.sort((a, b) => a.CHANNEL > b.CHANNEL ? 1 : -1).map(data => [
      {
        stringValue: data.CHANNEL + " ",
        popup: (data.CHANNEL === 'WILL CALL')
          ? {
            content: 'Will Call includes Stock Will Call or In-Store Stock items only.',
            icon: 'info circle circleInfoButton',
            color: 'blue' //Semantic-UI doesn't have Circle Info Icon without background
          } : undefined
      },
      {
        pctValue: divide(data[timeframe + '_ON_TIME_NUM'], data[timeframe + '_ON_TIME_DEN'])
      },
      { numValue: formatNumberWithCommas(data[timeframe + '_MISS_SLA_COUNT']) },
      {
        numValue: data.CHANNEL !== 'WILL CALL' ? formatNumberWithCommas(Math.round(data[timeframe + '_AVG_MINS_TO_PICK'])) : '--'

      },
      { pctValue: data.CHANNEL !== 'WILL CALL' ? divide(data[timeframe + '_DS_COMPLIANCE_NUM'], data[timeframe + '_DS_COMPLIANCE_DEN']) : '--' } // TODO: Get the real data for this
    ]),
    footer: totalRow.map(data => [
      {
        stringValue: data.CHANNEL
      },
      {
        pctValue: divide(data[timeframe + '_ON_TIME_NUM'], data[timeframe + '_ON_TIME_DEN'])
      },
      { numValue: formatNumberWithCommas(data[timeframe + '_MISS_SLA_COUNT']) },
      {
        numValue: formatNumberWithCommas(Math.round(data[timeframe + '_AVG_MINS_TO_PICK']))

      },
      { pctValue: divide(data[timeframe + '_DS_COMPLIANCE_NUM'], data[timeframe + '_DS_COMPLIANCE_DEN']) }
    ])
  }
}
import { Header, Loader, Segment } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { OSA_OHM_NO_LOC_TAGS_GOAL } from '../../constants/goals';
import { getLastTimeframe, getParamByLevel } from '../../utils/timeFrameUtils';
import useQueryApi from '../../hooks/useQueryApi';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../utils/string';
import { buildSublevelLinkColumn } from '../../utils/dataTable';
import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';
import { getOHMNoLocationTagsBreadcrumbItems } from '../../utils/breadcrumbItems';

export const OHMNoLocationTagsLevel = ({ timeframe, settimeframe, level, fpToggle, locNbr, levelName }) => {
  const {
    data: ohmNoLocationTagsData,
    isError,
    isLoading
  } = useQueryApi(`query/osaOHMNoLocTagsPlusOne/${level}`, { locNbr, fpToggle });
  const capitalizeSubLevel = capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel);

  if (isError) return <p>Something went wrong.</p>;

  if (isLoading) return <Loader active>Loading</Loader>;

  if (!ohmNoLocationTagsData || ohmNoLocationTagsData.length === 0)
    return <Header textAlign='center'>Invalid Store Number</Header>;

  const tranformDate = ohmNoLocationTagsData[0].TRNSFM_DT;

  const overviewDstFilter = ohmNoLocationTagsData.filter(row => {
    return row['METRIC_LEVEL'] === 'DST'
      ? row['METRIC_LEVEL'] === 'DST'
      : row['METRIC_LEVEL'] === 'RGN'
        ? row['METRIC_LEVEL'] === 'RGN'
        : null;
  });

  const ltf = getLastTimeframe(timeframe);

  const ohmNoLocationFiltered = overviewDstFilter.reduce((acc, curr) => {
    const item = acc.find(item => (level === 'region' ? item.DST_NBR === curr.DST_NBR : item.RGN_NBR === curr.RGN_NBR));
    if (item) {
      item[METRIC_CONSTANTS[level]['propToRender']] = curr[METRIC_CONSTANTS[level]['propToRender']];
      item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT'];
      item[timeframe + '_TOTAL_PALLET_COUNT'] += curr[timeframe + '_TOTAL_PALLET_COUNT'];
      item.ACTUAL = item[timeframe + '_NO_LOC_PALLET_COUNT'] / item[timeframe + '_TOTAL_PALLET_COUNT'];
      item[ltf + '_LY_NO_LOC_PALLET_COUNT'] += curr[ltf + '_LY_NO_LOC_PALLET_COUNT'];
      item[ltf + '_LY_TOTAL_PALLET_COUNT'] += curr[ltf + '_LY_TOTAL_PALLET_COUNT'];
      item.BPS =
        item[ltf + '_LY_TOTAL_PALLET_COUNT'] !== 0
          ? item[ltf + '_LY_NO_LOC_PALLET_COUNT'] / item[ltf + '_LY_TOTAL_PALLET_COUNT']
          : '-';
    } else {
      acc.push({
        RGN_NM: curr.RGN_NM,
        DST_NM: curr.DST_NM,
        DST_NBR: curr.DST_NBR,
        RGN_NBR: curr.RGN_NBR,
        [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
        [timeframe + '_TOTAL_PALLET_COUNT']: curr[timeframe + '_TOTAL_PALLET_COUNT'],
        ACTUAL: curr[timeframe + '_NO_LOC_PALLET_COUNT'] / curr[timeframe + '_TOTAL_PALLET_COUNT'],
        [ltf + '_LY_NO_LOC_PALLET_COUNT']: curr[ltf + '_LY_NO_LOC_PALLET_COUNT'],
        [ltf + '_LY_TOTAL_PALLET_COUNT']: curr[ltf + '_LY_TOTAL_PALLET_COUNT'],
        BPS:
          curr[ltf + '_LY_TOTAL_PALLET_COUNT'] !== 0
            ? curr[ltf + '_LY_NO_LOC_PALLET_COUNT'] / curr[ltf + '_LY_TOTAL_PALLET_COUNT']
            : '-'
      });
    }
    return acc;
  }, []);

  const getOHMNoLocationCompareStyle = value => ({ color: value > OSA_OHM_NO_LOC_TAGS_GOAL ? 'red' : 'green' });

  const getBPSValue = (actual, bps) => {
    const result = {
      value:
        bps === '-' || isNaN(bps) ? '-' : ((actual - bps) * 10000).toLocaleString(undefined, { maximumFractionDigits: 0 })
    };
    return result.value;
  };

  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending',
    pagination: true,
    headers: [
      { name: `${capitalizeSubLevel} ` },
      { name: 'Actual ' },
      { name: 'Goal ' },
      { name: ltf === null ? 'v bps ' : `v${ltf} bps ` }
    ],
    data: ohmNoLocationFiltered.map(row => [
      { ...buildSublevelLinkColumn({ level, row, path: `/osa/ohmnoloc/${METRIC_CONSTANTS[level]['urlLinkLevel']}` }) },
      {
        pctValue: row['ACTUAL'],
        decimals: 1,
        customStyle: getOHMNoLocationCompareStyle(row['ACTUAL'])
      },
      { pctValue: OSA_OHM_NO_LOC_TAGS_GOAL },
      { stringValue: getBPSValue(row['ACTUAL'], row['BPS']) }
    ])
  };

  const urlParam = getParamByLevel(level);
  const breadcrumbItems = getOHMNoLocationTagsBreadcrumbItems({ level, urlParam, locNbr });

  return (
    <Segment>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-3'>
        <DataView.Title>
          OHM No Loc Tags % - {levelName}
          {tranformDate && <Header.Subheader>Data as of {tranformDate}</Header.Subheader>}
        </DataView.Title>

        <DataView.Controls className='d-flex flex-column align-items-center'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>

        <DataTable data={dataObj} />
      </DataView>
    </Segment>
  );
};

import { DataView } from '../../Common/DataView';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { getQuotesDataTable } from '../utils/specialtyProRental';
import { STORE } from '../../../utils/constantsByLevel';

const { levelName: storeLevelName } = STORE;

export const Quotes = props => {
  const { quotesData } = props;
  const { level, timeframe } = useSpecialtyProRentalContext();
  const { levelName } = level;
  const quotesDataTable = getQuotesDataTable({ level, timeframe, quotesData });

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Quotes</DataView.Title>

      <DataView.DataTable data={quotesDataTable} />

      <>
        {levelName === storeLevelName && (
          <p className='mt-2'>
            * Sold % is calculated as the total number of quotes sold divided by all quotes in all statuses (Open. Sold,
            Cancelled).
          </p>
        )}
      </>
    </DataView>
  );
};

import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { formatNumberWithCommas } from '../../../../../../../utils/formatter';

export const Boss = (props) => {
  const { associate, nonCompliance, missedSLA } = props
  const { assocOnTimePickupData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const [timeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['# Missed SLA', 'Non-Compliant Orders'];
  const nonComplianceFiltered = nonCompliance.filter(row => row[timeframe + '_FLG'] > 0)
  const missedSLAFiltered = missedSLA.filter(row => row[timeframe + '_FLG'] > 0)

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate' },
            { name: 'Pick On Time %' },
            { name: '# Orders Missed' },
            { name: 'Avg Mins to Pick' },
            { name: 'DS Compliance %' }
          ],
          data: associate.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: formatNumberWithCommas(data.PICK_ON_TIME) },
            { numValue: formatNumberWithCommas(data.MISSED_ORDERS) },
            { numValue: formatNumberWithCommas(data.AVG_MINS_TO_PICK) },
            { pctValue: data?.DS_COMPLIANCE }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case '# Missed SLA':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'Order #' }, { name: 'Order Date' }, { name: 'Mins To Pick' }],
          data: missedSLAFiltered.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: data.ORDER_NUMBER },
            { numValue: String(data.ORDER_DATE).split('T')[0] },
            { numValue: formatNumberWithCommas(data.AVG_MINS_TO_PICK) },
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case 'Non-Compliant Orders':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Order #' },
            { name: 'Order Date' },
            { name: 'Directed Location' },
            { name: 'Staged Location' }
          ],
          data: nonComplianceFiltered.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: data.EXTN_HOST_ORDER_REF },
            { numValue: data.CAL_DT },
            { numValue: data.DIRECTED_LOCATION },
            { numValue: data.STAGED_LOCATION },
          ])
        };
      case 'DS Compliance':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'DS Compliance %' }, { name: '# Non-Compliance Orders' }],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { pctValue: data.DS_COMPLIANCE_PCT },
            { numValue: data.NO_ORDER_COMPLIANCE_NOM }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' },
          { key: 'Non-Compliant Orders', text: 'Non-Compliant Orders', value: 'Non-Compliant Orders' },
          { key: 'DS Compliance', text: 'DS Compliance', value: 'DS Compliance' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataTable data={getData()} />
    </>
  );
};

import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { buildBaseShrinkUrl, buildBaseDrillLink } from './url';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getPercentString } from '../../Common/TableCellUtils';
import { BOSS_CNCL_NUM, BOSS_CNCL_DEN } from '../utils/constants';
import { getRowLevelNm, getRowSubLevelNm } from '../../../utils/getRowLevelNm';

export const shrinkMonitorMetricsDrillHeaders = level => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} # ` },
  { name: 'Value' }
];

export const shrinkMonitorMetricsDrillBreadcrumbItems = (level, locNbr, title) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  return [
    { to: `/${baseLevelShrinkUrl}?${METRIC_CONSTANTS[level].urlParam}=${locNbr}`, text: 'Back to Shrink Overview' },
    { text: title }
  ];
};

export const shrinkRtvDeletedTagsRows = (metricsScoreData, metricsData, timeframe, level) => {
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const previousLocNbr = metricsScoreData[METRIC_CONSTANTS[level].propToRender];
  return [
    {
      stringValue: previousLocNbr,
      link: {
        to: `/${baseLevelShrinkUrl}/rtvdeletedtag`,
        params: {
          [METRIC_CONSTANTS[level].urlSubLevelParam]: previousLocNbr
        }
      }
    },
    {
      decimals: 2,
      pctValue: metricsData[previousLocNbr][timeframe + '_RTV_DEL_NUM'] / metricsData[previousLocNbr][timeframe + '_RTV_DEL_DEN']
    }
  ];
};

export const shrinkReducedTagsRows = (metricsScoreData, metricsData, timeframe, level) => {
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const previousLocNbr = metricsScoreData[METRIC_CONSTANTS[level].propToRender];

  let sublevelNm = getRowSubLevelNm({row: metricsScoreData, level, previousLocNbr});
  if (level === 'region') {
    sublevelNm = `DST ${previousLocNbr}`;
  }

  return [
    {
      stringValue: sublevelNm,
      link: {
        to: `/${baseLevelShrinkUrl}/reducedtag`,
        params: {
          [METRIC_CONSTANTS[level].urlSubLevelParam]: previousLocNbr
        }
      }
    },
    {
      stringValue: getPercentString(
        metricsData[previousLocNbr][timeframe + '_REDUCED_TAG_NUM'] /
        metricsData[previousLocNbr][timeframe + '_CORE_SLS'],
        2
      )
    }
  ];
};

export const shrinkMonitorMetricsDataFiltered = (data, level, timeframe) =>
  data.filter(row => row && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level].subMetric && row['TIME_FRAME'] === timeframe);

export const shrinkMonitorMetricsDataReduced = (data, level) =>
  data.reduce((acc, row) => {
    if (acc[METRIC_CONSTANTS[level].propToRender]) return acc;
    return { ...acc, [row[METRIC_CONSTANTS[level].propToRender]]: row };
  }, {});

// Shrink Boss Cancel

const getShrinkBossCancelData = ({ shrinkMetricsByLevelData, level, timeframe }) => {
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const { currentLevel: subCurrentLevel, urlParam: subUrlParam } = METRIC_CONSTANTS[level].subLevel;

  return shrinkMetricsByLevelData.map(row => {
    const subLevelNbr = row[subCurrentLevel];
    const bossCnclNum = row[`${timeframe}_${BOSS_CNCL_NUM}`];
    const bossCnclDen = row[`${timeframe}_${BOSS_CNCL_DEN}`];
    const value = getPercentString((bossCnclNum / bossCnclDen), 2);
    const valueOrderBy = bossCnclNum / bossCnclDen;
    return [
      {
        stringValue: level === 'core' ? getRowLevelNm({row, level}) : getRowSubLevelNm({row, level}),
        orderBy: subLevelNbr,
        link: {
          to: `/${baseLevelShrinkUrl}/bosscancel`,
          params: {
            [subUrlParam]: subLevelNbr
          }
        }
      },
      {
        stringValue: value,
        orderBy: valueOrderBy
      }
    ];
  });
};

export const getShrinkBossCancelDataTable = ({ level, shrinkMetricsByLevelData, timeframe }) => ({
  headers: shrinkMonitorMetricsDrillHeaders(level),
  data: getShrinkBossCancelData({ shrinkMetricsByLevelData, level, timeframe }),
  sortable: true,
  sortableDefault: 0
});
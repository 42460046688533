import { Ltsa } from '../components/Ltsa';
import { VocSummary } from '../components/VocSummary';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { Grid, Header, Loader, Segment } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';

const { core: level } = METRIC_CONSTANTS;

export const LtsaPage = ({ timeframe, settimeframe, fpToggle }) => {
  const vocSummary = useQueryApi('queryVocSummaryCore', { isCore: true, fpToggle });
  const ltsaSummary = useQueryApi('queryLtsaSummaryCore', { isCore: true, fpToggle });

  if (vocSummary.isLoading || ltsaSummary.isLoading) return <Loader active>Loading...</Loader>;
  if (vocSummary.isError || ltsaSummary.isError)
    return <Header textAlign='center'>Something went wrong fetching data.</Header>;

  return (
    <Segment fluid>
      <Header textAlign='left' style={{ marginTop: '1rem' }}>
        Overview - GET / LTSA - Core
        <Header.Subheader>Data as of {vocSummary.data[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            Timeframe:
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <VocSummary data={vocSummary.data} timeframe={timeframe} />
      <Ltsa level={level} data={ltsaSummary.data} timeframe={timeframe} />
    </Segment>
  );
};

import React from 'react';
import OrderFulfillmentContextProvider from '../../../context/OrderFulfillmentContext';
import { Header, Tab, Segment } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import Complete from '../components/complete/Complete';
import OnTime from '../components/ontime/OnTime';
import Summary from '../components/Summary';
import { useFetchLevelName } from '../../../../../hooks/useFetchLevelName';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { Loader } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { NotFoundPage } from '../../notFound/pages/NotFoundPage';
import { useEnhancedQueries } from '../../../../../hooks/useEnhancedQueries';

export const OrderFulfillmentPage = ({ timeframe, settimeframe, fpToggle, level, unit, setUnit, ...props }) => {
  const { dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const queries = [
    'queryOnTimeDelivery',
    'queryOnTimeTotalDelivery',
    'orderSummaryByLvl',
    'queryDeliveryComplete',
    'queryCompleteTotalDelivery',
    'queryOnTimePickUp',
    'queryOnTimeTotalPickUp',
    'queryPickUpCurbside'
  ]
  const queryResults = useEnhancedQueries(queries, fpToggle)
  const locNbr = dstNbr || rgnNbr || divNbr || '0000';
  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, level, locNbr });
  const isUSD = true;
  const {
    results: [{ data: sumData = [] }]
  } = useEnhancedQueries(['orderSummaryByLvl'], fpToggle);

  if (isLoadingName || queryResults.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  if (queryResults.isError) {
    return <NotFoundPage />
  }

  const date = sumData[0]?.TRNSFM_DT || '2021-01-01';

  const panes = [
    {
      menuItem: 'ON TIME',
      render: () => <OnTime />
    },
    {
      menuItem: 'COMPLETE',
      render: () => <Complete />
    }
  ];

  const propsToShare = {    
    level,
    timeframe,
    unit,
    fpToggle,
    ...queryResults,
    ...props
  };

  const title = level === 'core' ? 'Core' : `${level} ${locNbr}, ${currentLevelName.toLowerCase()}`;

  return (
    <OrderFulfillmentContextProvider {...propsToShare}>
      <Segment fluid>
        <DataView className='my-3'>
          <DataView.Title>
            Order Fulfillment - {title}
            <Header.Subheader>Data as of {date}</Header.Subheader>
          </DataView.Title>

          <DataView.Controls className='ui grid column  my-3'>
            <div className='column eight wide'>
              <p className='my-0'>Unit:</p>
              <UnitMenu unit={unit} setUnit={setUnit} isUSD={isUSD} />
            </div>
            <div className='column eight wide'>
              <p className='my-0'>Timeframe:</p>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </div>
          </DataView.Controls>

          <Summary />

          <Tab menu={{ secondary: true, pointing: true, color: 'orange' }} panes={panes} />
        </DataView>
      </Segment>
    </OrderFulfillmentContextProvider>
  );
};

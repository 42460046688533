import { Loader } from 'semantic-ui-react';
import { DaysSafe } from '../../components/safety/DaysSafe';
import { SafetySummary } from '../../components/safety/SafetySummary';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';

const { core: level } = METRIC_CONSTANTS;

export const CoreSafety = ({ fpToggle, settimeframe, timeframe }) => {
  const { data: daysSafeData = [], isLoading } = useQueryApi('querySafetyIncOshaLtGlbiByCore', {
    fpToggle,
    isCore: true
  });
  const { data: safetySummary = [], isLoading: isLoadingSafetySummary } = useQueryApi(
    'querySafetyIncOshaLtGlbiByCoreForSafetySummary',
    {
      fpToggle,
      isCore: true
    }
  );

  if (isLoading || isLoadingSafetySummary) return <Loader active>Loading...</Loader>;

  return (
    <>
      <DaysSafe level={level} daysSafeData={daysSafeData} />
      <SafetySummary level={level} settimeframe={settimeframe} timeframe={timeframe} safetySummary={safetySummary} />
    </>
  );
};

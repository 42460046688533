import { useState } from 'react'
import { DataTable } from '../../../../../../components/Common/DataTable'
import { CustomDropDown } from '../CustomDropdown';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';

export default function OnTimeDeliveryMissed() {
  const [timeframe, settimeframe] = useState('WTD');
  const [storeOnTimeDlvMissed] = useQueryKeys([`storeOnTimeDlvMissed`])

  const filterByTimeframe = storeOnTimeDlvMissed.filter(row => row[timeframe + '_FLG'] > 0)
  
  const dataObj = {
    headers: [
      { name: 'Truck Type' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Delivery Miss Bucket' },
      { name: 'Miss Code' },
      { name: 'Miss Date' },
    ],
    data: filterByTimeframe.map(data => [
      { stringValue: data.TRUCK_TYPE },
      { numValue: data.CUST_ORD_NBR },
      { numValue: data.WORK_ORDER_NO },
      { stringValue: data.MISS_BUCKET },
      { stringValue: data.REASON },
      { stringValue: data.FULFILL_DATE },
    ]),
    pagination: true,
    sortable: true
  };

  return (
    <>
      <CustomDropDown
        view='ShowDropDown'
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'LM', text: 'LM', value: 'LM' },
          { key: 'MTD', text: 'MTD', value: 'MTD' },
        ]}
        showCustomTimeframe={['ShowDropDown']}
      />
      <DataTable data={dataObj} />
    </>
  );
}

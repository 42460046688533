import { METRIC_CONSTANTS } from "../../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../../utils/string";
import { getCompareStyle } from "../../../Common/TableCellUtils";

const BUILD_ROWS_DEFAULT_PARAMS = { data: [], isCommercial: false, isConsumer: false, isTotal: false }
const BUILD_HEADERS_DEFAULT_PARAMS = { isTotal: false }

export const buildHeaders = ({ isTotal, level } = BUILD_HEADERS_DEFAULT_PARAMS) => {
  const baseHeaders = [{ name: capitalizeFirstLetter(level) }, { name: 'Apps' }, { name: '% vGoal' }];
  if (isTotal) return [...baseHeaders, { name: 'App Efficiency' }];

  return baseHeaders;
};

export const buildLinkColumn = (row, level) => {
  const { subLevel } = METRIC_CONSTANTS[level] || METRIC_CONSTANTS.core;
  const { currentLevel: levelMetric, levelName, METRIC_LEVEL, urlParam } = subLevel;

  return {
    link: {
      to: `/${levelName}/credit`,
      params: {
        [urlParam]: row[levelMetric]
      }
    },
    stringValue: `${METRIC_LEVEL} ${row[levelMetric]}`,
    orderBy: row[levelMetric]
  }
}

export const buildRows = ({ data, level, isCommercial, isConsumer, isTotal, timeframe } = BUILD_ROWS_DEFAULT_PARAMS) => {
  if (isTotal) return buildTotalRows(data, level, timeframe)
  if (isConsumer) return buildConsumerRows(data, level, timeframe)
  if (isCommercial) return buildCommercialRows(data, level, timeframe)
}

const buildCommercialRows = (data, level, timeframe) => {
  return data.map(row => {
    const vGoalValue = row[timeframe + '_COMM_APPS'] / row[timeframe + '_COMM_APP_GOAL'] - 1

    return [
      {
        ...buildLinkColumn(row, level),
      },
      {
        orderBy: row[timeframe + '_COMM_APPS'],
        stringValue: row[timeframe + '_COMM_APPS']
      },
      {
        customStyle: getCompareStyle(vGoalValue),
        orderBy: vGoalValue,
        pctValue: vGoalValue,
      },
    ]
  })
}

const buildConsumerRows = (data, level, timeframe) => {
  return data.map(row => {
    const vGoalValue = row[timeframe + '_CONS_APPS'] / row[timeframe + '_CONS_APP_GOAL'] - 1

    return [
      {
        ...buildLinkColumn(row, level),
      },
      {
        orderBy: row[timeframe + '_CONS_APPS'],
        stringValue: row[timeframe + '_CONS_APPS']
      },
      {
        customStyle: getCompareStyle(vGoalValue),
        orderBy: vGoalValue,
        pctValue: vGoalValue
      },
    ]
  })
}

const buildTotalRows = (data, level, timeframe) => {
  return data.map(row => {
    const vGoalValue = row[timeframe + '_TOT_APPS'] / row[timeframe + '_TOT_APP_GOAL'] - 1
    const appEfficiencyValue = row[timeframe + '_TXN_COUNT'] / row[timeframe + '_TOT_APPS']

    return [
      {
        ...buildLinkColumn(row, level),
      },
      {
        orderBy: row[timeframe + '_TOT_APPS'],
        stringValue: row[timeframe + '_TOT_APPS']
      },
      {
        customStyle: getCompareStyle(vGoalValue),
        orderBy: vGoalValue,
        pctValue: vGoalValue
      },
      {
        numValue: isFinite(appEfficiencyValue) ? appEfficiencyValue.toLocaleString(undefined, {
          maximumFractionDigits: 0
        }) : '--',
        orderBy: appEfficiencyValue
      }
    ]
  })
}

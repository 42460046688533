import { Tab } from 'semantic-ui-react';
import { ApplicationByLevelTab } from './ApplicationByLevelTab';

export const LevelList = () => {
  const panes = [
    {
      menuItem: 'Total',
      render: () => <ApplicationByLevelTab isTotal />
    },
    {
      menuItem: 'Consumer',
      render: () => <ApplicationByLevelTab isConsumer />
    },
    {
      menuItem: 'Commercial',
      render: () => <ApplicationByLevelTab isCommercial />
    }
  ];

  return (
    <Tab.Pane>
      <Tab panes={panes} />
    </Tab.Pane>
  );
};

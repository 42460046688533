// URL
export const SPEC_PRO_RENTAL_PATH = 'specialtyprorental';

// Quotes Table
export const OPEN_QUOTE_AMT = 'OPEN_QUOTE_AMT';
export const OPEN_QUOTE_CNT = 'OPEN_QUOTE_CNT';
export const QUOTE_SOLD_AMT = 'QUOTE_SOLD_AMT';
export const QUOTE_SOLD_CNT = 'QUOTE_SOLD_CNT';
export const TOT_QUOTE_CNT = 'TOT_QUOTE_CNT';

// Specialty Key Metrics Table

export const LEADS_VLY_PCT = 'LEADS_VLY_PCT';
export const MEAS_VLY_PCT = 'MEAS_VLY_PCT';
export const WALK_COMPLETE = 'WALK_COMPLETE';
export const TOTAL_WALK = 'TOTAL_WALK';
export const WALK_COMPLETE_PCT = 'WALK_COMPLETE_PCT';
export const YES_RESP_PCT = 'YES_RESP_PCT';
export const SPOT_CMPLT_PCT = 'SPOT_CMPLT_PCT';
export const CSU_CMPLT_PCT = 'CSU_CMPLT_PCT';
export const SPEC_OVR_GOAL = 'SPEC_OVR_GOAL';

//Specialty Key Metrics Cont Table
export const YES_RESP = 'YES_RESP';
export const TOT_RESP = 'TOT_RESP';
export const CNT_CMPLT = 'CNT_CMPLT';
export const CNT_ASSOCS = 'CNT_ASSOCS';
export const CSU_CNT_CMPLT = 'CSU_CNT_CMPLT';
export const CSU_CNT_ASSOCS = 'CSU_CNT_ASSOCS';
export const OVR_GOAL = 'OVR_GOAL';
export const TOT_ASSOC = 'TOT_ASSOC';

// Rental Key Metrics Table

export const DMG_WVR_PCT = 'DMG_WVR_PCT';
export const TTL_CAD_WO = 'TTL_CAD_WO';
export const CAD_NO_PARTS = 'CAD_NO_PARTS';
export const LTRA_PCT = 'LTRA_PCT';
export const GET_PCT = 'GET_PCT';
export const LTRA_GOAL = '0.85';
export const RENTAL_GET_GOAL = '0.85';

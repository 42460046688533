import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { DataTable } from '../../../Common/DataTable';
import { TimeframeDropdown } from '../../../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../../../Common/TimeframeDropdownMon';
import { ChannelAssociate } from '../../ChannelAssociate';
import { creditStorePageBuilder } from '../utils/creditStorePageBuilder';

export const CreditStorePage = ({
  locNbr,
  timeframe,
  settimeframe,
  creditPerfStrByStr,
  creditSlsPenStrByStr,
  creditAssocChnlByStr
}) => {
  const { applicationData, efficiencyData, usageData } = creditStorePageBuilder(
    creditPerfStrByStr,
    creditSlsPenStrByStr,
    timeframe
  );

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        Credit - Store #{locNbr}, {String(creditAssocChnlByStr[0]?.STR_NM).toLowerCase()}
        {/* TODO: replace dumb date with correct one */}
        <Header.Subheader>Data As Of {creditAssocChnlByStr[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>
      {/* Applications Summary */}
      <Header textAlign='center'>Applications</Header>
      <DataTable data={applicationData} />
      <p>Note: If % vGoal ≥ 0%, then the goal is met.</p>
      {/* Efficiency Summary */}
      <Header textAlign='center'>Efficiency</Header>
      <DataTable data={efficiencyData} />
      {/* Usage Summary */}
      <Header textAlign='center'>Usage</Header>
      <DataTable data={usageData} />
      {/* Channel-Associate Tab Toggle */}
      <ChannelAssociate credAssocChnlData={creditAssocChnlByStr} timeframe={timeframe} />
    </>
  );
};

export default CreditStorePage;

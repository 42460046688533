import { useContext } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { OnTimePickupAssoc } from './OnTimePickupAssoc';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { DataView } from '../../../../../../components/Common/DataView';
import { onTimePickUpBuilder } from '../../../../utils/onTimePickUpBuilder';

export const OnTimePickup = () => {
    const { orderFulfillmentPickupData } = useContext(StoreOrderFulfillmentContext);
    const [storeOnTimePickUp] = useQueryKeys(['storeOnTimePickUp'])
    const { timeframe } = useContext(StoreOrderFulfillmentContext)
    const totalRow = storeOnTimePickUp.filter(row => row.CHANNEL === 'TOTAL')
    const bodyRows = storeOnTimePickUp.filter(row => row.CHANNEL !== 'TOTAL')
    const pickupMethodsDataObj = {
        headers: [
            { name: 'Method' },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
            { name: 'Avg Min to Pick' },
            { name: 'DS Compliance %' }
        ],
        ...onTimePickUpBuilder({ bodyRows, totalRow, timeframe })
    }

    const curbsideDataObj = {
        headers: [
            { name: 'Method' },
            { name: 'Avg Customer Wait Time' },
            { name: '# of Orders Missed SLA' },
            { name: '% of Orders Missed SLA' }
        ],
        data: orderFulfillmentPickupData.filter(({ METHOD }) => METHOD === 'Curbside')
            .map(data => [
                { stringValue: data.METHOD },
                { numValue: data.AVG_MIN_TO_PICK },
                { numValue: data.TOTAL_ORDERS - data.PICK_ON_TIME },
                { pctValue: (data.TOTAL_ORDERS - data.PICK_ON_TIME) / data.TOTAL_ORDERS },
            ])
    };

    return (
        <DataView className='my-4'>
            <DataView.Title as='h3'>Pickup Details by Method</DataView.Title>
            <DataView.DataTable data={pickupMethodsDataObj} />
            <DataView.Title as='h3'>Pickup Details for Curbside</DataView.Title>
            <DataView.DataTable data={curbsideDataObj} />
            <DataView.Title as='h3'>More Details</DataView.Title>
            <OnTimePickupAssoc />
        </DataView>
    );
};




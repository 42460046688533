import { useState } from 'react';
import { Header, Loader, Menu, Segment } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { useSpecialtyProRentalServicesQueries } from '../hooks/useSpecialtyProRentalServicesQueries';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { DataView } from '../../Common/DataView';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { TabMenu } from '../../Common/TabMenu';
import { ServicesSales } from '../components/ServicesSales';
import { ServicesPipeline } from '../components/ServicesPipeline';
import { LeadsMeasuresTable } from '../components/LeadsMeasuresTable';
import {
  getBreadcrumbItems,
  getTitle,
  FOCUS_6,
  menuItems,
  SALES,
  PIPELINE,
  TOTAL
} from '../utils/specialtyProRentalServices';
import { getDataAsOf } from '../../../utils/getDataAsOf';

const getPanes = params => {
  const { focusSixAllProgramsData, activeMenu, level, locNbr, timeframe, pipelineFilter, onChangePipelineFilter } = params;

  return [
    {
      menuItem: SALES,
      render: () => (
        <ServicesSales
          focusSixAllProgramsData={focusSixAllProgramsData}
          activeMenu={activeMenu}
          level={level}
          locNbr={locNbr}
          timeframe={timeframe}
        />
      )
    },
    {
      menuItem: PIPELINE,
      render: () => (
        <ServicesPipeline
          focusSixAllProgramsData={focusSixAllProgramsData}
          activeMenu={activeMenu}
          level={level}
          locNbr={locNbr}
          timeframe={timeframe}
          pipelineFilter={pipelineFilter}
          onChangePipelineFilter={onChangePipelineFilter}
        />
      )
    }
  ];
};

export const SpecialtyProRentalServicesPage = () => {
  const { level, locNbr, timeframe, setTimeframe, currentLevelName, isLoadingCurrentLevelName } =
    useSpecialtyProRentalContext();
  const [activeMenu, setActiveMenu] = useState(FOCUS_6);
  const [pipelineFilter, setPipelineFilter] = useState(TOTAL);
  const onChangePipelineFilter = (_, { value }) => {
    setPipelineFilter(value);
  };
  const { isLoading, isError, focusSixAllProgramsData, leadsAndMeasuresPerformanceData } =
    useSpecialtyProRentalServicesQueries();

  if (isLoadingCurrentLevelName || isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong</p>;

  const breadcrumbItems = getBreadcrumbItems({ level, locNbr });
  const title = getTitle({ level, locNbr, currentLevelName });
  const dataAsOf = getDataAsOf({ data: focusSixAllProgramsData });
  const panes = getPanes({
    focusSixAllProgramsData,
    activeMenu,
    level,
    locNbr,
    timeframe,
    pipelineFilter,
    onChangePipelineFilter
  });

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-4'>
        <DataView.Title position='center'>
          {title}
          <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='d-flex justify-content-center'>
          <TimeframeDropdown timeframe={timeframe} settimeframe={setTimeframe} />
        </DataView.Controls>

        <Menu attached='top' color='orange' size='small' tabular widths={2}>
          {menuItems.map(menuItem => (
            <Menu.Item
              key={menuItem}
              name={menuItem}
              onClick={() => setActiveMenu(menuItem)}
              active={menuItem === activeMenu}
            />
          ))}
        </Menu>

        <Segment attached='bottom'>
          <TabMenu color='orange' className='mb-4' panes={panes} />
          <LeadsMeasuresTable
            leadsAndMeasuresPerformanceData={leadsAndMeasuresPerformanceData}
            activeMenu={activeMenu}
            level={level}
            locNbr={locNbr}
            timeframe={timeframe}
            pipelineFilter={pipelineFilter}
          />
        </Segment>
      </DataView>
    </>
  );
};

import { Header, Loader } from 'semantic-ui-react';
import { SidekickExecSkuBay } from '../../components/osa/SidekickExecSkuBay';
import { SidekickSkuBayTasksCompletion } from '../../components/osa/SidekickSkuBayTasksCompletion';
import { Breadcrumb } from '../../../../../../components/Common/Breadcrumb';
import { useSidekickskubayQueries } from '../../hooks/useSidekickskubayQueries';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';
import { getLastTimeframe } from '../../../../../../utils/timeFrameUtils';
import { getBreadcrumbItems } from '../../utils/osa/sidekickskubay';

const { core: level } = METRIC_CONSTANTS;
const { subLevel } = level;

export const Sidekickskubay = props => {
  const { fpToggle, timeframe, settimeframe } = props;
  const { levelName } = level;
  const ltf = getLastTimeframe(timeframe);

  const { isError, isLoading, osaSidekickSkuBayByCoreData, sidekickExeScoresPlusOneByCoreData } = useSidekickskubayQueries({
    fpToggle
  });

  if (isError) return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;
  if (!osaSidekickSkuBayByCoreData.length || !sidekickExeScoresPlusOneByCoreData.length)
    return <Header textAlign='center'>Invalid Store Number</Header>;

  const breadcrumbItems = getBreadcrumbItems({ levelName });

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <SidekickExecSkuBay
        osaSidekickSkuBayData={osaSidekickSkuBayByCoreData}
        sidekickExeScoresPlusOneData={sidekickExeScoresPlusOneByCoreData}
        level={level}
        subLevel={subLevel}
        timeframe={timeframe}
        settimeframe={settimeframe}
        ltf={ltf}
      />
      <SidekickSkuBayTasksCompletion
        osaSidekickSkuBayData={osaSidekickSkuBayByCoreData}
        sidekickExeScoresPlusOneData={sidekickExeScoresPlusOneByCoreData}
        level={level}
        subLevel={subLevel}
        timeframe={timeframe}
        ltf={ltf}
      />
    </>
  );
};

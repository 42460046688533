import { getPercentString } from '../../../../../components/Common/TableCellUtils';
import { buildBaseDrillLink } from '../../../../../components/ShrinkContainer/utils/url';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../../../utils/level';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { getRowLevelNm } from "../../../../../utils/getRowLevelNm";

export const invalidScandCoreData = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [
    { name: capitalizeFirstLetter(METRIC_CONSTANTS['core']['urlLinkLevel']) + ' # ' },
    { name: 'Value ' },
    { name: 'Goal ' },
    { name: '% of Stores Passing ' }
  ],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink('core');

    return [
      {
        stringValue: getRowLevelNm({row, level}),
        link: {
          to: `/${baseLevelShrinkUrl}/invalidscans`,
          params: {
            [METRIC_CONSTANTS['core']['urlSubLevelParam']]: formatLevelNumber(row[METRIC_CONSTANTS['core']['propToRender']])
          }
        }
      },
      {
        stringValue: getPercentString(row[timeframe + '_INVAL_SCN_NUM'] / row[timeframe + '_CORE_SLS'], 2)
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(score => {
            return (
              score && score[METRIC_CONSTANTS['core']['propToRender']] === row[METRIC_CONSTANTS['core']['propToRender']]
            );
          })[0]['INV_SCN_GOAL'],
          2
        )
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score =>
              score && score[METRIC_CONSTANTS['core']['propToRender']] === row[METRIC_CONSTANTS['core']['propToRender']]
          )[0]['INVALID_SCAN_SCORE']
        )
      }
    ];
  })
});

import { getCompareStyle } from '../../../../../../components/Common/TableCellUtils';
import { LEVEL_NBR } from '../../../../../../utils/constantsByLevel';
import { formatNumberWithCommas } from '../../../../../../utils/formatter';
import { getRowSubLevelNm } from '../../../../../../utils/getRowLevelNm';

export const coreLeadsAdapter = coreLead => {
  const sumProgNmFilter = coreLead.filter(row => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL';
  });

  let totalRecords = coreLead;
  if (coreLead?.length > 0) {
    const totalCoreLeads = coreLead[0];
    totalRecords = [totalCoreLeads, ...coreLead];
  }
  return { sumProgNmFilter, totalRecords };
};

export const isMonday = new Date().getDay() === 1;

export const leadsBySumProgramAdapter = (timeframe, sumProgNmFilter, allWebStore, unit) => ({
  headers: [
    {
      name: 'Summary Program Name '
    },
    { name: isMonday && timeframe === 'WTD' ? 'TW Target ' : 'Target' },
    isMonday && timeframe === 'WTD' ? { name: 'LW Target ' } : false,
    {
      name: 'Leads '
    },
    {
      name: unit === 'PCT' ? 'VLY% ' : 'VLY '
    }
  ].filter(row => Boolean(row)),
  data: sumProgNmFilter.map(row =>
    [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS_TGT']),
        orderBy: row[timeframe + allWebStore + '_LEADS_TGT']
      },
      isMonday &&
        timeframe === 'WTD' && {
          numValue: formatNumberWithCommas(row['LW' + allWebStore + '_LEADS_TGT']),
          orderBy: row['LW' + allWebStore + '_LEADS_TGT']
        },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS']),
        orderBy: +row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM'
        ? {
            numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
            orderBy: row[timeframe + allWebStore + '_VLY'],
            customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
          }
        : {
            pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
            orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
            customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
            decimals: 2
          }
    ].filter(row => Boolean(row))
  ),
  sortable: true,
  sortableDefault: 0
});

export const totalLeadsDataObjAdapter = ({ level, timeframe, unit, totalRecords, allWebStore }) => {
  const { levelName, subLevel } = level;
  const { levelName: subLevelName, urlParam: subUrlParam, [LEVEL_NBR]: levelNbr } = subLevel;

  return {
    headers: [
      { name: 'Org # ' },
      { name: isMonday && timeframe === 'WTD' ? 'TW Target ' : 'Target ' },
      isMonday && timeframe === 'WTD' ? { name: 'LW Target ' } : false,
      { name: 'Leads ' },
      { name: unit === 'PCT' ? 'VLY% ' : 'VLY ' }
    ].filter(row => Boolean(row)),
    data: totalRecords.map((row, index) =>
      [
        {
          stringValue:
            row.METRIC_LEVEL === 'DIV' ? getRowSubLevelNm({ row, level: levelName, levelNbr: row[levelNbr] }) : 'CORE',
          link:
            row.METRIC_LEVEL === 'DIV'
              ? {
                  to: `/${subLevelName}/leads`,
                  params: {
                    [subUrlParam]: row[levelNbr]
                  }
                }
              : undefined
        },
        {
          numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS_TGT']),
          orderBy: row[timeframe + allWebStore + '_LEADS_TGT']
        },
        isMonday &&
          timeframe === 'WTD' && {
            numValue: formatNumberWithCommas(row['LW' + allWebStore + '_LEADS_TGT']),
            orderBy: row['LW' + allWebStore + '_LEADS_TGT']
          },
        {
          numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_LEADS']),
          orderBy: row[timeframe + allWebStore + '_LEADS']
        },
        unit === 'NOM'
          ? {
              numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
              orderBy: row[timeframe + allWebStore + '_VLY'],
              customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
            }
          : {
              pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
              orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
              customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
              decimals: 2
            }
      ].filter(row => Boolean(row))
    ),
    sortable: true,
    sortableDefault: 0
  };
};

import { capitalizeFirstLetter } from "../../../utils/string";
import { divide } from "../../../utils/numbers";

export const onTimeDeliveryData = (storeOntimeDelivery, timeframe) => {
    const dataDlv = storeOntimeDelivery.map(data => {
        return [
          { stringValue: capitalizeFirstLetter(data.TRUCK_TYPE) },
          { pctValue: divide(data[timeframe+'_ON_TIME_COUNT_NEW_NUM'], data[timeframe+'_ON_TIME_COUNT_NEW_DEN'])},
          { numValue: data[timeframe+'_MISS_DELIVERY_COUNT_NUM']},
          { pctValue: divide(data[timeframe+'_MISS_DELIVERY_COUNT_NUM'],data[timeframe+'_MISS_DELIVERY_COUNT_DEN']) },
      ]})

      const total = dataDlv.reduce((acc, data) => {
        acc[0].stringValue = 'Total Orders';
        acc[1].pctValue += data[1].pctValue/dataDlv.length;
        acc[2].numValue += data[2].numValue;
        acc[3].pctValue += data[3].pctValue/dataDlv.length;
        return acc;
      },[
        { stringValue: 'Total Orders' },
        { pctValue: 0 },
        { numValue: 0 },
        { pctValue: 0 },
      ])

      dataDlv.push(total)
    return {
        headers: [
            { name: 'Truck Type' },
            { name: 'Pick on Time %' },
            { name: 'Delivery Misses' },
            { name: 'Delivery Misses %' },
        ],
        data: dataDlv
    }
};
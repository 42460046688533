import React, { useState, useEffect } from "react";

import {
  Header,
  Grid,
  Table,
  Loader,
  Tab,
  Menu,
  Icon,
  Button,
  Modal,
  Segment,
  Pagination,
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getData } from "../../service/DataService";
import { getDollarString, getPercentString } from "../Common/TableCellUtils";
import { UnitMenu } from '../Common/UnitMenu'

export const DstShrinkDept = withRouter(({ fpToggle, location }) => {
  const search = new URLSearchParams(location.search);

  const dstNbr = search.has("dstNbr") ? search.get("dstNbr") : 0;
  const deptNbrInt = search.has("deptNbr") ? search.get("deptNbr") : 0;

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [classSumUnits, setClassSumUnits] = useState("NOM");
  const [sortColumnClass, setSortColumnClass] = useState("DEPT_NBR");
  const [sortDirectionClass, setSortDirectionClass] = useState("ascending");
  const [skuSumUnits, setSkuSumUnits] = useState("NOM");
  const [sortColumnSku, setSortColumnSku] = useState("SKU_NBR");
  const [sortDirectionSku, setSortDirectionSku] = useState("ascending");
  const [pageNum, setPageNum] = useState(1);
  const [pageNumSku, setPageNumSku] = useState(1);

  let rowsPerPage = 20;

  const shrinkActualsClassByDept = useQuery(
    ["queryShrinkActualsClsByDstDept", { dstNbr, deptNbrInt, fpToggle }],
    getData
  );
  const shrinkActualsSkuByDept = useQuery(
    ["queryShrinkActualsSkuByDstDept", { dstNbr, deptNbrInt, fpToggle }],
    getData
  );

  useEffect(() => {
    setPageNum(1)
  }, [sortColumnClass, sortDirectionClass])

  useEffect(() => {
    setPageNumSku(1)
  }, [sortColumnSku, sortDirectionSku])

  if (shrinkActualsClassByDept.isLoading || shrinkActualsSkuByDept.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  if (!shrinkActualsClassByDept.data[0] || !shrinkActualsSkuByDept.data[0]) {
    return <Header textAlign="center">Invalid District Number</Header>;
  }
  const classSumData = shrinkActualsClassByDept.data;
  const skuSumData = shrinkActualsSkuByDept.data;
  const deptNm = classSumData[0].DEPT_NM;

  let classSumDataFilter = classSumData.filter((row) => {
    return row.DEPT_NBR == deptNbrInt;
  });

  let classSumrySorted = classSumDataFilter.sort((left, right) => {
    let result = left[sortColumnClass] - right[sortColumnClass];
    if (sortColumnClass === "EXT_CLASS_NBR") {
      result = left.EXT_CLASS_NBR > right.EXT_CLASS_NBR ? 1 : -1;
    }
    if (sortColumnClass === "TOTAL_SHRINK_AMT") {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1;
    }
    if (sortColumnClass === "TOTAL_SALES_AMT") {
      if (isNaN(left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortColumnClass === "LY_TOTAL_SHRINK_AMT") {
      result =
        left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT >
          right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortColumnClass === "LY_TOTAL_SALES_AMT") {
      const l = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT - left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT;
      const r =  right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT - right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT;
      if (isNaN(l)) { return 1; }
      if (isNaN(r)) { return -1; }
      result = l > r ? 1 : -1;
    }
    if (sortColumnClass === "OPS_SHRINK_AMT") {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1;
    }
    if (sortColumnClass === "OPS_SALES_AMT") {
      if (isNaN(left.OPS_SHRINK_AMT / left.OPS_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.OPS_SALES_AMT)) { return -1; }
      result =
        left.OPS_SHRINK_AMT / left.OPS_SALES_AMT >
          right.OPS_SHRINK_AMT / right.OPS_SALES_AMT
          ? 1
          : -1;
    }

    if (sortDirectionClass === "descending") {
      result *= -1;
    }
    return result;
  });
  //SKU
  let skuSumDataFilter = skuSumData.filter((row) => {
    return row.DEPT_NBR == deptNbrInt;
  });

  let skuSumrySorted = skuSumDataFilter.sort((left, right) => {
    let result = left[sortColumnSku] - right[sortColumnSku];
    if (sortColumnSku === "SKU_NBR") {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1;
    }
    if (sortColumnSku === "DEPT_NBR") {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1;
    }
    if (sortColumnSku === "TOTAL_SHRINK_AMT") {
      result = left.TOTAL_SHRINK_AMT > right.TOTAL_SHRINK_AMT ? 1 : -1;
    }
    if (sortColumnSku === "TOTAL_SALES_AMT") {
      if (isNaN(left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT)) { return 1; }
      if (isNaN(right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT)) { return -1; }
      result =
        left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT >
          right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT
          ? 1
          : -1;
    }
    if (sortColumnSku === "LY_TOTAL_SHRINK_AMT") {
      result =
        left.TOTAL_SHRINK_AMT - left.LY_TOTAL_SHRINK_AMT >
          right.TOTAL_SHRINK_AMT - right.LY_TOTAL_SHRINK_AMT
          ? 1
          : -1;
    }
    if (sortColumnSku === "LY_TOTAL_SALES_AMT") {
      const l = left.TOTAL_SHRINK_AMT / left.TOTAL_SALES_AMT - left.LY_TOTAL_SHRINK_AMT / left.LY_TOTAL_SALES_AMT;
      const r =  right.TOTAL_SHRINK_AMT / right.TOTAL_SALES_AMT - right.LY_TOTAL_SHRINK_AMT / right.LY_TOTAL_SALES_AMT;
      if (isNaN(l)) { return 1; }
      if (isNaN(r)) { return -1; }
      result = l > r ? 1 : -1;
    }
    if (sortColumnSku === "OPS_SHRINK_AMT") {
      result = left.OPS_SHRINK_AMT > right.OPS_SHRINK_AMT ? 1 : -1;
    }
    if (sortColumnSku === "MAL_SHRINK_AMT") {
      if (isNaN(left.OPS_SHRINK_AMT / left.OPS_SALES_AMT)) { return 1; }
      if (isNaN(right.OPS_SHRINK_AMT / right.OPS_SALES_AMT)) { return -1; }
      result =
        left.OPS_SHRINK_AMT / left.OPS_SALES_AMT >
          right.OPS_SHRINK_AMT / right.OPS_SALES_AMT
          ? 1
          : -1;
    }

    if (sortDirectionSku === "descending") {
      result *= -1;
    }
    return result;
  });

  let dataPaginationClass = classSumrySorted.slice(
    rowsPerPage * (pageNum - 1),
    parseInt(Math.min(rowsPerPage * pageNum, classSumrySorted.length))
  );
  let dataPaginationSku = skuSumrySorted.slice(
    rowsPerPage * (pageNumSku - 1),
    parseInt(Math.min(rowsPerPage * pageNumSku, skuSumrySorted.length))
  );

  const panes = [
    {
      menuItem: "CLASSES",
      render: () => (
        <Tab.Pane attached={false}>
          <Grid>
            <Grid.Column width={8}>
              <Grid.Row>Units:</Grid.Row>
              <Menu compact size="small">
                <UnitMenu
                  unit={classSumUnits}
                  setUnit={setClassSumUnits}
                />
              </Menu>
            </Grid.Column>
            {classSumrySorted.length > rowsPerPage ? <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                <Pagination
                  size='mini'
                  defaultActivePage={1}
                  totalPages={Math.floor((classSumrySorted.length - 1) / rowsPerPage) + 1}
                  activePage={pageNum}
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) => {
                    setPageNum(activePage)
                  }}
                />
              </Grid.Column>
            </Grid.Row> : <Grid.Row></Grid.Row>}
          </Grid>
          <Table textAlign="center" unstackable celled sortable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  sorted={
                    sortColumnClass === "EXT_CLASS_NBR"
                      ? sortDirectionClass
                      : null
                  }
                  onClick={() => {
                    if (sortColumnClass !== "EXT_CLASS_NBR") {
                      setSortColumnClass("EXT_CLASS_NBR");
                      setSortDirectionClass("ascending");
                    } else {
                      sortDirectionClass === "ascending"
                        ? setSortDirectionClass("descending")
                        : setSortDirectionClass("ascending");
                    }
                  }}
                >
                  Class{" "}
                  {sortColumnClass !== "EXT_CLASS_NBR" && (
                    <Icon fitted name="sort" />
                  )}
                </Table.HeaderCell>
                {classSumUnits === "NOM" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnClass === "TOTAL_SHRINK_AMT"
                        ? sortDirectionClass
                        : null
                    }
                    onClick={() => {
                      if (sortColumnClass !== "TOTAL_SHRINK_AMT") {
                        setSortColumnClass("TOTAL_SHRINK_AMT");
                        setSortDirectionClass("ascending");
                      } else {
                        sortDirectionClass === "ascending"
                          ? setSortDirectionClass("descending")
                          : setSortDirectionClass("ascending");
                      }
                    }}
                  >
                    Shrink ${" "}
                    {sortColumnClass !== "TOTAL_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {classSumUnits === "PCT" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnClass === "TOTAL_SALES_AMT"
                        ? sortDirectionClass
                        : null
                    }
                    onClick={() => {
                      if (sortColumnClass !== "TOTAL_SALES_AMT") {
                        setSortColumnClass("TOTAL_SALES_AMT");
                        setSortDirectionClass("ascending");
                      } else {
                        sortDirectionClass === "ascending"
                          ? setSortDirectionClass("descending")
                          : setSortDirectionClass("ascending");
                      }
                    }}
                  >
                    Shrink %{" "}
                    {sortColumnClass !== "TOTAL_SALES_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {classSumUnits === "NOM" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnClass === "LY_TOTAL_SHRINK_AMT"
                        ? sortDirectionClass
                        : null
                    }
                    onClick={() => {
                      if (sortColumnClass !== "LY_TOTAL_SHRINK_AMT") {
                        setSortColumnClass("LY_TOTAL_SHRINK_AMT");
                        setSortDirectionClass("ascending");
                      } else {
                        sortDirectionClass === "ascending"
                          ? setSortDirectionClass("descending")
                          : setSortDirectionClass("ascending");
                      }
                    }}
                  >
                    vLY ${" "}
                    {sortColumnClass !== "LY_TOTAL_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {classSumUnits === "PCT" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnClass === "LY_TOTAL_SALES_AMT"
                        ? sortDirectionClass
                        : null
                    }
                    onClick={() => {
                      if (sortColumnClass !== "LY_TOTAL_SALES_AMT") {
                        setSortColumnClass("LY_TOTAL_SALES_AMT");
                        setSortDirectionClass("ascending");
                      } else {
                        sortDirectionClass === "ascending"
                          ? setSortDirectionClass("descending")
                          : setSortDirectionClass("ascending");
                      }
                    }}
                  >
                    vLY %{" "}
                    {sortColumnClass !== "LY_TOTAL_SALES_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {classSumUnits === "NOM" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnClass === "OPS_SHRINK_AMT"
                        ? sortDirectionClass
                        : null
                    }
                    onClick={() => {
                      if (sortColumnClass !== "OPS_SHRINK_AMT") {
                        setSortColumnClass("OPS_SHRINK_AMT");
                        setSortDirectionClass("ascending");
                      } else {
                        sortDirectionClass === "ascending"
                          ? setSortDirectionClass("descending")
                          : setSortDirectionClass("ascending");
                      }
                    }}
                  >
                    Ops Shrink ${" "}
                    {sortColumnClass !== "OPS_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {classSumUnits === "PCT" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnClass === "OPS_SALES_AMT"
                        ? sortDirectionClass
                        : null
                    }
                    onClick={() => {
                      if (sortColumnClass !== "OPS_SALES_AMT") {
                        setSortColumnClass("OPS_SALES_AMT");
                        setSortDirectionClass("ascending");
                      } else {
                        sortDirectionClass === "ascending"
                          ? setSortDirectionClass("descending")
                          : setSortDirectionClass("ascending");
                      }
                    }}
                  >
                    Ops Shrink %{" "}
                    {sortColumnClass !== "OPS_SALES_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dataPaginationClass.map((data) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <Link
                        style={{
                          color: "#EE7125",
                          textDecoration: "underline",
                        }}
                        to={`/district/shrink/class?dstNbr=${dstNbr}&classNbr=${data["EXT_CLASS_NBR"]}`}
                      >
                        {data.EXT_CLASS_NBR} - {data.CLASS_DESC.replaceAll('/', '/\u200b')}
                      </Link>
                    </Table.Cell>
                    {classSumUnits === "NOM" && (
                      <Table.Cell>
                        {getDollarString(data.TOTAL_SHRINK_AMT)}
                      </Table.Cell>
                    )}
                    {classSumUnits === "PCT" && (
                      <Table.Cell>
                        {getPercentString(
                          data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2
                        )}
                      </Table.Cell>
                    )}
                    {classSumUnits === "NOM" && (
                      <Table.Cell>
                        {getDollarString(
                          data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT
                        )}
                      </Table.Cell>
                    )}
                    {classSumUnits === "PCT" && (
                      <Table.Cell>
                        {getPercentString(
                          data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT -
                          data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT, 2, 2
                        )}
                      </Table.Cell>
                    )}
                    {classSumUnits === "NOM" && (
                      <Table.Cell>
                        {getDollarString(data.OPS_SHRINK_AMT)}
                      </Table.Cell>
                    )}
                    {classSumUnits === "PCT" && (
                      <Table.Cell>
                        {getPercentString(
                          data.OPS_SHRINK_AMT / data.OPS_SALES_AMT, 2, 2
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Tab.Pane>
      ),
    },

    {
      menuItem: "SKUS",
      render: () => (
        <Tab.Pane attached={false}>
          {/*  ____  __ _  _  _ 
              / ___)(  / )/ )( \
              \___ \ )  ( ) \/ (
              (____/(__\_)\____/*/}
          <Grid>
            <Grid.Column width={8}>
              <Grid.Row>Units:</Grid.Row>
              <Menu compact size="small">
                <UnitMenu
                  unit={skuSumUnits}
                  setUnit={setSkuSumUnits}
                />
              </Menu>
            </Grid.Column>
            {skuSumrySorted.length > rowsPerPage ? <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                <Pagination
                  size='mini'
                  defaultActivePage={1}
                  totalPages={Math.floor((skuSumrySorted.length - 1) / rowsPerPage) + 1}
                  activePage={pageNumSku}
                  boundaryRange={0}
                  siblingRange={1}
                  onPageChange={(e, { activePage }) => {
                    setPageNumSku(activePage)
                  }}
                />
              </Grid.Column>
            </Grid.Row> : <Grid.Row></Grid.Row>}
          </Grid>

          <Table textAlign="center" unstackable celled sortable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  sorted={sortColumnSku === "SKU_NBR" ? sortDirectionSku : null}
                  onClick={() => {
                    if (sortColumnSku !== "SKU_NBR") {
                      setSortColumnSku("SKU_NBR");
                      setSortDirectionSku("ascending");
                    } else {
                      sortDirectionSku === "ascending"
                        ? setSortDirectionSku("descending")
                        : setSortDirectionSku("ascending");
                    }
                  }}
                >
                  SKU #{" "}
                  {sortColumnSku !== "SKU_NBR" && <Icon fitted name="sort" />}
                </Table.HeaderCell>
                <Table.HeaderCell>SKU Desc</Table.HeaderCell>
                {skuSumUnits === "NOM" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnSku === "TOTAL_SHRINK_AMT"
                        ? sortDirectionSku
                        : null
                    }
                    onClick={() => {
                      if (sortColumnSku !== "TOTAL_SHRINK_AMT") {
                        setSortColumnSku("TOTAL_SHRINK_AMT");
                        setSortDirectionSku("ascending");
                      } else {
                        sortDirectionSku === "ascending"
                          ? setSortDirectionSku("descending")
                          : setSortDirectionSku("ascending");
                      }
                    }}
                  >
                    Shrink ${" "}
                    {sortColumnSku !== "TOTAL_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {skuSumUnits === "PCT" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnSku === "TOTAL_SALES_AMT"
                        ? sortDirectionSku
                        : null
                    }
                    onClick={() => {
                      if (sortColumnSku !== "TOTAL_SALES_AMT") {
                        setSortColumnSku("TOTAL_SALES_AMT");
                        setSortDirectionSku("ascending");
                      } else {
                        sortDirectionSku === "ascending"
                          ? setSortDirectionSku("descending")
                          : setSortDirectionSku("ascending");
                      }
                    }}
                  >
                    Shrink %{" "}
                    {sortColumnSku !== "TOTAL_SALES_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {skuSumUnits === "NOM" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnSku === "LY_TOTAL_SHRINK_AMT"
                        ? sortDirectionSku
                        : null
                    }
                    onClick={() => {
                      if (sortColumnSku !== "LY_TOTAL_SHRINK_AMT") {
                        setSortColumnSku("LY_TOTAL_SHRINK_AMT");
                        setSortDirectionSku("ascending");
                      } else {
                        sortDirectionSku === "ascending"
                          ? setSortDirectionSku("descending")
                          : setSortDirectionSku("ascending");
                      }
                    }}
                  >
                    vLY ${" "}
                    {sortColumnSku !== "LY_TOTAL_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {skuSumUnits === "PCT" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnSku === "LY_TOTAL_SALES_AMT"
                        ? sortDirectionSku
                        : null
                    }
                    onClick={() => {
                      if (sortColumnSku !== "LY_TOTAL_SALES_AMT") {
                        setSortColumnSku("LY_TOTAL_SALES_AMT");
                        setSortDirectionSku("ascending");
                      } else {
                        sortDirectionSku === "ascending"
                          ? setSortDirectionSku("descending")
                          : setSortDirectionSku("ascending");
                      }
                    }}
                  >
                    vLY %{" "}
                    {sortColumnSku !== "LY_TOTAL_SALES_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {skuSumUnits === "NOM" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnSku === "OPS_SHRINK_AMT"
                        ? sortDirectionSku
                        : null
                    }
                    onClick={() => {
                      if (sortColumnSku !== "OPS_SHRINK_AMT") {
                        setSortColumnSku("OPS_SHRINK_AMT");
                        setSortDirectionSku("ascending");
                      } else {
                        sortDirectionSku === "ascending"
                          ? setSortDirectionSku("descending")
                          : setSortDirectionSku("ascending");
                      }
                    }}
                  >
                    Ops Shrink ${" "}
                    {sortColumnSku !== "OPS_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
                {skuSumUnits === "PCT" && (
                  <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    sorted={
                      sortColumnSku === "MAL_SHRINK_AMT"
                        ? sortDirectionSku
                        : null
                    }
                    onClick={() => {
                      if (sortColumnSku !== "MAL_SHRINK_AMT") {
                        setSortColumnSku("MAL_SHRINK_AMT");
                        setSortDirectionSku("ascending");
                      } else {
                        sortDirectionSku === "ascending"
                          ? setSortDirectionSku("descending")
                          : setSortDirectionSku("ascending");
                      }
                    }}
                  >
                    Ops Shrink %{" "}
                    {sortColumnSku !== "MAL_SHRINK_AMT" && (
                      <Icon fitted name="sort" />
                    )}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dataPaginationSku.map((data) => {
                return (
                  <Table.Row>
                    <Table.Cell
                      style={{ color: "#EE7125", textDecoration: "underline" }}
                      onClick={() => {
                        setOpen(true);
                        setModalData(data);
                      }}
                    >
                      {data["SKU_NBR"].toString().length > 6 // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                        ? data["SKU_NBR"].toString().substring(0, 4) +
                        "-" +
                        data["SKU_NBR"].toString().substring(4, 7) +
                        "-" +
                        data["SKU_NBR"].toString().substring(7)
                        : data["SKU_NBR"].toString().substring(0, 3) +
                        "-" +
                        data["SKU_NBR"].toString().substring(3)}
                    </Table.Cell>
                    <Table.Cell>{data.SKU_DESC.replaceAll('/', '/\u200b')}</Table.Cell>
                    {skuSumUnits === "NOM" && (
                      <Table.Cell>
                        {getDollarString(data.TOTAL_SHRINK_AMT)}
                      </Table.Cell>
                    )}
                    {skuSumUnits === "PCT" && (
                      <Table.Cell>
                        {getPercentString(
                          data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT, 2, 2
                        )}
                      </Table.Cell>
                    )}
                    {skuSumUnits === "NOM" && (
                      <Table.Cell>
                        {getDollarString(
                          data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT
                        )}
                      </Table.Cell>
                    )}
                    {skuSumUnits === "PCT" && (
                      <Table.Cell>
                        {getPercentString(
                          data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT -
                          data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT, 2, 2
                        )}
                      </Table.Cell>
                    )}
                    {skuSumUnits === "NOM" && (
                      <Table.Cell>
                        {getDollarString(data.OPS_SHRINK_AMT)}
                      </Table.Cell>
                    )}
                    {skuSumUnits === "PCT" && (
                      <Table.Cell>
                        {getPercentString(
                          data.OPS_SHRINK_AMT / data.OPS_SALES_AMT, 2, 2
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {open && (
            <Modal
              closeIcon
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Segment color="orange">
                <Grid>
                  <Grid.Column width={16}>
                    <Grid.Row>
                      <Header textAlign="center">
                        {modalData["SKU_NBR"].toString().length > 6 // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                          ? modalData["SKU_NBR"].toString().substring(0, 4) +
                          "-" +
                          modalData["SKU_NBR"].toString().substring(4, 7) +
                          "-" +
                          modalData["SKU_NBR"].toString().substring(7)
                          : modalData["SKU_NBR"].toString().substring(0, 3) +
                          "-" +
                          modalData["SKU_NBR"].toString().substring(3)}
                      </Header>
                    </Grid.Row>
                    <Grid.Row>&nbsp;</Grid.Row>
                    <Grid.Row>
                      <Header>Class</Header>
                    </Grid.Row>
                    <Grid.Row>
                      <p textAlign="center">
                        {modalData["EXT_CLASS_NBR"] +
                          "-" +
                          modalData["CLASS_DESC"]}
                      </p>
                    </Grid.Row>
                    <Grid.Row>&nbsp;</Grid.Row>
                    <Grid.Row>
                      <Header>Subclass</Header>
                    </Grid.Row>
                    <Grid.Row>
                      <p textAlign="center">
                        {modalData["EXT_SUB_CLASS_NBR"] +
                          "-" +
                          modalData["SUB_CLASS_DESC"]}
                      </p>
                    </Grid.Row>
                    <Grid.Row>&nbsp;</Grid.Row>
                    <Grid.Row>
                      <Header>SKU Description</Header>
                    </Grid.Row>
                    <Grid.Row>
                      <p textAlign="center">{modalData["SKU_DESC"]}</p>
                    </Grid.Row>
                  </Grid.Column>
                  &nbsp; &nbsp;
                  <Grid.Column>
                    <Grid.Row>&nbsp;</Grid.Row>
                    <Grid.Row>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        style={{ color: "white", backgroundColor: "#EE7125" }}
                        content="Close"
                      />
                    </Grid.Row>
                    <Grid.Row>&nbsp;</Grid.Row>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Modal>
          )}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/shrink?dstNbr=${dstNbr}`} >
        Shrink
      </Link>
      {' / '}
      {deptNbrInt}-{deptNm}
      <Header textAlign="left">
        {deptNbrInt} - {deptNm}
      </Header>
      <Tab
        menu={{
          attached: false,
          pointing: false,
          secondary: false,
          borderless: false,
          compact: true,
          size: "small",
          widths: 2,
        }}
        panes={panes}
      />
    </>
  );
});

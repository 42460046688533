import { useMemo, useState } from 'react';
import { Header, Menu } from 'semantic-ui-react';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { DataTable } from '../../../../../components/Common/DataTable';
import { coreSummaryTableBuilder } from '../utils/coreSummaryTableBuilder';

export const CoreSummaryTable = ({ summaryData }) => {
  const [unit, setUnit] = useState('NOM');
  const isUnitNom = unit === 'NOM';

  const dataTableData = useMemo(() => coreSummaryTableBuilder(summaryData, isUnitNom), [summaryData, isUnitNom]);

  return (
    <>
      <Header textAlign='center'>Core Summary</Header>
      <Menu compact size='small'>
        <UnitMenu unit={unit} setUnit={setUnit} />
      </Menu>
      <DataTable data={dataTableData} />
    </>
  );
};

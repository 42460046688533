import { createContext } from 'react';

export const StoreOrderFulfillmentContext = createContext();

const StoreOrderFulfillmentContextProvider = ({ children, fpToggle, strNbr, ...props }) => {
  
  const value = {
    ...props,
    fpToggle,
    strNbr,
  };

  return (
    <StoreOrderFulfillmentContext.Provider value={value}>
      {children}
    </StoreOrderFulfillmentContext.Provider>
  );
};

export default StoreOrderFulfillmentContextProvider;

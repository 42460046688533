import { useContext, useState } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { Dropdown } from 'semantic-ui-react';
import { onTimeDeliveryData, onTimeDeliveryHeaders, selectTrunkTypeOptions } from '../../../../utils/onTimeDelivery';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { TOTAL } from '../../../../utils/consts';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';

export default function OnTimeDelivery() {
  const { level, timeframe, locNbr } = useContext(OrderFulfillmentContext);
  const formatQueryKeys = ['query/queryOnTimeDelivery/', 'query/queryOnTimeTotalDelivery/'].map(query => query + `${level}/${locNbr}`)
  const [onTimeDelivery, totalDelivery] = useQueryKeys(formatQueryKeys)
  const [view, setView] = useState(TOTAL);
  const dataFilter = onTimeDelivery.filter(data => data.TRUCK_TYPE === String(view).toUpperCase());
  const headers = onTimeDeliveryHeaders(level);
  const data = {
    headers,
    data: view !== TOTAL ? dataFilter.map(data => onTimeDeliveryData(data, level, timeframe)) : totalDelivery.map(data => onTimeDeliveryData(data, level, timeframe)),
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <p className='my-0'>Select Truck Type</p>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={selectTrunkTypeOptions}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Delivery Details by {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
}

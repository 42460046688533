import { buildSublevelLinkColumn } from '../../../utils/dataTable';
import { formatNumberWithCommas } from '../../../utils/formatter';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { getCompareStyle, getPercentString, getThousandDollarString } from '../../Common/TableCellUtils';

const sortDataTableValues = { sortable: true };

export const aggregateByDivNbr = (data, currentForCore) => {
  return data.reduce((acc, curr) => {
    const levelNbr = curr[currentForCore];
    if (!acc[levelNbr]) {
      acc[levelNbr] = { ...curr };
    } else {
      Object.keys(curr).forEach(key => {
        if (key !== currentForCore && typeof curr[key] === 'number') {
          acc[levelNbr][key] += curr[key];
        }
      });
    }
    return acc;
  }, {});
};

export const slsDepts = salesData =>
  salesData
    .sort((left, right) => left?.MER_DEPT_NM.localeCompare(right?.MER_DEPT_NM))
    .reduce((acc, data) => {
      if (!acc.includes(data?.MER_DEPT_NM)) {
        acc.push(data?.MER_DEPT_NM);
      }
      return acc;
    }, []);

export const salesRentalDataBuilder = (salesData, timeframe, subMetric, unit, levelName, locNbr) => {
  const urlParam = getParamByLevel(levelName);
  const paramBuilder = () => (levelName === 'core' ? {} : { [urlParam]: locNbr });
  const isProXtra = sale => sale?.MER_DEPT_NM === 'PRO XTRA REGISTERED TENDER';
  const isServices = sale => sale?.MER_DEPT_NM === 'SERVICES';
  const isTotal = sale => sale?.MER_DEPT_NM === 'Total';
  const servicesRow = salesData.find(sale => isServices(sale));
  const proXtraRow = salesData.find(sale => isProXtra(sale));

  const dataFiltered = salesData.filter(sale => !isTotal(sale) && !isServices(sale) && !isProXtra(sale));
  dataFiltered.splice(0, 0, proXtraRow);
  dataFiltered.splice(1, 0, servicesRow);

  const dataSalesRentalDataBuilder = {
    headers: [{ name: '' }, { name: 'Act' }, { name: 'vP' }, { name: 'vLY' }],
    footer: footerTotal(salesData, timeframe, unit),
    data: dataFiltered.map(sale => [
      {
        stringValue: sale?.MER_DEPT_NM,
        link: sale?.MER_DEPT_NM !== 'Total' &&
          !isProXtra(sale) && {
            to: sale?.MER_DEPT_NM === 'SERVICES' ? `/${levelName}/services` : `/${levelName}/sales/dept`,
            params: {
              ...paramBuilder(),
              deptNbr: sale?.DEPT_NBR
            }
          }
      },
      { stringValue: formatNumberWithCommas(sale[`${timeframe}_TY_SLS_AMT`]) },
      {
        customStyle: getCompareStyle(sale[`${timeframe}_SLS_VP`]),
        stringValue: isProXtra(sale)
          ? '--'
          : unit === 'NOM'
            ? getThousandDollarString(sale[`${timeframe}_SLS_VP`], 2)
            : getPercentString(sale[`${timeframe}_SLS_VP_PCT`], 2)
      },
      {
        customStyle: getCompareStyle(sale[`${timeframe}_SLS_VLY`]),
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[`${timeframe}_SLS_VLY`], 2)
            : getPercentString(sale[`${timeframe}_SLS_VLY_PCT`], 2)
      }
    ])
  };
  return { dataSalesRentalDataBuilder };
};

export const footerTotal = (salesData, timeframe, unit) => {
  const sublevelFooter = salesData
    .filter(sale => sale['MER_DEPT_NM'] === 'Total')
    .map(sale => [
      {
        customStyle: { fontWeight: 'bold' },
        stringValue: sale['MER_DEPT_NM']
      },
      {
        stringValue: formatNumberWithCommas(sale[timeframe + '_TY_SLS_AMT'])
      },
      {
        customStyle: { fontWeight: 'bold' },
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VP'], 2)
            : getPercentString(sale[timeframe + '_SLS_VP_PCT'], 2)
      },
      {
        customStyle:
          sale['MER_DEPT_NM'] !== 'Total' ? getCompareStyle(sale[timeframe + '_SLS_VLY']) : { fontWeight: 'bold' },
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VLY'], 2)
            : getPercentString(sale[timeframe + '_SLS_VLY_PCT'], 2)
      }
    ]);
  return sublevelFooter;
};

export const salesRentalSubLevelDataBuilder = (slsSubLevel, subLevelSalesData, timeframe, unit, levelName, footer) => {
  const dataSalesRentalSubLevelDataBuilder = {
    headers: [{ name: `${slsSubLevel} #` }, { name: 'Act' }, { name: 'vP' }, { name: 'vLY' }],
    data: subLevelSalesData.map(sale => [
      buildSublevelLinkColumn({ level: levelName, row: sale, to: `/specialtyprorental` }),
      {
        stringValue: formatNumberWithCommas(sale[timeframe + '_TY_SLS_AMT'])
      },
      {
        customStyle: getCompareStyle(sale[timeframe + '_SLS_VP']),
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VP'])
            : getPercentString(sale[timeframe + '_SLS_VP_PCT'])
      },
      {
        customStyle: getCompareStyle(sale[timeframe + '_SLS_VLY']),
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VLY'])
            : getPercentString(sale[timeframe + '_SLS_VLY_PCT'])
      }
    ]),
    footer: footer,
    ...sortDataTableValues
  };

  return { dataSalesRentalSubLevelDataBuilder };
};

export const moveProXtraToFirst = data => {
  const proXtraIndex = data.findIndex(sale => sale['MER_DEPT_NM'] === 'PRO XTRA REGISTERED TENDER');
  if (proXtraIndex !== -1) {
    const [proXtra] = data.splice(proXtraIndex, 1);
    data.unshift(proXtra);
  }
  return data;
};

export const moveServicesAndTotal = data => {
  const servicesIndex = data.findIndex(sale => sale['MER_DEPT_NM'] === 'SERVICES');
  const totalIndex = data.findIndex(sale => sale['MER_DEPT_NM'] === 'Total');
  if (servicesIndex !== -1 && totalIndex !== -1) {
    const [services] = data.splice(servicesIndex, 1);
    data.splice(totalIndex - 1, 0, services);
    const [total] = data.splice(totalIndex, 1);
    data.push(total);
  }
  return data;
};

export const salesDataFixed = salesData =>
  salesData.map(sale => {
    if (sale['MER_DEPT_NM'].includes('1-')) {
      return { ...sale, MER_DEPT_NM: sale['MER_DEPT_NM'].replace('1-', '') };
    } else if (sale['MER_DEPT_NM'].includes('42-')) {
      return { ...sale, MER_DEPT_NM: sale['MER_DEPT_NM'].replace('42-', '') };
    } else {
      return sale;
    }
  });

import { Header, Icon } from 'semantic-ui-react';
import { DataTable } from '../../../../../components/Common/DataTable';
import { getCompareStyle, getCompareIconStyle } from '../../../../../components/Common/TableCellUtils';
import { VOC_GET_GOAL, VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../../../../constants/goals';
import { useCoreOverviewContext } from '../../../hooks/useCoreOverviewContext';

export const Overview = () => {
  const { timeframe, userEmplCtgryNm, unit, swmDetailsData, SafetyIncOshaLtGlbiData } = useCoreOverviewContext();
  const isNOM = unit === 'NOM';
  const isPCT = unit === 'PCT';
  const userEmplLinkAccess =
    userEmplCtgryNm.toUpperCase().includes('SALAR') ||
    userEmplCtgryNm.toUpperCase().includes('OFFICER') ||
    userEmplCtgryNm.toUpperCase().includes('CORP_CNTRC');

  if (!swmDetailsData[0]) return <Header textAlign='center'>Invalid or Unauthorized </Header>;

  const metrics = swmDetailsData[0];

  const data = [
    [
      { stringValue: 'Days Safe', link: userEmplLinkAccess ? { to: `/core/safety` } : undefined },
      { stringValue: SafetyIncOshaLtGlbiData.length > 0 ? SafetyIncOshaLtGlbiData[0].DAYS_SAFE : '-' },
      { stringValue: '-' },
      { stringValue: '-' }
    ],
    [
      {
        stringValue: 'Sales',
        popup: {
          content: (
            <>
              <Header textAlign='center'>Sales:</Header>
              <p>Sales Plan data includes a financial hedge, so will not add up from Store, District, and Region levels</p>
            </>
          ),
          icon: 'info circle',
          color: 'blue'
        }
      },
      { dollarValue: metrics[timeframe + '_SLS_AMT_ACT'] },
      isNOM ? ({
        compareStyle: true,
        dollarValue: isNOM ? metrics[timeframe + '_SLS_VP'] : undefined,
      }):({
        compareStyle: true,
        pctValue: isPCT ? metrics[timeframe + '_SLS_AMT_VP_PCT'] : undefined,
        decimals: 2,
      }),
      isNOM ? ({
        compareStyle: true,
        dollarValue: isNOM ? metrics[timeframe + '_SLS_AMT_VLY'] : undefined,
      }) : ({
        compareStyle: true,
        pctValue: isPCT ? metrics[timeframe + '_SLS_AMT_VLY_PCT'] : undefined,
        decimals: 2,
      }),
    ],
    [
      { stringValue: 'Units' },
      { stringValue: metrics[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        compareStyle: true,
        stringValue: isNOM
          ? metrics[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? metrics[timeframe + '_UNTS_VLY_PCT'] : undefined,
        decimals: 2,
      }
    ],
    [
      { stringValue: 'Transactions' },
      { stringValue: metrics[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        compareStyle: true,
        stringValue: isNOM
          ? metrics[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? metrics[timeframe + '_TRNS_VLY_PCT'] : undefined,
        decimals: 2,
      }
    ],
    [
      { stringValue: 'Average Ticket' },
      { dollarValue: metrics[timeframe + '_AVG_TCKT_ACT'], decimals: 2 },
      { stringValue: '-' },
      {
        compareStyle: true,
        dollarValue: isNOM ? metrics[timeframe + '_AVG_TCKT_VLY'] : undefined,
        pctValue: isPCT ? metrics[timeframe + '_AVG_TCKT_VLY_PCT'] : undefined,
        decimals: 2,
      }
    ],
    [
      { stringValue: 'Units per Basket' },
      {
        stringValue: (metrics['TY_' + timeframe + '_UNT_SLS'] / metrics['TY_' + timeframe + '_TXN_CNT']).toLocaleString(
          undefined,
          { maximumFractionDigits: 2 }
        )
      },
      { stringValue: '-' },
      {
        compareStyle: true,
        stringValue: isNOM
          ? (
            metrics['TY_' + timeframe + '_COMP_UNT_SLS'] / metrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
            metrics['LY_' + timeframe + '_COMP_UNT_SLS'] / metrics['LY_' + timeframe + '_COMP_TXN_CNT']
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : undefined,
        pctValue: isPCT
          ? (metrics['TY_' + timeframe + '_COMP_UNT_SLS'] / metrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
            metrics['LY_' + timeframe + '_COMP_UNT_SLS'] / metrics['LY_' + timeframe + '_COMP_TXN_CNT']) /
          (metrics['LY_' + timeframe + '_COMP_UNT_SLS'] / metrics['LY_' + timeframe + '_COMP_TXN_CNT'])
          : undefined,
        decimals: 2
      }
    ],
    [
      { stringValue: 'On-Shelf Availability', link: { to: `/core/osa` } },
      { stringValue: '-' },
      { stringValue: '-' },
      { stringValue: '-' }
    ],
    [
      { stringValue: 'SMD', link: { to: `/core/smd` } },
      { pctValue: metrics[timeframe + '_SMD_ACT'], decimals: 2 },
      {
        stringValue: isNOM
          ? metrics[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
          : '-',
        customStyle: getCompareStyle(-1 * metrics[timeframe + '_SMD_VP']),
        postIcon: getCompareIconStyle(Icon, -1 * metrics[timeframe + '_SMD_VP'])
      },
      {
        stringValue: isNOM
          ? metrics[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
          : '-',
        customStyle: getCompareStyle(-1 * metrics[timeframe + '_SMD_VLY']),
        postIcon: getCompareIconStyle(Icon, -1 * metrics[timeframe + '_SMD_VLY'])
      }
    ],
    [
      { stringValue: 'Labor', link: { to: `/core/labor` } },
      {
        stringValue: metrics[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'
      },
      isNOM ? ({
        stringValue: isNOM
        ? metrics[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'
        : undefined,
        customStyle: {
          color: metrics[timeframe + '_LBR_VF_PCT'] > 0.01 || metrics[timeframe + '_LBR_VF_PCT'] < -0.01 ? 'red' : 'green'
        }
      }) : ({
        pctValue: isPCT ? metrics[timeframe + '_LBR_VF_PCT'] : undefined,
        decimals: 2,
        customStyle: {
          color: metrics[timeframe + '_LBR_VF_PCT'] > 0.01 || metrics[timeframe + '_LBR_VF_PCT'] < -0.01 ? 'red' : 'green'
        }
      }),
      { stringValue: '-' }
    ],
    [
      { stringValue: 'GET', link: { to: `/core/ltsa` } },
      {
        pctValue: metrics[timeframe + '_GET_ACT'],
        decimals: 2,
        customStyle: { color: metrics[timeframe + '_GET_ACT'] >= VOC_GET_GOAL ? 'green' : 'red' },
        postIcon: getCompareIconStyle(Icon, metrics[timeframe + '_GET_ACT'], VOC_GET_GOAL)
      },
      { stringValue: '-' },
      {
        decimals: 2,
        pctValue: isNOM ? metrics[timeframe + '_GET_VLY'] : '-',
        compareStyle: true,
        postIcon: getCompareIconStyle(Icon, metrics[timeframe + '_GET_VLY'])
      }
    ],
    [
      { stringValue: 'LTSA', link: { to: `/core/ltsa` } },
      {
        pctValue: metrics[timeframe + '_LTSA_ACT'],
        decimals: 2,
        customStyle: { color: metrics[timeframe + '_LTSA_ACT'] >= VOC_LTSA_GOAL ? 'green' : 'red' },
        postIcon: getCompareIconStyle(Icon, metrics[timeframe + '_LTSA_ACT'],VOC_LTSA_GOAL)
      },
      { stringValue: '-' },
      {
        pctValue: isNOM ? metrics[timeframe + '_LTSA_VLY'] : '-',
        compareStyle: true,
        decimals: 2,
        postIcon: getCompareIconStyle(Icon, metrics[timeframe + '_LTSA_VLY'])
      }
    ],
    [
      { stringValue: 'LTPA', link: { to: `/core/voc` } },
      {
        pctValue: metrics[timeframe + '_ONLINE_SAT_ACT'] ? metrics[timeframe + '_ONLINE_SAT_ACT'] : undefined,
        decimals: 2,
        customStyle: {
          color:
            metrics[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
              ? 'green'
              : metrics[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                ? 'red'
                : 'black'
        },
        postIcon: getCompareIconStyle(Icon, metrics[timeframe + '_ONLINE_SAT_ACT'],VOC_LTPA_GOAL)
      },
      { stringValue: '-' },
      {
        stringValue: isNOM
          ? metrics[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + '%'
          : '--',
        compareStyle: true,
        pctValue: isPCT ? '-' : undefined,
        postIcon: getCompareIconStyle(Icon, metrics[timeframe + '_ONLINE_SAT_VLY'])
      }
    ],
    [
      { stringValue: 'Leads', link: { to: `/core/leads` } },
      { stringValue: metrics[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        stringValue: isNOM
          ? metrics[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? metrics[timeframe + '_LEADS_VLY_PCT'] : undefined,
        decimals: 2,
        compareStyle: true
      }
    ],
    [
      { stringValue: 'Measures', link: { to: `/core/meas` } },
      { stringValue: metrics[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        stringValue: isNOM
          ? metrics[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? metrics[timeframe + '_MEAS_VLY_PCT'] : undefined,
        decimals: 2,
        compareStyle: true
      }
    ],
    [
      {
        stringValue: 'Cashier Metrics',
        link: {
          to: `https://pr-mstr-so.homedepot.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-MYNUMBER-IS-20202-1&Project=PONE-SO&Port=0&evt=2048001&documentID=9C90664A48024856B23381BCFE86A029&&currentViewMedia=2&elementsPromptAnswers=E15D73A24D9970F1A0A15CBECADE46AE;E15D73A24D9970F1A0A15CBECADE46AE:`
        }
      },
      { stringValue: '-' },
      { stringValue: '-' },
      { stringValue: '-' }
    ],
    [
      { stringValue: 'HDPP - MA UA' },
      { pctValue: metrics[timeframe + '_MA_HDPP'], decimals: 2 },
      { pctValue: metrics[timeframe + '_MA_HDPP_VG'], compareStyle: true, decimals: 2 },
      { pctValue: metrics[timeframe + '_MA_HDPP_VLY'], compareStyle: true, decimals: 2 }
    ],
    [
      { stringValue: 'HDPP - GM UA' },
      { pctValue: metrics[timeframe + '_GM_HDPP'], decimals: 2 },
      { pctValue: metrics[timeframe + '_GM_HDPP_VG'], compareStyle: true, decimals: 2 },
      { pctValue: metrics[timeframe + '_GM_HDPP_VLY'], compareStyle: true, decimals: 2 }
    ]
  ];

  const dataObj = {
    headers: [
      { name: '' },
      { name: 'Actual' },
      { name: `vP/vF ${isPCT ? '%' : '($)'}` },
      { name: `vLY ${isPCT ? '%' : '($)'}` }
    ],
    data: data
  };

  return (
    <>
      <DataTable data={dataObj} />
    </>
  );
};

import { Grid, Tab } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';
import { useState } from 'react';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { Link } from '../../../../../../components/Common/Link';
import { MenuItems } from '../../../../../../components/Common/MenuItems';
import { UnitMenu } from '../../../../../../components/Common/UnitMenu';
import { getCompareStyle, getPercentString } from '../../../../../../components/Common/TableCellUtils';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../../../../utils/string';
import { formatNumberWithCommas } from '../../../../../../utils/formatter';
import { useLaborContext } from '../../hooks/useLaborContext';
import { useLaborQueries } from '../../hooks/useLaborQueries';
import { getRowSubLevelNm } from '../../../../../../utils/getRowLevelNm';

const headersBySelection = ({ unit, dataPerspective, daysLaborDst, timeframe }) => {
  if (unit === 'NOM' && daysLaborDst === 'Total') {
    return [
      { name: 'HRS ' },
      (timeframe !== 'WTD' || new Date().getDay() === 1) && timeframe === 'WTD' ? { name: 'O/T ' } : { name: 'PROJ O/T ' },
      { name: 'AvF ' },
      { name: 'AvS ' }
    ];
  }
  if (unit === 'PCT' && daysLaborDst === 'Total') {
    return [
      { name: 'HRS ' },
      (timeframe !== 'WTD' || new Date().getDay() === 1) && timeframe === 'WTD' ? { name: 'O/T ' } : { name: 'PROJ O/T ' },
      { name: 'AvF % ' },
      { name: 'AvS % ' }
    ];
  }
  if (unit === 'NOM' && daysLaborDst === 'Wkdy/Wknd' && dataPerspective === 'AVF') {
    return [{ name: 'WKDY AvF ' }, { name: 'WKND AvF ' }, { name: 'Total AvF ' }];
  }
  if (unit === 'NOM' && daysLaborDst === 'Wkdy/Wknd' && dataPerspective === 'AVS') {
    return [{ name: 'WKDY AvS ' }, { name: 'WKKD AvS ' }, { name: 'Total AvS ' }];
  }
  if (unit === 'PCT' && daysLaborDst === 'Wkdy/Wknd' && dataPerspective === 'AVS') {
    return [{ name: 'WKDY AvS % ' }, { name: 'WKKD AvS % ' }, { name: 'Total AvS % ' }];
  }
  if (unit === 'PCT' && daysLaborDst === 'Wkdy/Wknd' && dataPerspective === 'AVF') {
    return [{ name: 'WKDY AvF % ' }, { name: 'WKND AvF % ' }, { name: 'Total AvF % ' }];
  }
  return [{ name: 'HRS ' }, { name: 'PROJ O/T ' }, { name: 'AvF ' }, { name: 'AvS ' }];
};

const bodyBySelection = ({ unit, daysLaborDst, dataPerspective, timeframe, row }) => {
  if (daysLaborDst === 'Total') {
    const avF = unit === 'NOM' ? row[timeframe + '_VF'] : row[timeframe + '_VF_PCT'] - 1;
    const avS =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS'] - row[timeframe + '_SCH_HRS'])
        : (row[timeframe + '_ACT_HRS'] - row[timeframe + '_SCH_HRS']) / row[timeframe + '_SCH_HRS'];
    return [
      {
        stringValue: Math.round(row[timeframe + '_ACT_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 }),
        sortedBy: Math.round(row[timeframe + '_ACT_HRS'])
      },
      timeframe !== 'WTD' || (new Date().getDay() === 1 && timeframe === 'WTD')
        ? {
            stringValue: formatNumberWithCommas(Math.round(row[timeframe + '_OT_HRS'])),
            sorterBy: Math.round(row[timeframe + '_OT_HRS'])
          }
        : {
            stringValue: formatNumberWithCommas(Math.round(row[timeframe + '_PROJ_OT_HRS'])),
            sorterBy: Math.round(row[timeframe + '_PROJ_OT_HRS'])
          },
      {
        stringValue: unit !== 'NOM' ? getPercentString(avF, 2) : formatNumberWithCommas(avF),
        sorterBy: avF,
        customStyle: unit !== 'NOM' ? getCompareStyle(avF) : undefined
      },
      {
        stringValue: unit !== 'NOM' ? getPercentString(avS, 2) : formatNumberWithCommas(avS),
        sorterBy: avS,
        customStyle: unit !== 'NOM' ? getCompareStyle(avS) : undefined
      }
    ];
  }
  if (daysLaborDst === 'Wkdy/Wknd' && dataPerspective === 'AVF') {
    const WKDYAvF =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS_WEEKDAY'] - row[timeframe + '_FCST_HRS_WEEKDAY'])
        : (row[timeframe + '_ACT_HRS_WEEKDAY'] - row[timeframe + '_FCST_HRS_WEEKDAY']) /
          row[timeframe + '_FCST_HRS_WEEKDAY'];
    const WKNDAvF =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS_WEEKEND'] - row[timeframe + '_FCST_HRS_WEEKEND'])
        : (row[timeframe + '_ACT_HRS_WEEKEND'] - row[timeframe + '_FCST_HRS_WEEKEND']) /
          row[timeframe + '_FCST_HRS_WEEKEND'];
    const TotalAvF =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS'] - row[timeframe + '_FCST_HRS'])
        : (row[timeframe + '_ACT_HRS'] - row[timeframe + '_FCST_HRS']) / row[timeframe + '_FCST_HRS'];
    return [
      {
        stringValue: unit === 'NOM' ? WKDYAvF : getPercentString(WKDYAvF),
        customStyle: unit !== 'NOM' ? getCompareStyle(row[timeframe + '_VF_PCT_WEEKDAY']) : undefined
      },
      {
        stringValue: WKNDAvF || '--'
      },
      {
        stringValue: unit === 'NOM' ? TotalAvF : getPercentString(TotalAvF),
        customStyle: unit !== 'NOM' ? getCompareStyle(row[timeframe + '_VF_PCT']) : undefined
      }
    ];
  }
  if (daysLaborDst === 'Wkdy/Wknd' && dataPerspective === 'AVS') {
    const WKDYAvS =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS_WEEKDAY'] - row[timeframe + '_SCH_HRS_WEEKDAY'])
        : (row[timeframe + '_ACT_HRS_WEEKDAY'] - row[timeframe + '_SCH_HRS_WEEKDAY']) / row[timeframe + '_SCH_HRS_WEEKDAY'];
    const WKNDAvS =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS_WEEKEND'] - row[timeframe + '_SCH_HRS_WEEKEND'])
        : (row[timeframe + '_ACT_HRS_WEEKEND'] - row[timeframe + '_FCST_HRS_WEEKEND']) /
          row[timeframe + '_FCST_HRS_WEEKEND'];
    const TotalAvS =
      unit === 'NOM'
        ? Math.round(row[timeframe + '_ACT_HRS'] - row[timeframe + '_SCH_HRS'])
        : (row[timeframe + '_ACT_HRS'] - row[timeframe + '_SCH_HRS']) / row[timeframe + '_SCH_HRS'];
    return [
      {
        stringValue: unit === 'NOM' ? WKDYAvS : getPercentString(WKDYAvS),
        customStyle: unit !== 'NOM' ? getCompareStyle(row[timeframe + '_VS_PCT_WEEKDAY']) : undefined
      },
      {
        stringValue: WKNDAvS || '--'
      },
      {
        stringValue: unit === 'NOM' ? TotalAvS : getPercentString(TotalAvS),
        customStyle: unit !== 'NOM' ? getCompareStyle(row[timeframe + '_VS_PCT']) : undefined
      }
    ];
  }
  return [];
};

export const LaborListTab = () => {
  const [unit, setUnit] = useState('NOM');
  const [classification, setClassification] = useState('Total');
  const [daysLaborDst, setDaysLaborDst] = useState('Total');
  const { settimeframe, timeframe, level, fpToggle } = useLaborContext();
  const { dataLaborOverview } = useLaborQueries({ fpToggle, timeframe });
  const [dataPerspective, setDataPerspective] = useState('AVF');
  const laborDetailFiltered = dataLaborOverview.filter(
    row =>
      row && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level].FILTER_BY_METRIC && row['CLASSIFICATION'] === classification
  );
  const detailDataObj = {
    headers: [
      { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} ` },
      ...headersBySelection({ classification, unit, daysLaborDst, dataPerspective, timeframe })
    ],
    data: laborDetailFiltered.map(row => [
      {
        link: {
          to: `/${METRIC_CONSTANTS[level].urlLinkLevel}/labor`,
          params: {
            [METRIC_CONSTANTS[level].urlSubLevelParam]: String(row[METRIC_CONSTANTS[level].propToRender]).padStart(4, '0')
          }
        },
        stringValue: getRowSubLevelNm({ row, level })
      },
      ...bodyBySelection({ daysLaborDst, row, timeframe, unit, dataPerspective })
    ]),
    sortable: true,
    sortableDefault: 0,
    sortableColumns: [0]
  };

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Column width={7}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview`}>
              Back to Overview
            </Link>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={9}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <MenuItems
              activeItem={daysLaborDst}
              dataTest='totalVsWkdy'
              items={['Total', 'Wkdy/Wknd']}
              setItem={setDaysLaborDst}
            />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={16} textAlign='right'>
          {daysLaborDst === 'Wkdy/Wknd' ? (
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column floated='right' textAlign='right'>
                <MenuItems
                  activeItem={dataPerspective}
                  dataTest='dataPerspective'
                  items={['AVF', 'AVS']}
                  setItem={setDataPerspective}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>&nbsp;</Grid.Row>
          )}
        </Grid.Column>
      </Grid>
      <DataView testId={'laborDistrictTab'} className={''}>
        <DataView.Title position='center'>Labor Overview - Divisions</DataView.Title>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          Data as of {dataLaborOverview[0]?.TRNSFM_DT || 'Loading...'}
        </div>
        <Grid>
          <Grid.Column textAlign='right' width={16}>
            <Grid.Row>
              Labor Categories
              <div>
                <select
                  name='classification'
                  onChange={e => {
                    setClassification(e.target.value);
                  }}
                  value={classification}
                  style={{ padding: 10 }}>
                  <option value='Total' selected>
                    Total
                  </option>
                  <option value='Core'>Core</option>
                  <option value='Specialty'>Specialty</option>
                  <option value='Operations'>Operations</option>
                  <option value='Tool/Pro'>Tool/Pro</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid>
        <DataView.DataTable data={detailDataObj} />
      </DataView>
    </Tab.Pane>
  );
};

import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { shrinkDataWithScore } from '../utils/RTVCreditCoreData';

export const RTVCreditCoreTable = ({ shrinkData, shrinkScoreData, timeframe, dataAsOf }) => {
  const level = 'core';
  return (
    <DataView className='mt-4'>
      <DataView.Title>
        Invalid Scans - Core
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={shrinkDataWithScore({ shrinkData, shrinkScoreData, timeframe, level })} />
    </DataView>
  );
};

import { Header, Loader } from 'semantic-ui-react';
import { SmdDetailDeptTab } from './Region/SmdDetailDeptTab';
import { SmdDetailDistrictListTab } from './Region/SmdDetailDistrictListTab';
import { SmdDetailReasonCodesTab } from './Region/SmdDetailReasonCodesTab';
import { TabMenu } from '../Common/TabMenu';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';

import { useQueryParams } from '../../hooks/useQueryParams';
import useQueryApi from '../../hooks/useQueryApi';

export const SmdDetailRgn = ({ fpToggle, level, settimeframe, timeframe }) => {
  const { rgnNbr = 0 } = useQueryParams();
  const { data: reasonCodesData, isLoading: isLoadingReasonCodes } = useQueryApi('querySmdByRgn', {
    dstNbr: rgnNbr,
    fpToggle
  });
  const { data: districtCompsData = [], isLoading: isLoadingDistrictComps } = useQueryApi('querySwmSmdOvrUndrByRgn', {
    locNbr: rgnNbr,
    fpToggle
  });
  const { data: smdDeptData = [], isLoading: isLoadingSmdDeptData } = useQueryApi('querySmdDeptByRgn', {
    locNbr: rgnNbr,
    fpToggle
  });
  const smdDeptDataFiltered = smdDeptData.filter(row => row && row.METRIC_LEVEL === 'RGN');

  if (isLoadingDistrictComps || isLoadingSmdDeptData || isLoadingReasonCodes) return <Loader active>Loading...</Loader>;

  const regionName = districtCompsData[0]?.RGN_NM;
  const transformDate = districtCompsData[0]?.TRNSFM_DT;

  const tabs = [
    {
      menuItem: 'District List',
      render: () => <SmdDetailDistrictListTab data={districtCompsData} level={level} timeframe={timeframe} />
    },
    {
      menuItem: 'Depts',
      render: () => <SmdDetailDeptTab data={smdDeptDataFiltered} timeframe={timeframe} />
    },
    {
      menuItem: 'Reason Codes',
      render: () => <SmdDetailReasonCodesTab data={reasonCodesData} timeframe={timeframe} />
    }
  ];

  return (
    <div>
      <Header className='mb-1 text-capitalize' textAlign='left'>
        SMD - {level} {rgnNbr}, {String(regionName).toLowerCase()}
      </Header>

      <p>Data as of {transformDate}</p>

      <span>Timeframe:</span>
      <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />

      <TabMenu className='mt-4' panes={tabs} />
    </div>
  );
};

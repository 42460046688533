import { useState } from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { capitalizeFirstLetter } from '../../../utils/string';
import { DataTable } from '../../Common/DataTable';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import {
  aggregateByDivNbr,
  moveProXtraToFirst,
  moveServicesAndTotal,
  salesDataFixed,
  salesRentalDataBuilder,
  salesRentalSubLevelDataBuilder,
  slsDepts,
  footerTotal
} from '../utils/salesRentalDataBuilder';
import { SalesProRentalLevelList } from './SalesProRentalLevelList';
import { UnitsAsComponent } from './UnitsAsComponent';
import { STORE } from '../../../utils/constantsByLevel';

const { levelName: storeLevelName } = STORE;

export const SalesProRental = ({ salesData, subLevelSalesData }) => {
  const { level, timeframe, locNbr } = useSpecialtyProRentalContext();
  const slsLevel = capitalizeFirstLetter(level.levelName);
  const slsSubLevel = capitalizeFirstLetter(level.subLevel.levelName);
  const { subMetric, subLevel, levelName } = level;
  const { levelName: subLevelName } = subLevel;
  const [deptNmLbr, setDeptNmLbr] = useState('Total');
  const currentForCore = `${subMetric}_NBR`;
  const [unit, setUnit] = useState('NOM');
  const aggregatedData = aggregateByDivNbr(
    subLevelSalesData.filter(level => level['MER_DEPT_NM'] === deptNmLbr),
    currentForCore
  );
  const aggregatedDataArray = Object.values(aggregatedData);

  const updatedSalesData = moveServicesAndTotal(moveProXtraToFirst(salesDataFixed(salesData)));

  if (!updatedSalesData.length) return <Header textAlign='center'>No data to show in Sales</Header>;

  const { dataSalesRentalDataBuilder } = salesRentalDataBuilder(
    updatedSalesData,
    timeframe,
    subMetric,
    unit,
    levelName,
    locNbr
  );
  const sublevelFooter = footerTotal(updatedSalesData, timeframe, unit);

  const { dataSalesRentalSubLevelDataBuilder } = salesRentalSubLevelDataBuilder(
    subLevelName,
    aggregatedDataArray,
    timeframe,
    unit,
    levelName,
    sublevelFooter
  );

  const slsDeptsFilter = slsDepts(salesData);

  const tabs = [
    { menuItem: slsLevel, render: () => <DataTable data={dataSalesRentalDataBuilder} /> },
    {
      menuItem: `${slsSubLevel} List`,
      render: () => (
        <SalesProRentalLevelList
          slsDepts={slsDeptsFilter}
          dataObj={dataSalesRentalSubLevelDataBuilder}
          setDeptNmLbr={setDeptNmLbr}
          deptNmLbr={deptNmLbr}
        />
      )
    }
  ];

  return (
    <>
      <Header textAlign='left'>Sales</Header>
      <UnitsAsComponent unit={unit} setUnit={setUnit} />

      {levelName === storeLevelName ? (
        <DataTable data={dataSalesRentalDataBuilder} />
      ) : (
        <Tab className='mt-2' panes={tabs} />
      )}

      <p className='mt-2'>
        ** Pro Xtra Registered Tender & Services are not included in the total due to overlapping sales in the department
        totals.
      </p>
    </>
  );
};

import { Header, Loader } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { Link } from '../../../../../components/Common/Link';
import { TabMenu } from '../../../../../components/Common/TabMenu';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { ServicesPrgmPipeline } from '../components/ServicesPrgmPipeline';
import { ServicesPrgmSales } from '../components/ServicesPrgmSales';

export const CoreServicesPrgmPage = ({ timeframe, settimeframe, fpToggle }) => {
  const { smryPrgmNm } = useQueryParams();

  const {
    data: ProgramSalesData,
    error: ProgramSalesDataError,
    isLoading: ProgramSalesDataLoading
  } = useQueryApi('query/queryServicesDtlPrgmByMetricLevel/core/0000/' + smryPrgmNm, {
    fpToggle,
    isCore: true
  });
  const {
    data: ProgramPipelineData,
    error: ProgramPipelineDataError,
    isLoading: ProgramPipelineDataLoading
  } = useQueryApi('query/queryServicesPrgmPipelineByMetricLevel/core/0000/' + smryPrgmNm, {
    fpToggle,
    isCore: true
  });

  const noData =
    !ProgramPipelineData || ProgramPipelineData.length === 0 || !ProgramSalesData || ProgramSalesData.length === 0;

  if (ProgramSalesDataLoading || ProgramPipelineDataLoading) return <Loader active>Loading...</Loader>;
  if (ProgramPipelineDataError || ProgramSalesDataError) return <Header textAlign='center'>Error fetching data</Header>;
  if (noData) {
    return <Header textAlign='center'>No data available</Header>;
  }

  return (
    <>
      <DataView className='mt-4'>
        <DataView.Title textAlign='center'>
          {ProgramSalesData[0].MER_DEPT_CD}-{ProgramSalesData[0].SUMMARY_PROGRAM_NAME} - Core
        </DataView.Title>
        <DataView.Controls>
          <div className='d-flex justify-content-between'>
            <Link to={`/core/services`}>Back to Services</Link>
            <div>
              Timeframe:
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </div>
          </div>
        </DataView.Controls>
      </DataView>
      <TabMenu
        className='mb-4'
        color='orange'
        panes={[
          {
            menuItem: 'Sales',
            render: () => <ServicesPrgmSales data={ProgramSalesData} level={'core'} timeframe={timeframe} />
          },
          {
            menuItem: 'Pipeline',
            render: () => <ServicesPrgmPipeline data={ProgramPipelineData} level={'core'} timeframe={timeframe} />
          }
        ]}
      />
    </>
  );
};

import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useFetchLevelName } from '../../../hooks/useFetchLevelName';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';

export const SpecialtyProRentalContext = createContext();

export const SpecialtyProRentalProvider = props => {
  const { children, timeframe, setTimeframe, fpToggle } = props;
  const { level: levelName } = useParams();
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const locNbrFormated = formatLevelNumber(locNbr);
  const { currentLevelName, isLoadingName: isLoadingCurrentLevelName } = useFetchLevelName({
    level: levelName,
    locNbr: locNbrFormated,
    fpToggle
  });

  const level = METRIC_CONSTANTS[levelName];

  const value = {
    fpToggle,
    timeframe,
    setTimeframe,
    level,
    locNbr,
    currentLevelName,
    isLoadingCurrentLevelName
  };

  return <SpecialtyProRentalContext.Provider value={value}>{children}</SpecialtyProRentalContext.Provider>;
};

import { DataView } from '../../Common/DataView';
import { useMemo, useState } from 'react';
import { UnitMenu } from '../../Common/UnitMenu';
import { Select } from '../../Common/Select';
import { footerTotal, headers, laborDataRow, laborFooterRow } from '../utils/specialityProRentalLabor';
import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { METRIC_CONSTANTS, STORE } from '../../../utils/constantsByLevel';
import { TOTAL } from '../utils/specialtyProRentalServices';

const { levelName: storeLevelName } = STORE;

export const SpecialtyProRentalLabor = ({ proSpecTrLbrData = [], queryProSpecTrLbrPlusOneData = [] }) => {
  const [unit, setUnit] = useState('NOM');
  const { timeframe } = useSpecialtyProRentalContext();
  const { level } = useSpecialtyProRentalContext();
  const { levelName } = level;

  const items = useMemo(
    () =>
      proSpecTrLbrData
        .filter(dept => dept?.DEPT_NM)
        .map(dept => ({
          value: dept?.DEPT_NM,
          text: dept?.DEPT_NM
        }))
        .sort((a, b) => b.text.localeCompare(a.text)),
    [proSpecTrLbrData]
  );
  const [deptSelected, setDeptSelected] = useState(TOTAL);
  const levelData = queryProSpecTrLbrPlusOneData.filter(
    row => row.METRIC_LEVEL === METRIC_CONSTANTS[levelName].METRIC_LEVEL
  );
  const subLevelData = queryProSpecTrLbrPlusOneData.filter(
    row => row.METRIC_LEVEL === METRIC_CONSTANTS[levelName].subMetric
  );

  const deptFilteredList = useMemo(
    () =>
      subLevelData.filter(row => {
        if (levelName === storeLevelName) {
          return row['DEPT_NM'] !== TOTAL;
        } else {
          return row['DEPT_NM'] === deptSelected;
        }
      }),
    [subLevelData, deptSelected]
  );

  const deptFilteredListLevel = useMemo(
    () => levelData.filter(row => row['DEPT_NM'] === deptSelected),
    [levelData, deptSelected]
  );

  const footer = useMemo(() => footerTotal(deptFilteredListLevel, timeframe), [deptFilteredListLevel, timeframe]);

  if (!proSpecTrLbrData.length || !queryProSpecTrLbrPlusOneData.length)
    return <Header textAlign='center'>No data to show in Labor.</Header>;

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers: headers(levelName, unit),
    footer: [laborFooterRow(footer, timeframe, unit)],
    data: deptFilteredList.map(row => laborDataRow(row, timeframe, level, unit))
  };

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Labor</DataView.Title>

      <DataView.Controls className='d-flex justify-content-between mb-4'>
        <section className='select-section'>
          <label>Units:</label>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </section>
        {levelName !== storeLevelName && (
          <section style={{ textAlign: 'right' }}>
            <Select label='Dept:' name='dept' value={deptSelected} items={items} onChange={setDeptSelected} />
          </section>
        )}
      </DataView.Controls>

      <DataView.DataTable data={data} />
    </DataView>
  );
};

import { useContext } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { OnTimeDeliveryAssoc } from './OnTimeDeliveryAssoc';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { onTimeDeliveryData } from '../../../../utils/OnTimeDelivery';
import { DataView } from '../../../../../../components/Common/DataView';

export const OnTimeDelivery = () => {

    const { timeframe } = useContext(StoreOrderFulfillmentContext);
    const [ storeOntimeDelivery ] = useQueryKeys(['storeOntimeDelivery']);
    const data = onTimeDeliveryData(storeOntimeDelivery, timeframe);
    
    return (
      <DataView className='my-4'>
        <DataView.Title as='h3'>Delivery</DataView.Title>
        <DataView.DataTable data={data} />
        <DataView.Title as='h3'>Delivery On Time</DataView.Title>
        <OnTimeDeliveryAssoc />
      </DataView>
    );
    
};
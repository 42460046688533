import { AssociateDataTable } from './CreditContainer/components/AssociateDataTable';
import { ChannelDataTable } from './CreditContainer/components/ChannelDataTable';
import { Tab } from 'semantic-ui-react';

export const ChannelAssociate = () => {
  const viewOption = [
    {
      menuItem: 'Channel',
      render: () => (
        <Tab.Pane>
          <ChannelDataTable />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Associate',
      render: () => (
        <Tab.Pane>
          <AssociateDataTable />
        </Tab.Pane>
      )
    }
  ];

  return <Tab panes={viewOption} />;
};

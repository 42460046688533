import { METRIC_CONSTANTS, LEVEL_NM } from './constantsByLevel';

const {
  division: { levelName: divisionLevelName }
} = METRIC_CONSTANTS;

//TODO: Remove this hardcoded values when all tables have DIV_NM
const DIVISION_NAMES = {
  1: 'SOUTHERN',
  2: 'WESTERN',
  4: 'NORTHERN'
};

export const getRowLevelNm = ({ row, level, levelNbr = 0 }) => {
  const { [LEVEL_NM]: levelNm, levelName } = METRIC_CONSTANTS[level];

  const rowLevelNm = row?.[levelNm];

  if (!Boolean(rowLevelNm) && Boolean(levelNbr) && levelName === divisionLevelName) {
    return DIVISION_NAMES[levelNbr];
  }

  return rowLevelNm;
};

export const getRowSubLevelNm = ({ row, level, levelNbr = 0 }) => {
  const {
    subLevel: { levelName: subLevelName }
  } = METRIC_CONSTANTS[level];

  return getRowLevelNm({ row, level: subLevelName, levelNbr });
};

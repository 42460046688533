import { Header, Loader, Segment } from 'semantic-ui-react';
import { Breadcrumb } from '../../../../../../components/Common/Breadcrumb';
import { DataView } from '../../../../../../components/Common/DataView';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { useOsaOhmNoLocTagsQueries } from '../../hooks/useOsaOhmNoLocTagsQueries';
import { getLastTimeframe } from '../../../../../../utils/timeFrameUtils';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';
import { getBreadcrumbItems, getTransformDate, getOhmNoLocTagsDataTable } from '../../utils/osa/osaOhmNoLocTags';

const { core: level } = METRIC_CONSTANTS;

export const OsaOhmNoLocTagsPage = props => {
  const { timeframe, settimeframe, fpToggle } = props;
  const { isError, isLoading, osaOhmNoLocTagsByCoreData } = useOsaOhmNoLocTagsQueries({ fpToggle });

  if (isError) return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;
  if (!osaOhmNoLocTagsByCoreData.length) return <Header textAlign='center'>Invalid Store Number</Header>;

  const ltf = getLastTimeframe(timeframe);
  const { levelName, subLevel } = level;

  const breadcrumbItems = getBreadcrumbItems({ levelName });
  const transformDate = getTransformDate({ osaOhmNoLocTagsByCoreData });
  const ohmNoLocTagsDataTable = getOhmNoLocTagsDataTable({ osaOhmNoLocTagsByCoreData, level, subLevel, timeframe, ltf });

  return (
    <Segment>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-3'>
        <DataView.Title>
          OHM No Loc Tags % - {levelName}
          <Header.Subheader>Data as of {transformDate}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='d-flex flex-column align-items-center'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>

        <DataTable data={ohmNoLocTagsDataTable} />
      </DataView>
    </Segment>
  );
};

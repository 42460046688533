import { EN_US } from '../../../utils/constants';
import { buildSublevelLinkColumn } from '../../../utils/dataTable';
import { formatNumberWithCommas } from '../../../utils/formatter';
import { capitalizeFirstLetter } from '../../../utils/string';
import { formatColor } from '../../Common/TableCellUtils';
import { SPEC_PRO_RENTAL_PATH } from './consts';
import { STORE } from '../../../utils/constantsByLevel';

const { levelName: storeLevelName } = STORE;

const PXTRA_SIGNUP = 'PXTRA_SIGNUP';
const PXTRA_SIGNUP_AVG = 'PXTRA_SIGNUP_AVG';
const PRO_MRO_SLS = 'PRO_MRO_SLS';
const PRO_MRO_AVG = 'PRO_MRO_AVG';
const PRO_LTSA_PCT = 'PRO_LTSA_PCT';
const PRO_GET_PCT = 'PRO_GET_PCT';

export const headers = params => {
  const { level } = params;
  const {
    levelName,
    subLevel: { levelName: subLevelName }
  } = level;

  return [
    ...(levelName !== storeLevelName ? [{ name: `${capitalizeFirstLetter(subLevelName)} #` }] : []),
    { name: 'Pro Xtra Signups' },
    { name: 'Pro Xtra Signups Avg' },
    { name: 'Pro MRO Act' },
    { name: 'Pro MRO Avg' },
    { name: 'Pro LTSA' },
    { name: 'Pro GET' }
  ];
};

const isNegativeformatColor = (value, validation) => ({
  color: value < validation ? 'red' : 'black'
});

export const prokeyMetricsDataRows = (proKeyMetricsData, timeframe, level) => {
  const { levelName } = level;
  const to = `/${SPEC_PRO_RENTAL_PATH}`;

  const firstColumn = buildSublevelLinkColumn({
    level: levelName,
    row: proKeyMetricsData,
    to
  });
  return prokeyMetricsRows(proKeyMetricsData, timeframe, firstColumn, level);
};

export const prokeyMetricsFooterRow = (proKeyMetricsData, timeframe) => {
  const firstColumn = { stringValue: 'Total' };
  return prokeyMetricsRows(proKeyMetricsData, timeframe, firstColumn);
};

const prokeyMetricsRows = (proKeyMetricsData, timeframe, firstColumn, level = '') => {
  const { levelName } = level;
  const PRO_XTRA_SIGNUPS = `${timeframe}_${PXTRA_SIGNUP}`;
  const PRO_XTRA_SIGNUPS_AVG = `${timeframe}_${PXTRA_SIGNUP_AVG}`;
  const PRO_MRO_ACT = `${timeframe}_${PRO_MRO_SLS}`;
  const PRO_MRO_AVG_COL = `${timeframe}_${PRO_MRO_AVG}`;
  const PRO_LTSA = `${timeframe}_${PRO_LTSA_PCT}`;
  const PRO_GET = `${timeframe}_${PRO_GET_PCT}`;

  return [
    ...(levelName !== storeLevelName ? [firstColumn] : []),
    { stringValue: formatNumberWithCommas(proKeyMetricsData[PRO_XTRA_SIGNUPS], 0, EN_US) },
    {
      numValue: proKeyMetricsData[PRO_XTRA_SIGNUPS_AVG],
      customStyle: formatColor(proKeyMetricsData[PRO_XTRA_SIGNUPS_AVG], 8),
      decimals: 1
    },
    {
      dollarValue: proKeyMetricsData[PRO_MRO_ACT],
      decimals: 0,
      customStyle: isNegativeformatColor(proKeyMetricsData[PRO_XTRA_SIGNUPS_AVG], 0)
    },
    {
      dollarValue: proKeyMetricsData[PRO_MRO_AVG_COL],
      customStyle: formatColor(proKeyMetricsData[PRO_MRO_AVG_COL], 1200),
      decimals: 0
    },
    { pctValue: proKeyMetricsData[PRO_LTSA], customStyle: formatColor(proKeyMetricsData[PRO_LTSA], 0.85) },
    { pctValue: proKeyMetricsData[PRO_GET], customStyle: formatColor(proKeyMetricsData[PRO_GET], 0.85) }
  ];
};

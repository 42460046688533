import { Tab } from 'semantic-ui-react';
import { CompletePickUp } from './CompletePickUp';
import { CompleteDelivery } from './CompleteDelivery';
import { DataView } from '../../../../../../components/Common/DataView';

export default function Complete() {
  const tabs = [
    {
      menuItem: 'PICKUP',
      render: () => <CompletePickUp />
    },
    {
      menuItem: 'DELIVERY',
      render: () => <CompleteDelivery />
    }
  ];

  return (
    <DataView className='my-4'>

      <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />
    </DataView>
  );
}

import { DataView } from '../../Common/DataView';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { getSpecialtyKeyMetricsContDataTable } from '../utils/specialtyProRental';
import { STORE } from '../../../utils/constantsByLevel';

const { levelName: storeLevelName } = STORE;

export const SpecialtyKeyMetricsCont = props => {
  const { specialtyKeyMetricsContData } = props;
  const { level, timeframe } = useSpecialtyProRentalContext();
  const { levelName } = level;

  if (levelName === storeLevelName) return null;

  const specialtyKeyMetricsContDataTable = getSpecialtyKeyMetricsContDataTable({
    level,
    timeframe,
    specialtyKeyMetricsContData
  });

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Specialty Key Metrics Cont.</DataView.Title>

      <DataView.DataTable data={specialtyKeyMetricsContDataTable} />
    </DataView>
  );
};

import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { damagedMarkdownsCoreData } from '../utils/damagedMarkdownsCoreData';
import { DamagedMarkdownsCoreTable } from './DamagedMarkdownsCoreTable';
import { FocusMetricsTimeFrame } from './FocusMetricsTimeFrame';

export const DamagedMarkdownsCorePage = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkCoreContext();
  const level = 'core';
  const defaultParams = {
    fpToggle,
    isCore: true
  };
  const {
    data: shrinkDamagedData = [],
    isLoading: shrinkDamagedDataLoading,
    isError: shrinkDamagedError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);
  const {
    data: shrinkDamagedScoreData = [],
    isLoading: shrinkDamagedScoreDataLoading,
    isError: shrinkDamagedScoreDataError
  } = useQueryApi('queryShrinkMetricsScoresCore', defaultParams);

  if (shrinkDamagedDataLoading || shrinkDamagedScoreDataLoading) return <Loader active>Loading...</Loader>;
  if (shrinkDamagedError || shrinkDamagedScoreDataError) return <Header textAlign='center'>Something went wrong.</Header>;

  const recordsByLevel = shrinkDamagedScoreData.filter(row => row['TIME_FRAME'] === timeframe);

  return (
    <div>
      <FocusMetricsTimeFrame timeframe={timeframe} settimeframe={settimeframe} />
      <DamagedMarkdownsCoreTable
        dataAsOf={recordsByLevel[0]?.TRNSFM_DT}
        dataObject={damagedMarkdownsCoreData({
          shrinkData: shrinkDamagedData,
          shrinkScoreData: recordsByLevel,
          timeframe,
          level
        })}
      />
    </div>
  );
};

import { Header } from 'semantic-ui-react';
import { DataView } from '../../Common/DataView';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { getSpecialtyKeyMetricsDataTables } from '../utils/specialtyProRental';

export const SpecialtyProRentalKeyMetrics = ({ specialtyKeyMetricsData = [] }) => {
  const { timeframe } = useSpecialtyProRentalContext();
  const { level, locNbr } = useSpecialtyProRentalContext();

  if (!specialtyKeyMetricsData.length) return <Header textAlign='center'>No data to show in Specialty Key Metrics.</Header>;

  const dataTables = getSpecialtyKeyMetricsDataTables({ level, locNbr, timeframe, specialtyKeyMetricsData });

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Specialty Key Metrics</DataView.Title>

      {dataTables.map((table, index) => (
        <DataView.DataTable key={index} data={table} />
      ))}
    </DataView>
  );
};

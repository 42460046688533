import { DataView } from '../../../../../../components/Common/DataView';
import { TabMenu } from '../../../../../../components/Common/TabMenu';
import { DirTasksTab } from '../../components/Tabs/osa/DirTasksTab';
import { MgrAddTab } from '../../components/Tabs/osa/MgrAddTab';
import { LoggedWkTab } from '../../components/Tabs/osa/LoggedWkTab';

const getPanes = ({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, level, subLevel, timeframe, ltf }) => {
  return [
    {
      menuItem: 'DIR TASKS',
      render: () => (
        <DirTasksTab
          osaSidekickSkuBayData={osaSidekickSkuBayData}
          sidekickExeScoresPlusOneData={sidekickExeScoresPlusOneData}
          level={level}
          subLevel={subLevel}
          timeframe={timeframe}
          ltf={ltf}
        />
      )
    },
    {
      menuItem: 'MGR ADD',
      render: () => (
        <MgrAddTab
          osaSidekickSkuBayData={osaSidekickSkuBayData}
          sidekickExeScoresPlusOneData={sidekickExeScoresPlusOneData}
          level={level}
          subLevel={subLevel}
          timeframe={timeframe}
          ltf={ltf}
        />
      )
    },
    {
      menuItem: 'LOGGED WK',
      render: () => (
        <LoggedWkTab
          osaSidekickSkuBayData={osaSidekickSkuBayData}
          sidekickExeScoresPlusOneData={sidekickExeScoresPlusOneData}
          level={level}
          subLevel={subLevel}
          timeframe={timeframe}
          ltf={ltf}
        />
      )
    }
  ];
};

export const SidekickSkuBayTasksCompletion = props => {
  const { osaSidekickSkuBayData, sidekickExeScoresPlusOneData, level, subLevel, timeframe, ltf } = props;
  const panes = getPanes({ osaSidekickSkuBayData, sidekickExeScoresPlusOneData, level, subLevel, timeframe, ltf });

  return (
    <DataView className='mt-3'>
      <DataView.Title>Tasks Completion</DataView.Title>

      <TabMenu panes={panes} />
    </DataView>
  );
};

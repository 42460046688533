import { useEnhancedQueries } from '../../../hooks/useEnhancedQueries';
import { useOverviewContext } from '../../../hooks/useOverviewContext';
import { PlaceholderLoading } from '../../Loading/PlaceholderLoading';
import { Top40Btm40Skus as Top40Btm40SkusContainer } from '../../ProdHierDrilldown/Top40Btm40Skus';

export const Top40Btm40Skus = () => {
  const { timeframe, fpToggle } = useOverviewContext();
  const {
    isError,
    isLoading,
    results: [{ data: salesSkuData = [] }]
  } = useEnhancedQueries(['querySalesSku'], fpToggle);

  if (isError) return <h1>Something went wrong</h1>;
  if (isLoading) return <PlaceholderLoading />;

  return <Top40Btm40SkusContainer data={salesSkuData} timeframe={timeframe} />;
};

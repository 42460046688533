import React, { useState } from 'react'
import { Table, Header, Loader, Grid, Menu, Segment } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { ACCESS_LEVELS } from '../Common/AccessChecks'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getPercentString, getCompareStyle } from '../Common/TableCellUtils'

export const SafetyDetail = withRouter(({ timeframe, settimeframe, fpToggle, userMgtLvlId, userIsCorporate, location, levelName }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const incOshaLtGlbiQuery = useQuery(['querySafetyIncOshaLtGlbiByStr', { strNbr, fpToggle }], getData)
  const srcHhmNgtQuery = useQuery(['querySafetySrcHhmNgtByStr', { strNbr, fpToggle }], getData)

  const [oeNhEq, setOeNhEq] = useState('OVEREXERTION')

  if (incOshaLtGlbiQuery.isLoading || srcHhmNgtQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let incOshaLtGlbiData = incOshaLtGlbiQuery.data[0]
  let srcHhmNgtData = srcHhmNgtQuery.data[0]

  return (
    ( userMgtLvlId < ACCESS_LEVELS.DEPT_SUP || userIsCorporate)
      ?
      <>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`}>Back to Overview</Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} />
        </Grid>
        <Header textAlign='center' style={{ textTransform: 'capitalize' }}>Safety - Store - {strNbr}, {levelName}</Header>
        <Header textAlign='center'>Days Safe: {incOshaLtGlbiData.DAYS_SAFE}</Header>
        <Grid>
          <Grid.Column width={8} />
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
        <Segment>
          <Header textAlign='center'>Safety Walk Completion</Header>
          <Table textAlign='center' unstackable celled size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Metric</Table.HeaderCell>
                <Table.HeaderCell>Completion %</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>SRC</Table.Cell>
                <Table.Cell style={getCompareStyle(srcHhmNgtData[timeframe + '_COMPLETED_SRC_TASK_COUNT'] / srcHhmNgtData[timeframe + '_TOTAL_SRC_TASK_COUNT'] - 0.90)}>{getPercentString(srcHhmNgtData[timeframe + '_COMPLETED_SRC_TASK_COUNT'] / srcHhmNgtData[timeframe + '_TOTAL_SRC_TASK_COUNT'])}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>HHM</Table.Cell>
                <Table.Cell style={getCompareStyle(srcHhmNgtData[timeframe + '_COMPLETED_HHM_TASK_COUNT'] / srcHhmNgtData[timeframe + '_TOTAL_HHM_TASK_COUNT'] - 0.90)}>{getPercentString(srcHhmNgtData[timeframe + '_COMPLETED_HHM_TASK_COUNT'] / srcHhmNgtData[timeframe + '_TOTAL_HHM_TASK_COUNT'])}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Night Safety</Table.Cell>
                <Table.Cell style={getCompareStyle(srcHhmNgtData[timeframe + '_COMPLETED_NIGHT_SAFETY_TASK_COUNT'] / srcHhmNgtData[timeframe + '_TOTAL_NIGHT_SAFETY_TASK_COUNT'] - 0.90)}>{getPercentString(srcHhmNgtData[timeframe + '_COMPLETED_NIGHT_SAFETY_TASK_COUNT'] / srcHhmNgtData[timeframe + '_TOTAL_NIGHT_SAFETY_TASK_COUNT'])}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Header textAlign='center'>Safety Summary</Header>
          <Table textAlign='center' unstackable celled size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Metric</Table.HeaderCell>
                <Table.HeaderCell>Actual</Table.HeaderCell>
                <Table.HeaderCell>vP</Table.HeaderCell>
                <Table.HeaderCell>vLY</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Incidents</Table.Cell>
                <Table.Cell>{incOshaLtGlbiData[timeframe + '_INCIDENT_COUNT_WC_TOTAL']}</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LY_INCIDENT_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_INCIDENT_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiData[timeframe + '_INCIDENT_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_LY_INCIDENT_COUNT_WC_TOTAL']) / incOshaLtGlbiData[timeframe + '_LY_INCIDENT_COUNT_WC_TOTAL'])}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>OSHA</Table.Cell>
                <Table.Cell>{incOshaLtGlbiData[timeframe + '_OSHA_COUNT_WC_TOTAL']}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_WC_COUNTS_PLAN'] - incOshaLtGlbiData[timeframe + '_OSHA_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiData[timeframe + '_OSHA_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_WC_COUNTS_PLAN']) / incOshaLtGlbiData[timeframe + '_WC_COUNTS_PLAN'])}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LY_OSHA_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_OSHA_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiData[timeframe + '_OSHA_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_LY_OSHA_COUNT_WC_TOTAL']) / incOshaLtGlbiData[timeframe + '_LY_OSHA_COUNT_WC_TOTAL'])}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Lost Time</Table.Cell>
                <Table.Cell>{incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_WC_TOTAL']}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LT_COUNTS_PLAN'] - incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_LT_COUNTS_PLAN']) / incOshaLtGlbiData[timeframe + '_LT_COUNTS_PLAN'])}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LY_LOST_TIME_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])}>{getPercentString((incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - incOshaLtGlbiData[timeframe + '_LY_LOST_TIME_COUNT_WC_TOTAL']) / incOshaLtGlbiData[timeframe + '_LY_LOST_TIME_COUNT_WC_TOTAL'])}</Table.Cell>          </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Header textAlign='center'>Details</Header>
          <Menu fluid attached='top' widths={3} color='orange'>
            <Menu.Item
              name='OVEREXERTION'
              active={oeNhEq === 'OVEREXERTION'}
              content='OVEREXERTION'
              onClick={() => {
                setOeNhEq('OVEREXERTION')
              }}
            />
            <Menu.Item
              name='NEW_HIRES'
              active={oeNhEq === 'NEW_HIRES'}
              content='NEW HIRE'
              onClick={() => {
                setOeNhEq('NEW_HIRES')
              }}
            />
            <Menu.Item
              name='EQUIPMENT'
              active={oeNhEq === 'EQUIPMENT'}
              content='EQUIPMENT'
              onClick={() => {
                setOeNhEq('EQUIPMENT')
              }}
            />
          </Menu>
          <Table textAlign='center' unstackable celled size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Metric</Table.HeaderCell>
                <Table.HeaderCell>Actual</Table.HeaderCell>
                <Table.HeaderCell>vLY</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Incidents</Table.Cell>
                <Table.Cell>{incOshaLtGlbiData[timeframe + '_INCIDENT_COUNT_' + oeNhEq]}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LY_INCIDENT_COUNT_' + oeNhEq] - incOshaLtGlbiData[timeframe + '_INCIDENT_COUNT_' + oeNhEq])}>{getPercentString((incOshaLtGlbiData[timeframe + '_INCIDENT_COUNT_' + oeNhEq] - incOshaLtGlbiData[timeframe + '_LY_INCIDENT_COUNT_' + oeNhEq]) / incOshaLtGlbiData[timeframe + '_LY_INCIDENT_COUNT_' + oeNhEq])}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>OSHA</Table.Cell>
                <Table.Cell>{incOshaLtGlbiData[timeframe + '_OSHA_COUNT_' + oeNhEq]}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LY_OSHA_COUNT_' + oeNhEq] - incOshaLtGlbiData[timeframe + '_OSHA_COUNT_' + oeNhEq])}>{getPercentString((incOshaLtGlbiData[timeframe + '_OSHA_COUNT_' + oeNhEq] - incOshaLtGlbiData[timeframe + '_LY_OSHA_COUNT_' + oeNhEq]) / incOshaLtGlbiData[timeframe + '_LY_OSHA_COUNT_' + oeNhEq])}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Lost Time</Table.Cell>
                <Table.Cell>{incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_' + oeNhEq]}</Table.Cell>
                <Table.Cell style={getCompareStyle(incOshaLtGlbiData[timeframe + '_LY_LOST_TIME_COUNT_' + oeNhEq] - incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_' + oeNhEq])}>{getPercentString((incOshaLtGlbiData[timeframe + '_LOST_TIME_COUNT_' + oeNhEq] - incOshaLtGlbiData[timeframe + '_LY_LOST_TIME_COUNT_' + oeNhEq]) / incOshaLtGlbiData[timeframe + '_LY_LOST_TIME_COUNT_' + oeNhEq])}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </> :
      <>
        <Header textAlign='center'>User does not have permission to access Safety Detail page. Please return to Overview.</Header>
      </>

  )

})
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatNumberWithCommas } from '../../../utils/formatter';
import { formatLevelNumber } from '../../../utils/level';
import { divide } from '../../../utils/numbers';
import { capitalizeFirstLetter } from '../../../utils/string';
import { TOTAL, BOPIS, BOSS, WILL_CALL, CURBSIDE, onTimePickUpSelectMethodOptions } from './consts';

export const onTimePickUpGetData = (view, level, orderFulfillmentOnTimePickupData, timeframe) => {
  switch (view) {
    case BOSS:
    case TOTAL:
    case BOPIS:
      return {
        sortable: true,
        sortableDefault: 0,
        pagination: true, 
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Pick On Time %' },
          { name: '# of Orders Missed SLA' },
          { name: 'Avg Min to Pick' },
          { name: 'DS Compliance %' }
        ],
        data: orderFulfillmentOnTimePickupData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(data[METRIC_CONSTANTS[level]['propToRender']])
              }
            }
          },
          {
            pctValue: divide(data[timeframe + '_ON_TIME_NUM'], data[timeframe + '_ON_TIME_DEN']),
            decimals: 2,
            orderBy: divide(data[timeframe + '_ON_TIME_NUM'], data[timeframe + '_ON_TIME_DEN'])
          },
          { numValue: formatNumberWithCommas(data[timeframe + '_MISS_SLA_COUNT']) },
          {
            numValue:
              data[timeframe + '_AVG_MINS_TO_PICK'] !== null
                ? data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 })
                : '-',
            orderBy: data[timeframe + '_AVG_MINS_TO_PICK']
          },
          {
            pctValue: divide(data[timeframe + '_DS_COMPLIANCE_NUM'], data[timeframe + '_DS_COMPLIANCE_DEN']),
            orderBy: divide(data[timeframe + '_DS_COMPLIANCE_NUM'], data[timeframe + '_DS_COMPLIANCE_DEN'])
          }
        ])
      };
    case WILL_CALL:
      return {
        sortable: true,
        sortableDefault: 0,
        pagination: true,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Pick On Time %' },
          { name: '# of Orders Missed SLA' }
        ],
        data: orderFulfillmentOnTimePickupData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(data[METRIC_CONSTANTS[level]['propToRender']])
              }
            }
          },
          {
            pctValue: divide(data[timeframe + '_CURB_MISS_SLA_NUM'], data[timeframe + '_CURB_MISS_SLA_DEN']),
            decimals: 2,
            orderBy: data[timeframe + '_PCT_ON_TM']
          },
          { numValue: formatNumberWithCommas(data[timeframe + '_CURB_MISS_SLA_DEN']) }
        ])
      };
    case CURBSIDE:
      return {
        sortable: true,
        sortableDefault: 0,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: <>Avg Customer <br /> Wait Time (Mins)</> },
          { name: '# of Orders Missed SLA' },
          { name: '% Of Orders Missed SLA' },
        ],
        data: orderFulfillmentOnTimePickupData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(data[METRIC_CONSTANTS[level]['propToRender']])
              }
            }
          },
          {
            numValue: +data[timeframe + '_AVG_WAIT_TIME'],
            decimals:2,
            orderBy: +data[timeframe + '_AVG_WAIT_TIME']
          },
          { numValue: formatNumberWithCommas(+data[timeframe + '_TOTAL_ORD']) },
          {
            pctValue: divide(data[timeframe + '_MISS_SLA'], data[timeframe + '_TOTAL_ORD']),
          },
        ]),
        pagination: true
      };
    default:
      return {
        headers: [],
        data: []
      };
  }
};

export const onTimeDeliveryMethods = onTimePickUpSelectMethodOptions.map(onTimePickUpSelectMethodOption => ({
  key: onTimePickUpSelectMethodOption,
  text: onTimePickUpSelectMethodOption,
  value: onTimePickUpSelectMethodOption
}));

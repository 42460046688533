import { useContext, useState } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { Dropdown } from 'semantic-ui-react';
import { completePickUpData, selectMethodOptions } from '../../../../utils/completePickUp';
import { TOTAL } from '../../../../utils/consts';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';

export const CompletePickUp = () => {
  const { level, timeframe, fpToggle } = useContext(OrderFulfillmentContext);
  const {
    results: [{ data: completeData = [] }]
  } = useEnhancedQueries(['queryCompletePickupLvl'], fpToggle);
  const [view, setView] = useState(TOTAL);
  const data = completePickUpData(view, level, completeData, timeframe);

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <p className='my-0'>Select Method</p>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={selectMethodOptions}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Pickup Details by Method {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
};
import { DataView } from '../../Common/DataView';
import {
  getLeadsAndMeasuresPerformanceDataTable,
  getLeadsAndMeasuresPerformanceDataTableStr
} from '../utils/specialtyProRentalServices';
import { STORE } from '../../../utils/constantsByLevel';

const { levelName: storeLevelName } = STORE;

export const LeadsMeasuresTable = params => {
  const { leadsAndMeasuresPerformanceData, activeMenu, level, locNbr, timeframe, pipelineFilter } = params;
  const { levelName } = level;

  const leadsAndMeasuresPerformanceDataTable =
    levelName === storeLevelName
      ? getLeadsAndMeasuresPerformanceDataTableStr({
          leadsAndMeasuresPerformanceData,
          activeMenu,
          level,
          locNbr,
          timeframe,
          pipelineFilter
        })
      : getLeadsAndMeasuresPerformanceDataTable({
          leadsAndMeasuresPerformanceData,
          activeMenu,
          level,
          timeframe,
          pipelineFilter
        });

  return (
    <DataView>
      <DataView.Title position='center'>Leads & Measures Performance</DataView.Title>

      <DataView.DataTable data={leadsAndMeasuresPerformanceDataTable} />
    </DataView>
  );
};

import { CreditUsage } from './CreditUsage';
import { CreditEfficiency } from './CreditEfficiency';
import { CreditApplications } from './CreditApplications';
import { useCreditContext } from '../hooks/useCreditContext';
import { TimeframeDropdown } from '../../../Common/TimeframeDropdown';
import { useEnhancedQueries } from '../../../../hooks/useEnhancedQueries';
import { capitalizeFirstLetter as capitalize } from '../../../../utils/string';
import { ChannelAssociate } from '../../ChannelAssociate';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { getDataAsOf } from '../../../../utils/getDataAsOf';
import { useFetchLevelName } from '../../../../hooks/useFetchLevelName';

export const CreditMainTab = () => {
  const {
    fpToggle,
    level: { levelName },
    locNbr,
    settimeframe,
    timeframe
  } = useCreditContext();
  const {
    isLoading,
    isError,
    results: [{ data: creditApps }, { data: creditUsage }, { data: creditChannellByLvlData }]
  } = useEnhancedQueries(['creditApplications', 'creditUsage', 'creditChannelByLvl'], fpToggle);
  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, levelName, locNbr })

  if (isLoading || isLoadingName) return <Loader active>Loading...</Loader>;
  if (isError) return <Header>Something went wrong</Header>;

  const levelNameCapitalized = capitalize(levelName);
  const subTitle = levelName === 'core' ? '' : `#${locNbr}`;
  const currentLevel = levelName === 'core' ? '' : `, ${String(currentLevelName).toLowerCase()}`;
  const dataAsOf = getDataAsOf({ data: creditChannellByLvlData });

  return (
    <>
      <Header className='text-capitalize'>
        Credit - {levelNameCapitalized} {subTitle}{currentLevel}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CreditApplications data={creditApps} timeframe={timeframe} />
      <p>Note: If % vGoal ≥ 0%, then the goal is met.</p>
      <CreditEfficiency data={creditApps} timeframe={timeframe} />
      <CreditUsage data={creditUsage} timeframe={timeframe} />
      <ChannelAssociate />
    </>
  );
};

import { useMemo, useState } from 'react';
import { useShrinkContext } from '../../hooks/useShrinkContext';
import { useShrinkQueries } from '../../hooks/useShrinkQueries';
import { buildBaseShrinkUrl } from '../../utils/url';
import { formatOptions } from '../../../../utils/dropdown';
import { getParamByLevel } from '../../../../utils/timeFrameUtils';
import { uniqueValues } from '../../../../utils/array';
import { UNIT_CHAR } from '../../utils/overview';
import { Dropdown, Tab } from 'semantic-ui-react';
import { DataView } from '../../../Common/DataView';
import { UnitMenu } from '../../../Common/UnitMenu';

export const ClassesSummaryTab = () => {
  const { fpToggle } = useShrinkContext();
  const {
    level,
    locNbr,
    results: [queryShrinkActualsCls]
  } = useShrinkQueries({ fpToggle, queries: ['query/queryShrinkActualsCls'] });
  const [unit, setUnit] = useState('NOM');
  const [deptNbr, setDeptNbr] = useState('All');

  const departmentOptions = useMemo(() => {
    const departmentNumbers = uniqueValues(queryShrinkActualsCls.data?.map(dept => dept.DEPT_NBR)).sort();
    return formatOptions(['All', ...departmentNumbers]);
  }, [queryShrinkActualsCls.data]);

  const classSumDataFilter = useMemo(
    () => queryShrinkActualsCls.data?.filter(row => deptNbr === 'All' || row?.DEPT_NBR?.toString() === deptNbr.toString()),
    [queryShrinkActualsCls.data, deptNbr]
  );

  const paramByLevel = getParamByLevel(level);
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const url = `/${baseLevelShrinkUrl}/class`;
  const isUnitNom = unit === 'NOM';

  const dataObj = {
    data: classSumDataFilter.map(row => {
      const shrinkDollar = row.TOTAL_SHRINK_AMT;
      const shrinkPct = row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT;

      const vlyDollar = row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT;
      const vlyPct = row.OPS_SHRINK_AMT / row.OPS_SALES_AMT - row.LY_OPS_SHRINK_AMT / row.LY_OPS_SALES_AMT;

      const openShrinkDollar = row.OPS_SHRINK_AMT;
      const openShrinkPct = row.OPS_SHRINK_AMT / row.OPS_SALES_AMT;

      return [
        {
          link: {
            to: url,
            params: {
              [paramByLevel]: locNbr,
              classNbr: row.EXT_CLASS_NBR
            }
          },
          stringValue: `${row.EXT_CLASS_NBR} - ${row.CLASS_DESC?.replaceAll('/', '/\u200b')}`
        },
        {
          dollarValue: isUnitNom ? shrinkDollar : undefined,
          orderBy: isUnitNom ? shrinkDollar : shrinkPct,
          pctValue: isUnitNom ? undefined : shrinkPct
        },
        {
          dollarValue: isUnitNom ? vlyDollar : undefined,
          orderBy: isUnitNom ? vlyDollar : vlyPct,
          pctValue: isUnitNom ? undefined : vlyPct
        },
        {
          dollarValue: isUnitNom ? openShrinkDollar : undefined,
          orderBy: isUnitNom ? openShrinkDollar : openShrinkPct,
          pctValue: isUnitNom ? undefined : openShrinkPct
        }
      ];
    }),
    headers: [
      { name: 'Class ' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title>Classes Summary</DataView.Title>

        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />

          <Dropdown
            className='mx-3'
            compact
            onChange={(_, { value }) => setDeptNbr(value)}
            options={departmentOptions}
            placeholder='Dept'
            selection
            value={deptNbr}
          />
        </DataView.Controls>

        <DataView.DataTable data={dataObj} />
      </DataView>
    </Tab.Pane>
  );
};

import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { getPercentString } from '../../../../../components/Common/TableCellUtils';
import { getRowLevelNm } from "../../../../../utils/getRowLevelNm";

export const shrinkCoreAgedWillCAllData = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [{ name: 'Div #' }, { name: 'Value' }, { name: 'Goal' }, { name: '% of Stores Passing' }],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(data => {
    const divNumber = data['DIV_NBR'];
    const baseLevelShrinkUrl = buildBaseShrinkUrl('division');
    const coreLink = {
      to: `/${baseLevelShrinkUrl}/agedwc?divNbr=${divNumber}`
    };
    // Find the corresponding shrink score data
    const shrinkScoreDataFilter = shrinkScoreData.find(row => row && row['DIV_NBR'] === divNumber);

    return [
      {
        stringValue: getRowLevelNm({row: data, level}),
        link: coreLink
      },
      {
        stringValue: getPercentString(data[timeframe + '_AGED_WC_NUM'] / data[timeframe + '_AGED_WC_DEN'], 2)
      },
      {
        stringValue: getPercentString(shrinkScoreDataFilter ? shrinkScoreDataFilter['AGED_WC_GOAL'] : 0, 2)
      },
      {
        stringValue: getPercentString(shrinkScoreDataFilter ? shrinkScoreDataFilter['AGED_WC_SCORE'] : 0)
      }
    ];
  })
});

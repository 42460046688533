import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';
import { formatNumberWithCommas } from '../../../../../../utils/formatter';
import { getCompareStyle } from '../../../../../../components/Common/TableCellUtils';
import { LEVEL_NBR } from '../../../../../../utils/constantsByLevel';
import { getRowSubLevelNm } from '../../../../../../utils/getRowLevelNm';

export const TotalMeasure = ({ level, data, timeframe, allWebStore, unit, isMonday }) => {
  const { levelName, subLevel } = level;
  const { levelName: subLevelName, urlParam: subUrlParam, [LEVEL_NBR]: subLevelNbr } = subLevel;

  const totalMeasureDataObj = {
    headers: [
      {
        name: 'Div # '
      },
      { name: isMonday && timeframe === 'WTD' ? 'TW Target ' : 'Target ' },
      isMonday && timeframe === 'WTD' ? { name: 'LW Target ' } : false,
      {
        name: 'Measure '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: [...data].map(row => {
      const levelNbr = row[subLevelNbr];

      return [
        {
          link:
            row.METRIC_LEVEL === 'DIV'
              ? {
                  to: `/${subLevelName}/meas`,
                  params: {
                    [subUrlParam]: levelNbr
                  }
                }
              : undefined,
          stringValue: row.METRIC_LEVEL === 'DIV' ? getRowSubLevelNm({ row, level: levelName, levelNbr }) : 'Core'
        },
        {
          numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS_TGT']),
          orderBy: row[timeframe + allWebStore + '_MEAS_TGT']
        },
        isMonday &&
          timeframe === 'WTD' && {
            numValue: formatNumberWithCommas(row['LW' + allWebStore + '_MEAS_TGT']),
            orderBy: row['LW' + allWebStore + '_MEAS_TGT']
          },
        {
          numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS']),
          orderBy: row[timeframe + allWebStore + '_MEAS']
        },
        unit === 'NOM'
          ? {
              numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
              orderBy: row[timeframe + allWebStore + '_VLY'],
              customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
            }
          : {
              pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
              customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
              orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
              decimals: 2
            }
      ].filter(row => Boolean(row));
    }),
    sortable: true,
    sortableDefault: 0
  };
  return (
    <DataView>
      <DataView.Title position='center'>
        Total Measure - Core
        <Header.Subheader>Data as of {data[0]?.TRNSFM_DT}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={totalMeasureDataObj} />
    </DataView>
  );
};

import { DataView } from '../../../../../components/Common/DataView';
import { buildFooter, buildHeaders, buildRows } from '../../../../../components/SpecialtyProRentalContainer/utils/servicesPrgmSalesTable';

export const ServicesPrgmSales = ({ data,level,timeframe }) => {
  const dataObj = {
    data: buildRows(data, level, 0, timeframe),
    headers: buildHeaders(level),
    footer: buildFooter(data, timeframe),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};

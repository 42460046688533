import { buildLinkColumn } from "./applicationByLevelTable";
import { capitalizeFirstLetter } from "../../../../utils/string";
import { getBpsString, getCompareStyle, getThousandDollarString } from "../../../Common/TableCellUtils";

export const buildHeaders = level => {
  return [{ name: capitalizeFirstLetter(level) }, { name: 'Credit Sales' }, { name: 'Usage' }, { name: 'bps vPlan' }];
}

export const buildRows = ({ data, isCommercial, isConsumer, isTotal, level, timeframe }) => {
  if (isTotal) return buildTotalRows({ data, level, timeframe })
  if (isConsumer) return buildConsumerRows({ data, level, timeframe })
  if (isCommercial) return buildCommercialRows({ data, level, timeframe })
}

const buildCommercialRows = ({ data, level, timeframe }) => {
  return data.map(row => {
    const creditSales = row[timeframe + '_COMM_SLS_AMT']
    const usageValue = creditSales / row[timeframe + '_TOT_SLS_AMT']
    const bpsValue = usageValue - row[timeframe + '_COMM_CRED_SLS_PLN'] / row[timeframe + '_TOT_SLS_PLN']

    return [
      {
        ...buildLinkColumn(row, level),
      },
      {
        orderBy: creditSales,
        stringValue: getThousandDollarString(creditSales),
      },
      {
        orderBy: usageValue,
        pctValue: usageValue,
      },
      {
        customStyle: getCompareStyle(bpsValue),
        orderBy: bpsValue,
        stringValue: getBpsString(bpsValue)
      }
    ]
  })
}

const buildConsumerRows = ({ data, level, timeframe }) => {
  return data.map(row => {
    const creditSales = row[timeframe + '_CONS_SLS_AMT']
    const usageValue = creditSales / row[timeframe + '_TOT_SLS_AMT']
    const bpsValue = usageValue - row[timeframe + '_CONS_CRED_SLS_PLN'] / row[timeframe + '_TOT_SLS_PLN']

    return [
      {
        ...buildLinkColumn(row, level),
      },
      {
        orderBy: creditSales,
        stringValue: getThousandDollarString(creditSales),
      },
      {
        orderBy: usageValue,
        pctValue: usageValue,
      },
      {
        customStyle: getCompareStyle(bpsValue),
        orderBy: bpsValue,
        stringValue: getBpsString(bpsValue)
      }
    ]
  })
}

const buildTotalRows = ({ data, level, timeframe }) => {
  return data.map(row => {
    const creditSales = row[timeframe + '_CRED_SLS_AMT']
    const usageValue = creditSales / row[timeframe + '_TOT_SLS_AMT']
    const bpsValue = usageValue - row[timeframe + '_TOT_CRED_SLS_PLN'] / row[timeframe + '_TOT_SLS_PLN']

    return [
      {
        ...buildLinkColumn(row, level),
      },
      {
        orderBy: creditSales,
        stringValue: getThousandDollarString(creditSales),
      },
      {
        orderBy: usageValue,
        pctValue: usageValue,
      },
      {
        customStyle: getCompareStyle(bpsValue),
        orderBy: bpsValue,
        stringValue: getBpsString(bpsValue)
      }
    ]
  })
}

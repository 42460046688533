import { METRIC_CONSTANTS } from './constantsByLevel';
import { formatLevelNumber } from './level';
import { getRowLevelNm, getRowSubLevelNm } from './getRowLevelNm';

const LEVELS_TO_SHOW_NUMBERS = ['store', 'district'];

const isRowAtSameMetricLevel = (row, level) => row.METRIC_LEVEL === METRIC_CONSTANTS[level].METRIC_LEVEL;

const getSublevelNumberValue = (row, level) => {
  const { METRIC_LEVEL, LEVEL_NBR } = METRIC_CONSTANTS[level];
  if (isRowAtSameMetricLevel(row, level)) return `${METRIC_LEVEL} ${row[LEVEL_NBR]}`;

  const {
    subLevel: { METRIC_LEVEL: SUB_METRIC_LEVEL, LEVEL_NBR: SUB_LEVEL_NBR }
  } = METRIC_CONSTANTS[level];
  return `${SUB_METRIC_LEVEL} ${row[SUB_LEVEL_NBR]}`;
};

const getSublevelStringValue = (row, level) => {
  const {
    subLevel: { levelName: subLevelName }
  } = METRIC_CONSTANTS[level];

  if (LEVELS_TO_SHOW_NUMBERS.includes(subLevelName)) return getSublevelNumberValue(row, level);
  if (isRowAtSameMetricLevel(row, level)) return getRowLevelNm({ level, row });
  return getRowSubLevelNm({ level, row });
};

export const buildSublevelLinkColumn = ({ extraParams = {}, level, path, row, to }) => {
  const {
    subLevel: { LEVEL_NBR: SUB_LEVEL_NBR, levelName: subLevelName, urlParam: subUrlParam }
  } = METRIC_CONSTANTS[level];
  const isRowAtSameMetricLevelValue = isRowAtSameMetricLevel(row, level);
  const stringValue = getSublevelStringValue(row, level);

  return {
    link: isRowAtSameMetricLevelValue
      ? undefined
      : {
          to: path || `/${subLevelName}${to}`,
          params: {
            ...extraParams,
            [subUrlParam]: formatLevelNumber(row[SUB_LEVEL_NBR])
          }
        },
    orderBy: isRowAtSameMetricLevelValue ? -1 : row[SUB_LEVEL_NBR],
    stringValue
  };
};

import { useContext } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { willCallZeroes } from '../../../../utils/PickUpDetails';
import { divide } from '../../../../../../utils/numbers';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';

export const PickUpDetailsMethod = () => {
  const { timeframe, strNbr } = useContext(StoreOrderFulfillmentContext);

  const [pickupData] = useQueryKeys([`query/queryCompletePickupLvl/store/${strNbr}`])
  const isWTD = timeframe === 'WTD';

  const [bopisData] = pickupData.filter(row => {
    return row['CHANNEL'] === 'BOPIS';
  });
  
  const [willCallData= willCallZeroes] = pickupData.filter(row => {
    return row['CHANNEL'] === 'WILL CALL';
  });
  
  const bopisFullfillmentPct = isWTD
    ? divide(bopisData['WTD_FULFILL_NUM'],bopisData['WTD_FULFILL_DEN'])
    : divide(bopisData[timeframe + '_FULFILL_NUM'], bopisData[timeframe + '_FULFILL_PCT_DEN']);

  const willCallFullfillmentPct = isWTD
    ? divide(willCallData['WTD_FULFILL_NUM'], willCallData['WTD_FULFILL_DEN'])
    : divide(willCallData[timeframe + '_FULFILL_NUM'], willCallData[timeframe + '_FULFILL_PCT_DEN']);

  const dataObj = {
    headers: [
      { name: 'Method' },
      { name: 'Fulfillment %' },
      { name: 'Units Unfulfilled' },
      { name: 'NIS Units' },
      { name: '$ Unfulfilled' }
    ],
    data: [
      [
        {
          stringValue: 'BOPIS'
        },
        {
          pctValue: bopisFullfillmentPct,
          decimals: 2,
          orderBy: bopisData['FULFILL_' + timeframe]
        },
        {
          numValue: bopisData[timeframe + '_CNCL_NIS_NUM']
        },
        {
          numValue: bopisData[timeframe + '_CNCL_NIS_DEN']
        },
        {
          dollarValue: bopisData[timeframe + '_UNFULFILLED_TOTAL']
        }
      ],
      [
        {
          stringValue: 'Will Call ',
          popup: {
            content: 'TBD',
            icon: 'info circle',
            color: 'blue' //Semantic-UI doesn't have Circle Info Icon without background
          }
        },
        {
          pctValue: willCallFullfillmentPct,
          decimals: 2,
          orderBy: willCallData['FULFILL_' + timeframe]
        },
        {
          numValue: willCallData[timeframe + '_CNCL_NIS_NUM']
        },
        {
          numValue: willCallData[timeframe + '_CNCL_NIS_DEN']
        },
        {
          dollarValue: willCallData[timeframe + '_UNFULFILLED_TOTAL']
        }
      ],
      [
        {
          stringValue: 'Total'
        },
        {
          pctValue: bopisFullfillmentPct + willCallFullfillmentPct / 2,
          decimals: 2
        },
        {
          numValue: bopisData[timeframe + '_CNCL_NIS_NUM'] + willCallData[timeframe + '_CNCL_NIS_NUM']
        },
        {
          numValue: bopisData[timeframe + '_CNCL_NIS_DEN'] + willCallData[timeframe + '_CNCL_NIS_DEN']
        },
        {
          dollarValue: bopisData[timeframe + '_UNFULFILLED_TOTAL'] + willCallData[timeframe + '_UNFULFILLED_TOTAL']
        }
      ]
    ]
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title>Pickup Details by Method</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};

import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkInvalidScans = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  let sortDirection = "descending"
  let skuSortColumn = "RNK"
  let skuSortDirection = 'ascending'

  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 10

  const shrinkInvalidScansClsByStrQuery = useQuery(['queryShrinkInvalidScansClsByStr', { strNbr, fpToggle }], getData)
  const shrinkInvalidScansTopSkusByStrQuery = useQuery(['queryShrinkInvalidScansTopSkusByStr', { strNbr, fpToggle }], getData)


  useEffect(() => {
    setPageNum(1)
  }, [sortDirection, rowsPerPage])

  if (shrinkInvalidScansClsByStrQuery.isLoading || shrinkInvalidScansTopSkusByStrQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkInvalidScansClsByStrQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const classData = shrinkInvalidScansClsByStrQuery.data
  const skuData = shrinkInvalidScansTopSkusByStrQuery.data
  const invalidScanField = timeframe + "_AFT_INTRVNTN_INVAL_SCAN_RETL_AMT"

  let classDataSorted = classData.sort((left, right) => {
    let result = left[invalidScanField] - right[invalidScanField]
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let skuDataFiltered = skuData.filter((row) => {
    return row && row['TIMEFRAME'] === timeframe
  })

  let skuDataSorted = skuDataFiltered.sort((left, right) => {
    let result = left[skuSortColumn] - right[skuSortColumn]
    if (skuSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let classDataPagination = classDataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, classDataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>Back to Shrink Overview</Link></Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Invalid Scans - Store {strNbr}</Header>
      <Header textAlign='center'>Top SKUs</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SKU #</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SKU Description</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Invalid Scan $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Invalid Scan %</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {skuDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['SKU_NBR']}</Table.Cell>
                <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data['AFT_INTRVNTN_INVAL_SCAN_RETL_AMT'])}</Table.Cell>
                <Table.Cell>{data['SLS_AMT'] !== 0 ? getPercentString(data['AFT_INTRVNTN_INVAL_SCAN_RETL_AMT'] / data['SLS_AMT'], 2) : '--'}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <Header textAlign='center'>Top Classes</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Class #</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Class Description</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Invalid Scans $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Invalid Scans %</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {classDataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['EXT_CLASS_NBR']}</Table.Cell>
                <Table.Cell>{data['CLASS_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data[invalidScanField])}</Table.Cell>
                <Table.Cell>{data[timeframe+'_SLS_AMT'] !== 0 ? getPercentString(data[invalidScanField] / data[timeframe+'_SLS_AMT'], 2) : '--'}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})
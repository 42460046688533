export const TOTAL = 'Total';
export const BOPIS = 'BOPIS';
export const BOSS = 'BOSS';
export const WILL_CALL = 'Will Call';
export const CURBSIDE = 'Curbside';
export const BOX = 'Box';
export const CAR = 'Car';
export const FLATBED = 'Flatbed';
export const VAN = 'Van';
export const OTHER = 'Other';

export const onTimePickUpSelectMethodOptions = [TOTAL, BOPIS, BOSS, WILL_CALL, CURBSIDE];
export const onTimePickUpSelectTruckTypeOptions = [TOTAL, BOX, CAR, FLATBED, VAN, OTHER];
export const completePickUpSelectMethodOptions = [TOTAL, BOPIS, WILL_CALL];
export const completePickUpSelectTruckTypeOptions = [TOTAL, CAR, FLATBED, VAN, BOX, OTHER];

import { DataView } from '../../Common/DataView';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { getRentalKeyMetricsDataTable } from '../utils/specialtyProRental';

export const RentalKeyMetrics = props => {
  const { rentalKeyMetricsData, trOpsDataTotal } = props;
  const { level, timeframe } = useSpecialtyProRentalContext();
  const rentalKeyMetricsDataTable = getRentalKeyMetricsDataTable({ level, timeframe, rentalKeyMetricsData, trOpsDataTotal });

  return (
    <DataView className='my-4'>
      <DataView.Title position='left'>Rental Key Metrics</DataView.Title>

      <DataView.DataTable data={rentalKeyMetricsDataTable} />
    </DataView>
  );
};

import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { METRIC_CONSTANTS } from '../../../../utils/constantsByLevel';
import { useFetchLevelName } from '../../../../hooks/useFetchLevelName';

export const CreditContext = createContext();

const CreditContextProvider = ({ children, fpToggle, ...props }) => {
  const { level: levelName } = useParams();
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;

  const { currentLevelName, isLoadingName } = useFetchLevelName({
    level: levelName,
    locNbr,
    fpToggle
  });

  const level = METRIC_CONSTANTS[levelName];
  const value = { ...props, fpToggle, currentLevelName, isLoadingName, level, locNbr, levelName };

  return <CreditContext.Provider value={value}>{children}</CreditContext.Provider>;
};

export default CreditContextProvider;

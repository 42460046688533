import { TimeframeMenu } from '../Menus/TimeFrameMenu';
import { useOverviewContext } from '../../hooks/useOverviewContext';

// TODO: Rename to withTimeframeMenu
export const withOverviewHeader = WrappedComponent => {
  return () => {
    const { timeframe, settimeframe, unit, setUnit } = useOverviewContext();

    return (
      <>
        <TimeframeMenu timeframe={timeframe} settimeframe={settimeframe} unit={unit} setUnit={setUnit} showUnits />

        <WrappedComponent />
      </>
    );
  };
};

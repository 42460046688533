export const queryParamByLevel = ({ level, levelNumber }) => {
  const urlParam = {
    store: { strNbr: levelNumber },
    district: { dstNbr: levelNumber }
  };
  return urlParam[level] || { locNbr: levelNumber };
};

export const LEVEL_NM = 'LEVEL_NM';
export const LEVEL_NBR = 'LEVEL_NBR';

export const columnName = {
  store: 'STR_NM',
  district: 'DST_NM',
  region: 'RGN_NM',
  division: 'DIV_NM'
};

export const STORE = {
  METRIC_LEVEL: 'STR',
  [LEVEL_NM]: 'STR_NM',
  [LEVEL_NBR]: 'STR_NBR',
  levelName: 'store',
  urlParam: 'strNbr',
  subMetric: 'STR',
  urlLinkLevel: 'store',
  urlSubLevelParam: 'strNbr',
  propToRender: 'STR_NBR',
  currentLevel: 'STR_NBR',
  FILTER_BY_METRIC: 'STR'
};

export const DISTRICT = {
  METRIC_LEVEL: 'DST',
  [LEVEL_NM]: 'DST_NM',
  [LEVEL_NBR]: 'DST_NBR',
  levelName: 'district',
  urlParam: 'dstNbr',
  subMetric: 'STR',
  urlLinkLevel: 'store',
  urlSubLevelParam: 'strNbr',
  propToRender: 'STR_NBR',
  currentLevel: 'DST_NBR',
  FILTER_BY_METRIC: 'STR'
};

export const REGION = {
  METRIC_LEVEL: 'RGN',
  [LEVEL_NM]: 'RGN_NM',
  [LEVEL_NBR]: 'RGN_NBR',
  levelName: 'region',
  urlParam: 'rgnNbr',
  subMetric: 'DST',
  urlLinkLevel: 'district',
  urlSubLevelParam: 'dstNbr',
  propToRender: 'DST_NBR',
  currentLevel: 'RGN_NBR',
  FILTER_BY_METRIC: 'DST'
};

export const DIVISION = {
  METRIC_LEVEL: 'DIV',
  [LEVEL_NM]: 'DIV_NM',
  [LEVEL_NBR]: 'DIV_NBR',
  levelName: 'division',
  urlParam: 'divNbr',
  subMetric: 'RGN',
  urlLinkLevel: 'region',
  urlSubLevelParam: 'rgnNbr',
  propToRender: 'RGN_NBR',
  currentLevel: 'DIV_NBR',
  FILTER_BY_METRIC: 'RGN'
};

export const CORE = {
  METRIC_LEVEL: 'CORE',
  [LEVEL_NM]: 'DIV_NM',
  [LEVEL_NBR]: 'DIV_NBR',
  levelName: 'core',
  subMetric: 'DIV',
  urlLinkLevel: 'division',
  urlSubLevelParam: 'divNbr',
  propToRender: 'DIV_NBR',
  currentLevel: 'CORE',
  FILTER_BY_METRIC: 'DIV'
};

export const METRIC_CONSTANTS = {
  store: {
    ...STORE,
    subLevel: { ...STORE }
  },
  district: {
    ...DISTRICT,
    subLevel: { ...STORE }
  },
  region: {
    ...REGION,
    subLevel: { ...DISTRICT }
  },
  division: {
    ...DIVISION,
    subLevel: { ...REGION }
  },
  core: {
    ...CORE,
    subLevel: { ...DIVISION }
  }
};

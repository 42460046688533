import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';
import { divide } from '../../../utils/numbers';
import { capitalizeFirstLetter } from '../../../utils/string';
import { onTimePickUpSelectTruckTypeOptions } from './consts';

export const onTimeDeliveryHeaders = level => [
  { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
  { name: 'Pick On Time %' },
  { name: 'Delivery Misses' },
  { name: 'Delivery Misses %' }
];

export const onTimeDeliveryData = (data, level, timeframe) => [
  {
    stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
    link: {
      to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
      params: {
        [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(data[METRIC_CONSTANTS[level]['propToRender']])
      }
    }
  },
  {
    pctValue: divide(data[timeframe + '_ON_TIME_COUNT_NEW_NUM'], data[timeframe + '_ON_TIME_COUNT_NEW_DEN']),
    decimals: 2,
    orderBy: divide(data[timeframe + '_ON_TIME_COUNT_NEW_NUM'], data[timeframe + '_ON_TIME_COUNT_NEW_DEN'])
  },
  { numValue: data[timeframe + '_MISS_DELIVERY_COUNT_NUM'] },
  {
    pctValue: divide(data[timeframe + '_MISS_DELIVERY_COUNT_NUM'], data[timeframe + '_MISS_DELIVERY_COUNT_DEN']),
    decimals: 2,
    orderBy: divide(data[timeframe + '_MISS_DELIVERY_COUNT_NUM'], data[timeframe + '_MISS_DELIVERY_COUNT_DEN'])
  }
];

export const selectTrunkTypeOptions = onTimePickUpSelectTruckTypeOptions.map(onTimePickUpSelectTruckTypeOption => ({
  key: onTimePickUpSelectTruckTypeOption,
  text: onTimePickUpSelectTruckTypeOption,
  value: onTimePickUpSelectTruckTypeOption
}));

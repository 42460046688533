import { useContext } from 'react';
import { CreditContext } from '../context/CreditContextProvider';

export const useCreditContext = () => {
  const creditContext = useContext(CreditContext);
  if (creditContext === undefined) {
    throw new Error('useCreditContext must be used within a CreditContextProvider');
  }
  return creditContext;
};

export const osaBreadcrumbItemsGenerator = ({ level, urlParam, locNbr, currentPage }) => [
  { to: `/${level}/overview?${urlParam}=${locNbr}`, text: 'Overview' },
  {
    to: `/osa?${urlParam}=${locNbr}`,
    text: 'OSA'
  },
  { text: currentPage }
];

export const getOHMNoLocationTagsBreadcrumbItems = ({ level, urlParam, locNbr }) =>
  osaBreadcrumbItemsGenerator({ level, urlParam, locNbr, currentPage: 'OHM No Loc Tags' });

export const getOSAImagingBreadcrumbItems = ({ level, urlParam, locNbr }) =>
  osaBreadcrumbItemsGenerator({ level, urlParam, locNbr, currentPage: 'Imaging%' });

export const getSideKickPurgeBreadcrumbItems = ({ level, urlParam, locNbr }) =>
  osaBreadcrumbItemsGenerator({ level, urlParam, locNbr, currentPage: 'Purge' });

export const getSideKickSkuBayBreadcrumbItems = ({ level, urlParam, locNbr }) =>
  osaBreadcrumbItemsGenerator({ level, urlParam, locNbr, currentPage: 'SKU/Bay' });

export const calculateRtvDeletedTagValue = (data, timeframe) => {
  return data[timeframe + '_RTV_DEL_NUM'] / data[timeframe + '_RTV_DEL_DEN'];
};

export const calculateLyRtvDeletedTagValue = (data, timeframe) => {
  return data[timeframe + '_LY_RTV_DEL_NUM'] / data[timeframe + '_LY_RTV_DEL_DEN'];
};

export const calculateRtvDeletedTagVersusLy = (data, timeframe) => {
  return calculateRtvDeletedTagValue(data, timeframe) - calculateLyRtvDeletedTagValue(data, timeframe);
};

export const calculateReducedTagsLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_REDUCED_TAG_NUM'] / data[timeframe + '_LY_CORE_SLS'];
};

export const calculateBossCancelLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_BOSS_CNCL_NUM'] / data[timeframe + '_LY_BOSS_CNCL_DEN'];
};

export const calculateOnHandAdjustmentsLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_OH_EMPT_PKG_NUM'] / data[timeframe + '_LY_OH_EMPT_PKG_DEN'];
};

export const calculateInvalidScansLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_INVAL_SCN_NUM'] / data[timeframe + '_LY_CORE_SLS'];
};

export const calculateRtvCreditsLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_RTV_CREDIT_NUM'] / data[timeframe + '_LY_RTV_CREDIT_DEN'];
};

export const calculateCullMarkDownsLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_CULL_MD_NUM'] / data[timeframe + '_LY_CULL_MD_DEN'];
};

export const calculateClearanceNoHomesLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_CLRNH_NUM'] / data[timeframe + '_LY_CLRNH_DEN'];
};

export const calculateDamagedMarkdownsLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_DAMAGED_MD_NUM'] / data[timeframe + '_LY_CORE_SLS'];
};

export const calculateAgedWillCallsLyValue = (data, timeframe) => {
  return data[timeframe + '_LY_AGED_WC_NUM'] / data[timeframe + '_LY_AGED_WC_DEN'];
};

import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';

export const focusMetricsDataCore = ({ timeframe, shrinkMetricsData, scoresData }) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');
  const scoresDataFiltered = scoresData.filter(row => row && row['TIME_FRAME'] === timeframe);

  return {
    headers: [
      { name: 'Metric' },
      { name: 'Value' },
      {
        name: 'vLY ',
        popup: {
          content:
            'If the metric is meeting the goal, but worse than last year, there is still an opportunity for shrink exposure. Always try to meet the goal first, then strive for improvement year-over-year.',
          icon: 'info circle',
          color: 'blue'
        }
      },
      { name: 'Goal' },
      { name: '% of Stores Passing ' }
    ],
    footer: [
      [
        { stringValue: 'Total Score' },
        { stringValue: '-' },
        { stringValue: '-' },
        { stringValue: '-' },
        {
          pctValue: scoresDataFiltered[0].SHRINK_EXEC_SCORE,
          decimals: scoresDataFiltered[0].SHRINK_EXEC_SCORE === 1 ? 0 : 2
        }
      ]
    ],
    data: [
      [
        {
          stringValue: 'Invalid Scans',
          link: {
            to: `/${baseLevelShrinkUrl}/invalidscans`
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'],
          compareValue: scoresDataFiltered[0].INV_SCN_GOAL,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
            shrinkMetricsData[timeframe + '_LY_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'],
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].INV_SCN_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].INVALID_SCAN_SCORE
        }
      ],
      [
        {
          stringValue: 'RTV Credits',
          link: {
            to: `/${baseLevelShrinkUrl}/rtvcredit`
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].RTV_CREDIT_GOAL
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'] -
            shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_DEN'],
          compareStyle: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].RTV_CREDIT_GOAL,
          decimals: 2,
          prefix: '≥ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].RTV_CREDIT_SCORE
        }
      ],
      [
        {
          stringValue: 'Cull Markdowns',
          link: {
            to: `/${baseLevelShrinkUrl}/cullmd`
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_CULL_MD_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].CULL_MD_GOAL
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_CULL_MD_DEN'] -
            shrinkMetricsData[timeframe + '_LY_CULL_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CULL_MD_DEN'],
          compareStyle: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].CULL_MD_GOAL,
          decimals: 2,
          prefix: '≥ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].CULL_MD_SCORE
        }
      ],
      [
        {
          stringValue: 'Clearance No-Homes',
          link: {
            to: `/${baseLevelShrinkUrl}/clearancenh`
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].CLRNH_GOAL,
          compareStyleInvert: true
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'] -
            shrinkMetricsData[timeframe + '_LY_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_LY_CLRNH_DEN'],
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].CLRNH_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].CLEAR_NH_SCORE
        }
      ],
      [
        {
          stringValue: 'Damaged Markdowns',
          link: {
            to: `/${baseLevelShrinkUrl}/damagedmd`
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].DAMAGED_MD_GOAL
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
            shrinkMetricsData[timeframe + '_LY_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'],
          compareStyle: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].DAMAGED_MD_GOAL,
          decimals: 2,
          prefix: '≥ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].DAMAGED_MD_SCORE
        }
      ],
      [
        {
          stringValue: 'Aged Will Calls',
          link: {
            to: `/${baseLevelShrinkUrl}/agedwc`
          },
          popup: {
            content:
              'Aged Will Calls: Average(# of Aged Will Call Lines) / Average(Total # Open Will Call Lines) per timeframe selected.',
            icon: 'info circle',
            color: 'blue'
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].AGED_WC_GOAL,
          compareStyleInvert: true
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'] -
            shrinkMetricsData[timeframe + '_LY_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_LY_AGED_WC_DEN'],
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].AGED_WC_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].AGED_WC_SCORE
        }
      ]
    ]
  };
};

import { buildSublevelLinkColumn } from '../../utils/dataTable';
import { DataView } from '../Common/DataView';

const headerLevel = {
  district: 'Store',
  region: 'District',
  division: 'Region'
};

const linkConfig = {
  district: { to: 'store', paramKey: 'strNbr' },
  region: { to: 'district', paramKey: 'dstNbr' },
  division: { to: 'region', paramKey: 'rgnNbr' }
};

const levelName = {
  district: 'Stores',
  region: 'Districts',
  division: 'Regions'
};

export const PurgePackdown = ({ data, dataScores, entityField, findItem, level, timeframe }) => {
  const purgePackDown = data.reduce((acc, curr) => {
    const sideExeData = dataScores?.find(r => r[entityField] === curr[entityField]);
    if (!sideExeData) return acc;

    let item = findItem(acc, entityField, curr);

    if (item) {
      item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT'];
      item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
      item.RMNG = item[timeframe + '_TOTAL_TASK_COUNT'] - item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'];
    } else {
      acc.push({
        [entityField]: curr[entityField],
        [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
        [timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']: sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'],
        RMNG: curr[timeframe + '_TOTAL_TASK_COUNT'] - sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'],
        RGN_NM: sideExeData.RGN_NM,
        DST_NM: sideExeData.DST_NM,
        DIV_NM: sideExeData.DIV_NM
      });
    }

    return acc;
  }, []);

  const dataObj = {
    headers: [{ name: headerLevel[level] }, { name: 'Comp Tasks' }, { name: 'Remaining Sent #' }],
    data: purgePackDown.map(data => [
      {
        ...buildSublevelLinkColumn({ level, row: data, path: `/osa/Purge/${linkConfig[level]?.to}` })
      },
      {
        stringValue: data[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'].toLocaleString()
      },
      {
        stringValue: data.RMNG.toLocaleString()
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView>
      <DataView.Title className='mt-4'>Purge Packdown by {levelName[level]}</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};

import { Loader, Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { TimeframeDropdownShrink } from "../../../../../components/Common/TimeframeDropdownShrink";
import { onHandAdjustment } from "../utils/onHandAdjustmetCore";
import useQueryApi from "../../../../../hooks/useQueryApi";
import { OnHandAdjustment } from "../../../../../components/ShrinkContainer/components/OnHandAdjustment";
import { buildBaseShrinkUrl } from "../../../../../components/ShrinkContainer/utils/url";

export const OnHandAdjustmentCore = ({ timeframe, settimeframe, fpToggle }) => {
  const {
    data: onHandAdjustmentData = [],
    isLoading,
    isError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', {
    fpToggle,
    isCore: true
  });
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');
  const level = 'core';
  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${baseLevelShrinkUrl}`} data-test='b2o-link'>
              Back to Shrink Overview
            </Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <OnHandAdjustment
        dataAsOf={onHandAdjustmentData[0]?.TRNSFM_DT}
        dataObject={onHandAdjustment({ shrinkData: onHandAdjustmentData, timeframe })}
        level={level}
      />
    </>
  );
}
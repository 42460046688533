import React, { useState } from 'react'
import { Header, Loader, Grid, Segment, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { useQueryParams } from '../../hooks/useQueryParams'
import { getCompareStyle } from '../Common/TableCellUtils'
import { DataTable } from '../Common/DataTable'
import { DataView } from '../Common/DataView'
import { formatNumberWithCommas } from '../../utils/formatter'
import { buildSublevelLinkColumn } from '../../utils/dataTable'

const METRIC_CONSTANTS = {
  store: { // There's a bug where the level stays 'store' when hitting the back button, so this compensates
    query: 'queryMeasDtlByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr',
    urlParamSubLevel: 'strNbr',
    subLevel: 'STR',
    subLevelName: 'store',
    columnName: 'DST_NBR',
    subLevelColumn: 'STR_NBR',
  },
  district: {
    query: 'queryMeasDtlByDst',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr',
    urlParamSubLevel: 'strNbr',
    subLevel: 'STR',
    subLevelName: 'store',
    columnName: 'DST_NBR',
    subLevelColumn: 'STR_NBR',
  },
  region: {
    query: 'queryMeasDtlByRgn',
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr',
    urlParamSubLevel: 'dstNbr',
    subLevel: 'DST',
    subLevelName: 'district',
    columnName: 'RGN_NBR',
    subLevelColumn: 'DST_NBR'
  },
  division: {
    query: 'queryMeasDtlByDiv',
    METRIC_LEVEL: 'DIV',
    urlParam: 'divNbr',
    urlParamSubLevel: 'rgnNbr',
    subLevel: 'RGN',
    subLevelName: 'region',
    columnName: 'DIV_NBR',
    subLevelColumn: 'RGN_NBR'
  },
  core: {
    query: 'queryMeasDtlByCore',
    METRIC_LEVEL: 'DIV',
    urlParam: 'divNbr',
    urlParamSubLevel: 'rgnNbr',
    subLevel: 'RGN',
    subLevelName: 'region',
    columnName: 'DIV_NBR',
    subLevelColumn: 'RGN_NBR'
  }
}

export const MeasDetailDst = ({ timeframe, settimeframe, level, fpToggle, levelName }) => {

  const { dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams()

  const [unit, setUnit] = useState('NOM')
  const [allWebStore, setAllWebStore] = useState('') // All = empty string, Web = '_WEB', Store = '_STR'

  const locNbr = dstNbr || rgnNbr || divNbr
  const measDetailQuery = useQuery([METRIC_CONSTANTS[level]['query'], { locNbr, fpToggle }], getData)

  if (measDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let sumProgNmFilter = measDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL']
  })

  let totalFilter = measDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['subLevel']
  })

  let totalFilterByLevel = measDetailQuery
    .data
    .filter(row => row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL'])

  const isMonday = new Date().getDay() === 1

  const totalMeasureDataObj = {
    headers: [
      {
        name: 'Org # '
      },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      {
        name: 'Measure '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: [...totalFilterByLevel, ...totalFilter].map((row, index) => [
      {
        ...buildSublevelLinkColumn({ level, row, to: '/meas' })
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS_TGT']),
        orderBy: row[timeframe + allWebStore + '_MEAS_TGT']
      },
      (isMonday && timeframe === 'WTD') && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_MEAS_TGT']),
        orderBy: row['LW' + allWebStore + '_MEAS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS']),
        orderBy: row[timeframe + allWebStore + '_MEAS']
      },
      unit === 'NOM' ? {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
        orderBy: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
        decimals: 2
      },
    ].filter(row => Boolean(row))),
    sortable: true,
    sortableDefault: 0,
  }

  const measureBySumProgram = {
    headers: [
      {
        name: 'Summary Program Name '
      },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      {
        name: 'Measure '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: sumProgNmFilter.map(row => [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS_TGT']),
        orderBy: row[timeframe + allWebStore + '_MEAS_TGT']
      },
      (isMonday && timeframe === 'WTD') && {
        numValue: formatNumberWithCommas(row['LW' + allWebStore + '_MEAS_TGT']),
        orderBy: row['LW' + allWebStore + '_MEAS_TGT']
      },
      {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_MEAS']),
        orderBy: row[timeframe + allWebStore + '_MEAS']
      },
      unit === 'NOM' ? {
        numValue: formatNumberWithCommas(row[timeframe + allWebStore + '_VLY']),
        orderBy: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        orderBy: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))
    ),
    sortable: true,
    sortableDefault: 0
  }

  const sumProNmView = <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>Units:</Grid.Row>
        <Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <Grid.Row>&nbsp;</Grid.Row>
        <Grid.Row>
          <Menu compact size='small'>
            <Menu.Item
              name='All'
              active={allWebStore === ''}
              content='All'
              onClick={() => {
                setAllWebStore('')
              }}
            />
            <Menu.Item
              name='Web'
              active={allWebStore === '_WEB'}
              content='Web'
              onClick={() => {
                setAllWebStore('_WEB')
              }}
            />
            <Menu.Item
              name='Store'
              active={allWebStore === '_STR'}
              content='Store'
              onClick={() => {
                setAllWebStore('_STR')
              }}
            />
          </Menu>
        </Grid.Row>
      </Grid.Column>
    </Grid>
    {/* Measures overview - aggregated summary programs */}
    <DataView>
      <DataView.Title position='center'>
        Total Measures - {level} {locNbr}, {levelName}
        <Header.Subheader >
          Data as of {totalFilterByLevel[0]?.TRNSFM_DT || 'Loading...'}
        </Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={totalMeasureDataObj} />
    </DataView>
    <p>*Target is reported as the total measures generated last year through the current period. For example, YTD target is all measures generated last year through the upcoming Sunday. Actual Measure counts and vLY are reported through the previous day.</p>
    {/* Measures detail - by individual summary program */}
    <Header textAlign='center'>Measures by Summary Program</Header>
    <DataTable data={measureBySumProgram} />
  </>

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2o-link'>Back to Overview</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {isMonday ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      <Segment attached='bottom'>
        {sumProNmView}
      </Segment>
    </>
  )
}
import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dropdown, Header, Icon, Loader, Modal, Segment } from 'semantic-ui-react';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';

import { useQuery } from 'react-query';
import { getData } from '../../service/DataService';
import { DataTable } from '../Common/DataTable';
import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';

export const VocDetail = withRouter(({ timeframe, settimeframe, fpToggle, location, levelName }) => {
  const search = new URLSearchParams(location.search);
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0;
  const [orderType, setOrderType] = useState('ALL');
  const [metric, setMetric] = useState('LTPA');

  const [open, setOpen] = useState(false);
  const [commentSelected, setCommentSelected] = useState(null);

  const vocDetailQuery = useQuery(['querySwmVocDtl', { strNbr, fpToggle }], getData);

  // Filter by timeframe
  const dataFilterTf = useMemo(
    () => vocDetailQuery.data?.filter(row => row[timeframe + '_FLG'] && row),
    [vocDetailQuery.data, timeframe]
  );

  // Filter by order type (for use in orderType dropdown, details table)
  const dataFilterType = useMemo(
    () =>
      dataFilterTf?.filter(row => {
        if (orderType === 'ALL') {
          return row;
        } else if (orderType === 'OTHER') {
          return row && row['ORDER_TYPE'] === '';
        } else {
          return row && row['ORDER_TYPE'] === orderType;
        }
      }),
    [dataFilterTf, orderType]
  );

  // VOC Overview Details Table Object
  const overviewDetailsObject = useMemo(
    () => ({
      data: dataFilterType?.map(row => {
        const isShowComments = row['CUSTOMER_IMPROVEMENT_COMMENTS'].length > 0 || row['EMPLOYEE_PRAISE_COMMENTS'].length > 0;
        return [
          {
            stringValue: (
              <>
                {row['ORDER_NUMBER']}
                {isShowComments && <Icon fitted style={{ color: 'black', textDecoration: 'none' }} name='comment outline' />}
              </>
            ),
            onClick: () => {
              if (isShowComments) {
                setOpen(true);
                setCommentSelected({
                  orderNumber: row['ORDER_NUMBER'],
                  improvementComment: row['CUSTOMER_IMPROVEMENT_COMMENTS'],
                  emplPrseCmt: row['EMPLOYEE_PRAISE_COMMENTS']
                });
              }
            },
            customStyle: {
              color: isShowComments ? '#EE7125' : 'black',
              textDecoration: isShowComments && 'underline',
              cursor: isShowComments && 'pointer'
            }
          },
          { stringValue: row['RESPONSE_DATE'].toString().slice(0, 10) },
          { stringValue: row['ORDER_TYPE'] },
          { stringValue: row['CUSTOMER_CONTACT_APPROVAL'] },
          metric === 'LTPA' && { stringValue: row['SATISFACTION_SCORE'] },
          metric === 'ProdCond' && { stringValue: row['PRODUCT_CONDITION_RATING'] },
          metric === 'OrderAcc' && { stringValue: row['ORDER_FULFILLMENT_ACCURACY'] },
          metric === 'WaitTime' && { stringValue: row['WAIT_TIME'] }
        ].filter(row => Boolean(row));
      }),
      headers: [
        { name: 'Order #' },
        { name: 'Response Date' },
        { name: 'Order Type' },
        { name: 'Cust Contact Approval' },
        metric === 'LTPA' && { name: 'LTPA' },
        metric === 'ProdCond' && { name: 'Product Condition' },
        metric === 'OrderAcc' && { name: 'Order Accuracy' },
        metric === 'WaitTime' && { name: 'Reasonable Wait Time' }
      ].filter(row => Boolean(row)),
      pagination: true,
      sortable: true,
      sortableDefault: 1
    }),
    [dataFilterType, metric]
  );

  // Filter by order type
  const bossFilter = dataFilterTf?.filter(row => row && row['ORDER_TYPE'] === 'BOSS');
  const bopisFilter = dataFilterTf?.filter(row => row && row['ORDER_TYPE'] === 'BOPIS');
  const mixedFilter = dataFilterTf?.filter(row => row && row['ORDER_TYPE'] === 'MIXED');

  // Find average by order type and metric (for use in voc overview grid)
  const satisfBoss =
    bossFilter?.length !== 0
      ? bossFilter?.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / bossFilter?.length
      : null;
  const satisfBopis =
    bopisFilter?.length !== 0
      ? bopisFilter?.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / bopisFilter?.length
      : null;
  const satisfMixed =
    mixedFilter?.length !== 0
      ? mixedFilter?.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / mixedFilter?.length
      : null;
  const satisfTotal =
    dataFilterTf?.length !== 0
      ? dataFilterTf?.reduce((sum, obj) => sum + (obj.SATISFACTION_SCORE === 5 ? 1 : 0), 0) / dataFilterTf?.length
      : null;
  const prodCondBoss =
    bossFilter?.length !== 0
      ? bossFilter?.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / bossFilter?.length
      : null;
  const prodCondBopis =
    bopisFilter?.length !== 0
      ? bopisFilter?.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / bopisFilter?.length
      : null;
  const prodCondMixed =
    mixedFilter?.length !== 0
      ? mixedFilter?.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / mixedFilter?.length
      : null;
  const prodCondTotal =
    dataFilterTf?.length !== 0
      ? dataFilterTf?.reduce((sum, obj) => sum + (obj.PRODUCT_CONDITION_RATING === 5 ? 1 : 0), 0) / dataFilterTf?.length
      : null;
  const orderAccBoss =
    bossFilter?.length !== 0
      ? bossFilter?.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / bossFilter?.length
      : null;
  const orderAccBopis =
    bopisFilter?.length !== 0
      ? bopisFilter?.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / bopisFilter?.length
      : null;
  const orderAccMixed =
    mixedFilter?.length !== 0
      ? mixedFilter?.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / mixedFilter?.length
      : null;
  const orderAccTotal =
    dataFilterTf?.length !== 0
      ? dataFilterTf?.reduce((sum, obj) => sum + (obj.ORDER_FULFILLMENT_ACCURACY === 5 ? 1 : 0), 0) / dataFilterTf?.length
      : null;
  const waitTmBoss =
    bossFilter?.length !== 0
      ? bossFilter?.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / bossFilter?.length
      : null;
  const waitTmBopis =
    bopisFilter?.length !== 0
      ? bopisFilter?.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / bopisFilter?.length
      : null;
  const waitTmMixed =
    mixedFilter?.length !== 0
      ? mixedFilter?.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / mixedFilter?.length
      : null;
  const waitTmTotal =
    dataFilterTf?.length !== 0
      ? dataFilterTf?.reduce((sum, obj) => sum + (obj.WAIT_TIME === 5 ? 1 : 0), 0) / dataFilterTf?.length
      : null;

  // VOC Overview Table Rows
  const overviewRows = [
    {
      name: 'LTPA',
      boss: satisfBoss,
      bopis: satisfBopis,
      mixed: satisfMixed,
      total: satisfTotal
    },
    {
      name: 'Product Condition',
      boss: prodCondBoss,
      bopis: prodCondBopis,
      mixed: prodCondMixed,
      total: prodCondTotal
    },
    {
      name: 'Order Accuracy',
      boss: orderAccBoss,
      bopis: orderAccBopis,
      mixed: orderAccMixed,
      total: orderAccTotal
    },
    {
      name: 'Reasonable Wait Time',
      boss: waitTmBoss,
      bopis: waitTmBopis,
      mixed: waitTmMixed,
      total: waitTmTotal
    }
  ];

  // VOC Overview Table Object
  const overviewObject = {
    data: overviewRows.map(row => [
      { stringValue: row.name },
      { pctValue: row.boss },
      { pctValue: row.bopis },
      { pctValue: row.mixed },
      { pctValue: row.total }
    ]),
    headers: [{ name: '' }, { name: 'BOSS' }, { name: 'BOPIS' }, { name: 'MIXED' }, { name: 'TOTAL' }]
  };

  if (vocDetailQuery.isLoading) return <Loader active>Loading...</Loader>;

  const breadcrumbItems = [{ to: `/store/overview?strNbr=${strNbr}`, text: 'Back to Overview' }, { text: 'VOC Detail' }];
  const orderTypeItems = [
    { key: 'ALL', text: 'ALL', value: 'ALL' },
    { key: 'BOPIS', text: 'BOPIS', value: 'BOPIS' },
    { key: 'BOSS', text: 'BOSS', value: 'BOSS' },
    { key: 'MIXED', text: 'MIXED', value: 'MIXED' },
    { key: 'OTHER', text: 'OTHER', value: 'OTHER' }
  ];
  const metricTypeItems = [
    { key: 'LTPA', text: 'LTPA', value: 'LTPA' },
    { key: 'ProdCond', text: 'Product Condition', value: 'ProdCond' },
    { key: 'OrderAcc', text: 'Order Accuracy', value: 'OrderAcc' },
    { key: 'WaitTime', text: 'Wait Time', value: 'WaitTime' }
  ];
  return (
    <Segment className='mb-4'>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-4'>
        <section style={{ textAlign: 'center' }}>
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </section>

        <DataView.Title className='mt-2' position='center'>
          VOC Overview - Store {strNbr}, {levelName}
        </DataView.Title>

        <DataView.DataTable data={overviewObject} />
      </DataView>

      <DataView className='my-4'>
        <DataView.Controls className='d-flex justify-content-between mb-4 gap-4'>
          <section className='full-width'>
            <p>Select Order Type:</p>
            <Dropdown
              fluid
              compact
              selection
              defaultValue={orderType}
              options={orderTypeItems}
              onChange={(_, { value }) => setOrderType(value)}
            />
          </section>
          <section className='full-width'>
            <p>Select Metric:</p>
            <Dropdown
              fluid
              compact
              selection
              defaultValue={metric}
              options={metricTypeItems}
              onChange={(_, { value }) => setMetric(value)}
            />
          </section>
        </DataView.Controls>

        <DataView.Title className='mt-2' position='center'>
          Online VOC Details
        </DataView.Title>

        <DataTable data={overviewDetailsObject} />
      </DataView>

      {open && commentSelected && (
        <Modal closeIcon open={open} onClose={() => setOpen(false)}>
          <Segment color='orange'>
            <section style={{ textAlign: 'center' }}>
              <Header>Order Number: {commentSelected.orderNumber}</Header>

              <Header style={{ color: 'red' }}>Customer Comments</Header>
              <p>{commentSelected.improvementComment}</p>

              <Header style={{ color: 'red' }}>Employee Praise Comments</Header>
              <p>{commentSelected.emplPrseCmt}</p>

              <Button
                className='mt-4'
                onClick={() => setOpen(false)}
                style={{ color: 'white', backgroundColor: '#EE7125' }}
                content='Close'
              />
            </section>
          </Segment>
        </Modal>
      )}
    </Segment>
  );
});

import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { DataTable } from '../../Common/DataTable';
import { getData } from '../../../service/DataService';
import { Dropdown, Grid, Header, Loader } from 'semantic-ui-react';
import { getLtsaPropsByMetric, ltsaMenuOptions } from '../../../constants/ltsa';
import { buildSublevelLinkColumn } from '../../../utils/dataTable';

const targetPreviousLevel = {
  region: 'District',
  division: 'Region'
};

export const RegionLtsa = ({ fpToggle, level, locNbr, timeframe }) => {
  const [metric, setMetric] = useState('Ltsa');
  const { data = [], isError, isLoading } = useQuery([`query/ltsaSummaryPlusOne/${level}`, { locNbr, fpToggle }], getData);

  const filterByCustomerType = (data, customerType) => {
    return data.filter(item => item && item['CUSTOMER_TYPE'] === customerType);
  };

  const props = useMemo(() => getLtsaPropsByMetric(metric), [metric]);
  const proCustomerData = useMemo(() => filterByCustomerType(data, 'PRO'), [data]);
  const consumerCustomerData = useMemo(() => filterByCustomerType(data, 'CONSUMER'), [data]);

  const combinedCustomerData = useMemo(
    () =>
      consumerCustomerData.map(consumer => {
        // this component could be used for division or region level
        const pro =
          level === 'division'
            ? proCustomerData.find(pro => pro['RGN_NBR'] === consumer['RGN_NBR'])
            : proCustomerData.find(pro => pro['DST_NBR'] === consumer['DST_NBR']);
        return {
          pro: pro || {},
          consumer: consumer,
          ...(level === 'division' ? { RGN_NBR: consumer['RGN_NBR'] } : { DST_NBR: consumer['DST_NBR'] })
        };
      }),
    [consumerCustomerData, proCustomerData]
  );

  const dataObj = useMemo(
    () => ({
      sortable: true,
      sortableDefault: 0,
      headers: [
        { name: level === 'division' ? 'Region #' : 'District #' },
        { name: 'Consumer' },
        { name: 'Pro' },
        { name: 'Total' }
      ],
      data: combinedCustomerData.map(({ consumer, pro }) => {
        return [
          {
            ...buildSublevelLinkColumn({ level, row: consumer, to: '/ltsa' })
          },
          { pctValue: consumer[`${timeframe}${props[0]}`] / consumer[`${timeframe}${props[1]}`] },
          { pctValue: pro[`${timeframe}${props[0]}`] / pro[`${timeframe}${props[1]}`] },
          {
            pctValue:
              (consumer[`${timeframe}${props[0]}`] + pro[`${timeframe}${props[0]}`]) /
              (consumer[`${timeframe}${props[1]}`] + pro[`${timeframe}${props[1]}`])
          }
        ];
      })
    }),
    [combinedCustomerData, timeframe, props]
  );

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong.</p>;

  return (
    <>
      <Grid>
        <Grid.Row style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Grid.Column width={8}>
            <Header textAlign='center'>LTSA By {targetPreviousLevel[level]}</Header>
          </Grid.Column>

          <Grid.Column width={8}>
            Select Metric:
            <Dropdown
              fluid
              compact
              selection
              defaultValue='Ltsa'
              options={ltsaMenuOptions}
              onChange={(_, { value }) => setMetric(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <DataTable data={dataObj} />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import { ProtectedRoute } from './components/Common/ProtectedRoute';
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4';
import { useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './App.css';
import { EditAlert } from './components/Support/EditAlert';
import { getAdminData } from './service/DataService';
import { buildBaseShrinkUrl } from './components/ShrinkContainer/utils/url';

// TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
import { LegacyPageNotice } from './components/Support/LegacyPageNotice';
import { useWindowsDimensions } from './hooks/useWindowDimensions';

import { NewChangeLog } from './components/Support/NewChangeLog';
import { PageTables } from './components/Support/PageTables';

import { DeniedMenu } from './components/Menus/DeniedMenu';
import { LoginMenu } from './components/Menus/LoginMenu';
import { TopMenu } from './components/Menus/TopMenu';
import { NotFoundPage } from './modules/level/features/notFound/pages/NotFoundPage';

import { Overview } from './components/Overviews/Overview';
import { ClassDrilldown } from './components/ProdHierDrilldown/ClassDrilldown';
import { DeptDrilldown } from './components/ProdHierDrilldown/DeptDrilldown';
import { SubclassDrilldown } from './components/ProdHierDrilldown/SubclassDrilldown';
import { CoreOverviewPage } from './modules/core/features/overview/pages/CoreOverviewPage';
import { CoreDrillDown } from './modules/core/features/overview/components/Tabs/Sales/Drilldown/CoreDrillDown';

import { AisleDrilldown } from './components/LocDrilldown/AisleDrilldown';
import { BayDrilldown } from './components/LocDrilldown/BayDrilldown';
import { BaysByStore } from './components/LocDrilldown/BaysByStore';
import { BaysByStoreBayDrilldown } from './components/LocDrilldown/BaysByStoreBayDrilldown';
import { DeptLocAisleDrilldown } from './components/LocDrilldown/DeptLocAisleDrilldown';
import { DeptLocBayDrilldown } from './components/LocDrilldown/DeptLocBayDrilldown';
import { DeptLocDrilldown } from './components/LocDrilldown/DeptLocDrilldown';

import { InvClassDrilldown } from './components/InventoryDrilldown/InvClassDrilldown';
import { InvDeptDrilldown } from './components/InventoryDrilldown/InvDeptDrilldown';
import { InvSubclassDrilldown } from './components/InventoryDrilldown/InvSubclassDrilldown';

import { LaborDetail } from './components/Details/LaborDetail';
import { LaborDetailDst } from './components/Details/LaborDetailDst';
import { LeadsAssocView } from './components/Details/LeadsAssocView';
import { LeadsDetail } from './components/Details/LeadsDetail';
import { LeadsDetailDst } from './components/Details/LeadsDetailDst';
import { LtsaDetail } from './components/Details/LtsaDetail';
import { LtsaDetailDst } from './components/Details/LtsaDetailDst';
import { MeasAssocView } from './components/Details/MeasAssocView';
import { MeasDetail } from './components/Details/MeasDetail';
import { MeasDetailDst } from './components/Details/MeasDetailDst';
import { SmdRgnRsn } from './components/Details/Region/SmdRgnRsn';
import { SmdDstRsn } from './components/Details/SmdDstRsn';
import { SmdStrRsn } from './components/Details/SmdStrRsn';
import { VocDetail } from './components/Details/VocDetail';
import { VocDetailDst } from './components/Details/VocDetailDst';
import { CoreLeads } from './modules/core/features/overview/pages/leads/CoreLeads';

import { DailyDeptView } from './components/HourlyDaily/DailyDeptView';
import { DailyView } from './components/HourlyDaily/DailyView';
import { IntradayDeptView } from './components/HourlyDaily/IntradayDeptView';
import { DstOrderFulfillment } from './components/OrderFulfillment/DstOrderFulfillment';
import { OrderFulfillment } from './components/OrderFulfillment/OrderFulfillment';
import { StoreOrderFulfillmentPage } from './modules/store/features/orderfulfillment/pages/StoreOrderFulfillmentPage';
import { SpecialtyProRentalProvider } from './components/SpecialtyProRentalContainer/context/SpecialtyProRentalContext';
import { SpecialtyProRentalPage } from './components/SpecialtyProRentalContainer/pages/SpecialtyProRentalPage';
import { ViewAlert } from './components/Support/ViewAlert';
import { ViewChangeLog } from './components/Support/ViewChangeLog';

import { Shrink } from './components/Shrink/Shrink';
import { ShrinkAgedWc } from './components/Shrink/ShrinkAgedWc';
import { ShrinkBossCancel } from './components/Shrink/ShrinkBossCancel';
import { ShrinkClearanceNh } from './components/Shrink/ShrinkClearanceNh';
import { ShrinkCullMarkdownsPage } from './components/ShrinkContainer/pages/ShrinkCullMarkdownsPage';
import { ShrinkCullMd } from './components/Shrink/ShrinkCullMd';
import { ShrinkDamagedMd } from './components/Shrink/ShrinkDamagedMd';
import { ShrinkInvalidScans } from './components/Shrink/ShrinkInvalidScans';
import { ShrinkOhEmptPkg } from './components/Shrink/ShrinkOhEmptPkg';
import { ShrinkOhEmptPkgDept } from './components/Shrink/ShrinkOhEmptPkgDept';
import { ShrinkReducedTag } from './components/Shrink/ShrinkReducedTag';
import { ShrinkRtvCredit } from './components/Shrink/ShrinkRtvCredit';
import { ShrinkRtvDeletedTag } from './components/Shrink/ShrinkRtvDeletedTag';
import { ShrinkRtvDeletedTagsPage } from './components/ShrinkContainer/pages/ShrinkRtvDeletedTagsPage';
import { DstShrinkAgedWc } from './components/ShrinkDst/DstShrinkAgedWc';
import { DstShrinkBossCancel } from './components/ShrinkDst/DstShrinkBossCancel';
import { DstShrinkClearanceNh } from './components/ShrinkDst/DstShrinkClearanceNh';
import { DstShrinkCullMd } from './components/ShrinkDst/DstShrinkCullMd';
import { DstShrinkDamagedMd } from './components/ShrinkDst/DstShrinkDamagedMd';
import { DstShrinkInvalidScans } from './components/ShrinkDst/DstShrinkInvalidScans';
import { DstShrinkOhEmptPkg } from './components/ShrinkDst/DstShrinkOhEmptPkg';
import { DstShrinkReducedTag } from './components/ShrinkDst/DstShrinkReducedTag';
import { ShrinkReducedTagPage } from './components/ShrinkContainer/pages/ShrinkReducedTagPage';
import { CoreReducedTagPage } from './modules/core/features/shrink/components/CoreReducedTagPage';
import { DstShrinkRtvCredit } from './components/ShrinkDst/DstShrinkRtvCredit';

import { ShrinkStoreClass } from './components/Shrink/ShrinkStoreClass';
import { ShrinkStoreDept } from './components/Shrink/ShrinkStoreDept';
import { ShrinkStoreSubClass } from './components/Shrink/ShrinkStoreSubClass';
import { DstShrinkClass } from './components/ShrinkDst/DstShrinkClass';
import { DstShrinkDept } from './components/ShrinkDst/DstShrinkDept';
import { ShrinkDeptPage } from './components/ShrinkContainer/pages/ShrinkDeptPage';
import { DstShrinkSubClass } from './components/ShrinkDst/DstShrinkSubClass';
import { ShrinkClassPage } from './components/ShrinkContainer/pages/ShrinkClassPage';
import { ShrinkSubclassPage } from './components/ShrinkContainer/pages/ShrinkSubclassPage';
import { ShrinkPage } from './components/ShrinkContainer/pages/ShrinkPage';
import { ShrinkProvider } from './components/ShrinkContainer/hooks/ShrinkProvider';
import { OhAutoRejectPage } from './components/ShrinkContainer/pages/OhAutoRejectPage';
import { OhAutoRejectCorePage } from './modules/core/features/shrink/pages/OhAutoRejectCorePage';
import { AccuracyAndReceivingMetricPage } from './components/ShrinkContainer/pages/AccuracyAndReceivingMetricPage';

import { SmdRgnDept } from './components/Details/Region/SmdRgnDept';
import { SafetyDetail } from './components/Details/SafetyDetail';
import { SafetyDetailDst } from './components/Details/SafetyDetailDst';
import { SmdDetailDiv } from './components/Details/SmdDetailDiv';
import { SmdDetailDst } from './components/Details/SmdDetailDst';
import { SmdDetailRgn } from './components/Details/SmdDetailRgn';
import { SmdDetailStr } from './components/Details/SmdDetailStr';
import { SmdDstDept } from './components/Details/SmdDstDept';
import { SmdStrDept } from './components/Details/SmdStrDept';
import { SmdStrDeptRsn } from './components/Details/SmdStrDeptRsn';
import { Events } from './components/Events/Events';
import { EventsDetailDst } from './components/Events/EventsDetailDst';
import { EventsDetailStr } from './components/Events/EventsDetailStr';
import { HeadlinesInsights } from './components/HeadlinesInsights/HeadlinesInsights';
import { OHMNoLocationTags } from './components/OSA/OHMNoLocationTags';
import { OHMNoLocationTagsByDept } from './components/OSA/OHMNoLocationTagsByDept';
import { OHMNoLocationTagsByDeptTag } from './components/OSA/OHMNoLocationTagsByDeptTag';
import { OHMNoLocationTagsDst } from './components/OSA/OHMNoLocationTagsDst';
import { OHMNoLocationTagsLevel } from './components/OSA/OHMNoLocationTagsLevel';
import { OsaOhmNoLocTagsPage } from './modules/core/features/overview/pages/osa/OsaOhmNoLocTagsPage';
import { OSAImaging } from './components/OSA/OSAImaging';
import { OSAImagingDept } from './components/OSA/OSAImagingDept';
import { OSAImagingDeptAisle } from './components/OSA/OSAImagingDeptAisle';
import { OSAImagingDist } from './components/OSA/OSAImagingDist';
import { OSALanding } from './components/OSA/OSALanding';
import { DstOverview } from './components/Overviews/DstOverview';
import { DstAutoKR } from './components/ShrinkDst/DstAutoKR';
import { DstOhAutoRej } from './components/ShrinkDst/DstOhAutoRej';
import { DstShrinkRtvDeletedTag } from './components/ShrinkDst/DstShrinkRtvDeletedTag';
import { DstUnmatchedXfers } from './components/ShrinkDst/DstUnmatchedXfers';
import { ServicesDtlDstPrgm } from './components/SpecialtyProRental/ServicesDtlDstPrgm';
import { SpecialtyProRentalServicesPage } from './components/SpecialtyProRentalContainer/pages/SpecialtyProRentalServicesPage';

import { LaborContextProvider } from './components/Details/LaborContextProvider';
import { LaborDetailAssoc } from './components/Details/LaborDetailAssoc';
import { LaborDetailDeptByDst } from './components/Details/LaborDetailDeptByDst';
import { LaborDetailMgrDeptByDst } from './components/Details/LaborDetailMgrDeptByDst';
import { LaborDetailDept } from './components/Details/Region/LaborDetailDept';
import { LaborDetailDeptManager } from './components/Details/Region/LaborDetailDeptManager';
import { ManagerAddedBays } from './components/OSA/ManagerAddedBays';
import { ManagerAddedBaysDetails } from './components/OSA/ManagerAddedBaysDetails';
import { SidekickSkuBayBayDetail } from './components/OSA/SidekickSkuBayBayDetail';
import { SidekickSkuBayDept } from './components/OSA/SidekickSkuBayDept';
import { SidekickSkuBayLoggedWork } from './components/OSA/SidekickSkuBayLoggedWork';
import { SideKickSkuBayStr } from './components/OSA/SideKickSkuBayStr';
import { Sidekickskubay as SidekickskubayCore } from './modules/core/features/overview/pages/osa/Sidekickskubay';

import { CoreSafety } from './modules/core/features/overview/pages/safety/CoreSafety';
import { DailySalesDepartment } from './modules/core/features/dailySales/pages/DailySalesDepartment';

import { useFetchLevelName } from './hooks/useFetchLevelName';
import { EventsPage } from './modules/core/features/events/pages/EventsPage';
import { CoreEventsDetailPage } from './modules/core/features/events/pages/CoreEventsDetailPage';
import { SubClassToSku } from './modules/core/features/overview/components/Tabs/Sales/Drilldown/SubClassToSku';

import { EventsDetailRgn } from './components/Events/EventsDetailRgn';
import { SideKickPurge } from './components/OSA/SideKickPurge';
import { SideKickPurgeDeptBay } from './components/OSA/SideKickPurgeDeptBay';
import { SideKickPurgeDeptBayByBay } from './components/OSA/SideKickPurgeDeptBayByBay';
import { SideKickPurgeDeptPurge } from './components/OSA/SideKickPurgeDeptPurge';
import { SideKickPurgeDeptPurgeByBay } from './components/OSA/SideKickPurgeDeptPurgeByBay';
import { SideKickPurgePage } from './components/OSA/SideKickPurgePage';
import { SideKickSkuBay } from './components/OSA/SideKickSkuBay';
import { RegionVocSummary } from './components/Overviews/Region/RegionVocSummary';
import IntradayContextProvider from './context/IntradayContext';
import OverviewContextProvider from './context/OverviewContext';
import { useQueryParams } from './hooks/useQueryParams';
import { DailySalesOverview } from './modules/core/features/dailySales/pages/DailySalesOverview';
import { CoreSalesDepartmentProvider } from './modules/core/features/overview/context/CoreSalesDepartmentContext';
import { HourlySales } from './modules/core/features/overview/pages/hourlySales/HourlySales';
import { IntradayDeptViewCore } from './modules/core/features/overview/pages/intradayDeptView/IntradayDeptViewCore';
import { LtpaOverviewPage } from './modules/core/features/overview/pages/Ltpa/LtpaOverviewPage';
import { LtsaPage } from './modules/core/features/overview/pages/LtsaPage';
import { MeasurePage } from './modules/core/features/overview/pages/MeasurePage';
import { OsaImagingPage } from './modules/core/features/overview/pages/osa/OsaImagingPage';
import { OsaOverviewPage } from './modules/core/features/overview/pages/osa/OsaOverviewPage';
import { OSAPurge } from './modules/core/features/overview/pages/osa/OSAPurge';
import { SmdDepartmentPage } from './modules/core/features/overview/pages/smd/SmdDepartmentPage';
import { SmdDetailPage } from './modules/core/features/overview/pages/smd/SmdDetailPage';
import { SmdReasonCodesPage } from './modules/core/features/overview/pages/smd/SmdReasonCodesPage';
import { InvalidScansPage } from './components/ShrinkContainer/pages/InvalidScansPage';
import LaborPage from './modules/core/features/overview/pages/LaborPage';
import { LaborDetailDeptDrill } from './modules/core/features/overview/components/labor/LaborDetailDeptDrill';
import { LaborDetailDeptMgr } from './modules/core/features/overview/components/labor/LaborDetailDeptMgr';
import { OrderFulfillmentPage } from './modules/level/features/orderfulfillment/pages/OrderFulfillmentPage';
import { CoreInventorySubClass } from './modules/core/features/overview/components/inventory/CoreInventorySubClass';
import { CoreInventoryClass } from './modules/core/features/overview/components/inventory/CoreInventoryClass';
import { CoreInventoryDrill } from './modules/core/features/overview/components/inventory/CoreInventoryDrill';
import { ShrinkCoreProvider } from './modules/core/context/CoreShrinkContext';
import { CoreShrinkACCRCVProvider } from './modules/core/context/CoreShrinkACCRCVContext';
import { AccuracyAndReceivingMetric } from './modules/core/features/shrink/components/ACCRCV/AccuracyAndReceivingMetric';
import ShrinkPageCore from './modules/core/features/shrink/pages/ShrinkPageCore';
import { InvalidScansCore } from './modules/core/features/shrink/components/InvalidScansCore';
import { ClearanceNoHomesPage } from './components/ShrinkContainer/pages/ClearanceNoHomesPage';
import { AgedWithCallsPage } from './components/ShrinkContainer/pages/AgedWithCallsPage';
import { DamagedMarkdownsPage } from './components/ShrinkContainer/pages/DamagedMarkdownsPage';
import { RTVCreditCore } from './modules/core/features/shrink/components/RTVCreditCore';
import { RTVCreditsPage } from './components/ShrinkContainer/pages/RTVCreditsPage';
import { OnHandAdjustmentEPPage } from './components/ShrinkContainer/pages/OnHandAdjustmentEPPage';
import { AgedWillCallsCore } from './modules/core/features/shrink/components/AgedWillCallsCore';
import { CoreShrinkDeptPage } from './modules/core/features/shrink/pages/CoreShrinkDeptPage';
import { RTVDeletingCore } from './modules/core/features/shrink/components/RTVDeletingCore';
import { ShrinkCullMarkdownsCore } from './modules/core/features/shrink/components/ShrinkCullMarkdownsCore';
import { ShrinkBossCancel as ShrinkBossCancelRgnAndDiv } from './components/ShrinkContainer/pages/ShrinkBossCancel';
import { ShrinkBossCancelPage as CoreShrinkBossCancelPage } from './modules/core/features/shrink/pages/ShrinkBossCancelPage';
import { ClearanceNoHomesCorePage } from './modules/core/features/shrink/components/ClearanceNoHomesCorePage';
import { DamagedMarkdownsCorePage } from './modules/core/features/shrink/components/DamagedMarkdownsCorePage';
import { OnHandAdjustmentCore } from './modules/core/features/shrink/pages/OnHandAdjustmentCore';
import { CoreShrinkClassPage } from './modules/core/features/shrink/pages/CoreShrinkClassPage';
import { CoreShrinkSubClassPage } from './modules/core/features/shrink/pages/CoreShrinkSubClassPage';
import { SpecialtyProRentalServicesPrgmPage } from './components/SpecialtyProRentalContainer/pages/SpecialtyProRentalServicesPrgmPage';
import { SpecialtyProRentalServicesProvider } from './components/SpecialtyProRentalContainer/context/SpecialtyProRentalServicesContext';
import { SPEC_PRO_RENTAL_PATH } from './components/SpecialtyProRentalContainer/utils/consts';
import { CoreServicesPrgmPage } from './modules/core/features/specialtyProRental/pages/CoreServicesPrgmPage';
import { CreditPage } from './components/Credit/CreditContainer/pages/CreditPage';
import CreditContextProvider from './components/Credit/CreditContainer/context/CreditContextProvider';

const App = withRouter(({ location }) => {
  useEffect(() => {
    console.log(`Location changing: ${JSON.stringify(location)}`);
    // ReactGA.pageview(location.pathname); // Record a pageview for the given page
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // ReactGA4 version
  }, [location]);

  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();

  const levelFromUrl = window.location.pathname.split('/').filter(Boolean)[0];
  // TODO: Refactor to remove ternarys
  const [level, setLevel] = useState(
    strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : levelFromUrl ? 'core' : 'store'
  );
  const [levelSelection, setLevelSelection] = useState(
    strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : levelFromUrl ? 'core' : 'store'
  );
  const [storeNumber, setStoreNumber] = useState(strNbr || dstNbr || rgnNbr || divNbr || '');
  const [storeNumberField, setStoreNumberField] = useState(strNbr || dstNbr || rgnNbr || divNbr || '');
  const [timeframe, settimeframe] = useState('WTD');
  const [metric, setMetric] = useState('sales');
  const [shrinkTimeframe, setShrinkTimeframe] = useState('LAST_INV');
  const [fpToggle, setFpToggle] = useState('storeops');
  const [clearedAlerts, setClearedAlerts] = useState(false);

  const [platformStatus, setPlatformStatus] = useState('loading');
  const [permissionDeniedRsn, setPDR] = useState('');

  const [userLdap, setUserLdap] = useState('');
  const [userIsCorporate, setUserIsCorporate] = useState(false);
  const [userGetsNPlus1, setUserGetsNPlus1] = useState(false);
  const [userMgtLvlId, setUserMgtLvlId] = useState(1000);
  const [userEmplCtgryNm, setUserEmplCtgryNm] = useState('');
  const [jobProfileNm, setJobProfileNm] = useState('');
  const [isLegacyAlertOpen, setIsLegacyAlertOpen] = useState(false);
  const [unit, setUnit] = useState('NOM');

  const adminQuery = useQuery(['queryGetStorePulseAlert'], getAdminData);
  const { currentLevelName: levelName } = useFetchLevelName({
    level,
    locNbr: strNbr || dstNbr || rgnNbr || divNbr,
    fpToggle
  });

  useEffect(() => {
    setStoreNumberField(strNbr || dstNbr || rgnNbr || divNbr || '');
    setLevelSelection(
      strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : levelFromUrl ? 'core' : 'store'
    );
    setLevel(
      strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : levelFromUrl ? 'core' : 'store'
    );
    setStoreNumber(strNbr || dstNbr || rgnNbr || divNbr || '');
  }, [location]);

  useEffect(() => {
    if (window.MscJS !== undefined && window.MscJS !== null) {
      // We detected the android user agent. Assume that we're on Firstphone, but have a 20 second countdown to catch non-FP android users
      // setPlatformStatus("assumedFP")
      // let timeout = window.setTimeout(() => setPlatformStatus("assumedDesktop"), 20*1000)

      // Add the event listener for the First Phone platform load. This takes a few seconds, but less than 20
      // Update: deviceready is a Cordova event, and it is no longer needed with mobile sidecar
      // document.addEventListener("deviceready", () => {
      setPlatformStatus('FPLoaded');
      // window.clearTimeout(timeout)
      // }, false)
    } else {
      // We don't detect android, so we serve up the desktop experience (which serves desktop and other mobile platforms)
      console.log('Android test returned false', navigator.userAgent);
      setPlatformStatus('assumedDesktop');
    }
  }, []);

  // TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
  const { isDesktop } = useWindowsDimensions();
  useEffect(() => {
    const LEGACY_PAGE = 'storewalk.homedepot.com';
    const LOCAL_PAGE = 'localhost:3000';
    const TESTING_PAGE = 'storewalk-np.homedepot.com';
    const isLegacyPage = window.location.host === LEGACY_PAGE || window.location.host === TESTING_PAGE;
    setIsLegacyAlertOpen(isLegacyPage);
  }, []);

  useEffect(() => {
    console.log(`handling status: ${platformStatus}`);
    if (platformStatus === 'FPLoaded') {
      // We're on the FP platform and it should be fully loaded
      // if (window.plugin && window.plugin.firstphone && window.plugin.firstphone.launcher) {
      if (window.MscJS !== undefined && window.MscJS !== null) {
        // console.log("Plugin exists")
        console.log('MscJS ready');
        // window.plugin.firstphone.launcher.getUser(userInfo => {
        const currentUserSessionData = JSON?.parse(
          window?.MscJS?.getCurrentUserSession() ?? '{"error":"Unknown Error, line 174"}'
        );
        const currentDeviceSessionData = JSON?.parse(
          window?.MscJS?.getCurrentDeviceSession() ?? '{"error":"Unknown Error, line 177"}'
        );
        if (
          currentUserSessionData &&
          currentUserSessionData?.status === 'success' &&
          currentDeviceSessionData &&
          currentDeviceSessionData?.status === 'success'
        ) {
          let currentUserSessionDataMessage = JSON?.parse(
            currentUserSessionData?.message ?? '{"message":"Unknown Error, line 182"}'
          );
          console.log('CurrentUserSession');
          console.log(currentUserSessionDataMessage);

          let currentDeviceSessionDataMessage = JSON?.parse(
            currentDeviceSessionData?.message ?? '{"message":"Unknown Error, line 186"}'
          );
          console.log('CurrentDeviceSession');
          console.log(currentDeviceSessionDataMessage);

          // if (userInfo && userInfo.thdSsoToken && userInfo.thdSsoToken !== "") {
          // if (datamessage && datamessage.thdSso && datamessage.thdSso !== "") {
          if (
            currentUserSessionDataMessage &&
            currentUserSessionDataMessage.pingUserAuthToken &&
            currentUserSessionDataMessage.pingUserAuthToken !== '' &&
            currentUserSessionDataMessage.pingUserRefreshToken &&
            currentUserSessionDataMessage.pingUserRefreshToken !== ''
            // && datamessage.thdSso && datamessage.thdSso !== ""
          ) {
            // new Cookies().set('THDSSO', datamessage?.thdSso, {
            //   domain: ".homedepot.com"
            // })
            setFpToggle('storeopsFP');
            setPlatformStatus('FPloggedIn');
          } else {
            setPlatformStatus('loggedOut');
          }

          //   setFpToggle('storeopsFP')
          //   setPlatformStatus("FPloggedIn")
          // } else {
          //   setPlatformStatus("assumedDesktop")
          // }

          // }, err => {
          //   console.log(`Error getting User Info: ${err}`)
          //   setPlatformStatus("assumedDesktop")
          // })

          // window.plugin.firstphone.launcher.getDevice(deviceInfo => {
          // window?.MscJS?.getCurrentUserSession(deviceInfo => {
          // console.log(deviceInfo)
          if (currentDeviceSessionDataMessage && currentDeviceSessionDataMessage.storeNumber) {
            setStoreNumber(currentDeviceSessionDataMessage.storeNumber);
            setStoreNumberField(currentDeviceSessionDataMessage.storeNumber);
          } else {
            console.log(`Problem getting location number. Assuming desktop`);
            setFpToggle('storeops');
            setPlatformStatus('assumedDesktop');
          }
          // }, err => console.log(`Error getting Device Info: ${err}`))
        }
      } else {
        // MSc not found, so assuming desktop.
        console.log('Mobile Sidecar not found. Assuming desktop');
        setFpToggle('storeops');
        setPlatformStatus('assumedDesktop');
      }
    } else if (platformStatus === 'assumedDesktop' || platformStatus === 'FPloggedIn') {
      fetch(`${process.env.REACT_APP_BACKEND}/api/${fpToggle}/loginCheck`, { credentials: 'include' })
        .then(resp => {
          if (resp.ok) {
            return resp.json();
          } else if (resp.status === 403) {
            return resp.text();
          } else {
            setPlatformStatus('loggedOut');
          }
        })
        .then(data => {
          if (data) {
            if (typeof data === 'string' || data instanceof String) {
              if (data.includes('Permission denied') || data.includes('Missing required permissions')) {
                setPlatformStatus('permissionDenied');
                setPDR(data);
              }
            } else {
              ReactGA.set({ userId: data.user });
              setUserLdap(data.user);
              setUserIsCorporate(data.iscorporate);
              setUserGetsNPlus1(data.getsnplus1);
              setUserMgtLvlId(data.mgtlvlid);
              setUserEmplCtgryNm(data.emplctgrynm);
              setJobProfileNm(data.jobprofilenm);
              setPlatformStatus('loggedIn');
            }
          } else {
            setPlatformStatus('loggedOut');
          }
        });
    }
  }, [platformStatus, fpToggle]);

  const baseCoreShrinkUrl = buildBaseShrinkUrl('core');
  const baseLevelShrinkUrl = buildBaseShrinkUrl();

  // TODO: We had to go with a non-blocking alert due to First Phones not being fully rolled out. Switch back to the blocking code when First Phones is fully rolled out.
  // if (isLegacyAlertOpen) {
  //   return <LegacyPageNotice />;
  // }

  switch (platformStatus) {
    case 'loading':
    case 'assumedFP':
    case 'FPLoaded':
    case 'FPloggedIn':
    case 'assumedDesktop':
      return <Loader active>Loading</Loader>;
    case 'permissionDenied':
      return <DeniedMenu pdr={permissionDeniedRsn} />;
    case 'loggedIn':
      return (
        <Container className='App'>
          {isLegacyAlertOpen && <LegacyPageNotice isSticky={isDesktop} />}
          <TopMenu
            level={level}
            setLevel={setLevel}
            levelSelection={levelSelection}
            setLevelSelection={setLevelSelection}
            storeNumber={storeNumber}
            setStoreNumber={setStoreNumber}
            storeNumberField={storeNumberField}
            setStoreNumberField={setStoreNumberField}
            fpToggle={fpToggle}
            location={location}
          />
          {!clearedAlerts && (
            <ViewAlert fpToggle={fpToggle} clearedAlerts={clearedAlerts} setClearedAlerts={setClearedAlerts} />
          )}

          <Switch>
            {/* Overview */}
            <Route exact path='/store/overview'>
              <Overview
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userGetsNPlus1={userGetsNPlus1}
                userIsCorporate={userIsCorporate}
                userMgtLvlId={userMgtLvlId}
              />
            </Route>
            <Route exact path='/district/overview'>
              <DstOverview
                timeframe={timeframe}
                settimeframe={settimeframe}
                metric={metric}
                setMetric={setMetric}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
              />
            </Route>
            <Route exact path='/core/overview'>
              <CoreOverviewPage
                metric={metric}
                setMetric={setMetric}
                fpToggle={fpToggle}
                settimeframe={settimeframe}
                timeframe={timeframe}
                userEmplCtgryNm={userEmplCtgryNm}
                unit={unit}
                setUnit={setUnit}
              />
            </Route>
            <Route exact path='/core/sales/dept'>
              <CoreSalesDepartmentProvider fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />
            </Route>
            <Route exact path='/core/safety'>
              <CoreSafety fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />
            </Route>
            <Route exact path='/core/sales/subclass'>
              <SubClassToSku fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />
            </Route>
            <ProtectedRoute exact path='/:level/overview' validParams={{ level: ['region', 'division'] }}>
              <OverviewContextProvider
                fpToggle={fpToggle}
                timeframe={timeframe}
                settimeframe={settimeframe}
                userEmplCtgryNm={userEmplCtgryNm}
                unit={unit}
                setUnit={setUnit}
                levelName={levelName?.toLowerCase()}
                metric={metric}
                setMetric={setMetric}
              />
            </ProtectedRoute>
            {/* Order Fulfillment */}
            <Route exact path='/store/orderfulfillment'>
              <OrderFulfillment timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/neworderfulfillment'>
              <StoreOrderFulfillmentPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/orderfulfillment'>
              <DstOrderFulfillment timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute
              exact
              path='/:level/neworderfulfillment'
              validParams={{ level: ['district', 'region', 'division', 'core'] }}>
              {/* Add more levels as soons as we need */}
              <OrderFulfillmentPage
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                level={level}
                unit={unit}
                setUnit={setUnit}
              />
            </ProtectedRoute>
            {/* Intraday */}
            <Route exact path='/core/intraday'>
              <HourlySales fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute
              exact
              path='/:level/intraday'
              validParams={{ level: ['store', 'district', 'region', 'division'] }}>
              <IntradayContextProvider fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/district/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/region/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/division/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/core/intraday/dept'>
              <IntradayDeptViewCore fpToggle={fpToggle} />
            </Route>
            {/* Daily */}
            <Route exact path='/core/daily'>
              <DailySalesOverview fpToggle={fpToggle} />
            </Route>
            <Route exact path='/:level/daily'>
              <DailyView fpToggle={fpToggle} levelName={levelName?.toLowerCase()} />
            </Route>
            <Route exact path='/store/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/district/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/region/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/core/daily/dept'>
              <DailySalesDepartment fpToggle={fpToggle} />
            </Route>
            <Route exact path='/division/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            {/* Credit */}
            <ProtectedRoute
              exact
              path={`/:level/credit`}
              validParams={{ level: ['region', 'division', 'core', 'store', 'district'] }}>
              <CreditContextProvider
                unit={unit}
                level={level}
                setUnit={setUnit}
                fpToggle={fpToggle}
                timeframe={timeframe}
                settimeframe={settimeframe}
                userEmplCtgryNm={userEmplCtgryNm}>
                <CreditPage />
              </CreditContextProvider>
            </ProtectedRoute>
            {/* Overview Metric Details */}
            <Route exact path='/store/safety'>
              <SafetyDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                userMgtLvlId={userMgtLvlId}
                userIsCorporate={userIsCorporate}
                fpToggle={fpToggle}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/:level/safety'>
              <SafetyDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                userMgtLvlId={userMgtLvlId}
                userIsCorporate={userIsCorporate}
                fpToggle={fpToggle}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/store/smd'>
              <SmdDetailStr level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/smd'>
              <SmdDetailDst level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/smd'>
              <SmdDetailRgn level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/division/smd'>
              <SmdDetailDiv level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/smd'>
              <SmdDetailPage fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />
            </Route>
            <Route exact path='/store/smd/reason'>
              <SmdStrRsn fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/smd/dept/reason'>
              <SmdStrDeptRsn fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/smd/reason'>
              <SmdDstRsn timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/smd/reason'>
              <SmdReasonCodesPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/:level/smd/reason'>
              <SmdRgnRsn timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/smd/dept'>
              <SmdStrDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/smd/dept'>
              <SmdDstDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/smd/dept'>
              <SmdDepartmentPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path='/:level/smd/dept' validParams={{ level: ['region', 'division'] }}>
              <SmdRgnDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/labor'>
              <LaborDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                userMgtLvlId={userMgtLvlId}
                jobProfileNm={jobProfileNm}
                userIsCorporate={userIsCorporate}
              />
            </Route>
            <Route exact path='/store/laborDetailAssoc'>
              <LaborDetailAssoc
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userMgtLvlId={userMgtLvlId}
                userIsCorporate={userIsCorporate}
              />
            </Route>
            <Route exact path='/district/labor'>
              <LaborDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/district/laborDetail'>
              <LaborDetailDeptByDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/laborDetailManager'>
              <LaborDetailMgrDeptByDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/labor'>
              <LaborPage
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                level={level}
              />
            </Route>
            <Route exact path='/:level/labor'>
              <LaborContextProvider
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/core/laborDetail'>
              <LaborDetailDeptDrill timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/:level/laborDetail'>
              <LaborDetailDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/core/laborDetailManager'>
              <LaborDetailDeptMgr timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/:level/laborDetailManager'>
              <LaborDetailDeptManager timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/store/ltsa'>
              <LtsaDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/ltsa'>
              <LtsaDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/core/ltsa'>
              <LtsaPage fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />
            </Route>
            <ProtectedRoute exact path='/:level/ltsa' validParams={{ level: ['region', 'division'] }}>
              <RegionVocSummary
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
                timeframe={timeframe}
                settimeframe={settimeframe}
              />
            </ProtectedRoute>
            <Route exact path='/store/leads'>
              <LeadsDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/leads'>
              <CoreLeads timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path='/:level/leads' validParams={{ level: ['district', 'region', 'division'] }}>
              <LeadsDetailDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/leads/assoc'>
              <LeadsAssocView timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/meas'>
              <MeasDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/meas'>
              <MeasDetailDst
                timeframe={timeframe}
                level={level}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/region/meas'>
              <MeasDetailDst
                timeframe={timeframe}
                level={level}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/division/meas'>
              <MeasDetailDst
                timeframe={timeframe}
                level={level}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/core/meas'>
              <MeasurePage fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />
            </Route>
            <Route exact path='/store/meas/assoc'>
              <MeasAssocView timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/voc'>
              <VocDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <ProtectedRoute exact path='/core/voc'>
              <LtpaOverviewPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <ProtectedRoute exact path='/:level/voc' validParams={{ level: ['district', 'region', 'division'] }}>
              <VocDetailDst timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </ProtectedRoute>
            {/* Sales Drilldowns by Prod Hier - NOTE: unreachable, to be retired, replaced by ./sales/. endpoints */}
            <Route exact path='/store/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/dept/class/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/dept/class/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/sales/class'>
              <CoreDrillDown fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />
            </Route>
            {/* Refined sales drilldown routes */}
            <ProtectedRoute
              exact
              path='/:level/sales/dept'
              validParams={{ level: ['store', 'district', 'region', 'division'] }}>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path='/:level/sales/class'
              validParams={{ level: ['store', 'district', 'region', 'division'] }}>
              <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/:level/sales/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            {/* Aisle-Bay drilldowns */}
            <Route exact path='/store/aisle'>
              <AisleDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/aisle/bay'>
              <BayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/deptLoc'>
              <DeptLocDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/deptLoc/aisle'>
              <DeptLocAisleDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/deptLoc/aisle/bay'>
              <DeptLocBayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/baysbystore'>
              <BaysByStore timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/baysbystore/bay'>
              <BaysByStoreBayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Inventory Drilldowns */}
            <Route exact path='/store/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/inventory/dept/class/subclass'>
              <InvSubclassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/inventory/dept/class/subclass'>
              <InvSubclassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/inventory/dept/class/subclass'>
              <InvSubclassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/division/inventory/dept/class/subclass'>
              <InvSubclassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/division/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/division/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/inventory/dept/class/subclass'>
              <CoreInventorySubClass fpToggle={fpToggle} />
            </Route>
            <Route exact path={`/core/inventory/dept/class`}>
              <CoreInventoryClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/inventory/dept'>
              <CoreInventoryDrill fpToggle={fpToggle} />
            </Route>
            {/* Shrink */}
            <Route exact path='/store/shrink'>
              <Shrink timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/reducedtag`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <CoreReducedTagPage />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/invalidscans`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <InvalidScansCore />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/rtvcredit`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <RTVCreditCore />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/agedwc`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <AgedWillCallsCore />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/rtvdeletedtag`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <RTVDeletingCore />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/cullmd`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkCullMarkdownsCore />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/clearancenh`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ClearanceNoHomesCorePage />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/damagedmd`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <DamagedMarkdownsCorePage />
              </ShrinkCoreProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/autokr`}>
              <CoreShrinkACCRCVProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <AccuracyAndReceivingMetric />
              </CoreShrinkACCRCVProvider>
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/unmatchedxfers`}>
              <CoreShrinkACCRCVProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <AccuracyAndReceivingMetric />
              </CoreShrinkACCRCVProvider>
            </Route>
            <ProtectedRoute exact path={`/${baseCoreShrinkUrl}`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkPageCore />
              </ShrinkCoreProvider>
            </ProtectedRoute>
            <Route exact path={`/${baseCoreShrinkUrl}/ohemptpkg`}>
              <OnHandAdjustmentCore timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute
              exact
              path={`/${baseLevelShrinkUrl}`}
              validParams={{ level: ['district', 'region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/store/shrink/invalidscans'>
              <ShrinkInvalidScans timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/invalidscans'>
              <DstShrinkInvalidScans timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute
              exact
              path={`/${baseLevelShrinkUrl}/invalidscans`}
              validParams={{ level: ['region', 'division'] }}>
              <InvalidScansPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/shrink/agedwc'>
              <ShrinkAgedWc fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/dept'>
              <ShrinkStoreDept fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/class'>
              <ShrinkStoreClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/subclass'>
              <ShrinkStoreSubClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/dept'>
              <DstShrinkDept fpToggle={fpToggle} />
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/dept`}>
              <CoreShrinkDeptPage fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/dept`} validParams={{ level: ['region', 'division'] }}>
              <ShrinkDeptPage fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/district/shrink/class'>
              <DstShrinkClass fpToggle={fpToggle} />
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/class`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <CoreShrinkClassPage />
              </ShrinkCoreProvider>
            </Route>
            ;{/* TODO: FakeRoute Remove 'new-' */}
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/class`} validParams={{ level: ['region', 'division'] }}>
              <ShrinkProvider fpToggle={fpToggle}>
                <ShrinkClassPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/district/shrink/subclass'>
              <DstShrinkSubClass fpToggle={fpToggle} />
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/subclass`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <CoreShrinkSubClassPage />
              </ShrinkCoreProvider>
            </Route>
            {/* TODO: FakeRoute Remove 'new-' */}
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/subclass`} validParams={{ level: ['region', 'division'] }}>
              <ShrinkProvider fpToggle={fpToggle}>
                <ShrinkSubclassPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/district/shrink/agedwc'>
              <DstShrinkAgedWc timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/agedwc`} validParams={{ level: ['region', 'division'] }}>
              <AgedWithCallsPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/shrink/clearancenh'>
              <ShrinkClearanceNh fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/clearancenh'>
              <DstShrinkClearanceNh timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute
              exact
              path={`/${baseLevelShrinkUrl}/clearancenh`}
              validParams={{ level: ['region', 'division'] }}>
              <ClearanceNoHomesPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/shrink/reducedtag'>
              <ShrinkReducedTag fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/reducedtag'>
              <DstShrinkReducedTag timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/reducedtag`} validParams={{ level: ['region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkReducedTagPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/store/shrink/cullmd'>
              <ShrinkCullMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/cullmd'>
              <DstShrinkCullMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/cullmd`} validParams={{ level: ['region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkCullMarkdownsPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/store/shrink/damagedmd'>
              <ShrinkDamagedMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/damagedmd'>
              <DstShrinkDamagedMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute path={`/${baseLevelShrinkUrl}/damagedmd`} validParams={{ level: ['region', 'division'] }}>
              <DamagedMarkdownsPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/shrink/rtvdeletedtag'>
              <ShrinkRtvDeletedTag timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/rtvdeletedtag'>
              <DstShrinkRtvDeletedTag timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute
              exact
              path={`/${baseLevelShrinkUrl}/rtvdeletedtag`}
              validParams={{ level: ['region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkRtvDeletedTagsPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/store/shrink/bosscancel'>
              <ShrinkBossCancel timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/bosscancel'>
              <DstShrinkBossCancel timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path={`/${baseCoreShrinkUrl}/bosscancel`}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <CoreShrinkBossCancelPage />
              </ShrinkProvider>
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/bosscancel`} validParams={{ level: ['region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkBossCancelRgnAndDiv />
              </ShrinkProvider>
            </ProtectedRoute>
            <Route exact path='/store/shrink/ohemptpkg'>
              <ShrinkOhEmptPkg timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/ohemptpkg/dept'>
              <ShrinkOhEmptPkgDept timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/ohemptpkg'>
              <DstShrinkOhEmptPkg timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/ohemptpkg`} validParams={{ level: ['region', 'division'] }}>
              <OnHandAdjustmentEPPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            <Route exact path='/store/shrink/rtvcredit'>
              <ShrinkRtvCredit timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/rtvcredit'>
              <DstShrinkRtvCredit timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/rtvcredit`} validParams={{ level: ['region', 'division'] }}>
              <RTVCreditsPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </ProtectedRoute>
            {/* Shrink Accuracy & Receiving */}
            <Route exact path={`/${baseCoreShrinkUrl}/ohautorej`}>
              <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <OhAutoRejectCorePage />
              </ShrinkCoreProvider>
            </Route>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/ohautorej`} validParams={{ level: ['district', 'region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <OhAutoRejectPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <ProtectedRoute exact path={`/${baseLevelShrinkUrl}/autokr`} validParams={{ level: ['district', 'region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <AccuracyAndReceivingMetricPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={`/${baseLevelShrinkUrl}/unmatchedxfers`}
              validParams={{ level: ['district', 'region', 'division'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <AccuracyAndReceivingMetricPage />
              </ShrinkProvider>
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={`/:level/${SPEC_PRO_RENTAL_PATH}`}
              validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
              <SpecialtyProRentalProvider timeframe={timeframe} setTimeframe={settimeframe} fpToggle={fpToggle}>
                <SpecialtyProRentalPage />
              </SpecialtyProRentalProvider>
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={`/:level/services`}
              validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
              <SpecialtyProRentalProvider timeframe={timeframe} setTimeframe={settimeframe} fpToggle={fpToggle}>
                <SpecialtyProRentalServicesPage />
              </SpecialtyProRentalProvider>
            </ProtectedRoute>
            <Route exact path='/district/services/program'>
              <ServicesDtlDstPrgm timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/services/program'>
              <CoreServicesPrgmPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <ProtectedRoute exact path='/:level/services/program' validParams={{ level: ['region', 'division'] }}>
              <SpecialtyProRentalServicesProvider timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle}>
                <SpecialtyProRentalServicesPrgmPage />
              </SpecialtyProRentalServicesProvider>
            </ProtectedRoute>
            {/* Events */}
            <Route exact path='/store/events'>
              <Events level='store' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/events'>
              <Events level='district' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/events'>
              <Events level='region' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/division/events'>
              <Events level='division' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/events'>
              <EventsPage fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/events/detail'>
              <CoreEventsDetailPage fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/events/detail'>
              <EventsDetailStr fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/events/detail'>
              <EventsDetailDst fpToggle={fpToggle} />
            </Route>
            <Route exact path='/:level/events/detail'>
              <EventsDetailRgn fpToggle={fpToggle} level={level} />
            </Route>
            {/* Headlines Insights */}
            <Route exact path='/store/headlinesinsights'>
              <HeadlinesInsights level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/headlinesinsights'>
              <HeadlinesInsights level={level} fpToggle={fpToggle} />
            </Route>
            {/* OSA */}
            <Route exact path='/core/osa'>
              <OsaOverviewPage
                fpToggle={fpToggle}
                timeframe={timeframe}
                settimeframe={settimeframe}
                userMgtLvlId={userMgtLvlId}
                jobProfileNm={jobProfileNm}
                userIsCorporate={userIsCorporate}
              />
            </Route>
            <Route exact path='/osa'>
              <OSALanding
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
                userMgtLvlId={userMgtLvlId}
                jobProfileNm={jobProfileNm}
                userIsCorporate={userIsCorporate}
              />
            </Route>
            <Route exact path='/osa/store/imaging'>
              <OSAImaging
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/core/osa/imaging'>
              <OsaImagingPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/core/osa/Purge'>
              <OSAPurge fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />
            </Route>
            <ProtectedRoute exact path='/osa/:level/imaging' validParams={{ level: ['district', 'region', 'division'] }}>
              <OSAImagingDist
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </ProtectedRoute>
            <Route exact path='/osa/imaging/dept'>
              <OSAImagingDept
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/imaging/dept/aisle'>
              <OSAImagingDeptAisle
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc'>
              <OHMNoLocationTags
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/district'>
              <OHMNoLocationTagsDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/region'>
              <OHMNoLocationTagsLevel
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/division'>
              <OHMNoLocationTagsLevel
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
                levelName={levelName?.toLowerCase()}
              />
            </Route>
            <Route exact path='/core/osa/ohmnoloc'>
              <OsaOhmNoLocTagsPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/osa/ohmnoloc/dept'>
              <OHMNoLocationTagsByDept
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/tag'>
              <OHMNoLocationTagsByDeptTag
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/store'>
              <SideKickPurge
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptPurge'>
              <SideKickPurgeDeptPurge
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptPurgeByBay'>
              <SideKickPurgeDeptPurgeByBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptBay'>
              <SideKickPurgeDeptBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptBayByBay'>
              <SideKickPurgeDeptBayByBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/:level'>
              <SideKickPurgePage
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/store'>
              <SideKickSkuBayStr
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/dept'>
              <SidekickSkuBayDept
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/logged'>
              <SidekickSkuBayLoggedWork
                level={level}
                timeframe={timeframe}
                locNbr={storeNumber}
                fpToggle={fpToggle}
                settimeframe={settimeframe}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/:level'>
              <SideKickSkuBay timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} locNbr={storeNumber} />
            </Route>
            <Route exact path='/core/osa/sidekickskubay'>
              <SidekickskubayCore fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />
            </Route>
            <Route exact path='/osa/sidekickskubay/dept/baypkdn'>
              <SidekickSkuBayBayDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/manageraddedbays'>
              <ManagerAddedBays
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/manageraddedbays/baydetails'>
              <ManagerAddedBaysDetails
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route path='*'>
              <NotFoundPage />
            </Route>
          </Switch>

          <ReactQueryDevtools initialIsOpen={false} />
          <ViewChangeLog fpToggle={fpToggle} />
          {fpToggle === 'storeops' && adminQuery.isSuccess && (
            <>
              <EditAlert />
              <NewChangeLog />
              <PageTables />
            </>
          )}
          {/* <Scanner /> */}
          {isLegacyAlertOpen && <LegacyPageNotice isSticky={isDesktop} isBottom />}
        </Container>
      );
    case 'loggedOut':
      return <LoginMenu />;
    default:
      // return <LoginMenu />
      return <Loader active>Loading</Loader>;
  }
});

export default App;

import { buildBaseDrillLink } from '../../../../../components/ShrinkContainer/utils/url';
import { getPercentString } from '../../../../../components/Common/TableCellUtils';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../../../utils/level';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { getRowLevelNm } from '../../../../../utils/getRowLevelNm';

export const clearanceNoHomesCoreData = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [
    { name: capitalizeFirstLetter(METRIC_CONSTANTS['core']['urlLinkLevel']) + ' # ' },
    { name: 'Value ' },
    { name: 'Goal ' },
    { name: '% of Stores Passing ' }
  ],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink('core');
    return [
      {
        stringValue: getRowLevelNm({row, level}),
        link: {
          to: `/${baseLevelShrinkUrl}/clearancenh`,
          params: {
            [METRIC_CONSTANTS['core']['urlSubLevelParam']]: formatLevelNumber(row[METRIC_CONSTANTS['core']['propToRender']])
          }
        }
      },
      {
        stringValue: getPercentString(row[timeframe + '_CLRNH_NUM'] / row[timeframe + '_CLRNH_DEN'], 2),
        orderBy: (row[timeframe + '_CLRNH_NUM'] / row[timeframe + '_CLRNH_DEN'])
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score =>
              score && score[METRIC_CONSTANTS['core']['propToRender']] === row[METRIC_CONSTANTS['core']['propToRender']]
          )[0]['CLRNH_GOAL'],
          2
        ),
        prefix: '≤ ',
        orderBy: shrinkScoreData.filter(
          score =>
            score && score[METRIC_CONSTANTS['core']['propToRender']] === row[METRIC_CONSTANTS['core']['propToRender']]
        )[0]['CLRNH_GOAL']
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score =>
              score && score[METRIC_CONSTANTS['core']['propToRender']] === row[METRIC_CONSTANTS['core']['propToRender']]
          )[0]['CLEAR_NH_SCORE']
        ),
        orderBy: shrinkScoreData.filter(
          score =>
            score && score[METRIC_CONSTANTS['core']['propToRender']] === row[METRIC_CONSTANTS['core']['propToRender']]
        )[0]['CLEAR_NH_SCORE']
      }
    ];
  })
});

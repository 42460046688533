import { aggregateObjectKeys } from "../../../utils/array";
import { formatNumberWithCommas } from "../../../utils/formatter";
export const summaryData = (sumData, timeframe, isNOM) => {
  const delivery = sumData.filter(data => {
    return data['METHOD'] === 'DELIVERY';
  });
  const pickup = sumData.filter(data => {
    return data['METHOD'] === 'PICKUP';
  });

  const deliveryData = aggregateObjectKeys(delivery);
  const pickupData = aggregateObjectKeys(pickup);

  return {
    headers: [
      { name: 'Method' },
      { name: 'Pick On Time %' },
      { name: 'Fulfillment %' },
      { name: isNOM ? 'Orders Fulfilled TY' : 'Sales TY $' },
      { name: isNOM ? 'Orders Fulfilled LY' : 'Sales LY $' }
    ],
    data: [
      [
        { stringValue: 'Pickup' },
        {
          pctValue: pickupData[`ON_TIME_` + timeframe] / sumData.length
        },
        { pctValue: pickupData['FULFILLMENT_' + timeframe] / sumData.length },
        {
          numValue: isNOM ? formatNumberWithCommas(pickupData[`${timeframe}_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? pickupData[`${timeframe}_FULFILL_SALES`] : undefined
        },
        {
          numValue: isNOM ? formatNumberWithCommas(pickupData[`${timeframe}_LY_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? pickupData[`${timeframe}_LY_FULFILL_SALES`] : undefined
        }
      ],
      [
        { stringValue: 'Delivery' },
        { pctValue: deliveryData['ON_TIME_' + timeframe] / sumData.length },
        { pctValue: deliveryData['FULFILLMENT_' + timeframe] / sumData.length },
        {
          numValue: isNOM ? formatNumberWithCommas(deliveryData[`${timeframe}_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? deliveryData[`${timeframe}_FULFILL_SALES`] : undefined
        },
        {
          numValue: isNOM ? formatNumberWithCommas(deliveryData[`${timeframe}_LY_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? deliveryData[`${timeframe}_LY_FULFILL_SALES`] : undefined
        }
      ],
      [
        { stringValue: 'Total' },
        {
          pctValue:
            (pickupData['ON_TIME_' + timeframe] / sumData.length +
              deliveryData['ON_TIME_' + timeframe] / sumData.length) /
            2
        },
        {
          pctValue:
            (pickupData['FULFILLMENT_' + timeframe] / sumData.length +
              deliveryData['FULFILLMENT_' + timeframe] / sumData.length) /
            2
        },
        {
          numValue: isNOM
            ? formatNumberWithCommas(pickupData[`${timeframe}_ORDERS_FULFILLED`] + deliveryData[`${timeframe}_ORDERS_FULFILLED`])
            : undefined,
          dollarValue: !isNOM
            ? pickupData[`${timeframe}_FULFILL_SALES`] + deliveryData[`${timeframe}_FULFILL_SALES`]
            : undefined
        },
        {
          numValue: isNOM
            ? formatNumberWithCommas(pickupData[`${timeframe}_LY_ORDERS_FULFILLED`] +
              deliveryData[`${timeframe}_LY_ORDERS_FULFILLED`])
            : undefined,
          dollarValue: !isNOM
            ? pickupData[`${timeframe}_LY_FULFILL_SALES`] + deliveryData[`${timeframe}_LY_FULFILL_SALES`]
            : undefined
        }
      ]
    ]
  };
};

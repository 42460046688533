import { Tab } from "semantic-ui-react";
import { DeliveryPush } from "./delivery/DeliveryPush";
import { TruckChanges } from "./delivery/TruckChanges";
import { DeliveryPull } from "./delivery/DeliveryPull";
import { Fulfillment } from "./delivery/Fulfillment";
import { DataView } from "../../../../../../components/Common/DataView";
import { useQueryKeys } from "../../../../../../components/ShrinkContainer/hooks/useQueryKeys";
import { useContext } from "react";
import { StoreOrderFulfillmentContext } from "../../../../context/StoreOrderFulfillmentContext";

export const DeliveryTabs = () => {
  const {strNbr} = useContext(StoreOrderFulfillmentContext);
  const [deliveryData] = useQueryKeys([`query/queryStoreDeliveryComplete/store/${strNbr}`]);

  const tabs = [
    {
      menuItem: 'Fulfillment',
      render: () => <Fulfillment />
    },
    {
      menuItem: 'Delivery Push',
      render: () => <DeliveryPush deliveryData={deliveryData}/>
    },
    {
      menuItem: 'Delivery Pull',
      render: () => <DeliveryPull deliveryData={deliveryData}/>
    },
    {
      menuItem: 'Truck Changes',
      render: () => <TruckChanges deliveryData={deliveryData}/>
    },
  ]

  return (
    <DataView className='my-4'>
      <DataView.Title className='mb-3'>Delivery Complete</DataView.Title>
      <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />
    </DataView>
  );
}
import { useMemo, useState } from "react"
import { Grid, Tab } from "semantic-ui-react"
import { useOverviewContext } from "../../../hooks/useOverviewContext"
import { useEnhancedQueries } from "../../../hooks/useEnhancedQueries"
import { getCalloutStyle } from "../../Common/TableCellUtils"
import { timeframeCode } from "../../Common/timeframeCode"
import { MetricDropDownMenu } from "../../Menus/MetricDropDownMenu"
import { DataView } from "../../Common/DataView"
import { PlaceholderLoading } from "../../Loading/PlaceholderLoading"

const headerByMetric = {
  SLS: [
    { name: 'Sales Amt ($)' },
    { name: 'Sales vLY ($)' },
    { name: 'Sales vLY (%)' }
  ],
  UNT: [
    { name: 'Unit Sales #' },
    { name: 'Units vLY #' },
    { name: 'Units vLY %' }
  ],
  TXN: [
    { name: 'Trans Cnt #' },
    { name: 'Trans vLY #' },
    { name: 'Trans vLY %' }
  ],
  AVG_TCKT: [
    { name: 'Avg Ticket ($)' },
    { name: 'Avg Ticket vLY ($)' },
    { name: 'Avg Ticket vLY (%)' }
  ]
}

const propsByMetric = {
  SLS: ['TY_SLS_AMT', 'SLS_COMP_NOM', 'SLS_COMP_PCT'],
  UNT: ['TY_UNT_SLS', 'UNT_COMP_NOM', 'UNT_COMP_PCT'],
  TXN: ['TY_TXN_CNT', 'TXN_COMP_NOM', 'TXN_COMP_PCT'],
  AVG_TCKT: ['TY_AVG_TCKT', 'AVG_TCKT_COMP_NOM', 'AVG_TCKT_COMP_PCT']
}

const optionsMetrics = [{ key: 'SLS', text: 'Sales', value: 'SLS' },
{ key: 'UNT', text: 'Units', value: 'UNT' },
{ key: 'TXN', text: 'Trans', value: 'TXN' },
{ key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' },
]

export const SalesSubClassesTab = () => {
  const { level, queryParam, rgnNbr, timeframe, fpToggle } = useOverviewContext()
  const { isError, isLoading, results: [{ data: slsSclsData = [] }] } = useEnhancedQueries(['querySalesSubclases'], fpToggle);
  const [metric, setMetric] = useState('SLS')
  const [dept, setDept] = useState('All')

  const dataFiltered = useMemo(() => {
    return slsSclsData.filter((row) => {
      return row && row['MER_SUB_CLASS_NM'] !== null && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
        && (dept === 'All' || row['DEPT_NBR'].toString() === dept)
    })
  }, [dept, slsSclsData, timeframe])

  if (isError) return <h1>Something went wrong</h1>
  if (isLoading) return <PlaceholderLoading />

  const tableHeaders = headerByMetric[metric]
  const dataObj = {
    sortable: true,
    sortableDefault: metric === 'SLS' ? 1 : undefined,
    sortDirection: metric === 'SLS' ? 'ascending' : undefined,
    pagination: true,
    headers: [
      { name: 'Subclass' },
      ...tableHeaders
    ],
    data: dataFiltered.map(row => ([
      {
        link: {
          to: '/region/sales/subclass',
          params: {
            rgnNbr,
            subClassNbr: row['EXT_SUB_CLASS_NBR']
          }
        },
        stringValue: row['MER_SUB_CLASS_NM'].replaceAll('/', '/\u200b'),
      },
      {
        dollarValue: (metric === 'SLS' || metric === 'AVG_TCKT') ? row[propsByMetric[metric][0]] : undefined,
        numValue: (metric === 'TXN' || metric === 'UNT') ? (row[propsByMetric[metric][0]]).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : undefined,
        orderBy: row[propsByMetric[metric][0]],
        customStyle: getCalloutStyle(row[propsByMetric[metric][0]])
      },
      {
        dollarValue: (metric === 'SLS' || metric === 'AVG_TCKT') ? row[propsByMetric[metric][1]] : undefined,
        numValue: (metric === 'TXN' || metric === 'UNT') ? (row[propsByMetric[metric][1]]).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : undefined,
        orderBy: row[propsByMetric[metric][1]],
        compareStyle: true
      },
      {
        pctValue: row[propsByMetric[metric][2]],
        compareStyle: true
      }
    ]))
  }

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Row>
          <MetricDropDownMenu
            metric={metric}
            setMetric={setMetric}
            optionList={optionsMetrics}
          />
          <Grid.Column width={8} textAlign='justified'>
            <Grid.Row>
              Dept:
              <div>
                <select
                  name="dept"
                  defaultValue={dept}
                  onChange={e => {
                    setDept(e.target.value)
                  }}
                  style={{ padding: 10 }}
                >
                  <option value="All" selected>All</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="59">59</option>
                  <option value="78">78</option>
                </select>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <DataView>
        <DataView.Title className="mt-2" textAlign='center'>{level} {queryParam} Subclasses</DataView.Title>

        <DataView.DataTable data={dataObj} />
      </DataView>
    </Tab.Pane>
  )
}
import { useState } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { getCompareStyle, getDollarString, getPercentString } from '../../../../../components/Common/TableCellUtils';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../../../../constants/goals';
import { formatNumberWithCommas } from '../../../../../utils/formatter';
import { useCoreOverviewContext } from '../../../hooks/useCoreOverviewContext';
import { formatLevelNumber } from '../../../../../utils/level';
import { getLevelColumn } from '../utils/overview/subLevelList';

const OPTIONS = [
  { key: 'sales', text: 'Sales', value: 'sales' },
  { key: 'units', text: 'Units', value: 'units' },
  { key: 'transactions', text: 'Transactions', value: 'transactions' },
  { key: 'average_ticket', text: 'Average Ticket', value: 'average_ticket' },
  { key: 'units_per_basket', text: 'Units per Basket', value: 'units_per_basket' },
  { key: 'days_safe', text: 'Days Safe', value: 'days_safe' },
  { key: 'smd', text: 'SMD', value: 'smd' },
  { key: 'labor', text: 'Labor', value: 'labor' },
  { key: 'get', text: 'GET', value: 'get' },
  { key: 'ltsa', text: 'LTSA', value: 'ltsa' },
  { key: 'ltpa', text: 'LTPA', value: 'ltpa' },
  { key: 'leads', text: 'Leads', value: 'leads' },
  { key: 'measures', text: 'Measures', value: 'measures' },
  { key: 'HDPP - MA UA', text: 'HDPP - MA UA', value: 'HDPP - MA UA' },
  { key: 'HDPP - GM UA', text: 'HDPP - GM UA', value: 'HDPP - GM UA' }
];

const DAYS_SAFE_HEADERS = [{ name: 'Division' }, { name: 'Days Safe ' }];
const DAYS_SAFE_METRIC = 'days_safe';
const UNITS_PER_BASKET = 'units_per_basket';

const getHeadersList = (metrict, unit) => {
  if (metrict === DAYS_SAFE_METRIC) return DAYS_SAFE_HEADERS;

  return [
    { name: 'Division' },
    { name: `Actual ` },
    { name: `vP/vF  ${unit === 'PCT' ? '% ' : ''}` },
    { name: `vLY  ${unit === 'PCT' ? '% ' : ''}` }
  ];
};

export const DivisionList = () => {
  const [unit, setUnit] = useState('NOM');
  const {
    salesSafetyByDivisionData = [],
    salesMetricsData = [],
    settimeframe,
    timeframe,
    setMetric,
    metric,
    level
  } = useCoreOverviewContext();
  const isUnitNom = unit === 'NOM';

  const daySafeData = salesSafetyByDivisionData.map(row => [
    getLevelColumn({ row, level }),
    {
      numValue: row.DAYS_SAFE,
      orderBy: row.DAYS_SAFE
    }
  ]);

  const metricsData = salesMetricsData.map(row => {
    // Transactions
    if (metric === 'transactions') {
      const actual = row[timeframe + objects[metric][0]['dollar']];

      const vlyNom = row[timeframe + objects[metric][2]['dollar']];
      const vlyPct = row[timeframe + objects[metric][2]['pct']];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: formatNumberWithCommas(actual),
          orderBy: actual
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit !== 'PCT' ? formatNumberWithCommas(vlyNom) : getPercentString(vlyPct),
          orderBy: unit !== 'PCT' ? vlyNom : vlyPct,
          customStyle: getCompareStyle(row[timeframe + objects[metric][2][isUnitNom ? 'dollar' : 'pct']])
        }
      ];
    }
    //Average Ticket
    if (metric === 'average_ticket') {
      const actual = row[timeframe + objects[metric][0]['dollar']];

      const vlyNom = row[timeframe + objects[metric][2]['dollar']];
      const vlyPct = row[timeframe + objects[metric][2]['pct']];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getDollarString(actual, 2),
          orderBy: actual
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit !== 'PCT' ? getDollarString(vlyNom, 2) : getPercentString(vlyPct, 2),
          orderBy: unit !== 'PCT' ? vlyNom : vlyPct,
          customStyle: getCompareStyle(row[timeframe + objects[metric][2][isUnitNom ? 'dollar' : 'pct']])
        }
      ];
    }
    // Units per Basket
    if (metric === UNITS_PER_BASKET) {
      const unitsPerBasketByNOM =
        row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
        row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT'];
      const unitsPerBasketByPCT =
        (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
          row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']) /
        (row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']);
      const totalUnitsPerBasket = row['TY_' + timeframe + '_UNT_SLS'] / row['TY_' + timeframe + '_TXN_CNT'];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: formatNumberWithCommas(totalUnitsPerBasket, 2),
          orderBy: totalUnitsPerBasket
        },
        {
          stringValue: '-'
        },
        {
          stringValue:
            unit !== 'PCT' ? formatNumberWithCommas(unitsPerBasketByNOM, 2) : getPercentString(unitsPerBasketByPCT, 2),
          customStyle: getCompareStyle(unitsPerBasketByNOM),
          orderBy: unitsPerBasketByNOM || unitsPerBasketByPCT
        }
      ];
    }
    // Days Safe
    if (metric === DAYS_SAFE_METRIC) {
      return daySafeData; //duplicate to prevent crash
    }
    // SMD
    if (metric === 'smd') {
      const actual = row[timeframe + '_SMD_ACT'];

      const vpvfNom = row[timeframe + '_SMD_VP'];

      const vlyNom = row[timeframe + '_SMD_VLY'];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getPercentString(actual, 2),
          orderBy: actual
        },
        {
          stringValue: unit !== 'PCT' ? vpvfNom.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' : '-',
          orderBy: vpvfNom,
          customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VP'])
        },
        {
          stringValue: unit !== 'PCT' ? vlyNom.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' : '-',
          orderBy: vlyNom,
          customStyle: getCompareStyle(-1 * vlyNom)
        }
      ];
    }
    // Labor
    if (metric === 'labor') {
      const actual = row[timeframe + '_LBR_ACT'];

      const vpvfNom = row[timeframe + objects[metric][1]['dollar']];
      const vpvfPct = row[timeframe + objects[metric][1]['pct']];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: formatNumberWithCommas(actual) + ' hrs',
          orderBy: actual
        },
        {
          stringValue: unit === 'NOM' ? formatNumberWithCommas(vpvfNom) + ' hrs' : getPercentString(vpvfPct),
          orderBy: unit === 'NOM' ? vpvfNom : vpvfPct,
          customStyle: unit === 'PCT' ? getCompareStyle(vpvfPct) : undefined
        },
        {
          stringValue: '-'
        }
      ];
    }
    // Get
    if (metric === 'get') {
      const actual = row[timeframe + objects[metric][0]['dollar']];

      const vlyNom = row[timeframe + objects[metric][2]['dollar']];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getPercentString(actual, 2),
          orderBy: actual,
          customStyle: {
            color: actual >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit === 'NOM' ? getPercentString(vlyNom, 2) : '-',
          orderBy: unit === 'NOM' ? vlyNom : '-',
          customStyle: {
            color: vlyNom >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        }
      ];
    }
    // LTSA
    if (metric === 'ltsa') {
      const actual = row[timeframe + objects[metric][0]['dollar']];

      const vlyNom = row[timeframe + objects[metric][2]['dollar']];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getPercentString(actual, 2),
          orderBy: actual,
          customStyle: {
            color: actual >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit === 'NOM' ? getPercentString(vlyNom, 2) : '-',
          orderBy: unit === 'NOM' ? vlyNom : '-',
          customStyle: {
            color: actual >= VOC_LTSA_GOAL ? 'green' : 'red'
          }
        }
      ];
    }
    // LTPA
    if (metric === 'ltpa') {
      const actual = row[timeframe + '_ONLINE_SAT_ACT'];

      const vlyPct = row[timeframe + '_ONLINE_SAT_VLY'];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getPercentString(actual),
          orderBy: actual,
          customStyle: {
            color: actual > VOC_LTPA_GOAL ? 'green' : actual < VOC_LTPA_GOAL ? 'red' : 'black'
          }
        },
        {
          stringValue: '-'
        },
        {
          stringValue: unit !== 'NOM' ? getPercentString(vlyPct, 2) : '-',
          orderBy: unit !== 'NOM' ? vlyPct : '-',
          customStyle: getCompareStyle(vlyPct)
        }
      ];
    }
    // HDPP - MA UA
    if (metric === 'HDPP - MA UA') {
      const actual = row[timeframe + '_MA_HDPP'];

      const vpvf = row[timeframe + '_MA_HDPP_VG'];

      const vly = row[timeframe + '_MA_HDPP_VLY'];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getPercentString(actual),
          orderBy: actual
        },
        {
          stringValue: getPercentString(vpvf),
          orderBy: vpvf,
          customStyle: getCompareStyle(vpvf)
        },
        {
          stringValue: getPercentString(vly),
          orderBy: vly,
          customStyle: getCompareStyle(vly)
        }
      ];
    }
    // HDPP - GM UA
    if (metric === 'HDPP - GM UA') {
      const actual = row[timeframe + '_GM_HDPP'];

      const vpvf = row[timeframe + '_GM_HDPP_VG'];

      const vly = row[timeframe + '_GM_HDPP_VLY'];

      return [
        getLevelColumn({ row, level }),
        {
          stringValue: getPercentString(actual),
          orderBy: actual
        },
        {
          stringValue: getPercentString(vpvf),
          orderBy: vpvf,
          customStyle: getCompareStyle(vpvf)
        },
        {
          stringValue: getPercentString(vly),
          orderBy: vly,
          customStyle: getCompareStyle(vly)
        }
      ];
    }
    //  | Leads | Measures
    const actualValue = row[timeframe + objects[metric][0]?.dollar];
    const varianceValue = row[timeframe + objects[metric][1][isUnitNom ? 'dollar' : 'pct']] || '-';
    const versusLastYearValue = row[timeframe + objects[metric][2][isUnitNom ? 'dollar' : 'pct']] || '-';

    if (metric === 'leads' || metric === 'measures') {
      return [
        {
          link: {
            to: `/division/overview`,
            params: {
              showList: true,
              divNbr: formatLevelNumber(row.DIV_NBR)
            }
          },
          orderBy: row.DIV_NBR,
          stringValue: formatLevelNumber(row.DIV_NBR)
        },
        {
          stringValue: formatNumberWithCommas(actualValue),
          orderBy: actualValue
        },
        {
          customStyle: getCompareStyle(varianceValue),
          dollarValue: isUnitNom ? varianceValue : undefined,
          pctValue: !isUnitNom ? varianceValue : undefined,
          orderBy: varianceValue
        },
        {
          customStyle: getCompareStyle(versusLastYearValue),
          stringValue: isUnitNom ? formatNumberWithCommas(versusLastYearValue) : undefined,
          pctValue: !isUnitNom ? versusLastYearValue : undefined,
          orderBy: versusLastYearValue
        }
      ];
    }
    // Sales | Units

    return [
      getLevelColumn({ row, level }),
      {
        dollarValue: actualValue,
        orderBy: actualValue
      },
      {
        customStyle: getCompareStyle(varianceValue),
        dollarValue: isUnitNom ? varianceValue : undefined,
        pctValue: !isUnitNom ? varianceValue : undefined,
        orderBy: varianceValue
      },
      {
        customStyle: getCompareStyle(versusLastYearValue),
        dollarValue: isUnitNom ? versusLastYearValue : undefined,
        pctValue: !isUnitNom ? versusLastYearValue : undefined,
        orderBy: versusLastYearValue
      }
    ];
  });
  const dataAsOf = salesSafetyByDivisionData[0].TRNSFM_DT; // TODO: Replace with actual data

  const dataObj = {
    headers: getHeadersList(metric, unit),
    data: metric === DAYS_SAFE_METRIC ? daySafeData : metricsData,
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView>
      <DataView.Controls className='d-flex gap-3'>
        <UnitMenu unit={unit} setUnit={setUnit} />

        <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />

        <Dropdown
          compact
          defaultValue={metric}
          selection
          size='tiny'
          options={OPTIONS}
          onChange={(_, { value }) => setMetric(value)}
        />
      </DataView.Controls>

      <DataView.Title>
        Overview - Core, Sales By Division
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};

const objects = {
  sales: [
    { dollar: '_SLS_AMT_ACT' },
    { dollar: '_SLS_VP', pct: '_SLS_AMT_VP_PCT' },
    { dollar: '_SLS_AMT_VLY', pct: '_SLS_AMT_VLY_PCT' }
  ],
  units: [{ dollar: '_UNTS_ACT' }, { dollar: null, pct: null }, { dollar: '_UNTS_VLY', pct: '_UNTS_VLY_PCT' }],
  transactions: [{ dollar: '_TRNS_ACT' }, { dollar: null, pct: null }, { dollar: '_TRNS_VLY', pct: '_TRNS_VLY_PCT' }],
  average_ticket: [
    { dollar: '_AVG_TCKT_ACT' },
    { dollar: null, pct: null },
    { dollar: '_AVG_TCKT_VLY', pct: '_AVG_TCKT_VLY_PCT' }
  ],
  smd: [{ dollar: '_SMD_ACT' }, { dollar: '_SMD_VP', pct: null }, { dollar: '_SMD_VLY', pct: null }],
  labor: [{ dollar: '_LBR_ACT' }, { dollar: '_LBR_VF', pct: '_LBR_VF_PCT' }, { dollar: null, pct: null }],
  get: [{ dollar: '_GET_ACT' }, { dollar: null, pct: null }, { dollar: '_GET_VLY', pct: null }],
  ltsa: [{ dollar: '_LTSA_ACT' }, { dollar: null, pct: null }, { dollar: '_LTSA_VLY', pct: null }],
  ltpa: [{ dollar: '_ONLINE_SAT_ACT' }, { dollar: null, pct: null }, { dollar: '_ONLINE_SAT_VLY', pct: null }],
  leads: [{ dollar: '_LEADS_ACT' }, { dollar: null, pct: null }, { dollar: '_LEADS_VLY', pct: '_LEADS_VLY_PCT' }],
  measures: [{ dollar: '_MEAS_ACT' }, { dollar: null, pct: null }, { dollar: '_MEAS_VLY', pct: '_MEAS_VLY_PCT' }],
  units_per_basket: [{ dollar: '_MEAS_ACT' }, { dollar: null, pct: null }, { dollar: '_MEAS_VLY', pct: '_MEAS_VLY_PCT' }],
  'HDPP - MA UA': [
    { dollar: '_MA_HDPP' },
    { dollar: '_MA_HDPP_VG', pct: '_MA_HDPP_VG' },
    { dollar: '_MA_HDPP_VLY', pct: '_MA_HDPP_VLY' }
  ],
  'HDPP - GM UA': [
    { dollar: '_MA_HDPP' },
    { dollar: '_GM_HDPP_VG', pct: '_GM_HDPP_VG' },
    { dollar: '_GM_HDPP_VLY', pct: '_GM_HDPP_VLY' }
  ]
};

import { Icon } from 'semantic-ui-react';
import { VOC_GET_GOAL, VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../constants/goals';
import { getCompareIconStyle, getCompareStyle, getPercentString } from '../components/Common/TableCellUtils';

export const districtTabBuilder = ({ userEmplCtgryNm, daysSafeDstFilter, dstMetrics, timeframe, unit, dstNbr }) => {
  return {
    data: [
      [
        {
          stringValue: 'Days Safe',
          link: userEmplCtgryNm.toUpperCase().includes('SALAR')
            ? {
                to: `/district/safety?dstNbr=${dstNbr}`
              }
            : null
        },
        { stringValue: daysSafeDstFilter[0]?.DAYS_SAFE ?? '-' },
        { stringValue: '-' },
        { stringValue: '-' }
      ],
      [
        { stringValue: 'Sales' },
        { dollarValue: dstMetrics[timeframe + '_SLS_AMT_ACT'] },
        unit === 'NOM'
          ? { dollarValue: dstMetrics[timeframe + '_SLS_VP'], compareValue: true }
          : { pctValue: dstMetrics[timeframe + '_SLS_AMT_VP_PCT'], decimals: 2, compareValue: true },
        unit === 'NOM'
          ? { dollarValue: dstMetrics[timeframe + '_SLS_AMT_VLY'], compareValue: true }
          : { pctValue: dstMetrics[timeframe + '_SLS_AMT_VLY_PCT'], decimals: 2, compareValue: true }
      ],
      [
        { stringValue: 'Units' },
        { stringValue: dstMetrics[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              customStyle: { color: dstMetrics[timeframe + '_UNTS_VLY'] > 0 ? 'green' : 'red' },
              stringValue: dstMetrics[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
            }
          : { pctValue: dstMetrics[timeframe + '_UNTS_VLY_PCT'], decimals: 2, compareValue: true }
      ],
      [
        { stringValue: 'Transactions' },
        { stringValue: dstMetrics[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              customStyle: { color: dstMetrics[timeframe + '_TRNS_VLY'] > 0 ? 'green' : 'red' },
              stringValue: dstMetrics[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
            }
          : { pctValue: dstMetrics[timeframe + '_TRNS_VLY_PCT'], decimals: 2, compareValue: true }
      ],
      [
        { stringValue: 'Average Ticket' },
        { dollarValue: dstMetrics[timeframe + '_AVG_TCKT_ACT'], decimals: 2 },
        { stringValue: '-' },
        unit === 'NOM'
          ? { dollarValue: dstMetrics[timeframe + '_AVG_TCKT_VLY'], decimals: 2, compareValue: true }
          : { pctValue: dstMetrics[timeframe + '_AVG_TCKT_VLY_PCT'], decimals: 2, compareValue: true }
      ],
      [
        { stringValue: 'Units per Basket' },
        {
          stringValue: (
            dstMetrics['TY_' + timeframe + '_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_TXN_CNT']
          ).toLocaleString(undefined, { maximumFractionDigits: 2 })
        },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              compareValue: true,
              dollarValue: (
                dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
              ).toLocaleString(undefined, { maximumFractionDigits: 2 })
            }
          : {
              compareValue: true,
              pctValue:
                (dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                  dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) /
                (dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT'])
            }
      ],
      [
        {
          stringValue: 'On-Shelf Availability',
          link: {
            to: `/osa?dstNbr=${dstNbr}`
          }
        },
        { stringValue: '-' },
        { stringValue: '-' },
        { stringValue: '-' }
      ],
      [
        {
          stringValue: 'SMD',
          link: {
            to: `/district/smd?dstNbr=${dstNbr}`
          }
        },
        {
          pctValue: dstMetrics[timeframe + '_SMD_ACT'],
          decimals: 2
        },
        unit === 'NOM'
          ? {
              stringValue: `${dstMetrics[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 })} bps`,
              postIcon: getCompareIconStyle(Icon, -1 * dstMetrics[timeframe + '_SMD_VP']),
              customStyle: getCompareStyle(-1 * dstMetrics[timeframe + '_SMD_VP'])
            }
          : { stringValue: '-' },
        unit === 'NOM'
          ? {
              stringValue: `${dstMetrics[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })} bps`,
              postIcon: getCompareIconStyle(Icon, -1 * dstMetrics[timeframe + '_SMD_VLY']),
              customStyle: getCompareStyle(-1 * dstMetrics[timeframe + '_SMD_VLY'])
            }
          : { stringValue: '-' }
      ],
      [
        {
          stringValue: 'Labor',
          link: {
            to: `/district/labor?dstNbr=${dstNbr}`
          }
        },
        { stringValue: `${dstMetrics[timeframe + '_LBR_ACT'].toLocaleString()} hrs` },
        unit === 'NOM'
          ? { stringValue: `${dstMetrics[timeframe + '_LBR_VF'].toLocaleString()} hrs` }
          : { pctValue: dstMetrics[timeframe + '_LBR_VF_PCT'], decimals: 2 },
        { stringValue: '-' }
      ],
      [
        {
          stringValue: 'GET',
          link: {
            to: `/district/ltsa?dstNbr=${dstNbr}`
          }
        },
        {
          stringValue: getPercentString(dstMetrics[timeframe + '_GET_ACT'], 2, 2),
          customStyle: {
            color: dstMetrics[timeframe + '_GET_ACT'] >= VOC_GET_GOAL ? 'green' : 'red'
          },
          postIcon: getCompareIconStyle(Icon, dstMetrics[timeframe + '_GET_ACT'], VOC_GET_GOAL)
        },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              pctValue: dstMetrics[timeframe + '_GET_VLY'],
              decimals: 2,
              compareValue: true
            }
          : { stringValue: '-' }
      ],
      [
        {
          stringValue: 'LTSA',
          link: {
            to: `/district/ltsa?dstNbr=${dstNbr}`
          }
        },
        {
          stringValue: getPercentString(dstMetrics[timeframe + '_LTSA_ACT'], 2, 2),
          customStyle: {
            color: dstMetrics[timeframe + '_LTSA_ACT'] >= VOC_LTSA_GOAL ? 'green' : 'red'
          },
          postIcon: getCompareIconStyle(Icon, dstMetrics[timeframe + '_LTSA_ACT'], VOC_LTSA_GOAL)
        },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              pctValue: dstMetrics[timeframe + '_LTSA_VLY'],
              decimals: 2,
              compareValue: true,
              postIcon: getCompareIconStyle(Icon, dstMetrics[timeframe + '_LTSA_VLY']),
              customStyle: getCompareStyle(dstMetrics[timeframe + '_LTSA_VLY'])
            }
          : { stringValue: '-' }
      ],
      [
        {
          stringValue: 'LTPA',
          link: {
            to: `/district/voc?dstNbr=${dstNbr}`
          }
        },
        {
          stringValue:
            dstMetrics[timeframe + '_ONLINE_SAT_ACT'] !== null
              ? getPercentString(dstMetrics[timeframe + '_ONLINE_SAT_ACT'])
              : '-',
          customStyle: {
            color:
              dstMetrics[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
                ? 'green'
                : dstMetrics[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                  ? 'red'
                  : 'black'
          }
        },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              stringValue:
                dstMetrics[timeframe + '_ONLINE_SAT_VLY'] !== null
                  ? `${dstMetrics[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}%`
                  : '--'
            }
          : { stringValue: '-' }
      ],
      [
        {
          stringValue: 'Leads',
          link: {
            to: `/district/leads?dstNbr=${dstNbr}`
          }
        },
        { stringValue: dstMetrics[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              stringValue: dstMetrics[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
            }
          : { pctValue: dstMetrics[timeframe + '_LEADS_VLY_PCT'], decimals: 2, compareValue: true }
      ],
      [
        {
          stringValue: 'Measures',
          link: {
            to: `/district/meas?dstNbr=${dstNbr}`
          }
        },
        { stringValue: dstMetrics[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) },
        { stringValue: '-' },
        unit === 'NOM'
          ? {
              stringValue: dstMetrics[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })
            }
          : { pctValue: dstMetrics[timeframe + '_MEAS_VLY_PCT'], decimals: 2, compareValue: true }
      ],
      [{ stringValue: 'Cashier Metrics' }, { stringValue: '-' }, { stringValue: '-' }, { stringValue: '-' }],
      [
        { stringValue: 'HDPP - MA UA' },
        { pctValue: dstMetrics[timeframe + '_MA_HDPP'], decimals: 2 },
        { pctValue: dstMetrics[timeframe + '_MA_HDPP_VG'], decimals: 2, compareValue: true },
        { pctValue: dstMetrics[timeframe + '_MA_HDPP_VLY'], decimals: 2, compareValue: true }
      ],
      [
        { stringValue: 'HDPP - GM UA' },
        { pctValue: dstMetrics[timeframe + '_GM_HDPP'], decimals: 2 },
        { pctValue: dstMetrics[timeframe + '_GM_HDPP_VG'], decimals: 2, compareValue: true },
        { pctValue: dstMetrics[timeframe + '_GM_HDPP_VLY'], decimals: 2, compareValue: true }
      ]
    ],
    headers: [
      { name: '' },
      { name: 'Actual' },
      { name: unit === 'NOM' ? 'vP/vF' : 'vP/vF %' },
      { name: unit === 'NOM' ? 'vLY' : 'vLY %' }
    ]
  };
};

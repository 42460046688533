import React from 'react';
import { Tab } from 'semantic-ui-react';
import OnTimeAverage from './OnTimeAverage';
import OnTimeDeliveryMissed from './OnTimeDeliveryMissed';

export const OnTimeDeliveryAssoc = () => {

    const tabs = [
        {
            menuItem: 'AVERAGE MIN TO PICK',
            render: () => <OnTimeAverage />
        },
        {
            menuItem: 'DELIVERY MISSES',
            render: () => <OnTimeDeliveryMissed />
        }
    ]
    return (
        <Tab
            menu={{ compact: true, size: 'small' }}
            panes={tabs}
        />
    );
};

import { DataView } from '../../../../../../components/Common/DataView';
import { getSmdCompareStyle, getThousandDollarString } from '../../../../../../components/Common/TableCellUtils';
import { getRowSubLevelNm } from '../../../../../../utils/getRowLevelNm';
import { LEVEL_NBR } from '../../../../../../utils/constantsByLevel';

export const SmdComparison = ({ level, data, timeframe }) => {
  const { levelName, subLevel } = level;
  const { levelName: subLevelName, urlParam: subUrlParam, [LEVEL_NBR]: subLevelNbr } = subLevel;

  const dataObj = {
    data: data.map(row => [
      {
        link:
          row.METRIC_LEVEL === 'CORE'
            ? undefined
            : {
                to: `/${subLevelName}/smd`,
                params: {
                  [subUrlParam]: row[subLevelNbr]
                }
              },
        stringValue: row.METRIC_LEVEL === 'CORE' ? 'Core' : getRowSubLevelNm({ row, level: levelName })
      },
      {
        dollarValue: getThousandDollarString(row[timeframe + '_SMD_AMT'], 1),
        orderBy: row[timeframe + '_SMD_AMT']
      },
      {
        decimals: 2,
        pctValue: row[timeframe + '_SMD_ACT']
      },
      {
        customStyle: { color: row[timeframe + '_SMD_BPS_VP'] > 0 ? 'green' : 'red' },
        postfix: ' bps',
        orderBy: row[timeframe + '_SMD_BPS_VP'],
        stringValue: row[timeframe + '_SMD_BPS_VP']
          ? row[timeframe + '_SMD_BPS_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : '-'
      },
      {
        customStyle: { color: row[timeframe + '_SMD_OVER_UNDER'] > 0 ? 'green' : 'red' },
        orderBy: row[timeframe + '_SMD_OVER_UNDER'],
        stringValue: getThousandDollarString(row[timeframe + '_SMD_OVER_UNDER'], 1)
      }
    ]),
    headers: [
      { name: 'Division #' },
      { name: 'SMD $' },
      { name: 'SMD %' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-3' testId='data-view-smd-detail-region-comps'>
      <DataView.Title>SMD - Divisions Comps</DataView.Title>
      <DataView.DataTable data={dataObj} />
      <br />
    </DataView>
  );
};

import { useContext } from 'react';
import { DeliveryTabs } from './DeliveryTabs';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { capitalizeFirstLetter } from '../../../../../../utils/string';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { divide } from '../../../../../../utils/numbers';
import { DataView } from '../../../../../../components/Common/DataView';

export const CompleteDelivery = () => {
    const { timeframe } = useContext(StoreOrderFulfillmentContext);
    const [storeCompleteDlv] = useQueryKeys([`storeCompleteDlv`])

    const dataDlv = storeCompleteDlv.map(data => {
      return [
        { stringValue: capitalizeFirstLetter(data.TRUCK_TYPE) },
        { pctValue: divide(data[timeframe+'_FULFIL_QTY'], data[timeframe+'_TOTAL_QTY'])},
        { pctValue: divide(data[timeframe+'_LY_FULFIL_QTY'], data[timeframe+'_LY_TOTAL_QTY'] )},
        { numValue: data[timeframe+'_MANIPULATED_NUM'] },
        { numValue: data[timeframe+'_CANCELED_QTY'] }
    ]})

    const total = dataDlv.reduce((acc, data) => {
      acc[0].stringValue = 'Total Orders';
      acc[1].pctValue += data[1].pctValue/acc.length;
      acc[2].pctValue += data[2].pctValue/acc.length;
      acc[3].numValue += data[3].numValue;
      acc[4].numValue += data[4].numValue;
      return acc;
    },[
      { stringValue: 'Total Orders' },
      { pctValue: 0 },
      { pctValue: 0 },
      { numValue: 0 },
      { numValue: 0 }
    ])

    dataDlv.push(total)

    const dataObj = {
      headers: [
        { name: 'Truck Type' },
        { name: 'Fullfilment %' },
        { name: 'Push %' },
        { name: 'Truck Changes' },
        { name: 'Unfulfilled Units' }
      ],
      data: dataDlv,
    };

    return (
      <DataView className='my-4'>
        <DataView.Title as='h3'>Delivery</DataView.Title>
        <DataView.DataTable data={dataObj} />
        <DeliveryTabs />
      </DataView>
    );
};
import { LEVEL_NBR } from '../../../../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../../../../utils/level';
import { getRowLevelNm, getRowSubLevelNm } from '../../../../../../utils/getRowLevelNm';

export const getLevelColumn = ({ row, level }) => {
  const { subLevel, levelName } = level;
  const { levelName: subLevelName, [LEVEL_NBR]: subLevelNbr } = subLevel;

  const to = `/${subLevelName}/overview`;
  const params = {
    showList: true,
    divNbr: formatLevelNumber(row?.[subLevelNbr])
  };

  return {
    link: { to, params },
    stringValue: getRowSubLevelNm({ row, level: levelName })
  };
};

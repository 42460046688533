import { Dropdown, Grid } from 'semantic-ui-react';

export const CustomDropDown = ({
  viewOptions,
  showCustomTimeframe,
  timeframeOptions,
  view,
  setView,
  extTimeframe,
  setExtTimeframe
}) => {
  const showCustomDropDown = showCustomTimeframe.includes(view);
  return (
    <Grid className='mb-1'>
      <Grid.Row columns={2}>
        <Grid.Column>
          {viewOptions ? (
            <>
              View <br />
              <Dropdown
                compact
                defaultValue={view}
                selection
                scrolling
                options={viewOptions}
                onChange={(_, { value }) => {
                  setView(value);
                }}
              />
            </>
          ) : null}
        </Grid.Column>
        <Grid.Column verticalAlign='bottom' textAlign='right'>
          {showCustomDropDown ? (
            <>
              Timeframe <br />
              <Dropdown
                compact
                defaultValue={extTimeframe}
                selection
                scrolling
                options={timeframeOptions}
                onChange={(_, { value }) => {
                  setExtTimeframe(value);
                }}
              />
            </>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

import React, { useContext } from 'react';
import { OrderFulfillmentContext } from '../../../context/OrderFulfillmentContext';
import { DataTable } from '../../../../../components/Common/DataTable';
import { DataView } from '../../../../../components/Common/DataView';
import { summaryData } from '../../../utils/summary';
import { useEnhancedQueries } from '../../../../../hooks/useEnhancedQueries';

const Summary = () => {
  const { unit, fpToggle, timeframe } = useContext(OrderFulfillmentContext);
  const {
    results: [{ data: sumData = [] }]
  } = useEnhancedQueries(['orderSummaryByLvl'], fpToggle);
  const isNOM = unit === 'NOM';
  const data = summaryData(sumData, timeframe, isNOM);
  
  return (
    <DataView className='my-4'>
      <DataView.Title className='mb-4' as='h3'>
        Summary
      </DataView.Title>

      <DataTable data={data} />
    </DataView>
  );
};

export default Summary;

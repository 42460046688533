import { aggregateObjectKeys } from "../../../utils/array";
import { METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../utils/string";
import { getParamByLevel } from "../../../utils/timeFrameUtils";
import { getCalloutStyle, getCompareStyle } from "../../Common/TableCellUtils";

export const buildFooter = (data, timeframe, pipelineFilter) => {
    const totalData = aggregateObjectKeys(data);
    const { leadCount: totalLead, measCount: totalMeas } = extractRowData(totalData, timeframe, pipelineFilter);
    const totalComp = calculateComp(totalData, timeframe, pipelineFilter);
    const totalConv = calculateConv(totalData, timeframe, pipelineFilter);

    return [
        [
            {
                name: 'total',
                stringValue: 'Total'
            },
            {
                name: 'lead',
                numValue: totalLead
            },
            {
                name: 'meas',
                numValue: totalMeas
            },
            {
                name: 'comp',
                pctValue: totalComp
            },
            {
                name: 'conv',
                pctValue: totalConv
            }
        ]
    ]
}

export const buildHeaders = (level) => [{ name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel'])} #` }, { name: 'Lead' }, { name: 'Meas' }, { name: 'Comp %' }, { name: 'Conv %' }]

export const buildRows = (data, pipelineFilter, level, locNbr, timeframe) => {
    const METRIC_LEVEL = METRIC_CONSTANTS[level];
    return data.map(row => {
        const {
            leadConvNum,
            leadCount,
            lyLeadCount,
            lyMeasCount,
            measConvNum,
            measCount
        } = extractRowData(row, timeframe, pipelineFilter);

        const comp = calculateComp(row, timeframe, pipelineFilter);
        const compStyle = (leadCount + measCount - lyLeadCount - lyMeasCount);

        const conv = calculateConv(row, timeframe, pipelineFilter);
        const convStyle = (leadConvNum + measConvNum);

        return [
            {
                link: {
                    to: `/${METRIC_LEVEL['urlLinkLevel']}/services`,
                    params: {
                        [METRIC_LEVEL['urlSubLevelParam']]: row[METRIC_LEVEL['propToRender']]
                    }
                },
                stringValue: `${METRIC_LEVEL['FILTER_BY_METRIC']} ${row[METRIC_LEVEL['propToRender']]}`
            },
            {
                customStyle: getCalloutStyle(leadCount),
                numValue: leadCount
            },
            {
                customStyle: getCompareStyle(measCount),
                numValue: measCount
            },
            {
                customStyle: getCompareStyle(compStyle),
                pctValue: comp
            },
            {
                customStyle: getCompareStyle(convStyle),
                pctValue: conv
            }
        ];
    })
}

const calculateComp = (row, timeframe, pipelineFilter) => {
    const { leadCount, measCount, lyLeadCount, lyMeasCount } = extractRowData(row, timeframe, pipelineFilter);

    return (leadCount + measCount - lyLeadCount - lyMeasCount) / (lyLeadCount + lyMeasCount);
}

const calculateConv = (row, timeframe, pipelineFilter) => {
    const { leadConvDen, leadConvNum, measConvDen, measConvNum } = extractRowData(row, timeframe, pipelineFilter);

    return (leadConvNum + measConvNum) / (leadConvDen + measConvDen);
}

const extractRowData = (row, timeframe, pipelineFilter) => {
    const leadCount = row[timeframe + '_LEAD_COUNT_' + pipelineFilter] || 0;
    const measCount = row[timeframe + '_MEASURE_COUNT_' + pipelineFilter] || 0;
    const lyLeadCount = row[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] || 0;
    const lyMeasCount = row[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter] || 0;
    const leadConvNum = row[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] || 0;
    const leadConvDen = row[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] || 0;
    const measConvNum = row[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter] || 0;
    const measConvDen = row[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter] || 0;

    return {
        leadCount,
        leadConvDen,
        leadConvNum,
        lyLeadCount,
        lyMeasCount,
        measCount,
        measConvDen,
        measConvNum
    }
}
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { getData } from '../service/DataService';
import { useQueryParams } from './useQueryParams';

export const useEnhancedQueries = (queries, fpToggle) => {
  const { level = 'store' } = useParams();
  const { classNbr, strNbr, divNbr, dstNbr, rgnNbr, subClassNbr } = useQueryParams();
  const locNbr = dstNbr || rgnNbr || divNbr || strNbr || '0000'; // '0000' for Core
  const params = { fpToggle, varPresent: true };
  const isCore = level === 'core';

  const buildQuery = queryKey => {
    const baseQuery = `query/${queryKey}/${level}/${locNbr}`;
    if (classNbr) return `${baseQuery}/class/${classNbr}`;
    if (subClassNbr) return `${baseQuery}/subclass/${subClassNbr}`;

    return baseQuery;
  };

  const queryList = queries.map(queryKey => ({ queryKey: [buildQuery(queryKey), params], queryFn: getData }));
  const results = useQueries(queryList);

  const isLoading = results.some(query => query.isLoading);
  const isError = results.some(query => query.isError);

  return {
    isLoading,
    isError,
    results,
    locNbr,
    level
  };
};

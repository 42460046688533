import React, { useState } from 'react';
import {
  AGG_TYPE,
  ALL,
  DATE_FORMAT_ITEMS,
  FISCAL_DAY,
  FSCL_YR,
  METRIC_ITEMS,
  PERIOD_DESC,
  PERIOD_END_DT,
  SALES_PLAN,
  scoreCardHeaders,
  TIMEFRAME_ITEMS,
  yearScoreCardDataColumns
} from '../utils/dailySales';
import { Select } from '../../../../../components/Common/Select';
import { DataView } from '../../../../../components/Common/DataView';
import { DataTable } from '../../../../../components/Common/DataTable';

export const SalesScorecard = ({ data }) => {
  const [metric, setMetric] = useState(SALES_PLAN);
  const [timeframe, setTimeframe] = useState(FISCAL_DAY);
  const [fiscalYear, setFiscalYear] = useState(ALL);
  const [dateFormat, setDateFormat] = useState(PERIOD_END_DT);
  const slsHistData = data || [];
  const slsHistDataFiltered = slsHistData.filter(
    row => row && row[AGG_TYPE] === timeframe && (fiscalYear === ALL || row[FSCL_YR].toString() === fiscalYear)
  );
  const fiscalYears = slsHistData.reduce((acc, curr) => (acc.includes(curr[FSCL_YR]) ? acc : [...acc, curr[FSCL_YR]]), []);
  const fiscalYearsSorted = fiscalYears.sort();
  const isDateFormatFiscalYear = dateFormat === PERIOD_DESC;

  const headers = scoreCardHeaders(timeframe, metric, dateFormat);
  const yearScoreCardData = yearScoreCardDataColumns(
    slsHistDataFiltered,
    isDateFormatFiscalYear,
    dateFormat,
    timeframe,
    metric
  );

  const yearScoreCardDataObj = {
    headers,
    data: yearScoreCardData,
    pagination: true,
    sortable: true,
    sortableDefault: isDateFormatFiscalYear ? 1 : 0
  };
  const fiscalYearsSortedItems = [{ value: ALL, text: ALL }, ...fiscalYearsSorted.map(row => ({ value: row, text: row }))];

  return (
    <DataView>
      <DataView.Controls className='d-flex flex-column gap-3'>
        <div className='d-flex justify-content-between'>
          <Select label='Metric:' name='metric' onChange={setMetric} value={metric} items={METRIC_ITEMS} />
          <Select label='Timeframe:' name='timeframe' onChange={setTimeframe} value={timeframe} items={TIMEFRAME_ITEMS} />
        </div>

        <div className='d-flex justify-content-between'>
          <Select
            label='Fiscal Year:'
            name='fiscalYear'
            onChange={setFiscalYear}
            value={fiscalYear}
            items={fiscalYearsSortedItems}
          />
          <Select
            label='Date Format:'
            name='dateFormat'
            onChange={setDateFormat}
            value={dateFormat}
            items={DATE_FORMAT_ITEMS}
          />
        </div>
      </DataView.Controls>
      <DataView.Title>Full Year Scorecard</DataView.Title>

      <DataTable data={yearScoreCardDataObj} />
    </DataView>
  );
};

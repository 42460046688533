import useQueryApi from '../../../hooks/useQueryApi';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { CORE } from '../../../utils/constantsByLevel';

const { levelName: coreLevelName } = CORE;

export const useSpecialtyProRentalServicesQueries = () => {
  const { fpToggle, level, locNbr } = useSpecialtyProRentalContext();
  const { levelName } = level;
  const queryParamsByLevel = levelName === coreLevelName ? { isCore: true } : { locNbr };
  const queryParams = { ...queryParamsByLevel, fpToggle };

  const {
    data: focusSixAllProgramsData,
    isLoading: focusSixAllProgramsIsLoading,
    isError: focusSixAllProgramsIsError
  } = useQueryApi(`query/focusSixAllPrograms/${levelName}`, queryParams);

  const {
    data: leadsAndMeasuresPerformanceData,
    isLoading: leadsAndMeasuresPerformanceIsLoading,
    isError: leadsAndMeasuresPerformanceIsError
  } = useQueryApi(`query/leadsAndMeasuresPerformance/${levelName}`, queryParams);

  const isLoading = focusSixAllProgramsIsLoading || leadsAndMeasuresPerformanceIsLoading;

  const isError = focusSixAllProgramsIsError || leadsAndMeasuresPerformanceIsError;

  return {
    isLoading,
    isError,
    focusSixAllProgramsData,
    leadsAndMeasuresPerformanceData
  };
};

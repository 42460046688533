import { DataView } from '../../Common/DataView';
import { getServicesSalesDataTable } from '../utils/specialtyProRentalServices';

export const ServicesSales = props => {
  const { focusSixAllProgramsData, activeMenu, level, locNbr, timeframe } = props;
  const servicesSalesDataTable = getServicesSalesDataTable({
    focusSixAllProgramsData,
    activeMenu,
    level,
    locNbr,
    timeframe
  });

  return (
    <DataView className='mt-4'>
      <DataView.Title position='center'>{activeMenu}</DataView.Title>

      <DataView.DataTable data={servicesSalesDataTable} />
    </DataView>
  );
};

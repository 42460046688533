import React, { useEffect } from 'react';
import { Header, Loader, Icon, Segment, Popup } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../service/DataService';
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited';
import { OSA_SIDEKICK_PURGE_GOAL } from '../../constants/goals';
import { DataTable } from '../Common/DataTable';
import { DataView } from '../Common/DataView';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { Breadcrumb } from '../Common/Breadcrumb';
import { getParamByLevel } from '../../utils/timeFrameUtils';
import { getSideKickPurgeBreadcrumbItems } from '../../utils/breadcrumbItems';

export const deptNbr = 38;
export const deptNm = 'Packout Team Tasks Breakdown';

export const SideKickPurge = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location, ...props }) => {
  useEffect(() => {
    if (timeframe !== 'WTD' && timeframe !== 'LW') {
      settimeframe('WTD');
    }
  }, [timeframe]);

  let currentDay = new Date().getDay();
  let displayTimeframe = timeframe;

  if (currentDay === 1) {
    // 1 represents Monday
    if (timeframe === 'WTD') {
      displayTimeframe = 'LW';
    } else if (timeframe === 'LW') {
      displayTimeframe = 'LLW';
    }
  }

  const OSASideKickPurgeQuery = useQuery([`query/queryOSASidekickSkuBay/${level}`, { strNbr: locNbr, fpToggle }], getData);

  const SidekickSkuBayDateDtlQuery = useQuery(
    [`queryOSASidekickSkuBayDateDtlBreakDown`, { strNbr: locNbr, fpToggle }],
    getData
  );

  if (OSASideKickPurgeQuery.isLoading || SidekickSkuBayDateDtlQuery.isLoading) {
    return <Loader active>Loading</Loader>;
  }

  if (
    !OSASideKickPurgeQuery.data ||
    OSASideKickPurgeQuery.data.length === 0 ||
    !SidekickSkuBayDateDtlQuery.data ||
    SidekickSkuBayDateDtlQuery.data.length === 0
  ) {
    return <Header textAlign='center'>Invalid Store Number</Header>;
  }

  const OSASideKickPurgeData = OSASideKickPurgeQuery.data.filter(({ DEPT }) => DEPT === '38');

  const SidekickSkuBayDateDtlData = SidekickSkuBayDateDtlQuery.data.filter(({ DEPT_NBR }) => DEPT_NBR === 38);

  let OSASideKickPurgeDataFilterPurge = OSASideKickPurgeData.filter(row => {
    return row['TASK_TYPE'] === 'PURGE';
  });

  const weekDetail = SidekickSkuBayDateDtlData.filter(({ WTD_FLG, LW_FLG }) =>
    timeframe === 'WTD' ? WTD_FLG === 1 : LW_FLG === 1
  );

  const dailyData = weekDetail.reduce(
    (acc, row) => {
      if (row.COMPLETED_DT !== null) {
        const [year, month, day] = row.COMPLETED_DT.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const dayOfWeek = date.getDay();
        acc[dayOfWeek].dirCom += row.TASK_TYPE === 'PURGE' ? row.COMPLETED_TASK_COUNT : 0;
        acc[dayOfWeek].comBay += row.TASK_TYPE === 'BAY' ? row.COMPLETED_TASK_COUNT : 0;
      }

      const [year, month, day] = row.CREATED_DT.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.getDay();
      acc[dayOfWeek].dirBay += row.TASK_TYPE === 'BAY' ? row.TOTAL_TASK_COUNT : 0;
      return acc;
    },
    [
      { dirCom: 0, dirBay: 0, comBay: 0 },
      { dirCom: 0, dirBay: 0, comBay: 0 },
      { dirCom: 0, dirBay: 0, comBay: 0 },
      { dirCom: 0, dirBay: 0, comBay: 0 },
      { dirCom: 0, dirBay: 0, comBay: 0 },
      { dirCom: 0, dirBay: 0, comBay: 0 },
      { dirCom: 0, dirBay: 0, comBay: 0 }
    ]
  );

  const TaskCompPurge = OSASideKickPurgeDataFilterPurge.reduce(
    (acc, curr) => {
      acc[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT'];
      acc[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT'];
      acc[timeframe + '_COMPLETED_POINTS'] += curr[timeframe + '_COMPLETED_POINTS'];
      acc[timeframe + '_TOTAL_POINTS'] += curr[timeframe + '_TOTAL_POINTS'];
      acc.RMNGTASKS = acc[timeframe + '_TOTAL_TASK_COUNT'] - acc[timeframe + '_COMPLETED_TASK_COUNT'];
      acc.TASKSCOMPPCT = acc[timeframe + '_COMPLETED_TASK_COUNT'] / acc[timeframe + '_TOTAL_TASK_COUNT'];
      acc.EXERT = acc[timeframe + '_COMPLETED_POINTS'] / acc[timeframe + '_TOTAL_POINTS'];
      return acc;
    },
    {
      [timeframe + '_COMPLETED_TASK_COUNT']: 0,
      [timeframe + '_TOTAL_TASK_COUNT']: 0,
      [timeframe + '_COMPLETED_POINTS']: 0,
      [timeframe + '_TOTAL_POINTS']: 0,
      RMNGTASKS: 0,
      TASKSCOMPPCT: 0,
      EXERT: 0
    }
  );

  const summaryDataObj = {
    headers: [
      { name: 'Tasks Comp #' },
      { name: 'Remaining Tasks #' },
      {
        name: 'Execution Rate %',
        popup: {
          content: (
            <>
              <Header textAlign='center'>Purge:</Header>
              <p>Execution Rate = Purge Tasks Completed/60</p>
              <p>A store is given 60 purge tasks to execute each week.</p>
            </>
          ),
          icon: 'info circle',
          color: 'blue'
        }
      },
      { name: 'Goal' }
    ],
    data: [
      [
        { numValue: TaskCompPurge[timeframe + '_COMPLETED_TASK_COUNT'] },
        { numValue: TaskCompPurge.RMNGTASKS },
        { pctValue: TaskCompPurge.EXERT },
        { pctValue: OSA_SIDEKICK_PURGE_GOAL }
      ]
    ]
  };

  const dailyDataObj = {
    headers: [{ name: 'M' }, { name: 'T' }, { name: 'W' }, { name: 'T' }, { name: 'F' }, { name: 'S' }, { name: 'S' }],
    data: [
      [
        { stringValue: dailyData[1].dirCom ? dailyData[1].dirCom : '-' },
        { stringValue: dailyData[2].dirCom ? dailyData[2].dirCom : '-' },
        { stringValue: dailyData[3].dirCom ? dailyData[3].dirCom : '-' },
        { stringValue: dailyData[4].dirCom ? dailyData[4].dirCom : '-' },
        { stringValue: dailyData[5].dirCom ? dailyData[5].dirCom : '-' },
        { stringValue: dailyData[6].dirCom ? dailyData[6].dirCom : '-' },
        { stringValue: dailyData[0].dirCom ? dailyData[0].dirCom : '-' }
      ]
    ]
  };

  const currentLevelName = OSASideKickPurgeData[0][METRIC_CONSTANTS[level].LEVEL_NM];
  const urlParam = getParamByLevel(level);
  const breadcrumbItems = getSideKickPurgeBreadcrumbItems({ level, urlParam, locNbr });

  return (
    <Segment>
      <Breadcrumb items={breadcrumbItems} />
      <DataView className='mt-4'>
        <DataView.Title>
          <span>
            Sidekick Execution - Purge - {level} {locNbr}, {currentLevelName}
            <Popup
              trigger={<Icon name='info circle' color='blue' />}
              content='Execution Rate = Purge Tasks Completed/60. A store is given 60 purge tasks to execute each week.'
              position='right center'
              on='click'
              textAlign='center'
            />
          </span>
          <Header.Subheader>Data as of {OSASideKickPurgeData[0]?.TRNSFM_DT}</Header.Subheader>
          <Header.Subheader style={{ color: 'black' }}>
            {deptNbr}-{deptNm}
          </Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='align-items-center d-flex flex-column'>
          Timeframe:
          <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
      </DataView>
      <Header textAlign='center'>Directed Tasks - Purge</Header>
      <DataTable data={summaryDataObj} />
      <Header textAlign='center'>Tasks breakdown by {displayTimeframe}</Header>
      <p style={{ textAlign: 'center' }}>Tasks</p>
      <DataTable data={dailyDataObj} />
      <p style={{ textAlign: 'center' }}>Directed tasks completed by day</p>
      &nbsp;
      <a href={`https://myview-cold.apps.homedepot.com/reports/osa?storeNum="${locNbr}"`}>
        <Icon className='external alternate icon' />
        VIEW IN MY VIEW
      </a>
    </Segment>
  );
});

import { Header, Loader, Tab } from 'semantic-ui-react';
import { useEnhancedQueries } from '../../../../hooks/useEnhancedQueries';
import { METRIC_CONSTANTS } from '../../../../utils/constantsByLevel';
import { capitalizeFirstLetter as capitalize } from '../../../../utils/string';
import { CreditMainTab } from '../components/CreditMainTab';
import { LevelList } from '../components/LevelList';
import { useCreditContext } from '../hooks/useCreditContext';
import CreditStorePage from './CreditStorePage';

export const CreditPage = () => {
  const {
    fpToggle,
    level: { levelName },
    timeframe,
    settimeframe,
    locNbr
  } = useCreditContext();
  const {
    isLoading,
    isError,
    results: [{ data: creditPerfStrByStr }, { data: creditSlsPenStrByStr }, { data: creditAssocChnlByStr }]
  } = useEnhancedQueries(
    [
      'queryCreditPerfStrByStr',
      'queryCreditSlsPenStrByStr',
      'queryCreditAssocChnlByStr',
      'queryCreditPerfBySublevel',
      'queryCreditSlsPenBySublevel',
      'creditApplications',
      'creditUsage',
      'creditChannelByLvl'
    ],
    fpToggle
  );

  const levelNameCapitalized = capitalize(levelName);
  const subLevelName = capitalize(METRIC_CONSTANTS[levelName].subLevel.levelName);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Error fetching Credit Data</Header>;

  const panes = [
    {
      menuItem: levelNameCapitalized,
      render: () => <CreditMainTab />
    },
    {
      menuItem: `${subLevelName} List`,
      render: () => <LevelList />
    }
  ];

  return (
    <>
      {levelName === 'store' ? (
        <CreditStorePage
          locNbr={locNbr}
          timeframe={timeframe}
          settimeframe={settimeframe}
          creditPerfStrByStr={creditPerfStrByStr[0]}
          creditSlsPenStrByStr={creditSlsPenStrByStr[0]}
          creditAssocChnlByStr={creditAssocChnlByStr}
        />
      ) : (
        <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />
      )}
    </>
  );
};

import { buildBaseDrillLink } from '../../../../../components/ShrinkContainer/utils/url';
import { getPercentString } from '../../../../../components/Common/TableCellUtils';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { getRowLevelNm } from "../../../../../utils/getRowLevelNm";

export const shrinkDataWithScore = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [{ name: 'Div #' }, { name: 'Value' }, { name: 'Goal' }, { name: '% of Stores Passing ' }],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(data => {
    const divNumber = data['DIV_NBR'];
    const baseLevelShrinkUrl = buildBaseDrillLink('core');
    const { urlParam } = METRIC_CONSTANTS.core.subLevel;

    const coreLink = {
      to: `/${baseLevelShrinkUrl}/rtvcredit`,
      params: {
        [urlParam]: divNumber
      }
    };

    // Find the corresponding shrink score data
    const shrinkScoreDataFilter = shrinkScoreData.find(row => row && row['DIV_NBR'] === divNumber);

    return [
      {
        stringValue: getRowLevelNm({row: data, level}),
        link: coreLink
      },
      {
        stringValue: getPercentString(data[timeframe + '_RTV_CREDIT_NUM'] / data[timeframe + '_RTV_CREDIT_DEN'], 2)
      },
      {
        stringValue: getPercentString(shrinkScoreDataFilter ? shrinkScoreDataFilter['RTV_CREDIT_GOAL'] : 0, 2)
      },
      {
        stringValue: getPercentString(shrinkScoreDataFilter ? shrinkScoreDataFilter['RTV_CREDIT_SCORE'] : 0)
      }
    ];
  })
});

import React, { useState } from 'react';
import { CustomDropDown } from '../CustomDropdown';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { DataView } from '../../../../../../components/Common/DataView';

export default function OnTimeAverage() {
  const [view, setView] = useState('Associate View');
  const [timeframe, settimeframe] = useState('WTD')
  const showCustomTimeframe = ['# Missed SLA']
  const [storeOnTimeAvgAssocView, storeOnTimeAvgMissedSLA] = useQueryKeys([`storeOnTimeAvgAssocView`, 'storeOnTimeAvgMissedSLA'])

  const isAssoc = view === 'Associate View';

  const dataAssoc = {
    headers: [
      { name: 'Associate' },
      { name: 'Pick On Time %' },
      { name: '# of Orders Missed' },
    ],
    data: storeOnTimeAvgAssocView.map(data => [
      { stringValue: data.ASSOC_NM },
      { pctValue: data.PICK_ON_TIME_PCT },
      { numValue: data.ORDERS_MISSED },
    ]),
    pagination: true,
    sortable: true
  }

  const filterByTimeframe = storeOnTimeAvgMissedSLA.filter(row => row[timeframe + '_FLG'] > 0)

  const dataMissedSLA = {
    headers: [
      { name: 'Truck Type' },
      { name: 'Associate Name' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Fulfill Date' },
    ],
    data: filterByTimeframe.map(data => [
      { stringValue: data.TRUCK_TYPE },
      { stringValue: data.ASSOC_NM },
      { stringValue: data.CUST_ORD_NBR },
      { numValue: data.WORK_ORDER_NO },
      { stringValue: data.FULFILL_DATE },
    ]),
    sortable: true,
    pagination: true
  }

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return dataAssoc;
      case '# Missed SLA':
        return dataMissedSLA;
    }
  }

  return (
    <DataView className='mt-4'>
      {isAssoc ? (
        <DataView.Title as='h4'>Delivery: Associate Report</DataView.Title>
      ) : (
        <DataView.Title as='h4'>Average Min to Pick</DataView.Title>
      )}
      <CustomDropDown
        view={view}
        setView={setView}
        showCustomTimeframe={showCustomTimeframe}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
}

import { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { DataView } from '../Common/DataView';
import { UnitMenu } from '../Common/UnitMenu';
import { DataTable } from '../Common/DataTable';
import { DstOverviewTabs } from './DstOverviewTabs';
import { districtTabBuilder } from '../../utils/districtTabBuilder';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';

export const DistrictTab = ({
  daysSafeDstFilter,
  dataSlsClsByDst,
  dataSlsDeptByDst,
  dataSlsSclsByDst,
  deptInventory,
  districtNumber,
  dstNm,
  dstNbr,
  dstMetrics,
  settimeframe,
  timeframe,
  Top40Btm40Query,
  userEmplCtgryNm
}) => {
  const [unit, setUnit] = useState('NOM');

  const overViewDataObj = districtTabBuilder({ userEmplCtgryNm, daysSafeDstFilter, dstMetrics, timeframe, unit, dstNbr });

  return (
    <DataView>
      <DataView.Controls>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            Timeframe:
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid>
      </DataView.Controls>

      <DataView.Title position='center'>
        Overview - District {districtNumber}, {dstNm}
      </DataView.Title>

      <div style={{ display: 'flex', justifyContent: 'center' }}>Data as of {dstMetrics['TRNSFM_DT']}</div>

      <DataTable data={overViewDataObj} />

      <DstOverviewTabs
        timeframe={timeframe}
        dstNm={dstMetrics['DST_NM']}
        deptInventory={deptInventory}
        dataSlsClsByDst={dataSlsClsByDst}
        Top40Btm40Query={Top40Btm40Query}
        dataSlsDeptByDst={dataSlsDeptByDst}
        dataSlsSclsByDst={dataSlsSclsByDst}
      />
    </DataView>
  );
};

import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useQueries } from 'react-query';
import { getData } from '../../../service/DataService';

export const useShrinkQueries = ({ fpToggle, queries = [] }) => {
  const { level } = useParams();
  const { classNbr, dstNbr, rgnNbr, subClassNbr, divNbr } = useQueryParams();
  const locNbr = dstNbr || rgnNbr || divNbr;
  const params = { fpToggle, varPresent: true };

  const buildQuery = queryKey => {
    const baseQuery = `${queryKey}/${level}/${locNbr}`;
    if (classNbr) return `${baseQuery}/class/${classNbr}`;
    if (subClassNbr) return `${baseQuery}/subclass/${subClassNbr}`;

    return baseQuery;
  }

  const queryList = queries.map(queryKey => ({ queryKey: [buildQuery(queryKey), params], queryFn: getData }));

  const results = useQueries(queryList);

  const isLoading = results.some(query => query.isLoading);
  const isError = results.some(query => query.isError);

  return {
    isLoading,
    isError,
    results,
    locNbr,
    level
  };
};

import { Header, Popup, Icon } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';
import { TimeframeDropdownMonLimited } from '../../../../../../components/Common/TimeframeDropdownMonLimited';
import { TimeframeDropdownLimited } from '../../../../../../components/Common/TimeframeDropdownLimited';
import { getOverviewDataTable } from '../../utils/osa/sidekickskubay';

export const SidekickExecSkuBay = props => {
  const { osaSidekickSkuBayData, sidekickExeScoresPlusOneData, level, subLevel, timeframe, settimeframe, ltf } = props;
  const { levelName } = level;
  const overviewDataTable = getOverviewDataTable({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    level,
    subLevel,
    timeframe,
    ltf
  });

  return (
    <DataView className='mt-2'>
      <DataView.Title>
        <span>
          Sidekick Execution - SKU/Bay - {levelName}
          <Popup
            on='click'
            position='bottom left'
            content='Execution Rate= Directed Tasks Completed/Directed Tasks Goal.  Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.'
            trigger={<Icon name={'info circle'} color={'blue'} />}
          />
        </span>
        <Header.Subheader>Data as of {osaSidekickSkuBayData[0]?.TRNSFM_DT}</Header.Subheader>
      </DataView.Title>

      <DataView.Controls className='align-items-center d-flex flex-column'>
        Timeframe:
        {new Date().getDay() === 1 ? (
          <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} />
        ) : (
          <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
        )}
      </DataView.Controls>
      <DataView.DataTable data={overviewDataTable} />
    </DataView>
  );
};

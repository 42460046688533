import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { getCompareStyle, getPercentString } from '../../../../../components/Common/TableCellUtils';

export const monitorMetricsDataCore = ({ shrinkMetricsData, timeframe }) => {
  const headers = [
    { name: 'Metric' },
    { name: 'Value' },
    {
      name: 'vLY ',
      popup: {
        content:
          'Although there are no goals for the Monitor Metrics, it is important to continually strive for improvement compared to last year.',
        icon: 'info circle',
        color: 'blue'
      }
    }
  ];
  if (!shrinkMetricsData) {
    return { headers, data: [] };
  }

  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');

  const rtvDeletedTagValue = shrinkMetricsData[timeframe + '_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_RTV_DEL_DEN'];
  const rtvDeletedTagValueLY = shrinkMetricsData[timeframe + '_LY_RTV_DEL_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_DEL_DEN']
  const rtvDeletedTagVersusLY = rtvDeletedTagValue - rtvDeletedTagValueLY;

  return {
    headers,
    data: [
      [
        {
          // RTV Deleted Tags
          stringValue: 'RTV Deleted Tags',
          link: {
            to: `/${baseLevelShrinkUrl}/rtvdeletedtag`
          }
        },
        {
          decimals: 2,
          pctValue: rtvDeletedTagValue,
        },
        {
          decimals: 2,
          pctValue: rtvDeletedTagVersusLY,
          customStyle: getCompareStyle(-1 * (rtvDeletedTagVersusLY))
        }
      ],
      [
        {
          stringValue: 'Reduced Tags',
          link: {
            to: `/${baseLevelShrinkUrl}/reducedtag`
          }
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'],
            2,
            2
          )
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
            shrinkMetricsData[timeframe + '_LY_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'],
            2,
            2
          ),
          customStyle: getCompareStyle(
            -1 *
            (shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
              shrinkMetricsData[timeframe + '_LY_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'])
          )
        }
      ],
      [
        {
          // BOSS RTV %
          stringValue: 'BOSS RTV %',
          link: {
            to: `/${baseLevelShrinkUrl}/bosscancel`
          }
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'],
            2,
            2
          )
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'] -
            shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_DEN'],
            2,
            2
          ),
          customStyle: getCompareStyle(
            shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'] -
            shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_DEN']
          )
        }
      ],
      [
        {
          // On Hand Adjustments & Empty Packages
          stringValue: 'On Hand Adjustments & Empty Packages',
          link: {
            to: `/${baseLevelShrinkUrl}/ohemptpkg`
          }
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'],
            2,
            2
          )
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'] -
            shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_DEN'],
            2,
            2
          ),
          compareStyle: getCompareStyle(
            -1 *
            (shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'] -
              shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_DEN'])
          )
        }
      ]
    ]
  };
};

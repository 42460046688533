import { Grid } from 'semantic-ui-react';
import { DataTable } from '../../Common/DataTable';

export const SalesProRentalLevelList = ({ slsDepts, dataObj, setDeptNmLbr, deptNmLbr }) => {
  return (
    <>
      <Grid.Column className='my-3' textAlign='justified' width={8}>
        Dept:
        <div>
          <select
            name='depts'
            onChange={e => {
              setDeptNmLbr(e.target.value === 'TOTAL' ? 'Total' : e.target.value);
            }}
            value={deptNmLbr}
            style={{ padding: 10 }}
            defaultValue={deptNmLbr}>
            <option value='TOTAL'>{'TOTAL **'}</option>
            {slsDepts.map(row => {
              return (
                row !== 'Total' && (
                  <option key={row} value={row}>
                    {row}
                  </option>
                )
              );
            })}
          </select>
        </div>
      </Grid.Column>
      <DataTable data={dataObj} />
    </>
  );
};

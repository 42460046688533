import { useState } from 'react';
import { MenuItems } from '../../../../../../../components/Common/MenuItems';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { getDirTasksDataTable } from '../../../utils/osa/sidekickskubay';

const SKU_BAY_OPTIONS = ['TOTAL', 'SKU', 'BAY'];
const [TOTAL, SKU] = SKU_BAY_OPTIONS;

export const DirTasksTab = props => {
  const { osaSidekickSkuBayData, sidekickExeScoresPlusOneData, level, subLevel, timeframe, ltf } = props;
  const [skuBaysOption, setSkuBaysOption] = useState('TOTAL');
  const isTotalSelected = skuBaysOption === TOTAL;
  const isSkuSelected = skuBaysOption === SKU;
  const dirTasksDataTable = getDirTasksDataTable({
    osaSidekickSkuBayData,
    sidekickExeScoresPlusOneData,
    level,
    subLevel,
    timeframe,
    ltf,
    isTotalSelected,
    isSkuSelected
  });

  return (
    <>
      <MenuItems
        activeItem={skuBaysOption}
        className='mt-3'
        dataTest='skuBaysOption'
        items={['TOTAL', 'SKU', 'BAY']}
        setItem={setSkuBaysOption}
      />

      <DataTable data={dirTasksDataTable} />
    </>
  );
};

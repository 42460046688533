import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../utils/string';
import { TOTAL, BOPIS, WILL_CALL, completePickUpSelectMethodOptions } from './consts';

export const completePickUpData = (view, level, completeData, timeframe) => {
  const isWTD = timeframe === 'WTD';
  const  bopisData  = completeData.filter(row => {
    return row['CHANNEL'] === 'BOPIS';
  });

  const  willCallData  = completeData.filter(row => {
    return row['CHANNEL'] === 'WILL CALL';
  });

  const totalData = completeData.filter(row => {
    return row['CHANNEL'] === 'BOPIS' || row['CHANNEL'] === 'WILL CALL';
  });

  switch (view) {
    case TOTAL:
      return {
        sortable: true,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Units Unfilfilled' },
          { name: 'NIS Units' },
          { name: '$ Unfulfilled' }
        ],
        data: totalData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
              }
            }
          },
          {
            pctValue: isWTD
              ? data['WTD_FULFILL_NUM'] / data['WTD_FULFILL_DEN']
              : data[timeframe + '_FULFILL_NUM'] / data[timeframe + '_FULFILL_PCT_DEN'],
            decimals: 2,
          },
          { numValue: data[timeframe + '_CNCL_NIS_NUM'] },
          { numValue: data[timeframe + '_CNCL_NIS_DEN'] },
          {
            dollarValue: data[timeframe + '_UNFULFILLED_TOTAL'],
            orderBy: data[timeframe + '_UNFULFILLED_TOTAL']
          }
        ])
      };
    case WILL_CALL:
      return {
        sortable: true,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Units Unfulfilled' }
        ],
        data: willCallData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
              }
            }
          },
          {
            pctValue: isWTD
              ? data['WTD_FULFILL_NUM'] / data['WTD_FULFILL_DEN']
              : data[timeframe + '_FULFILL_NUM'] / data[timeframe + '_FULFILL_PCT_DEN'],
            decimals: 2,
          },
          { numValue: data[timeframe + '_CNCL_NIS_NUM'] }
        ])
      };
    case BOPIS:
      return {
        sortable: true,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Units Unfilfilled' },
          { name: 'NIS Units' },
          { name: '$ Unfulfilled' }
        ],
        data: bopisData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
              }
            }
          },  
          {
            pctValue: isWTD
              ? data['WTD_FULFILL_NUM'] / data['WTD_FULFILL_DEN']
              : data[timeframe + '_FULFILL_NUM'] / data[timeframe + '_FULFILL_PCT_DEN'],
            decimals: 2,
          },
          { numValue: data[timeframe + '_CNCL_NIS_NUM'] },
          { numValue: data[timeframe + '_CNCL_NIS_DEN'] },
          {
            dollarValue: data[timeframe + '_UNFULFILLED_TOTAL'],
            orderBy: data[timeframe + '_UNFULFILLED_TOTAL']
          }
        ])
      };
    default:
      return {
        headers: [],
        data: []
      };
  }
};

export const selectMethodOptions = completePickUpSelectMethodOptions.map(completePickUpSelectMethodOption => ({
  key: completePickUpSelectMethodOption,
  text: completePickUpSelectMethodOption,
  value: completePickUpSelectMethodOption
}));

import { getCalloutStyle } from '../../../components/Common/TableCellUtils';
import { EMPTY_CELL } from '../../../constants/dataTable';

export const allClassesTabBuilder = ({ coreClasses, metric, unit }) => {
  return {
    data: coreClasses.map(data =>
      [
        {
          link: {
            to: `/core/sales/class`,
            params: {
              classNbr: data['EXT_CLASS_NBR']
            }
          },
          stringValue: data['MER_CLASS_NM']?.replaceAll('/', '/\u200b') // NAME VALUE COULD BE EMPTY, ADDED ? TO PREVENT CRASH
        },
        metric === 'SLS' && {
          customStyle: getCalloutStyle(data['TY_SLS_AMT']),
          ...formatCellValue({ value: data['TY_SLS_AMT'], dollarValue: true })
        },
        metric === 'SLS' &&
          unit === 'NOM' && {
            ...formatCellValue({ value: data['SLS_VP_NOM'], dollarValue: true, compareStyle: true })
          },
        metric === 'SLS' &&
          unit === 'PCT' && {
            ...formatCellValue({ value: data['SLS_VP_PCT'], pctValue: true, compareStyle: true, decimals: 2 })
          },
        metric === 'SLS' &&
          unit === 'NOM' && {
            ...formatCellValue({ value: data['SLS_COMP_NOM'], dollarValue: true, compareStyle: true })
          },
        metric === 'SLS' &&
          unit === 'PCT' && {
            ...formatCellValue({ value: data['SLS_COMP_PCT'], pctValue: true, compareStyle: true, decimals: 2 })
          },
        metric === 'UNT' && {
          ...formatCellValue({ value: data['TY_UNT_SLS'], stringValue: true, compareStyle: true })
        },
        metric === 'UNT' &&
          unit === 'NOM' && {
            ...formatCellValue({ value: data['UNT_COMP_NOM'], stringValue: true, compareStyle: true })
          },
        metric === 'UNT' &&
          unit === 'PCT' && {
            ...formatCellValue({ value: data['UNT_COMP_PCT'], pctValue: true, compareStyle: true, decimals: 2 })
          },
        metric === 'TXN' && {
          ...formatCellValue({ value: data['TY_TXN_CNT'], stringValue: true, compareStyle: true })
        },
        metric === 'TXN' &&
          unit === 'NOM' && {
            ...formatCellValue({ value: data['TXN_COMP_NOM'], dollarValue: true, compareStyle: true })
          },
        metric === 'TXN' &&
          unit === 'PCT' && {
            ...formatCellValue({ value: data['TXN_COMP_PCT'], pctValue: true, compareStyle: true, decimals: 2 })
          },
        metric === 'AVG_TCKT' && {
          ...formatCellValue({ value: data['TY_AVG_TCKT'], dollarValue: true, compareStyle: true, decimals: 2 })
        },
        metric === 'AVG_TCKT' &&
          unit === 'NOM' && {
            ...formatCellValue({ value: data['AVG_TCKT_COMP_NOM'], dollarValue: true, compareStyle: true, decimals: 2 })
          },
        metric === 'AVG_TCKT' &&
          unit === 'PCT' && {
            ...formatCellValue({ value: data['AVG_TCKT_COMP_PCT'], pctValue: true, compareStyle: true, decimals: 2 })
          }
      ].filter(Boolean)
    ), // Filter out falsy values
    headers: [
      { name: 'Class ' },
      metric === 'SLS' && { name: 'Sales Amt ($) ' },
      metric === 'SLS' && unit === 'NOM' && { name: 'Sales vP ($) ' },
      metric === 'SLS' && unit === 'PCT' && { name: 'Sales vP (%) ' },
      metric === 'SLS' && unit === 'NOM' && { name: 'Sales vLY ($) ' },
      metric === 'SLS' && unit === 'PCT' && { name: 'Sales vLY (%) ' },
      metric === 'UNT' && { name: 'Unit Sales (#) ' },
      metric === 'UNT' && unit === 'NOM' && { name: 'Units vLY (#) ' },
      metric === 'UNT' && unit === 'PCT' && { name: 'Units vLY (%) ' },
      metric === 'TXN' && { name: 'Trans Cnt (#) ' },
      metric === 'TXN' && unit === 'NOM' && { name: 'Trans vLY (#) ' },
      metric === 'TXN' && unit === 'PCT' && { name: 'Trans vLY (%) ' },
      metric === 'AVG_TCKT' && { name: 'Avg Ticket ($) ' },
      metric === 'AVG_TCKT' && unit === 'NOM' && { name: 'Avg Ticket vLY ($) ' },
      metric === 'AVG_TCKT' && unit === 'PCT' && { name: 'Avg Ticket vLY (%) ' }
    ].filter(Boolean), // Filter out falsy values
    sortable: true,
    sortableDefault: 0
  };
};

export const buildClassNameColumn = (className, classNbr, to = '/core/sales/class') => ({
  link: {
    to,
    params: { classNbr }
  },
  stringValue: className.replaceAll('/', '/\u200b')
});

export const buildUnitValue = value => {
  if (!value && value !== 0) return EMPTY_CELL;

  return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const formatCellValue = ({
  value,
  decimals = 0,
  dollarValue = false,
  stringValue = false,
  compareStyle = false,
  pctValue = false
}) => ({
  dollarValue: dollarValue ? value : undefined,
  stringValue: stringValue
    ? value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    : undefined,
  pctValue: pctValue ? value : undefined,
  orderBy: value,
  decimals,
  compareStyle
});

export const unitCellBuilder = (unit, dollarValue, pctValue) => (unit === 'NOM' ? { dollarValue } : { pctValue });

export const salesByDepartmentRowBuilder = (row, timeframe, unit) => {
  return [
    {
      link: {
        to: `/core/sales/dept`,
        params: {
          deptNbr: row['DEPT_NBR']
        }
      },
      stringValue: row['MER_DEPT_NM']
    },
    {
      dollarValue: row[`${timeframe}_SLS_AMT_ACT`],
      orderBy: row[`${timeframe}_SLS_AMT_ACT`]
    },
    {
      compareStyle: true,
      ...unitCellBuilder(unit, row[`${timeframe}_SLS_VP`], row[`${timeframe}_SLS_AMT_VP_PCT`])
    },
    {
      compareStyle: true,
      ...unitCellBuilder(unit, row[`${timeframe}_SLS_AMT_VLY`], row[`${timeframe}_SLS_AMT_VLY_PCT`])
    }
  ];
};

export const CLASSES_METRIC_OPTIONS = [
  { key: 'SLS', text: 'Sales', value: 'SLS' },
  { key: 'UNT', text: 'Units', value: 'UNT' },
  { key: 'TXN', text: 'Trans', value: 'TXN' },
  { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' }
];

export const CLASSES_DEPARTMENT_OPTIONS = [
  { key: 'All', text: 'All', value: 'All' },
  { key: '21', text: '21', value: '21' },
  { key: '22', text: '22', value: '22' },
  { key: '23', text: '23', value: '23' },
  { key: '24', text: '24', value: '24' },
  { key: '25', text: '25', value: '25' },
  { key: '26', text: '26', value: '26' },
  { key: '27', text: '27', value: '27' },
  { key: '28', text: '28', value: '28' },
  { key: '29', text: '29', value: '29' },
  { key: '30', text: '30', value: '30' },
  { key: '59', text: '59', value: '59' },
  { key: '78', text: '78', value: '78' }
];

export const DEPARTMENTS_HEADER_TABS = [{ name: 'DEPT' }, { name: 'Actual' }, { name: 'vP' }, { name: 'vLY' }];

export const SUBCLASS_HEADERS_BY_METRIC = {
  SLS: [{ name: 'Sales Amt ($)' }, { name: 'Sales vLY ($)' }, { name: 'Sales vLY (%)' }],
  UNT: [{ name: 'Unit Sales #' }, { name: 'Units vLY #' }, { name: 'Units vLY %' }],
  TXN: [{ name: 'Trans Cnt #' }, { name: 'Trans vLY #' }, { name: 'Trans vLY %' }],
  AVG_TCKT: [{ name: 'Avg Ticket ($)' }, { name: 'Avg Ticket vLY ($)' }, { name: 'Avg Ticket vLY (%)' }]
};

export const propsByMetric = {
  SLS: ['TY_SLS_AMT', 'SLS_COMP_NOM', 'SLS_COMP_PCT'],
  UNT: ['TY_UNT_SLS', 'UNT_COMP_NOM', 'UNT_COMP_PCT'],
  TXN: ['TY_TXN_CNT', 'TXN_COMP_NOM', 'TXN_COMP_PCT'],
  AVG_TCKT: ['TY_AVG_TCKT', 'AVG_TCKT_COMP_NOM', 'AVG_TCKT_COMP_PCT']
};

export const SKUS_TABLE_HEADER_NOM = {
  SLS: [{ name: 'Sales Amt ($)' }, { name: 'Sales vLY ($)' }],
  UNT: [{ name: 'Unit Sales (#)' }, { name: 'Units vLY (#)' }],
  TXN: [{ name: 'Trans Cnt #' }, { name: 'Trans vLY #' }],
  AVG_TCKT: [{ name: 'Avg Ticket ($)' }, { name: 'Avg Ticket vLY ($)' }]
};

export const SKUS_TABLE_HEADER_PCT = {
  SLS: [{ name: 'Sales Amt ($)' }, { name: 'Sales vLY (%)' }],
  UNT: [{ name: 'Unit Sales (#)' }, { name: 'Units vLY (%)' }],
  TXN: [{ name: 'Trans Cnt (#)' }, { name: 'Trans vLY (%)' }],
  AVG_TCKT: [{ name: 'Avg Ticket ($)' }, { name: 'Avg Ticket vLY (%)' }]
};

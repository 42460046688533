import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { divide } from '../../../utils/numbers';
import { capitalizeFirstLetter } from '../../../utils/string';
import { TOTAL, VAN, FLATBED, CAR, BOX, OTHER, completePickUpSelectTruckTypeOptions } from './consts';

export const completeDeliveryData = (view, level, completeDeliveryData, timeframe) => {
  switch (view) {
    case TOTAL:
    case VAN:
    case FLATBED:
    case CAR:
    case BOX:
    case OTHER:
      return {
        sortable: true,
        sortableDefault: 0,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Push %' },
          { name: 'Truck Changes' },
          { name: 'Unfulfilled Units' }
        ],
        data: completeDeliveryData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
              }
            }
          },
          {
            // fulfillment % is fulfilled units / (fulfilled + cancelled units)
            pctValue: divide(data[timeframe + '_FULFIL_QTY'], (data[timeframe + '_FULFIL_QTY'] + data[timeframe + '_CANCELED_QTY'])),
            decimals: 2,
            orderBy: divide(data[timeframe + '_FULFIL_QTY'], (data[timeframe + '_FULFIL_QTY'] + data[timeframe + '_CANCELED_QTY']))
          },
          {
            // push % should be push count / total count.
            pctValue: divide(data[timeframe + '_PP_MANIPULATED_NUM'], data[timeframe + '_WORK_ORDER_NO']),
            decimals: 2,
            orderBy: divide(data[timeframe + '_PP_MANIPULATED_NUM'], data[timeframe + '_WORK_ORDER_NO'])
          },
          {
            // trucks changes is count of truck changes
            numValue: data[timeframe + '_MANIPULATED_NUM'],
            orderBy: data[timeframe + '_MANIPULATED_NUM']
          },
          {
            numValue: data[timeframe + '_CANCELED_QTY'],
            orderBy: data[timeframe + '_CANCELED_QTY']
          }
        ])
      };
    default:
      return {
        headers: [],
        data: []
      };
  }
};

export const selectTruckTypeOptions = completePickUpSelectTruckTypeOptions.map(completePickUpSelectTruckTypeOption => ({
  key: completePickUpSelectTruckTypeOption,
  text: completePickUpSelectTruckTypeOption,
  value: completePickUpSelectTruckTypeOption
}));

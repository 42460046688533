import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { SmdComparison } from '../../components/smd/SmdComparison';
import { SmdReasonCodes } from '../../components/smd/SmdReasonCodes';
import { SmdDepartaments } from '../../components/smd/SmdDepartaments';
import { TabMenu } from '../../../../../../components/Common/TabMenu';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';

const { core: level } = METRIC_CONSTANTS;

export const SmdDetailPage = ({ fpToggle, settimeframe, timeframe }) => {
  const divisionSmdComparison = useQueryApi('queryDivisionSmdComparisonAtCore', { isCore: true, fpToggle });
  const smdDepartments = useQueryApi('querySmdDepartmentsAtCore', { isCore: true, fpToggle });
  const smdReasonCodes = useQueryApi('querySmdReasonCodesAtCore', { isCore: true, fpToggle });

  if (divisionSmdComparison.isLoading || smdDepartments.isLoading || smdReasonCodes.isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  if (divisionSmdComparison.isError || smdDepartments.isError || smdReasonCodes.isError) {
    return <Header textAlign='center'>Something went wrong fetching data.</Header>;
  }

  const tabs = [
    {
      menuItem: 'Division List',
      render: () => <SmdComparison level={level} data={divisionSmdComparison.data} timeframe={timeframe} />
    },
    {
      menuItem: 'Depts',
      render: () => <SmdDepartaments data={smdDepartments.data} timeframe={timeframe} />
    },
    {
      menuItem: 'Reason Codes',
      render: () => <SmdReasonCodes data={smdReasonCodes.data} timeframe={timeframe} />
    }
  ];

  const dataAsOf = divisionSmdComparison.data[0]?.TRNSFM_DT;

  return (
    <div>
      <Header className='mb-1 text-capitalize' textAlign='left'>
        SMD - Core
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>
      <br />

      <span>Timeframe:</span>
      <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />

      <TabMenu className='mt-4' panes={tabs} />
    </div>
  );
};

import { createContext } from 'react';
import { useOverviewQueries } from '../hooks/useOverviewQueries';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';

const { core } = METRIC_CONSTANTS;
export const CoreContext = createContext();

const CoreContextProvider = ({ children, fpToggle, metric, setMetric, levelName, ...props }) => {
  const {
    isError,
    isLoading,
    salesClassesData,
    salesDeptData,
    salesMetricsData,
    salesSafetyByDivisionData,
    salesSkusData,
    salesSubClassesData,
    swmDetailsData,
    SafetyIncOshaLtGlbiData,
    InvDepByCore,
    isErrorInvDepByCore,
    isLoadingInvDepByCore
  } = useOverviewQueries(fpToggle);

  const value = {
    ...props,
    level: core,
    metric,
    setMetric,
    isError,
    isLoading,
    salesClassesData,
    salesDeptData,
    salesMetricsData,
    salesSafetyByDivisionData,
    salesSkusData,
    salesSubClassesData,
    swmDetailsData,
    SafetyIncOshaLtGlbiData,
    InvDepByCore,
    isErrorInvDepByCore,
    isLoadingInvDepByCore
  };

  return <CoreContext.Provider value={value}>{children}</CoreContext.Provider>;
};

export default CoreContextProvider;

import { useCreditContext } from '../hooks/useCreditContext';
import { useEnhancedQueries } from '../../../../hooks/useEnhancedQueries';
import {
  buildHeaders as buildApplicationHeaders,
  buildRows as buildApplicationRows
} from '../utils/applicationByLevelTable';
import { buildHeaders as buildUsageHeaders, buildRows as buildUsageRows } from '../utils/usageByLevelTable';
import { Tab } from 'semantic-ui-react';
import { DataView } from '../../../Common/DataView';

export const ApplicationByLevelTab = ({ isCommercial, isConsumer, isTotal }) => {
  const {
    fpToggle,
    level: {
      levelName,
      subLevel: { levelName: subLevelName }
    },
    timeframe
  } = useCreditContext();
  const {
    results: [{ data: creditPerfData }, { data: creditSalesPenData }]
  } = useEnhancedQueries(['queryCreditPerfBySublevel', 'queryCreditSlsPenBySublevel'], fpToggle);

  const dataApplicationsObj = {
    data: buildApplicationRows({ data: creditPerfData, level: levelName, isCommercial, isConsumer, isTotal, timeframe }),
    headers: buildApplicationHeaders({ isTotal, level: subLevelName }),
    sortable: true,
    sortableDefault: 0
  };

  const dataUsageObj = {
    data: buildUsageRows({ data: creditSalesPenData, isCommercial, isConsumer, isTotal, level: levelName, timeframe }),
    headers: buildUsageHeaders(subLevelName),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane>
      <DataView>
        <DataView.Title>Applications by {subLevelName}</DataView.Title>

        <DataView.DataTable data={dataApplicationsObj} />
      </DataView>

      <DataView className='mt-4'>
        <DataView.Title>Usage by {subLevelName}</DataView.Title>

        <DataView.DataTable data={dataUsageObj} />
      </DataView>
    </Tab.Pane>
  );
};

ApplicationByLevelTab.propTypes = {
  isCommercial: validateAtLeastOneProp,
  isConsumer: validateAtLeastOneProp,
  isTotal: validateAtLeastOneProp
};

function validateAtLeastOneProp(props, _, componentName) {
  const propsArr = [props.isCommercial, props.isConsumer, props.isTotal];

  if (propsArr.every(prop => prop === undefined)) {
    return new Error(`One of 'isCommercial', 'isConsumer' or 'isTotal' must be provided to '${componentName}'`);
  }
}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UnitMenu } from '../Common/UnitMenu';
import { Grid, Header, Table } from 'semantic-ui-react';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { VOC_GET_GOAL, VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../constants/goals';
import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils';

export const StoreListTab = ({
  daysSafeStrSorted,
  dstNbr,
  metric,
  metric2PathExtn,
  metric2SortColumn,
  setMetric,
  strMetricsSorted,
  settimeframe,
  timeframe,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  sortColumnDaysSafe,
  setSortColumnDaysSafe,
  sortDirectionDaysSafe,
  setSortDirectionDaysSafe
}) => {
  const [unit, setUnit] = useState('NOM');

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column width={8} />
        <Grid.Column width={8}>
          Metric:
          <div>
            <select
              name='metric'
              id='metric'
              onChange={e => {
                setMetric(e.target.value);
              }}
              value={metric}
              style={{ padding: 10 }}>
              <option value='sales'>Sales</option>
              <option value='units'>Units</option>
              <option value='transactions'>Transactions</option>
              <option value='average_ticket'>Average Ticket</option>
              <option value='units_per_basket'>Units per Basket</option>
              <option value='days_safe'>Days Safe</option>
              <option value='smd'>SMD</option>
              <option value='labor'>Labor</option>
              <option value='get'>GET</option>
              <option value='ltsa'>LTSA</option>
              <option value='ltpa'>LTPA</option>
              <option value='leads'>Leads</option>
              <option value='measures'>Measures</option>
              <option value='HDPP - MA UA'>HDPP - MA UA</option>
              <option value='HDPP - GM UA'>HDPP - GM UA</option>
            </select>
          </div>
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>
        District #{dstNbr} - {metric} By Store
      </Header>

      {metric === 'days_safe' && (
        <Table textAlign='center' celled unstackable sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumnDaysSafe === 'STR_NBR' ? sortDirectionDaysSafe : null}
                onClick={() => {
                  if (sortColumnDaysSafe !== 'STR_NBR') {
                    setSortColumnDaysSafe('STR_NBR');
                    setSortDirectionDaysSafe('ascending');
                  } else {
                    sortDirectionDaysSafe === 'ascending'
                      ? setSortDirectionDaysSafe('descending')
                      : setSortDirectionDaysSafe('ascending');
                  }
                }}>
                Store #
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumnDaysSafe === 'DAYS_SAFE' ? sortDirectionDaysSafe : null}
                onClick={() => {
                  if (sortColumnDaysSafe !== 'DAYS_SAFE') {
                    setSortColumnDaysSafe('DAYS_SAFE');
                    setSortDirectionDaysSafe('ascending');
                  } else {
                    sortDirectionDaysSafe === 'ascending'
                      ? setSortDirectionDaysSafe('descending')
                      : setSortDirectionDaysSafe('ascending');
                  }
                }}>
                Days Safe
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {daysSafeStrSorted.map(data => {
              return (
                <Table.Row>
                  <Table.Cell>STR {data.STR_NBR}</Table.Cell>
                  <Table.Cell>{data.DAYS_SAFE}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {metric !== 'days_safe' && (
        <Table textAlign='center' celled unstackable sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'STR_NBR') {
                    setSortColumn('STR_NBR');
                    setSortDirection('ascending');
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                  }
                }}>
                Store #
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === timeframe + metric2SortColumn[metric][0] ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + metric2SortColumn[metric][0]) {
                    setSortColumn(timeframe + metric2SortColumn[metric][0]);
                    setSortDirection('ascending');
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                  }
                }}>
                Actual
              </Table.HeaderCell>
              {unit === 'NOM' && (
                <Table.HeaderCell
                  sorted={sortColumn === timeframe + metric2SortColumn[metric][1] ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== timeframe + metric2SortColumn[metric][1]) {
                      setSortColumn(timeframe + metric2SortColumn[metric][1]);
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  vP/vF
                </Table.HeaderCell>
              )}
              {unit === 'PCT' && (
                <Table.HeaderCell
                  sorted={sortColumn === timeframe + metric2SortColumn[metric][2] ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== timeframe + metric2SortColumn[metric][2]) {
                      setSortColumn(timeframe + metric2SortColumn[metric][2]);
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  vP/vF %
                </Table.HeaderCell>
              )}
              {unit === 'NOM' && (
                <Table.HeaderCell
                  sorted={sortColumn === timeframe + metric2SortColumn[metric][3] ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== timeframe + metric2SortColumn[metric][3]) {
                      setSortColumn(timeframe + metric2SortColumn[metric][3]);
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  vLY
                </Table.HeaderCell>
              )}
              {unit === 'PCT' && (
                <Table.HeaderCell
                  sorted={sortColumn === timeframe + metric2SortColumn[metric][4] ? sortDirection : null}
                  onClick={() => {
                    if (sortColumn !== timeframe + metric2SortColumn[metric][4]) {
                      setSortColumn(timeframe + metric2SortColumn[metric][4]);
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  vLY %
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {strMetricsSorted.map(data => {
              return (
                <Table.Row>
                  {/* <Table.Cell>{data['STR_NBR']}</Table.Cell> */}
                  <Table.Cell>
                    <Link
                      style={{ color: '#EE7125', textDecoration: 'underline' }}
                      to={`/store/${metric2PathExtn[metric]}?strNbr=${data['STR_NBR'].toString().padStart(4, '0')}`}>
                      {'STR ' + data['STR_NBR']}
                    </Link>
                  </Table.Cell>
                  {metric === 'sales' && (
                    <Table.Cell singleLine>{getDollarString(data[timeframe + '_SLS_AMT_ACT'])}</Table.Cell>
                  )}
                  {metric === 'sales' && unit === 'NOM' && (
                    <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_VP'])}>
                      {getDollarString(data[timeframe + '_SLS_VP'])}
                    </Table.Cell>
                  )}
                  {metric === 'sales' && unit === 'PCT' && (
                    <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VP_PCT'])}>
                      {getPercentString(data[timeframe + '_SLS_AMT_VP_PCT'])}
                    </Table.Cell>
                  )}
                  {metric === 'sales' && unit === 'NOM' && (
                    <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY'])}>
                      {getDollarString(data[timeframe + '_SLS_AMT_VLY'])}
                    </Table.Cell>
                  )}
                  {metric === 'sales' && unit === 'PCT' && (
                    <Table.Cell singleLine style={getCompareStyle(data[timeframe + '_SLS_AMT_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_SLS_AMT_VLY_PCT'], 3)}
                    </Table.Cell>
                  )}
                  {metric === 'units' && (
                    <Table.Cell>
                      {data[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'units' && <Table.Cell>-</Table.Cell>}
                  {metric === 'units' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_UNTS_VLY'])}>
                      {data[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'units' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_UNTS_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_UNTS_VLY_PCT'])}
                    </Table.Cell>
                  )}
                  {metric === 'transactions' && (
                    <Table.Cell>
                      {data[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'transactions' && <Table.Cell>-</Table.Cell>}
                  {metric === 'transactions' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_TRNS_VLY'])}>
                      {data[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'transactions' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_TRNS_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_TRNS_VLY_PCT'])}
                    </Table.Cell>
                  )}
                  {metric === 'average_ticket' && (
                    <Table.Cell>{getDollarString(data[timeframe + '_AVG_TCKT_ACT'], 2)}</Table.Cell>
                  )}
                  {metric === 'average_ticket' && <Table.Cell>-</Table.Cell>}
                  {metric === 'average_ticket' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_AVG_TCKT_VLY'])}>
                      {getDollarString(data[timeframe + '_AVG_TCKT_VLY'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'average_ticket' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_AVG_TCKT_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_AVG_TCKT_VLY_PCT'], 3)}
                    </Table.Cell>
                  )}
                  {metric === 'units_per_basket' && (
                    <Table.Cell>
                      {(data['TY_' + timeframe + '_UNT_SLS'] / data['TY_' + timeframe + '_TXN_CNT']).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2 }
                      )}
                    </Table.Cell>
                  )}
                  {metric === 'units_per_basket' && <Table.Cell>-</Table.Cell>}
                  {metric === 'units_per_basket' && unit === 'NOM' && (
                    <Table.Cell
                      style={getCompareStyle(
                        data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                          data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']
                      )}>
                      {(
                        data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                        data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']
                      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </Table.Cell>
                  )}
                  {metric === 'units_per_basket' && unit === 'PCT' && (
                    <Table.Cell
                      style={getCompareStyle(
                        data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                          data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']
                      )}>
                      {getPercentString(
                        (data['TY_' + timeframe + '_COMP_UNT_SLS'] / data['TY_' + timeframe + '_COMP_TXN_CNT'] -
                          data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']) /
                          (data['LY_' + timeframe + '_COMP_UNT_SLS'] / data['LY_' + timeframe + '_COMP_TXN_CNT']),
                        2
                      )}
                    </Table.Cell>
                  )}
                  {metric === 'smd' && <Table.Cell>{getPercentString(data[timeframe + '_SMD_ACT'], 2)}</Table.Cell>}
                  {metric === 'smd' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(-1 * data[timeframe + '_SMD_VP'])}>
                      {data[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                    </Table.Cell>
                  )}
                  {metric === 'smd' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                  {metric === 'smd' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(-1 * data[timeframe + '_SMD_VLY'])}>
                      {data[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
                    </Table.Cell>
                  )}
                  {metric === 'smd' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}
                  {metric === 'labor' && (
                    <Table.Cell>
                      {data[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
                    </Table.Cell>
                  )}
                  {metric === 'labor' && unit === 'NOM' && (
                    <Table.Cell>
                      {data[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
                    </Table.Cell>
                  )}
                  {metric === 'labor' && unit === 'PCT' && (
                    <Table.Cell
                      style={{
                        color:
                          data[timeframe + '_LBR_VF_PCT'] > 0.01 || data[timeframe + '_LBR_VF_PCT'] < -0.01 ? 'red' : 'green'
                      }}>
                      {getPercentString(data[timeframe + '_LBR_VF_PCT'])}
                    </Table.Cell>
                  )}
                  {metric === 'labor' && <Table.Cell>-</Table.Cell>}
                  {metric === 'get' && (
                    <Table.Cell style={{ color: data[timeframe + '_GET_ACT'] >= VOC_GET_GOAL ? 'green' : 'red' }}>
                      {getPercentString(data[timeframe + '_GET_ACT'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'get' && <Table.Cell>-</Table.Cell>}
                  {metric === 'get' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_GET_VLY'])}>
                      {getPercentString(data[timeframe + '_GET_VLY'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'get' && unit === 'PCT' && <Table.Cell>-</Table.Cell>}

                  {metric === 'ltsa' && (
                    <Table.Cell style={{ color: data[timeframe + '_LTSA_ACT'] >= VOC_LTSA_GOAL ? 'green' : 'red' }}>
                      {getPercentString(data[timeframe + '_LTSA_ACT'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'ltsa' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LTSA_VP'])}>
                      {getPercentString(data[timeframe + '_LTSA_VP'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'ltsa' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LTSA_VP_PCT'])}>
                      {getPercentString(data[timeframe + '_LTSA_VP_PCT'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'ltsa' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LTSA_VLY'])}>
                      {getPercentString(data[timeframe + '_LTSA_VLY'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'ltsa' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LTSA_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_LTSA_VLY_PCT'], 2)}
                    </Table.Cell>
                  )}

                  {metric === 'ltpa' && (
                    <Table.Cell
                      style={{
                        color:
                          data[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
                            ? 'green'
                            : data[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                              ? 'red'
                              : 'black'
                      }}>
                      {data[timeframe + '_ONLINE_SAT_ACT'] != null
                        ? getPercentString(data[timeframe + '_ONLINE_SAT_ACT'], 2)
                        : '-'}
                    </Table.Cell>
                  )}
                  {metric === 'ltpa' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_ONLINE_SAT_VP'])}>
                      {getPercentString(data[timeframe + '_ONLINE_SAT_VP'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'ltpa' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_ONLINE_SAT_VP_PCT'])}>
                      {getPercentString(data[timeframe + '_ONLINE_SAT_VP_PCT'], 2)}
                    </Table.Cell>
                  )}
                  {metric === 'ltpa' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_ONLINE_SAT_VLY'])}>
                      {data[timeframe + '_ONLINE_SAT_VLY'] != null
                        ? getPercentString(data[timeframe + '_ONLINE_SAT_VLY'], 2)
                        : '-'}
                    </Table.Cell>
                  )}
                  {metric === 'ltpa' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_ONLINE_SAT_VLY_PCT'])}>
                      {data[timeframe + '_ONLINE_SAT_VLY_PCT'] != null
                        ? getPercentString(data[timeframe + '_ONLINE_SAT_VLY_PCT'], 2)
                        : '-'}
                    </Table.Cell>
                  )}

                  {metric === 'leads' && (
                    <Table.Cell>
                      {data[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'leads' && <Table.Cell>-</Table.Cell>}
                  {metric === 'leads' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LEADS_VLY'])}>
                      {data[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'leads' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_LEADS_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_LEADS_VLY_PCT'])}
                    </Table.Cell>
                  )}
                  {metric === 'measures' && (
                    <Table.Cell>
                      {data[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'measures' && <Table.Cell>-</Table.Cell>}
                  {metric === 'measures' && unit === 'NOM' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_MEAS_VLY'])}>
                      {data[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Table.Cell>
                  )}
                  {metric === 'measures' && unit === 'PCT' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_MEAS_VLY_PCT'])}>
                      {getPercentString(data[timeframe + '_MEAS_VLY_PCT'])}
                    </Table.Cell>
                  )}
                  {metric === 'HDPP - MA UA' && <Table.Cell>{getPercentString(data[timeframe + '_MA_HDPP'])}</Table.Cell>}
                  {metric === 'HDPP - MA UA' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_MA_HDPP_VG'])}>
                      {getPercentString(data[timeframe + '_MA_HDPP_VG'])}
                    </Table.Cell>
                  )}
                  {metric === 'HDPP - MA UA' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_MA_HDPP_VLY'])}>
                      {getPercentString(data[timeframe + '_MA_HDPP_VLY'])}
                    </Table.Cell>
                  )}
                  {metric === 'HDPP - GM UA' && <Table.Cell>{getPercentString(data[timeframe + '_GM_HDPP'])}</Table.Cell>}
                  {metric === 'HDPP - GM UA' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_GM_HDPP_VG'])}>
                      {getPercentString(data[timeframe + '_GM_HDPP_VG'])}
                    </Table.Cell>
                  )}
                  {metric === 'HDPP - GM UA' && (
                    <Table.Cell style={getCompareStyle(data[timeframe + '_GM_HDPP_VG'])}>
                      {getPercentString(data[timeframe + '_GM_HDPP_VLY'])}
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

import { buildBaseDrillLink } from "../../../../../components/ShrinkContainer/utils/url";
import { formatLevelNumber } from "../../../../../utils/level";
import { getCompareStyle } from "../../../../../components/Common/TableCellUtils";
import { getRowLevelNm } from "../../../../../utils/getRowLevelNm";

export const executionScoreByDivisionTableBuilder = (data) => ({
  data: data.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink('core');
    const vLYValue = row.SHRINK_EXEC_SCORE - row.LY_SHRINK_EXEC_SCORE;
    const level = 'core';
    return [
      {
        link: {
          to: `/${baseLevelShrinkUrl}`,
          params: {
            divNbr: formatLevelNumber(row.DIV_NBR)
          }
        },
        stringValue: getRowLevelNm({row, level} )
      },
      {
        pctValue: row.SHRINK_EXEC_SCORE
      },
      {
        customStyle: getCompareStyle(vLYValue),
        pctValue: vLYValue
      }
    ];
  }),
  headers: [{ name: 'Division' }, { name: 'Total Score %' }, { name: 'vLY%' }],
  sortable: true,
  sortableDefault: 0
});

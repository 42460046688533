import React from 'react';
import { Tab } from 'semantic-ui-react';
import { BopisComplete } from './assoc/BopisComplete';
import { WillCallComplete } from './assoc/WillCallComplete';
import { PickUpDetailsMethod } from './PickUpDetailsMethod';
import { DataView } from '../../../../../../components/Common/DataView';

const panes = [
  {
    menuItem: 'BOPIS',
    render: () => <BopisComplete />
  },
  {
    menuItem: 'WILL CALL',
    render: () => <WillCallComplete />
  }
];

export const CompletePickup = () => {
  return (
    <DataView className='my-4'>
      <PickUpDetailsMethod/>
      <DataView.Title>More Details</DataView.Title>
      <Tab menu={{ compact: true, size: 'small' }} panes={panes} />
    </DataView>
  );
};
import { createObjectSchema } from '../utils/channelAssociateTableBuilder';
import { useCreditContext } from '../hooks/useCreditContext';
import { useEnhancedQueries } from '../../../../hooks/useEnhancedQueries';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../Common/DataTable';
import { PlaceholderLoading } from '../../../Loading/PlaceholderLoading';

export const ChannelDataTable = () => {
  const { fpToggle, timeframe } = useCreditContext();
  const {
    isLoading,
    isError,
    results: [{ data: creditChannellByLvlData = [] }]
  } = useEnhancedQueries(['creditChannelByLvl'], fpToggle);

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading content='Loading Associates...' />;

  const associateObjectSchema = createObjectSchema(creditChannellByLvlData, timeframe, true);

  return <DataTable data={associateObjectSchema} />;
};

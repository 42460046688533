import { useState } from "react";
import { DataView } from "../../../../../../../components/Common/DataView";
import { capitalizeFirstLetter } from "../../../../../../../utils/string";
import { CustomDropDown } from "../../CustomDropdown";
export const TruckChanges = ({deliveryData = []}) => {

  const [view, setView] = useState('Changed Work Orders');
  const [timeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['Changed Work Orders'];
  const filterByTimeframe = deliveryData.filter((data)=>{return data[timeframe+'_FLG'] === 1});
  
  const data = {
    headers: [
      { name: 'Associate Name' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Original Truck' },
      { name: 'New Truck' },
      { name: 'Change Date' }
    ],
    data: filterByTimeframe.map(data => [
      { stringValue: capitalizeFirstLetter(data.ASSOC_NAME) },
      { stringValue: data.CUST_ORD_NBR },
      { numValue: data.WORK_ORD_NBR },
      { stringValue: data.INTIAL_TRUCK_TYPE ? data.INTIAL_TRUCK_TYPE : '-' },
      { dollarValue: data.ACTUAL_TRUCK_TYPE },
      { numValue: data.TRNSFM_DT }
    ]),
    pagination: true
  };

  return (
    <DataView className='my-4'>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Changed Work Orders', text: 'Changed Work Orders', value: 'Changed Work Orders' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataView.DataTable data={data} />
    </DataView>
  );
}
import React from 'react';
import { Tab } from 'semantic-ui-react';
import { CompletePickup } from './CompletePickup';
import { CompleteDelivery } from './CompleteDelivery';
import { DataView } from '../../../../../../components/Common/DataView';

export const Complete = () => {
  const tabs = [
    {
      menuItem: 'PICKUP',
      render: () => <CompletePickup />
    },
    {
      menuItem: 'DELIVERY',
      render: () => <CompleteDelivery />
    }
  ];

  return (
    <DataView className='my-4'>
      
      <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />
    </DataView>
  );
};

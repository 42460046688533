import useQueryApi from '../../../hooks/useQueryApi';
import { STORE, CORE } from '../../../utils/constantsByLevel';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';

const { levelName: coreLevelName } = CORE;
const { levelName: storeLevelName } = STORE;

export const useSpecialtyProRentalQueries = () => {
  const { fpToggle, level, locNbr } = useSpecialtyProRentalContext();
  const { levelName } = level;
  const queryParamsByLevel = levelName === coreLevelName ? { isCore: true } : { locNbr };
  const queryParams = { ...queryParamsByLevel, fpToggle };

  const {
    data: quotesAggData,
    isLoading: quotesAggIsLoading,
    isError: quotesAggIsError
  } = useQueryApi(`query/queryQuotesAgg/${levelName}`, queryParams);

  const {
    data: proSpecTrLbrData,
    isLoading: proSpecTrLbrIsLoading,
    isError: proSpecTrLbrIsError
  } = useQueryApi(`query/queryProSpecTrLbr/${levelName}`, queryParams);

  const {
    data: queryProSpecTrLbrPlusOneData,
    isLoading: queryProSpecTrLbrPlusOneIsLoading,
    isError: queryProSpecTrLbrPlusOneIsError
  } = useQueryApi(`query/queryProSpecTrLbrPlusOne/${levelName}`, queryParams);

  const {
    data: specialtyKeyMetricsContData,
    isLoading: specialtyKeyMetricsContIsLoading,
    isError: specialtyKeyMetricsContIsError
  } = useQueryApi(`query/querySpecialtyKeyMetricsCont/${levelName}`, {
    ...queryParams,
    enabled: levelName !== storeLevelName
  });

  const {
    data: specialtyKeyMetricsData,
    isLoading: specialtyKeyMetricsIsLoading,
    isError: specialtyKeyMetricsIsError
  } = useQueryApi(`query/querySpecOpsPlusOne/${levelName}`, { ...queryParams, enabled: levelName !== storeLevelName });

  const {
    data: specialtyKeyMetricsStrData,
    isLoading: specialtyKeyMetricsStrIsLoading,
    isError: specialtyKeyMetricsStrIsError
  } = useQueryApi(`querySpecOpsByStr`, { ...queryParams, enabled: storeLevelName === levelName });

  const {
    data: proOpsData,
    isLoading: proOpsIsLoading,
    isError: proOpsIsError
  } = useQueryApi(`query/queryProOps/${levelName}`, queryParams);

  const {
    data: queryProOpsPlusOneData,
    isLoading: queryProOpsPlusOneIsLoading,
    isError: queryProOpsPlusOneIsError
  } = useQueryApi(`query/queryProOpsPlusOne/${levelName}`, queryParams);

  const {
    data: trOpsData,
    isLoading: trOpsIsLoading,
    isError: trOpsIsError
  } = useQueryApi(`query/queryTrOps/${levelName}`, queryParams);

  const {
    data: trOpsDataTotal,
    isLoading: trOpsTotalIsLoading,
    isError: trOpsTotalIsError
  } = useQueryApi(`query/queryTrOpsTotal/${levelName}`, queryParams);

  const {
    data: salesData,
    isLoading: salesDataIsLoading,
    isError: salesDataIsError
  } = useQueryApi(`query/querySales/${levelName}`, queryParams);

  const {
    data: subLevelSalesData,
    isLoading: subLevelSalesIsLoading,
    isError: subLevelSalesIsError
  } = useQueryApi(`query/querySubLevelSales/${levelName}`, queryParams);

  const isLoading =
    quotesAggIsLoading ||
    proSpecTrLbrIsLoading ||
    specialtyKeyMetricsContIsLoading ||
    proOpsIsLoading ||
    trOpsIsLoading ||
    queryProSpecTrLbrPlusOneIsLoading ||
    specialtyKeyMetricsIsLoading ||
    specialtyKeyMetricsStrIsLoading ||
    queryProOpsPlusOneIsLoading ||
    salesDataIsLoading ||
    subLevelSalesIsLoading ||
    trOpsTotalIsLoading;

  const isError =
    quotesAggIsError ||
    proSpecTrLbrIsError ||
    specialtyKeyMetricsContIsError ||
    proOpsIsError ||
    trOpsIsError ||
    queryProSpecTrLbrPlusOneIsError ||
    specialtyKeyMetricsIsError ||
    specialtyKeyMetricsStrIsError ||
    queryProOpsPlusOneIsError ||
    salesDataIsError ||
    subLevelSalesIsError ||
    trOpsTotalIsError;

  return {
    isLoading,
    isError,
    trOpsDataTotal,
    quotesAggData,
    proSpecTrLbrData,
    specialtyKeyMetricsContData,
    proOpsData,
    trOpsData,
    queryProSpecTrLbrPlusOneData,
    specialtyKeyMetricsData: levelName !== storeLevelName ? specialtyKeyMetricsData : specialtyKeyMetricsStrData,
    queryProOpsPlusOneData,
    salesData,
    subLevelSalesData
  };
};

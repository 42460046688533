import { getCompareStyle, getPercentString } from '../../../../../components/Common/TableCellUtils';
import { buildBaseDrillLink, buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { getRowLevelNm } from '../../../../../utils/getRowLevelNm';

export const accuracyAndReceivingDataCore = ({ queryACCRCVByLevel, timeframe }) => {
  const dstAccRcvDataFilter = queryACCRCVByLevel.filter(row => {
    // TODO: METRIC LEVEL is not a key in the row object, we use DIV as a default value
    return row && row['METRIC_LEVEL'] === 'DIV';
  });

  const coreData = dstAccRcvDataFilter.reduce((acc, curr) => {
    acc[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] += curr[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'];
    acc[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT'] += curr[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT'];
    acc[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'] += curr[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'];
    acc[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] += curr[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'];
    return acc;
  }, {
    [timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT']: 0,
    [timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT']: 0,
    [timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT']: 0,
    [timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT']: 0
  });

  const GOAL_OH_AUTO_REJ_RATE = 0.03;
  const GOAL_AUTO_KR_COUNT = 0;
  const GOAL_UNMATCHED_XFER_CNT = 0;
  const baseCoreShrinkUrl = buildBaseShrinkUrl('core')

  return {
    headers: [{ name: 'Metric' }, { name: 'Value' }, { name: 'Goal' }],
    data: [
      [
        {
          stringValue: 'OH-Auto Rej Rate',
          link: {
            to: `/${baseCoreShrinkUrl}/ohautorej`
          }
        },
        {
          stringValue: getPercentString(
            coreData[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] > 0
              ? coreData[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] /
              coreData[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT']
              : 0
          ),
          customStyle: {
            color:
              coreData[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] /
                coreData[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT'] >
                GOAL_OH_AUTO_REJ_RATE
                ? 'red'
                : 'green'
          }
        },
        {
          stringValue: `< ${getPercentString(GOAL_OH_AUTO_REJ_RATE)}`
        }
      ],
      [
        {
          stringValue: 'Auto Key-recs',
          link: {
            to: `/${baseCoreShrinkUrl}/autokr`,
          }
        },
        {
          stringValue:
            coreData[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'] > 0
              ? coreData[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT']
              : 0,
          customStyle: getCompareStyle(coreData[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'])
        },
        {
          stringValue: `${getPercentString(GOAL_AUTO_KR_COUNT)}`
        }
      ],
      [
        {
          stringValue: 'Unmatched Transfers',
          link: {
            to: `/${baseCoreShrinkUrl}/unmatchedxfers`,
          }
        },
        {
          stringValue:
            coreData[timeframe.replace('R12M', 'L12').replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] > 0
              ? coreData[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT']
              : 0,
          customStyle: getCompareStyle(
            coreData[timeframe.replace('R12M', 'L12').replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT']
          )
        },
        {
          stringValue: `${getPercentString(GOAL_UNMATCHED_XFER_CNT)}`
        }
      ]
    ]
  };
};

export const accuracyandReceivingDrillHeaders = level => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} # ` },
  { name: 'Value' },
  { name: 'Goal' }
];

export const ohAutoRejectRows = (metricsScoreData, metricsData, timeframe, level) => {
  const previousLocNbr = metricsScoreData[METRIC_CONSTANTS[level].propToRender];
  const total =
    metricsData[previousLocNbr][`${timeframe}_OH_AUTO_RJT_CNT`] /
      metricsData[previousLocNbr][`${timeframe}_OH_NEED_APPRV_CNT`] || 0;

  const baseCoreShrinkUrl = buildBaseDrillLink('core');
  const linkUrl = `/${baseCoreShrinkUrl}/ohautorej`;
  return [
    {
      stringValue: getRowLevelNm({row: metricsScoreData, level}),
      link: {
        to: linkUrl,
        params: {
          [METRIC_CONSTANTS[level].urlSubLevelParam]: previousLocNbr
        }
      }
    },
    {
      pctValue: total,
      decimals: 2,
      customStyle: { color: total < 0.03 ? 'green' : 'red' }
    },
    { stringValue: '<3%' }
  ];
};

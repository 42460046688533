import { useContext } from 'react';
import { StoreOrderFulfillmentContext } from '../../../context/StoreOrderFulfillmentContext';
import { DataView } from '../../../../../components/Common/DataView';
import { useQueryKeys } from '../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { summaryData } from '../../../utils/Summary';

const Summary = ({unit}) => {
    const { timeframe } = useContext(StoreOrderFulfillmentContext);
    const [ storeOrderSummary ] = useQueryKeys(['storeOrderSummary']);
    const isNOM = unit === 'NOM';
    const data = summaryData(storeOrderSummary, timeframe, isNOM);

    return (
      <DataView className='my-4'>
        <DataView.Title className='mb-4' as='h3'>
          Summary
        </DataView.Title>

        <DataView.DataTable data={data} />
      </DataView>
    );
};

export default Summary;
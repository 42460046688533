import { Header, Loader, Segment, Tab } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import { useFetchLevelName } from '../../../../../hooks/useFetchLevelName';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import StoreOrderFulfillmentContextProvider from '../../../context/StoreOrderFulfillmentContext';
import { Complete } from '../components/complete/Complete';
import { OnTime } from '../components/ontime/OnTime';
import Summary from '../components/Summary';
import { useOrderFulfillmentQueries } from '../../../hooks/useOrderFulfillmentQueries';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { useState } from 'react';
import { DataView } from '../../../../../components/Common/DataView';
import { useEnhancedQueries } from '../../../../../hooks/useEnhancedQueries';

export const StoreOrderFulfillmentPage = ({ timeframe, settimeframe, fpToggle, ...props }) => {
  const { strNbr = 0 } = useQueryParams();
  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, level: 'store', locNbr: strNbr });
  const queryProps = useOrderFulfillmentQueries({ fpToggle, strNbr });
  const [unit, setUnit] = useState('NOM');
  const isUSD = true;
  const {
    results: [{ data: storeOrderSummary = [] }]
  } = useEnhancedQueries(['storeOrderSummary'], fpToggle);

  const panes = [
    {
      menuItem: 'ON TIME',
      render: () => <OnTime />
    },
    {
      menuItem: 'COMPLETE',
      render: () => <Complete />
    }
  ];

  if (isLoadingName || queryProps.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  const value = {
    ...props,
    ...queryProps,
    fpToggle,
    timeframe,
    settimeframe,
    strNbr
  };

  const date = storeOrderSummary[0]?.TRNSFM_DT || '2021-01-01';

  const title = `Store ${strNbr}, ${String(currentLevelName).toLocaleLowerCase()}`

  return (
    <StoreOrderFulfillmentContextProvider {...value}>
      <Segment fluid>
        <DataView className='my-3'>
          <DataView.Title>
            Order Fulfillment - {title}
            <Header.Subheader>Data as of {date}</Header.Subheader>
          </DataView.Title>

          <DataView.Controls className='ui grid column  my-3'>
            <div className='column eight wide'>
              <p className='my-0'>Unit:</p>
              <UnitMenu unit={unit} setUnit={setUnit} isUSD={isUSD} />
            </div>
            <div className='column eight wide'>
              <p className='my-0'>Timeframe:</p>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </div>
          </DataView.Controls>

          <Summary unit={unit} />

          <Tab menu={{ secondary: true, pointing: true, color: 'orange' }} panes={panes} />
        </DataView>
      </Segment>
    </StoreOrderFulfillmentContextProvider>
  );
};

import { Dropdown } from 'semantic-ui-react';
import { DataView } from '../../Common/DataView';
import { getServicesPipelineDataTable, PIPELINE_OPTIONS } from '../utils/specialtyProRentalServices';

export const ServicesPipeline = props => {
  const { focusSixAllProgramsData, activeMenu, level, locNbr, timeframe, pipelineFilter, onChangePipelineFilter } = props;
  const servicesPipelineDataTable = getServicesPipelineDataTable({
    focusSixAllProgramsData,
    activeMenu,
    level,
    locNbr,
    timeframe,
    pipelineFilter
  });

  return (
    <DataView className='mt-4'>
      <DataView.Title position='center'>{activeMenu}</DataView.Title>

      <DataView.Controls className='d-flex justify-content-end'>
        <Dropdown selection options={PIPELINE_OPTIONS} onChange={onChangePipelineFilter} value={pipelineFilter} />
      </DataView.Controls>

      <DataView.DataTable data={servicesPipelineDataTable} />
    </DataView>
  );
};

import { DataView } from '../../../Common/DataView';
import { creditApplicationsBuilder } from '../utils/creditApplicationsBuilder';

export const CreditApplications = ({ data = [], timeframe }) => {
  const dataTableData = {
    headers: [{ name: 'Category' }, { name: 'Apps' }, { name: 'LY' }, { name: 'Goal' }, { name: '% vGoal' }],
    data: creditApplicationsBuilder(data, timeframe)
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title position='left'>Applications</DataView.Title>
      <DataView.DataTable data={dataTableData} />
    </DataView>
  );
};

import React, { useState } from 'react';
import { Header, Grid, Table, Loader } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getData } from '../../service/DataService';
import { getPercentString } from '../Common/TableCellUtils';
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink';
import { calculateDamagedMarkdownsLyValue } from '../../utils/shrinkCalculator';

export const DstShrinkDamagedMd = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {
  const search = new URLSearchParams(location.search);
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0;
  const [sortColumn, setSortColumn] = useState('STR_NBR');
  const [sortDirection, setSortDirection] = useState('ascending');
  const shrinkQuery = useQuery(['queryShrinkMetricsStrByDst', { dstNbr, fpToggle }], getData);
  const shrinkScoreQuery = useQuery(['queryShrinkMetricsScoresByDst', { dstNbr, fpToggle }], getData);

  if (shrinkQuery.isLoading || shrinkScoreQuery.isLoading) {
    return <Loader active>Loading...</Loader>;
  }

  if (!shrinkQuery.data) {
    return <Header textAlign='center'>Invalid Store Number</Header>;
  }

  const shrinkData = shrinkQuery.data;
  const shrinkScoreData = shrinkScoreQuery.data;

  let shrinkScoreDataFilter = shrinkScoreData.filter(row => {
    return row && row['METRIC_LEVEL'] === 'STR' && row['TIME_FRAME'] === timeframe;
  });

  let shrinkDataSorted = shrinkData.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn];
    if (sortColumn === 'VALUE') {
      result =
        left[timeframe + '_DAMAGED_MD_NUM'] / left[timeframe + '_CORE_SLS'] -
        right[timeframe + '_DAMAGED_MD_NUM'] / right[timeframe + '_CORE_SLS'];
    }
    if (sortColumn === 'SCORE') {
      result =
        shrinkScoreDataFilter.filter(row => {
          return row && row['STR_NBR'] === left['STR_NBR'];
        })[0]['DAMAGED_MD_SCORE'] -
        shrinkScoreDataFilter.filter(row => {
          return row && row['STR_NBR'] === right['STR_NBR'];
        })[0]['DAMAGED_MD_SCORE'];
    }
    if (sortDirection === 'descending') {
      result *= -1;
    }
    return result;
  });

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/district/shrink?dstNbr=${dstNbr}`} data-test='b2o-link'>
              Back to Shrink Overview
            </Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>Damaged Markdowns - District {dstNbr}</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'STR_NBR') {
                  setSortColumn('STR_NBR');
                  setSortDirection('ascending');
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                }
              }}>
              Store #
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'VALUE' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'VALUE') {
                  setSortColumn('VALUE');
                  setSortDirection('ascending');
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                }
              }}>
              Value
            </Table.HeaderCell>
            <Table.HeaderCell>LY</Table.HeaderCell>
            <Table.HeaderCell>Goal</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'SCORE' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'SCORE') {
                  setSortColumn('SCORE');
                  setSortDirection('ascending');
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                }
              }}>
              Score
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {shrinkDataSorted.map(data => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link
                    to={`/store/shrink/damagedmd?strNbr=${data['STR_NBR']}`}
                    style={{ color: '#EE7125', textDecoration: 'underline' }}>
                    {data['STR_NBR']}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {getPercentString(data[timeframe + '_DAMAGED_MD_NUM'] / data[timeframe + '_CORE_SLS'])}
                </Table.Cell>
                <Table.Cell>{getPercentString(calculateDamagedMarkdownsLyValue(data, timeframe))}</Table.Cell>
                <Table.Cell>
                  ≥{' '}
                  {getPercentString(
                    shrinkScoreDataFilter.filter(row => {
                      return row && row['STR_NBR'] === data['STR_NBR'];
                    })[0]['DAMAGED_MD_GOAL'],
                    2
                  )}
                </Table.Cell>
                <Table.Cell>
                  {getPercentString(
                    shrinkScoreDataFilter.filter(row => {
                      return row && row['STR_NBR'] === data['STR_NBR'];
                    })[0]['DAMAGED_MD_SCORE']
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
});

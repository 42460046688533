import { getPercentString } from '../../../../../components/Common/TableCellUtils';
import { buildBaseDrillLink, buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { getRowLevelNm } from "../../../../../utils/getRowLevelNm";

export const shrinkMonitorCoreMetricsDrillHeaders = () => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS['core'].urlLinkLevel)} # ` },
  { name: 'Value' }
];

export const shrinkMonitorCoreMetricsDrillBreadcrumbItems = (locNbr, title) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');
  return [{ to: `/${baseLevelShrinkUrl}`, text: 'Back to Shrink Overview' }, { text: title }];
};

export const shrinkRtvDeletedTagsCoreRows = (metricsScoreData, metricsData, timeframe) => {
  const baseLevelShrinkUrl = buildBaseDrillLink('core');
  const previousNbr = metricsScoreData[METRIC_CONSTANTS['core'].propToRender];
  const level = 'core';
  return [
    {
      stringValue: getRowLevelNm({row: metricsScoreData, level}),
      link: {
        to: `/${baseLevelShrinkUrl}/rtvdeletedtag`,
        params: {
          [METRIC_CONSTANTS['core'].urlSubLevelParam]: previousNbr
        }
      }
    },
    {
      stringValue: getPercentString(
        metricsData[previousNbr][timeframe + '_RTV_DEL_NUM'] / metricsData[previousNbr][timeframe + '_RTV_DEL_DEN'],
        2
      )
    }
  ];
};

export const shrinkReducedTagsCoreRows = (metricsScoreData, metricsData, timeframe) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(METRIC_CONSTANTS['core'].urlLinkLevel);
  const previousNbr = metricsScoreData[METRIC_CONSTANTS['core'].propToRender];
  return [
    {
      stringValue: previousNbr,
      link: {
        to: `/${baseLevelShrinkUrl}/rtvdeletedtag`,
        params: {
          [METRIC_CONSTANTS['core'].urlSubLevelParam]: previousNbr
        }
      }
    },
    {
      stringValue: getPercentString(
        (metricsData[previousNbr][timeframe + '_REDUCED_TAG_NUM'] - metricsData[previousNbr][timeframe + '_RTV_DEL_DEN']) /
        metricsData[previousNbr][timeframe + '_CORE_SLS']
      )
    }
  ];
};

export const shrinkMonitorMetricsCoreDataFiltered = (data, timeframe) =>
  data.filter(row => row && row['METRIC_LEVEL'] === METRIC_CONSTANTS['core'].subMetric && row['TIME_FRAME'] === timeframe);

export const shrinkMonitorMetricsDataCoreReduced = data =>
  data.reduce((acc, row) => {
    if (acc[METRIC_CONSTANTS['core'].propToRender]) return acc;
    return { ...acc, [row[METRIC_CONSTANTS['core'].propToRender]]: row };
  }, {});

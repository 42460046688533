import { createObjectSchema } from '../utils/channelAssociateTableBuilder';
import { useCreditContext } from '../hooks/useCreditContext';
import { useEnhancedQueries } from '../../../../hooks/useEnhancedQueries';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../Common/DataTable';
import { PlaceholderLoading } from '../../../Loading/PlaceholderLoading';

export const AssociateDataTable = () => {
  const { fpToggle, timeframe } = useCreditContext();
  const {
    isLoading,
    isError,
    results: [{ data: creditAssociateByLvlData = [] }]
  } = useEnhancedQueries(['creditAssociateByLvl'], fpToggle);
  const filteredData = creditAssociateByLvlData.filter(
    row => row[`${timeframe}_TOT_APPS`] + row[`${timeframe}_COMM_APPS`] + row[`${timeframe}_CONS_APPS`] > 0
  );

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading content='Loading Associates...' />;

  const associateObjectSchema = createObjectSchema(filteredData, timeframe, true);

  return <DataTable data={associateObjectSchema} />;
};

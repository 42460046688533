// NOTE: isUndefined is used because this is broken the app for core

export const creditEfficiencyBuilder = (data = [], timeframe) => {
  const [item] = data;
  const isUndefined = item === undefined;

  const TXN_COUNT = !isUndefined ? item[`${timeframe}_TXN_COUNT`] : 0;
  const TOTAL_APPS = !isUndefined ? item[`${timeframe}_TOT_APPS`] : 0;
  const LY_TOT_APPS = !isUndefined ? item[`${timeframe}_LY_TOT_APPS`] : 0;
  const LY_TXN_COUNT = !isUndefined ? item[`${timeframe}_LY_TXN_COUNT`] : 0;

  const APP_EFFICIENCY =
    TOTAL_APPS === 0 ? '-' : TXN_COUNT != null && TOTAL_APPS != null ? TXN_COUNT / TOTAL_APPS : 'Loading';

  const LY = LY_TOT_APPS === 0 ? '-' : LY_TXN_COUNT != null && LY_TOT_APPS != null ? LY_TXN_COUNT / LY_TOT_APPS : 'Loading';

  return [
    [
      { numValue: APP_EFFICIENCY.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: LY.toLocaleString(undefined, { maximumFractionDigits: 0 }) }
    ]
  ];
};

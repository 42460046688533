import { getCompareStyle } from '../../Common/TableCellUtils';
import { CORE, DIVISION, REGION, STORE, LEVEL_NBR, LEVEL_NM } from '../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../utils/string';
import { buildSublevelLinkColumn } from '../../../utils/dataTable';

const { levelName: coreLevelName } = CORE;
const { levelName: divisionLevelName } = DIVISION;
const { levelName: regionLevelName } = REGION;
const { levelName: storeLevelName } = STORE;

export const FOCUS_6 = 'Focus 6';
export const ALL_PROGRAMS = 'All Programs';
export const menuItems = [FOCUS_6, ALL_PROGRAMS];

export const SALES = 'Sales';
export const PIPELINE = 'Pipeline';

export const getBreadcrumbItems = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;
  const baseUrl = `/${levelName}/specialtyprorental`;
  const urlWithParams = `${baseUrl}?${urlParam}=${locNbr}`;

  const to = levelName === coreLevelName ? baseUrl : urlWithParams;
  const text = 'Back to Specialty/Pro/Rental';

  return [{ to, text }];
};

export const getTitle = params => {
  const { level, locNbr, currentLevelName } = params;
  const { levelName } = level;
  const baseTitle = `Services - ${levelName}`;
  const title = `${baseTitle} #${locNbr}, ${currentLevelName}`;

  if (levelName === coreLevelName) {
    return baseTitle;
  }

  return title;
};

export const TOTAL = 'TOTAL';
export const PIPELINE_OPTIONS = [
  { key: TOTAL, text: 'All', value: TOTAL },
  { key: 'STR', text: 'Store', value: 'STR' },
  { key: 'WEB', text: 'Web', value: 'WEB' },
  { key: 'SP', text: 'Service Provider', value: 'SP' },
  { key: 'OTHER', text: 'Other', value: 'OTHER' }
];

const getTotal = params => {
  const { data } = params;

  return data.reduce((acc, row) => {
    Object.keys(row).forEach(key => {
      acc[key] = (acc[key] || 0) + row[key];
    });

    return acc;
  }, {});
};

export const getServicesData = params => {
  const { servicesData, activeMenu } = params;
  const focus6Data = servicesData.filter(row => row.TOP_6_CATEGORY <= 6);
  const allProgramsData = servicesData;
  const data = activeMenu === FOCUS_6 ? focus6Data : allProgramsData;

  return { focus6Data, allProgramsData, data };
};

const getProgramColumn = params => {
  const { row, program, level, locNbr } = params;
  const { levelName, urlParam } = level;

  return {
    ...(levelName !== storeLevelName && {
      link: {
        to: `/${levelName}/services/program`,
        params: {
          ...(levelName !== coreLevelName && {
            [urlParam]: locNbr
          }),
          smryPrgmNm: row[SUMMARY_PROGRAM_NAME]
        }
      }
    }),
    stringValue: program
  };
};

export const calculateComp = (row, timeframe, pipelineFilter) => {
  return (
    (row[timeframe + '_LEAD_COUNT_' + pipelineFilter] +
      row[timeframe + '_MEASURE_COUNT_' + pipelineFilter] -
      row[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] -
      row[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) /
    (row[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + row[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])
  );
};

export const calculateConv = (row, timeframe, pipelineFilter) => {
  return (
    (row[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + row[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) /
    (row[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + row[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter])
  );
};

// Services Sales Table

const MER_DEPT_CD = 'MER_DEPT_CD';
const SUMMARY_PROGRAM_NAME = 'SUMMARY_PROGRAM_NAME';
const TY_SLS_AMT = 'TY_SLS_AMT';
const SLS_PLN_AMT = 'SLS_PLN_AMT';
const TY_COMP_SLS_AMT = 'TY_COMP_SLS_AMT';
const LY_COMP_SLS_AMT = 'LY_COMP_SLS_AMT';

export const getServicesSalesTableHeaders = () => [
  { name: 'Program' },
  { name: 'Actual' },
  { name: 'vP $' },
  { name: 'Comp %' }
];

const getServicesSalesRowValues = params => {
  const { row, activeMenu, timeframe } = params;

  const summaryProgramName = row[SUMMARY_PROGRAM_NAME];
  const merDeptCd = row[MER_DEPT_CD];
  const program = activeMenu === FOCUS_6 ? summaryProgramName : `${merDeptCd} - ${summaryProgramName}`;
  const tySlsAmt = row[`${timeframe}_${TY_SLS_AMT}`];
  const slsPlnAmt = row[`${timeframe}_${SLS_PLN_AMT}`];
  const tyCompSlsAmt = row[`${timeframe}_${TY_COMP_SLS_AMT}`];
  const lyCompSlsAmt = row[`${timeframe}_${LY_COMP_SLS_AMT}`];

  return { program, tySlsAmt, slsPlnAmt, tyCompSlsAmt, lyCompSlsAmt };
};

const getServicesSalesRow = params => {
  const { tySlsAmt, slsPlnAmt, tyCompSlsAmt, lyCompSlsAmt } = params;

  const actual = tySlsAmt;
  const vpDollar = tySlsAmt - slsPlnAmt;
  const compPct = (tyCompSlsAmt - lyCompSlsAmt) / lyCompSlsAmt;

  return [
    { dollarValue: actual, compareStyle: true },
    { dollarValue: vpDollar, compareStyle: true },
    { pctValue: compPct, compareStyle: true }
  ];
};

const getServicesSalesTableData = params => {
  const { data, activeMenu, level, locNbr, timeframe } = params;

  return data.map(row => {
    const servicesSalesRowValues = getServicesSalesRowValues({ row, activeMenu, timeframe });
    const { program } = servicesSalesRowValues;

    return [getProgramColumn({ row, program, level, locNbr }), ...getServicesSalesRow(servicesSalesRowValues)];
  });
};

const getServicesSalesTableFooter = params => {
  const { focus6Data, allProgramsData, activeMenu, timeframe } = params;

  if (activeMenu !== FOCUS_6) {
    return [];
  }

  const servicesSalesTotalFocus6Data = getTotal({ data: focus6Data });
  const servicesSalesTotalFocus6RowValues = getServicesSalesRowValues({
    row: servicesSalesTotalFocus6Data,
    activeMenu,
    timeframe
  });
  const servicesSalesTotalFocus6 = [
    { stringValue: 'Total Focus 6 Programs' },
    ...getServicesSalesRow(servicesSalesTotalFocus6RowValues)
  ];

  const servicesSalesTotalAllProgramsData = getTotal({ data: allProgramsData });
  const servicesSalesTotalAllProgramsRowValues = getServicesSalesRowValues({
    row: servicesSalesTotalAllProgramsData,
    activeMenu,
    timeframe
  });
  const servicesSalesTotalAllPrograms = [
    { stringValue: 'Total Programs' },
    ...getServicesSalesRow(servicesSalesTotalAllProgramsRowValues)
  ];

  return [servicesSalesTotalFocus6, servicesSalesTotalAllPrograms];
};

export const getServicesSalesDataTable = ({ focusSixAllProgramsData, activeMenu, level, locNbr, timeframe }) => {
  const { data, focus6Data, allProgramsData } = getServicesData({ servicesData: focusSixAllProgramsData, activeMenu });

  return {
    headers: getServicesSalesTableHeaders(),
    data: getServicesSalesTableData({ data, activeMenu, level, locNbr, timeframe }),
    footer: getServicesSalesTableFooter({ focus6Data, allProgramsData, activeMenu, timeframe }),
    sortable: true,
    sortableDefault: 0
  };
};

// Services Pipeline Table

const LEAD_COUNT = 'LEAD_COUNT';
const MEASURE_COUNT = 'MEASURE_COUNT';
const LY_LEAD_COUNT = 'LY_LEAD_COUNT';
const LY_MEASURE_COUNT = 'LY_MEASURE_COUNT';
const LEAD_CONV_NUM = 'LEAD_CONV_NUM';
const LEAD_CONV_DEN = 'LEAD_CONV_DEN';
const MEASURE_CONV_NUM = 'MEASURE_CONV_NUM';
const MEASURE_CONV_DEN = 'MEASURE_CONV_DEN';

const getServicesPipelineTableHeaders = () => [
  { name: 'Program' },
  { name: 'Lead' },
  { name: 'Meas' },
  { name: 'Comp %' },
  { name: 'Conv %' }
];

const getServicesPipelineRowValues = params => {
  const { row, activeMenu, timeframe, pipelineFilter } = params;

  const summaryProgramName = row[SUMMARY_PROGRAM_NAME];
  const merDeptCd = row[MER_DEPT_CD];

  const program = activeMenu === FOCUS_6 ? summaryProgramName : `${merDeptCd} - ${summaryProgramName}`;
  const leadCount = row[`${timeframe}_${LEAD_COUNT}_${pipelineFilter}`] || 0;
  const measCount = row[`${timeframe}_${MEASURE_COUNT}_${pipelineFilter}`] || 0;
  const lyLeadCount = row[`${timeframe}_${LY_LEAD_COUNT}_${pipelineFilter}`] || 0;
  const lyMeasCount = row[`${timeframe}_${LY_MEASURE_COUNT}_${pipelineFilter}`] || 0;
  const leadConvNum = row[`${timeframe}_${LEAD_CONV_NUM}_${pipelineFilter}`] || 0;
  const leadConvDen = row[`${timeframe}_${LEAD_CONV_DEN}_${pipelineFilter}`] || 0;
  const measConvNum = row[`${timeframe}_${MEASURE_CONV_NUM}_${pipelineFilter}`] || 0;
  const measConvDen = row[`${timeframe}_${MEASURE_CONV_DEN}_${pipelineFilter}`] || 0;

  return { program, leadCount, measCount, lyLeadCount, lyMeasCount, leadConvNum, leadConvDen, measConvNum, measConvDen };
};

const getServicesPipelineRow = params => {
  const { leadCount, measCount, lyLeadCount, lyMeasCount, leadConvNum, leadConvDen, measConvNum, measConvDen } = params;

  const lead = leadCount;
  const meas = measCount;
  const comp = (leadCount + measCount - lyLeadCount - lyMeasCount) / (lyLeadCount + lyMeasCount);
  const conv = (leadConvNum + measConvNum) / (leadConvDen + measConvDen);

  return [
    { stringValue: lead.toLocaleString(undefined, { maximumFractionDigits: 0 }), compareStyle: true },
    { stringValue: meas.toLocaleString(undefined, { maximumFractionDigits: 0 }), compareStyle: true },
    { pctValue: comp, compareStyle: true },
    { pctValue: conv, compareStyle: true }
  ];
};

const getServicesPipelineTableData = params => {
  const { data, activeMenu, level, locNbr, timeframe, pipelineFilter } = params;

  return data.map(row => {
    const servicesPipelineRowValues = getServicesPipelineRowValues({ row, activeMenu, timeframe, pipelineFilter });
    const { program } = servicesPipelineRowValues;

    return [getProgramColumn({ row, program, level, locNbr }), ...getServicesPipelineRow(servicesPipelineRowValues)];
  });
};

const getServicesPipelineDataTableFooter = params => {
  const { focus6Data, allProgramsData, activeMenu, timeframe, pipelineFilter } = params;

  if (activeMenu !== FOCUS_6) {
    return [];
  }

  const servicesPipelineTotalFocus6Data = getTotal({ data: focus6Data });
  const servicesPipelineTotalFocus6RowValues = getServicesPipelineRowValues({
    row: servicesPipelineTotalFocus6Data,
    activeMenu,
    timeframe,
    pipelineFilter
  });
  const servicesPipelineTotalFocus6 = [
    { stringValue: 'Total Focus 6 Programs' },
    ...getServicesPipelineRow(servicesPipelineTotalFocus6RowValues)
  ];

  const servicesPipelineTotalAllProgramsData = getTotal({ data: allProgramsData });
  const servicesPipelineTotalAllProgramsRowValues = getServicesPipelineRowValues({
    row: servicesPipelineTotalAllProgramsData,
    activeMenu,
    timeframe,
    pipelineFilter
  });
  const servicesPipelineTotalAllPrograms = [
    { stringValue: 'Total Programs' },
    ...getServicesPipelineRow(servicesPipelineTotalAllProgramsRowValues)
  ];

  return [servicesPipelineTotalFocus6, servicesPipelineTotalAllPrograms];
};

export const getServicesPipelineDataTable = params => {
  const { focusSixAllProgramsData, activeMenu, level, locNbr, timeframe, pipelineFilter } = params;
  const { data, focus6Data, allProgramsData } = getServicesData({ servicesData: focusSixAllProgramsData, activeMenu });

  return {
    headers: getServicesPipelineTableHeaders(),
    data: getServicesPipelineTableData({ data, activeMenu, level, locNbr, timeframe, pipelineFilter }),
    footer: getServicesPipelineDataTableFooter({ focus6Data, allProgramsData, activeMenu, timeframe, pipelineFilter }),
    sortable: true,
    sortableDefault: 0
  };
};

// Leads Measures & Performance Table

const getDataBySubLevelNbr = params => {
  const { data, level, timeframe, pipelineFilter } = params;
  const { subLevel } = level;
  const { [LEVEL_NBR]: subLevelNbr, [LEVEL_NM]: subLevelNm } = subLevel;

  return data.reduce((acc, row) => {
    const group = acc.find(group => group[subLevelNbr] === row[subLevelNbr]);
    const leadsAndMeasuresPerformanceRowValues = getLeadsAndMeasuresPerformanceRowValues({ row, timeframe, pipelineFilter });

    if (group) {
      Object.keys(leadsAndMeasuresPerformanceRowValues).forEach(key => {
        group[key] += leadsAndMeasuresPerformanceRowValues[key];
      });
    } else {
      acc.push({ [subLevelNbr]: row[subLevelNbr], [subLevelNm]: row[subLevelNm], ...leadsAndMeasuresPerformanceRowValues });
    }
    return acc;
  }, []);
};

const getLeadsAndMeasuresPerformanceTableHeaders = params => {
  const { level } = params;
  const { subLevel } = level;
  const { levelName: subLevelName } = subLevel;

  return [
    { name: capitalizeFirstLetter(subLevelName) },
    { name: 'Leads' },
    { name: 'Meas' },
    { name: 'Comp %' },
    { name: 'Conv %' }
  ];
};

const getLeadsAndMeasuresPerformanceRowValues = params => {
  const { row, timeframe, pipelineFilter } = params;

  const leadCount = row[`${timeframe}_${LEAD_COUNT}_${pipelineFilter}`] || 0;
  const lyLeadCount = row[`${timeframe}_${LY_LEAD_COUNT}_${pipelineFilter}`] || 0;
  const measureCount = row[`${timeframe}_${MEASURE_COUNT}_${pipelineFilter}`] || 0;
  const lyMeasureCount = row[`${timeframe}_${LY_MEASURE_COUNT}_${pipelineFilter}`] || 0;
  const leadConvNum = row[`${timeframe}_${LEAD_CONV_NUM}_${pipelineFilter}`] || 0;
  const measureConvNum = row[`${timeframe}_${MEASURE_CONV_NUM}_${pipelineFilter}`] || 0;
  const leadConvDen = row[`${timeframe}_${LEAD_CONV_DEN}_${pipelineFilter}`] || 0;
  const measureConvDen = row[`${timeframe}_${MEASURE_CONV_DEN}_${pipelineFilter}`] || 0;

  return { leadCount, lyLeadCount, measureCount, lyMeasureCount, leadConvNum, measureConvNum, leadConvDen, measureConvDen };
};

const getLeadsAndMeasuresPerformanceRow = params => {
  const { row } = params;
  const { leadCount, lyLeadCount, measureCount, lyMeasureCount, leadConvNum, measureConvNum, leadConvDen, measureConvDen } =
    row;

  const leads = leadCount;
  const meas = measureCount;
  const comp = (leadCount + measureCount - lyLeadCount - lyMeasureCount) / (lyLeadCount + lyMeasureCount);
  const conv = (leadConvNum + measureConvNum) / (leadConvDen + measureConvDen);

  return [
    { numValue: leads },
    { numValue: meas, compareStyle: true },
    { pctValue: comp, compareStyle: true },
    { pctValue: conv }
  ];
};

const getLeadsAndMeasuresPerformanceTableData = params => {
  const { data, level } = params;
  const { levelName } = level;

  return data.map(row => {
    return [
      buildSublevelLinkColumn({ level: levelName, row, to: `/services` }),
      ...getLeadsAndMeasuresPerformanceRow({ row })
    ];
  });
};

const getLeadsAndMeasuresPerformanceTableFooter = params => {
  const { data } = params;

  const leadsAndMeasuresPerformanceTotalData = getTotal({ data });

  return [[{ stringValue: 'Total' }, ...getLeadsAndMeasuresPerformanceRow({ row: leadsAndMeasuresPerformanceTotalData })]];
};

export const getLeadsAndMeasuresPerformanceDataTable = params => {
  const { leadsAndMeasuresPerformanceData, activeMenu, level, timeframe, pipelineFilter } = params;
  const { data } = getServicesData({ servicesData: leadsAndMeasuresPerformanceData, activeMenu });
  const dataBySubLevelNbr = getDataBySubLevelNbr({ data, level, timeframe, pipelineFilter });

  return {
    headers: getLeadsAndMeasuresPerformanceTableHeaders({ level }),
    data: getLeadsAndMeasuresPerformanceTableData({ data: dataBySubLevelNbr, level, timeframe, pipelineFilter }),
    footer: getLeadsAndMeasuresPerformanceTableFooter({ data: dataBySubLevelNbr }),
    sortable: true,
    sortableDefault: 0
  };
};

// Leads Measures & Performance Table Str

const getLeadsAndMeasuresPerformanceTableStrHeaders = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;

  return [
    { link: { to: `/${levelName}/leads`, params: { [urlParam]: locNbr } }, name: 'Lead Comp' },
    { link: { to: `/${levelName}/meas`, params: { [urlParam]: locNbr } }, name: 'Measure Comp' }
  ];
};

const getLeadsAndMeasuresPerformanceTableStrData = params => {
  const { data, timeframe, pipelineFilter } = params;
  const row = getTotal({ data });

  const leadCount = row[`${timeframe}_${LEAD_COUNT}_${pipelineFilter}`] || 0;
  const lyLeadCount = row[`${timeframe}_${LY_LEAD_COUNT}_${pipelineFilter}`] || 0;
  const measureCount = row[`${timeframe}_${MEASURE_COUNT}_${pipelineFilter}`] || 0;
  const lyMeasureCount = row[`${timeframe}_${LY_MEASURE_COUNT}_${pipelineFilter}`] || 0;

  const leadComp = (leadCount - lyLeadCount) / lyLeadCount;
  const measureComp = (measureCount - lyMeasureCount) / lyMeasureCount;

  return [
    [
      { pctValue: leadComp, customStyle: getCompareStyle(leadComp) },
      { pctValue: measureComp, customStyle: getCompareStyle(measureComp) }
    ]
  ];
};

export const getLeadsAndMeasuresPerformanceDataTableStr = params => {
  const { leadsAndMeasuresPerformanceData, activeMenu, level, locNbr, timeframe, pipelineFilter } = params;
  const { data } = getServicesData({ servicesData: leadsAndMeasuresPerformanceData, activeMenu });

  return {
    headers: getLeadsAndMeasuresPerformanceTableStrHeaders({ level, locNbr }),
    data: getLeadsAndMeasuresPerformanceTableStrData({ data, timeframe, pipelineFilter })
  };
};

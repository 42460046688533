import { DataView } from '../../../../../../components/Common/DataView';
import { OnTimePickUp } from './OnTimePickUp';
import OnTimeDelivery from './OnTimeDelivery';
import { Tab } from 'semantic-ui-react';

export default function OnTime() {
  const tabs = [
    {
      menuItem: 'PICKUP',
      render: () => <OnTimePickUp />
    },
    {
      menuItem: 'DELIVERY',
      render: () => <OnTimeDelivery />
    }
  ];

  return (
    <DataView className='my-4'>

      <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />
    </DataView>
  );
}

import { useQueries } from 'react-query';
import { getData } from '../../../service/DataService';

export const useOrderFulfillmentQueries = params => {
  const { fpToggle, strNbr } = params;
  const queryParams = { fpToggle, strNbr };
  const {
    data: orderFulfillmentPickupData,
    isError: isErrorOrderFulfillmentPickup,
    isLoading: isLoadingOrderFulfillmentPickup
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { METHOD: 'BOPIS', PICK_ON_TIME: 90, AVG_MIN_TO_PICK: 15, FULFIL_TY: 90, TOTAL_ORDERS: 100, FULFIL_LY: 123, SALES_TY: 1200, SALES_LY: 1230 },
      { METHOD: 'BOSS', PICK_ON_TIME: 90, AVG_MIN_TO_PICK: 15, FULFIL_TY: 90, TOTAL_ORDERS: 100, FULFIL_LY: 123, SALES_TY: 1200, SALES_LY: 1230 },
      { METHOD: 'Will Call', PICK_ON_TIME: 90, AVG_MIN_TO_PICK: null, FULFIL_TY: 90, TOTAL_ORDERS: 100, FULFIL_LY: 123, SALES_TY: 1200, SALES_LY: 1230 },
      { METHOD: 'Total', PICK_ON_TIME: 90, AVG_MIN_TO_PICK: 15, FULFIL_TY: 90, TOTAL_ORDERS: 100, FULFIL_LY: 123, SALES_TY: 1200, SALES_LY: 1230 },
      { METHOD: 'Curbside', PICK_ON_TIME: 90, AVG_MIN_TO_PICK: 15, FULFIL_TY: 90, TOTAL_ORDERS: 100, FULFIL_LY: 123, SALES_TY: 1200, SALES_LY: 1230 },
      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  };
  const {
    data: orderFulfillmentDeliveryData,
    isError: isErrorOrderFulfillmentDelivery,
    isLoading: isLoadingOrderFulfillmentDelivery
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { METHOD: 'DELIVERY', PICK_ON_TIME_PCT: 0.9, FULFIL_PCT: null, FULFIL_TY: 123, FULFIL_LY: 123, SALES_TY: 1200, SALES_LY: 1230 },
      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  };


  const {
    data: assocOnTimePickupData,
    isError: isErrorAssocOnTimePickup,
    isLoading: isLoadingAssocOnTimePickup
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { ASSOCIATE: 'John Doe', TOTAL_ORDERS: 100, MISSED_SLA: 10, DS_COMPLIANCE: 0.9, ORDER_DATE: '21/12/2023', MINS_TO_PICK: '120', DIRECTED_LOCATION: 'LC', STAGED_LOCATION: 'sd-015', DS_COMPLIANCE_PCT: '0.9', NO_ORDER_COMPLIANCE_NOM: '123', WC_PICK_ON_TIME_PCT: '0.1', WC_NO_ORDER_COMPLIANCE_NOM: '123', WC_ORDER_NOM: '231231223', WC_ORDER_DATE: '21/12/2023', CUST_ORDER: "1234567890", DIR_LOC: "LC", STG_LOC: "sd-015", DS_COMP: ".90", NUM_DS_COMP: "90", CS_COSTUMER_WAIT_TIME: 30, },
      { ASSOCIATE: 'Jane Smith', TOTAL_ORDERS: 100, MISSED_SLA: 10, DS_COMPLIANCE: 0.9, ORDER_DATE: '20/12/2023', MINS_TO_PICK: '240', DIRECTED_LOCATION: 'LC', STAGED_LOCATION: 'sd-015', DS_COMPLIANCE_PCT: '0.9', NO_ORDER_COMPLIANCE_NOM: '123', WC_PICK_ON_TIME_PCT: '0.2', WC_NO_ORDER_COMPLIANCE_NOM: '123', WC_ORDER_NOM: '231861223', WC_ORDER_DATE: '21/12/2023', CUST_ORDER: "1987654321", DIR_LOC: "LC", STG_LOC: "sd-016", DS_COMP: ".87", NUM_DS_COMP: "87", CS_COSTUMER_WAIT_TIME: 15 },
      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  }

  const {
    data: assocCompletePickupData,
    isError: isErrorAssocCompletePickup,
    isLoading: isLoadingAssocCompletePickup
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { ASSOCIATE: 'John Doe', TOTAL_ORDERS: 100, MISSED_SLA: 10, DS_COMPLIANCE: 0.9, ORDER_DATE: '21/12/2023', NIIS_UNITS: '120', WC_PICK_ON_TIME_PCT: '0.1', UNFULFILLED_NOM: '60', UNFULFILLED_AMOUNT: '210', WC_ORDER_NOM: '231231223', BOPIS_CANCELED_DATE: '21/12/2023', SKU: '2312888153', CANCEL_QTY: 30, REASON_CODE: 'Not in Stock' },
      { ASSOCIATE: 'Jane Smith', TOTAL_ORDERS: 100, MISSED_SLA: 10, DS_COMPLIANCE: 0.9, ORDER_DATE: '20/12/2023', NIIS_UNITS: '120', WC_PICK_ON_TIME_PCT: '0.2', UNFULFILLED_NOM: '30', UNFULFILLED_AMOUNT: '15', WC_ORDER_NOM: '231861223', BOPIS_CANCELED_DATE: '21/12/2023', SKU: '2312223353', CANCEL_QTY: 10, REASON_CODE: 'Damaged' },
      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  }

  const {
    data: orderDeliveryData,
    isError: isErrorOrderDelivery,
    isLoading: isLoadingOrderDelivery
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { TRUCK: 'Box', PICK_ON_TIME: 0.9, DLV_MISSES: 10, DLV_MISSES_PCT: 0.1 },
      { TRUCK: 'Car', PICK_ON_TIME: 0.9, DLV_MISSES: 10, DLV_MISSES_PCT: 0.1 },
      { TRUCK: 'Flatbed', PICK_ON_TIME: 0.9, DLV_MISSES: 10, DLV_MISSES_PCT: 0.1 },
      { TRUCK: 'Van', PICK_ON_TIME: 0.9, DLV_MISSES: 10, DLV_MISSES_PCT: 0.1 },
      { TRUCK: 'Other', PICK_ON_TIME: 0.9, DLV_MISSES: 10, DLV_MISSES_PCT: 0.1 },
      { TRUCK: 'Totals Orders', PICK_ON_TIME: 0.9, DLV_MISSES: 50, DLV_MISSES_PCT: 0.5 },

      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  }

  const {
    data: OnTimeAverageData,
    isError: isErrorOnTimeAverage,
    isLoading: isLoadingOnTimeAverage
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { TRUCK: 'Flatbed', ASSOCIATE: 'J. Doe', PICK_ON_TIME: 0.1, DLV_MISSES: 10, ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, FULFIL_DATE: '21/12/2023' },
      { TRUCK: 'Box', ASSOCIATE: 'J. Smith', PICK_ON_TIME: 0.1, DLV_MISSES: 10, ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, FULFIL_DATE: '21/12/2023' },
      { TRUCK: 'Van', ASSOCIATE: 'J. Johns', PICK_ON_TIME: 0.1, DLV_MISSES: 10, ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, FULFIL_DATE: '21/12/2023' },
      { TRUCK: 'Car', ASSOCIATE: 'J. Ander', PICK_ON_TIME: 0.1, DLV_MISSES: 10, ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, FULFIL_DATE: '21/12/2023' },
      { TRUCK: 'Flatbed', ASSOCIATE: 'J. Jones', PICK_ON_TIME: 0.1, DLV_MISSES: 10, ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, FULFIL_DATE: '21/12/2023' },
      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  }

  const {
    data: DeliveryPushData,
    isError: isErrorDeliveryPush,
    isLoading: isLoadingDeliveryPush
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { ASSOCIATE: 'John Doe', MANAGER: 'Jack Thomas', TOTAL_ORDERS: 100, MISSED_SLA: 10, DS_COMPLIANCE: 0.9, ORDER_DATE: '21/12/2023', NIIS_UNITS: '120', WC_PICK_ON_TIME_PCT: '0.1', UNFULFILLED_NOM: '60', UNFULFILLED_AMOUNT: '210', WC_ORDER_NOM: '231231223', BOPIS_CANCELED_DATE: '21/12/2023', SKU: '2312888153', CANCEL_QTY: 30, REASON_CODE: 'Not in Stock', WORK_ORDER_PUSHED: 21, WORK_ORDER_NOM: '278546321', PUSH_AMOUNT: 100000, ORIGINAL_TRUCK: 'Van', NEW_TRUCK: 'Flatbed' },
      { ASSOCIATE: 'Jane Smith', MANAGER: 'Jack Thomas', TOTAL_ORDERS: 100, MISSED_SLA: 10, DS_COMPLIANCE: 0.9, ORDER_DATE: '20/12/2023', NIIS_UNITS: '120', WC_PICK_ON_TIME_PCT: '0.2', UNFULFILLED_NOM: '30', UNFULFILLED_AMOUNT: '15', WC_ORDER_NOM: '231861223', BOPIS_CANCELED_DATE: '21/12/2023', SKU: '2312223353', CANCEL_QTY: 10, REASON_CODE: 'Damaged', WORK_ORDER_PUSHED: 7, WORK_ORDER_NOM: '278223641', PUSH_AMOUNT: 100000, ORIGINAL_TRUCK: 'Flatbed', NEW_TRUCK: 'Van' },
      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  }

  const {
    data: OnTimeMissedData,
    isError: isErrorOnTimeMissed,
    isLoading: isLoadingOnTimeMissed
    // } = useQueryApi('TODO', queryParams);
  } = { // Mock data until we get the real data
    // No idea what the final data will actually look like, so I'm going to create a monster object with everything to be safe.
    data: [
      { TRUCK: 'Flatbed', ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, DLV_MISS_BUCKET: 'Readiness', MISS_CODE: 'Order Already Delivered', MISS_DATE: '21/12/2023' },
      { TRUCK: 'Box', ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, DLV_MISS_BUCKET: 'Accuracy', MISS_CODE: 'Damage to Product', MISS_DATE: '21/12/2023' },
      { TRUCK: 'Car', ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, DLV_MISS_BUCKET: 'Readiness', MISS_CODE: 'Store Readiness', MISS_DATE: '21/12/2023' },
      { TRUCK: 'Van', ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, DLV_MISS_BUCKET: 'Readiness', MISS_CODE: 'Wrong Pallet Count', MISS_DATE: '21/12/2023' },
      { TRUCK: 'Other', ORDER_NOM: 1234567890, WRK_ORDER_NOM: 1234567890, DLV_MISS_BUCKET: 'Readiness', MISS_CODE: 'Product Did Not Fit', MISS_DATE: '21/12/2023' },

      // Add more mock objects as needed
    ],
    isError: false, // or true, depending on what you want to simulate
    isLoading: false // or true, depending on what you want to simulate
  }

  // Add queries for Order Fulfillment, NO remove previous dumb queries
  // Until complete the entire section

  const queries = ['storeOnTimeAvgAssocView',
    'storeOnTimeAvgMissedSLA',
    'storeOnTimeDlvMissed',
    'storeCompleteDlv',
    'storeCompleteDlvFulfill',
    'storeOntimeDelivery',
    'storeOnTimePickUp',
    'storeOrderSummary',
    `query/queryCompletePickupLvl/store/${strNbr}`,
    `query/queryStoreDeliveryComplete/store/${strNbr}`,
    `query/queryCurbSideAssocView/store/${strNbr}`,
    `query/queryCurbSideMissedSLA/store/${strNbr}`,
    `query/queryAssociateView/store/${strNbr}`,
    `query/queryMissedSLA/store/${strNbr}`,
    `storeNonComplianceView`,
  ]

  const queryList = queries.map(queryKey => ({ queryKey: [queryKey, queryParams], queryFn: getData }));

  const results = useQueries(queryList);

  const isLoadingQueries = results.some(query => query.isLoading);
  const isErrorQueries = results.some(query => query.isError);


  const isError =
    isErrorOrderFulfillmentPickup ||
    isErrorOrderFulfillmentDelivery ||
    isErrorAssocOnTimePickup ||
    isErrorAssocCompletePickup ||
    isErrorOrderDelivery ||
    isErrorDeliveryPush ||
    isErrorOnTimeAverage ||
    isErrorOnTimeMissed
    // IsError from EnhanceQueries
    || isErrorQueries;
  const isLoading =
    isLoadingOrderFulfillmentPickup ||
    isLoadingOrderFulfillmentDelivery ||
    isLoadingAssocOnTimePickup ||
    isLoadingAssocCompletePickup ||
    isLoadingOrderDelivery ||
    isLoadingDeliveryPush ||
    isLoadingOnTimeAverage ||
    isLoadingOnTimeMissed
    // IsLoading from EnhanceQueries
    || isLoadingQueries;

  return {
    isError,
    isLoading,
    orderFulfillmentPickupData,
    orderFulfillmentDeliveryData,
    assocOnTimePickupData,
    assocCompletePickupData,
    orderDeliveryData,
    DeliveryPushData,
    OnTimeAverageData,
    OnTimeMissedData
  };
};

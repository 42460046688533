// NOTE: isUndefined is used because this is broken the app for core

const CATEGORIES = {
  Consumer: 'CONS',
  Commercial: 'COMM',
  Total: 'TOT'
};

export const creditApplicationsBuilder = (data = [], timeframe) => {
  const [item] = data;
  const isUndefined = item === undefined;
  return Object.entries(CATEGORIES).map(([key, value]) => {
    const CATEGORY_NAME = key;
    const APPS = !isUndefined ? item[`${timeframe}_${value}_APPS`] || 0 : 0;
    const LAST_YEAR_APPS = !isUndefined ? item[`${timeframe}_LY_${value}_APPS`] || 0 : 0;
    const APPS_GOAL = !isUndefined ? item[`${timeframe}_${value}_APP_GOAL`] || 0 : 0;

    const VARIANCE_GOAL =
      APPS_GOAL === 0
        ? '0%'
        : APPS != null && APPS_GOAL != null
          ? `${(100 * (APPS / APPS_GOAL - 1)).toFixed(1)}%`
          : 'Loading';

    return [
      { stringValue: CATEGORY_NAME },
      { numValue: APPS.toLocaleString() },
      { numValue: LAST_YEAR_APPS.toLocaleString() },
      { numValue: APPS_GOAL.toLocaleString() },
      { stringValue: VARIANCE_GOAL, compareStyle: true }
    ];
  });
};

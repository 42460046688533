export const willCallZeroes = {
    "HTD_FULFILL_NUM": 0,
    "HTD_FULFILL_PCT_DEN": 0,
    "HTD_CNCL_NIS_NUM": 0,
    "HTD_CNCL_NIS_DEN": 0,
    "HTD_UNFULFILLED_TOTAL": 0,
    "LM_FULFILL_NUM": 0,
    "LM_FULFILL_PCT_DEN": 0,
    "LM_CNCL_NIS_NUM": 0,
    "LM_CNCL_NIS_DEN": 0,
    "LM_UNFULFILLED_TOTAL": 0,
    "LW_FULFILL_NUM": 0,
    "LW_FULFILL_PCT_DEN": 0,
    "LW_CNCL_NIS_NUM": 0,
    "LW_CNCL_NIS_DEN": 0,
    "LW_UNFULFILLED_TOTAL": 0,
    "L12_FULFILL_NUM": 0,
    "L12_FULFILL_PCT_DEN": 0,
    "L12_CNCL_NIS_NUM": 0,
    "L12_CNCL_NIS_DEN": 0,
    "L12_UNFULFILLED_TOTAL": 0,
    "MTD_FULFILL_NUM": 0,
    "MTD_FULFILL_PCT_DEN": 0,
    "MTD_CNCL_NIS_NUM": 0,
    "MTD_CNCL_NIS_DEN": 0,
    "MTD_UNFULFILLED_TOTAL": 0,
    "QTD_FULFILL_NUM": 0,
    "QTD_FULFILL_PCT_DEN": 0,
    "QTD_CNCL_NIS_NUM": 0,
    "QTD_CNCL_NIS_DEN": 0,
    "QTD_UNFULFILLED_TOTAL": 0,
    "YTD_FULFILL_NUM": 0,
    "YTD_FULFILL_PCT_DEN": 0,
    "YTD_CNCL_NIS_NUM": 0,
    "YTD_CNCL_NIS_DEN": 0,
    "YTD_UNFULFILLED_TOTAL": 0,
    "WTD_FULFILL_NUM": 0,
    "WTD_FULFILL_DEN": 0,
    "WTD_UNFULFILLED_TOTAL": 0,
    "WTD_CNCL_NIS_DEN": 0,
    "WTD_CNCL_NIS_NUM": 0,
    "STR_NM": "SOUTHCENTER",
    "STR_NBR": 0,
    "CHANNEL": "WILL CALL"
};

import { Fragment } from 'react';
import { BreadcrumbSection, Breadcrumb as BreadcrumbWrapper } from 'semantic-ui-react';
import { Link } from './Link';

export const Breadcrumb = ({ items }) => (
  <BreadcrumbWrapper>
    {items.map(({ text, to }, index) => (
      <Fragment key={text}>
        {!to ? (
          <span>{text}</span>
        ) : (
          <BreadcrumbSection>
            <Link to={to} text={text} />
          </BreadcrumbSection>
        )}

        {items.length !== index + 1 && <span> / </span>}
      </Fragment>
    ))}
  </BreadcrumbWrapper>
);

import React from 'react';
import { Header, Loader, Segment } from 'semantic-ui-react';
import { withRouter, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';

import { getData } from '../../service/DataService';

import { IMAGING_GOAL } from './OSAImaging';
import { buildSublevelLinkColumn } from '../../utils/dataTable';
import { columnName, METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../utils/string';
import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';
import { getParamByLevel } from '../../utils/timeFrameUtils';
import { getOSAImagingBreadcrumbItems } from '../../utils/breadcrumbItems';

export const OSAImagingDist = withRouter(({ timeframe, settimeframe, fpToggle, locNbr, levelName }) => {
  const { level } = useParams();
  const osaImagingQuery = useQuery([`query/osaImageCapturePlusOne/${level}`, { locNbr, fpToggle }], getData);
  if (osaImagingQuery.isLoading) {
    return <Loader active>Loading</Loader>;
  }
  if (!osaImagingQuery.data || osaImagingQuery.data.length === 0) {
    return <Header textAlign='center'>Invalid Store Number</Header>;
  }

  const lastTimeframeMap = {
    WTD: 'LW',
    LW: null, // We don't have comparisons for last week or month, so they'll be null
    LM: null,
    MTD: 'LM',
    QTD: 'LQ',
    HTD: 'LH',
    YTD: 'YTD_LY',
    L12: 'L12_LY'
  };

  const ltf = lastTimeframeMap[timeframe];

  const storeSummary = osaImagingQuery.data.reduce((acc, row) => {
    const ColumField = row[METRIC_CONSTANTS[level]['propToRender']].toString().padStart(4, '0');
    if (!acc[ColumField]) {
      acc[ColumField] = { ...row, actual: 0, goal: 0, lastActual: 0, lastGoal: 0 };
    }
    acc[ColumField].actual += row[`${timeframe}_IMAGES_TAKEN`];
    acc[ColumField].goal += row[`${timeframe}_IMAGES_EXPECTED`];
    if (ltf) {
      acc[ColumField].lastActual += row[`${ltf}_IMAGES_TAKEN`];
      acc[ColumField].lastGoal += Number(row[`${ltf}_IMAGES_EXPECTED`]);
    } else {
      acc[ColumField].lastActual = null;
      acc[ColumField].lastGoal = null;
    }
    return acc;
  }, {});

  const storeData = {
    sortable: true,
    headers: [
      { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel'])} ` },
      { name: 'Actual ' },
      { name: 'Goal ' },
      { name: ltf ? `v${ltf} bps ` : '- ' }
    ],
    data: Object.entries(storeSummary).map(([store, dat]) => {
      const checkValues = ['L12', 'YTD'].includes(timeframe) && dat.lastGoal === 0; // prevent NaN bps value, no values from previous year
      return [
        {
          ...buildSublevelLinkColumn({ level, row: dat, path: `/osa/${METRIC_CONSTANTS[level]['urlLinkLevel']}/imaging/` })
        },
        { pctValue: dat.actual / dat.goal, decimals: 1, compareValue: IMAGING_GOAL },
        { pctValue: IMAGING_GOAL },
        ltf && !checkValues
          ? {
              numValue: Math.round((dat.actual / dat.goal - dat.lastActual / dat.lastGoal) * 10000),
              compareStyle: true,
              postfix: ' bps'
            }
          : { stringValue: '--' }
      ];
    }),
    sortableDefault: 0
  };
  const urlParam = getParamByLevel(level);
  const breadcrumbItems = getOSAImagingBreadcrumbItems({ level, urlParam, locNbr });

  return (
    <Segment>
      <Breadcrumb items={breadcrumbItems} />
      <DataView className='mt-4'>
        <DataView.Title>
          Overhead Imaging %, {level} {locNbr}, {osaImagingQuery.data[0][columnName[level]]}
          <Header.Subheader>Data as of {osaImagingQuery.data[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='align-items-center d-flex flex-column'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
      </DataView>

      <DataView.DataTable data={storeData} />
    </Segment>
  );
});

import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { DataView } from '../../../../../../../components/Common/DataView';
import { Header } from 'semantic-ui-react';
import { useQueryKeys } from '../../../../../../../components/ShrinkContainer/hooks/useQueryKeys';
import { formatNumberWithCommas } from '../../../../../../../utils/formatter';

const ASSOCIATE_VIEW = 'Associate View';
const MISSED_SLA = '# Missed SLA';

export const Curbside = () => {
  const { strNbr, timeframe } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState(ASSOCIATE_VIEW);
  const [queryCurbSideAssocView, curbsideMissedSLA] = useQueryKeys([`query/queryCurbSideAssocView/store/${strNbr}`, `query/queryCurbSideMissedSLA/store/${strNbr}`])
  const [localtimeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = [MISSED_SLA];
  const filterMissedSLA = curbsideMissedSLA.filter(row => row[localtimeframe + '_FLG'] > 0)
  const filterAssocView = queryCurbSideAssocView.filter(row => row[`${timeframe}_ORDER_NUMBER`] > 0)
  const getData = () => {
    switch (view) {
      case ASSOCIATE_VIEW:
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Avg Customer Wait Time' },
            { name: '# Orders Missed SLA' },
            { name: '% Orders Missed SLA' }
          ],
          data: filterAssocView.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: formatNumberWithCommas(+data[`${timeframe}_TOTAL_TIME_ADJ`], 2) },
            { numValue: formatNumberWithCommas(+data[`${timeframe}_MISS_SLA_NUM`]) },
            { pctValue: data[`${timeframe}_MISS_SLA_NUM`] / data[`${timeframe}_ORDER_NUMBER`] }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case MISSED_SLA:
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Order Date' },
            { name: 'Customer Wait Time (mins)' }
          ],
          data: filterMissedSLA.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) },
            { numValue: data.ORDER_NUMBER },
            { numValue: String(data.ORDER_DT) },
            { numValue: +data.CUSTOM_WAIT_TIME }
          ]),
          pagination: true,
          sortable: true,
          sortableDefault: 3,
          sortDirection: 'ascending'
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  const viewOptions = [ASSOCIATE_VIEW, MISSED_SLA].map(view => ({ key: view, text: view, value: view }));
  const timeframeOptions = ['WTD', 'LW', 'LM', 'MTD'].map(timeframe => ({ key: timeframe, text: timeframe, value: timeframe }));

  return (
    <DataView className='my-3'>
      <Header className='mt-4 mb-1' size='small' as='h3'>
        Curbside: {view}
      </Header>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        viewOptions={viewOptions}
        timeframeOptions={timeframeOptions}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
};

import { METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { LEVELS } from "../../../utils/level";

export const SHRINK_PATH = 'shrink';

export const buildBaseShrinkUrl = (level = ':level') => {
  if (!LEVELS.includes(level)) return ''

  return `${level}/${SHRINK_PATH}`
};

export const buildBaseDrillLink = level => {
  const { urlLinkLevel } = METRIC_CONSTANTS[level]
  if (['district', 'region'].includes(level)) return `${urlLinkLevel}/shrink`

  return buildBaseShrinkUrl(urlLinkLevel);
}

import { useContext, useState } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { Dropdown } from 'semantic-ui-react';
import { onTimeDeliveryMethods, onTimePickUpGetData } from '../../../../utils/onTimePickUp';
import { BOPIS, TOTAL, WILL_CALL, BOSS } from '../../../../utils/consts';
import { useQueryKeys } from '../../../../../../components/ShrinkContainer/hooks/useQueryKeys';

export const OnTimePickUp = () => {
  const { level, timeframe, locNbr } = useContext(OrderFulfillmentContext);
  const formatQueryKeys = ['query/queryOnTimePickUp/', 'query/queryOnTimeTotalPickUp/', 'query/queryPickUpCurbside/'].map(query => query + `${level}/${locNbr}`)
  const [view, setView] = useState(TOTAL);
  const [queryOnTimePickUp, queryOnTimeTotalPickUp, queryPickUpCurbside] = useQueryKeys(formatQueryKeys)
  const dataFIlter = {
    Curbside: queryPickUpCurbside,
    BOSS: queryOnTimePickUp.filter(data => data.CHANNEL === BOSS),
    BOPIS: queryOnTimePickUp.filter(data => data.CHANNEL === BOPIS),
    'Will Call': queryOnTimePickUp.filter(data => data.CHANNEL === String(WILL_CALL).toUpperCase()),
    Total: queryOnTimeTotalPickUp,
  }
  const data = onTimePickUpGetData(view, level, dataFIlter[view], timeframe);

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <div>Select Method</div>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={onTimeDeliveryMethods}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Pickup Details by Method {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
};

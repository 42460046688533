import { DataView } from '../../../Common/DataView';
import { creditEfficiencyBuilder } from '../utils/creditEfficiencyBuilder';

export const CreditEfficiency = ({ data = [], timeframe }) => {
  const dataTableData = {
    headers: [{ name: 'App Efficiency' }, { name: 'LY' }],
    data: creditEfficiencyBuilder(data, timeframe)
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title position='left'>Efficiency</DataView.Title>
      <DataView.DataTable data={dataTableData} />
    </DataView>
  );
};

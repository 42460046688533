import { createContext } from 'react';
import { useOrderFulfillmentQueries } from '../hooks/useOrderFulfillmentQueries';

export const OrderFulfillmentContext = createContext();

const OrderFulfillmentContextProvider = ({ children, fpToggle, level, timeframe, ...props }) => {
  const queryProps = useOrderFulfillmentQueries({ fpToggle, level, timeframe });

  const value = {
    ...props,
    ...queryProps,
    fpToggle,
    level,
    timeframe
  };

  return (
    <OrderFulfillmentContext.Provider value={value}>
      {children}
    </OrderFulfillmentContext.Provider>
  );
};

export default OrderFulfillmentContextProvider;
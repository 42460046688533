import { buildSublevelLinkColumn } from '../../../utils/dataTable';
import { capitalizeFirstLetter } from '../../../utils/string';
import { formatColor, getThousandDollarString } from '../../Common/TableCellUtils';
import { formatLevelNumber } from '../../../utils/level';
import { STORE, CORE, LEVEL_NBR } from '../../../utils/constantsByLevel';
import {
  SPEC_PRO_RENTAL_PATH,
  OPEN_QUOTE_AMT,
  OPEN_QUOTE_CNT,
  QUOTE_SOLD_AMT,
  QUOTE_SOLD_CNT,
  TOT_QUOTE_CNT,
  LEADS_VLY_PCT,
  MEAS_VLY_PCT,
  WALK_COMPLETE,
  TOTAL_WALK,
  WALK_COMPLETE_PCT,
  YES_RESP_PCT,
  SPOT_CMPLT_PCT,
  CSU_CMPLT_PCT,
  SPEC_OVR_GOAL,
  YES_RESP,
  TOT_RESP,
  CNT_CMPLT,
  CNT_ASSOCS,
  CSU_CNT_CMPLT,
  CSU_CNT_ASSOCS,
  OVR_GOAL,
  TOT_ASSOC,
  DMG_WVR_PCT,
  TTL_CAD_WO,
  CAD_NO_PARTS,
  GET_PCT,
  LTRA_GOAL,
  LTRA_PCT,
  RENTAL_GET_GOAL
} from '../utils/consts';

const { levelName: coreLevelName } = CORE;
const { levelName: storeLevelName } = STORE;

export const getTitle = params => {
  const { level, locNbr, currentLevelName } = params;
  const { levelName } = level;
  const baseTitle = 'Specialty, Pro, Rental';
  const locNbrFormated = formatLevelNumber(locNbr);

  const title = `${baseTitle} - ${capitalizeFirstLetter(levelName)}`;
  const titleSufix = locNbr ? `${locNbrFormated}, ${currentLevelName}` : '';

  return `${title} ${titleSufix}`;
};

const getSubLevelColumn = params => {
  const { level, row } = params;
  const { levelName } = level;

  if (levelName === storeLevelName) {
    return [];
  }

  const to = `/${SPEC_PRO_RENTAL_PATH}`;
  const firstColumn = buildSublevelLinkColumn({
    level: levelName,
    row,
    to
  });

  return [firstColumn];
};

// Quotes Table

const getQuotesTableHeaders = params => {
  const { level } = params;
  const {
    levelName,
    subLevel: { levelName: subLevelName }
  } = level;

  return [
    ...(levelName !== storeLevelName ? [{ name: `${capitalizeFirstLetter(subLevelName)} #` }] : []),
    { name: 'Open Opportunity' },
    { name: 'Open Count' },
    { name: 'Sold $' },
    { name: 'Sold Count' },
    { name: 'Sold Quote % *' }
  ];
};

const getQuotesRowValues = params => {
  const { row, timeframe } = params;

  const openOpportunity = row[`${timeframe}_${OPEN_QUOTE_AMT}`];
  const openCount = row[`${timeframe}_${OPEN_QUOTE_CNT}`];
  const sold = row[`${timeframe}_${QUOTE_SOLD_AMT}`];
  const soldCount = row[`${timeframe}_${QUOTE_SOLD_CNT}`];
  const soldQuotePct = soldCount / row[`${timeframe}_${TOT_QUOTE_CNT}`];

  return { openOpportunity, openCount, sold, soldCount, soldQuotePct };
};

const getQuotesRow = params => {
  const { openOpportunity, openCount, sold, soldCount, soldQuotePct } = params;

  return [
    {
      stringValue: getThousandDollarString(openOpportunity),
      orderBy: openOpportunity
    },
    {
      stringValue: openCount?.toLocaleString(undefined, { maximumFractionDigits: 1 }),
      orderBy: openCount
    },
    {
      stringValue: getThousandDollarString(sold),
      orderBy: sold
    },
    {
      stringValue: soldCount?.toLocaleString(undefined, { maximumFractionDigits: 1 }),
      orderBy: soldCount
    },
    {
      pctValue: soldQuotePct
    }
  ];
};

const getQuotesTableData = params => {
  const { level, timeframe, quotesData } = params;

  return quotesData.map(row => {
    const { openOpportunity, openCount, sold, soldCount, soldQuotePct } = getQuotesRowValues({ row, timeframe });
    const subLevelColumn = getSubLevelColumn({ level, row });

    return [...subLevelColumn, ...getQuotesRow({ openOpportunity, openCount, sold, soldCount, soldQuotePct })];
  });
};

const getQuotesTableFooter = params => {
  const { level, timeframe, quotesData } = params;
  const { levelName } = level;

  if (levelName === storeLevelName) {
    return [];
  }

  const quotesTotalData = quotesData.reduce((acc, row) => {
    const { openOpportunity, openCount, sold, soldCount, soldQuotePct } = getQuotesRowValues({ row, timeframe });

    return {
      openOpportunity: (acc.openOpportunity || 0) + openOpportunity,
      openCount: (acc.openCount || 0) + openCount,
      sold: (acc.sold || 0) + sold,
      soldCount: (acc.soldCount || 0) + soldCount,
      soldQuotePct: (acc.soldQuotePct || 0) + soldQuotePct
    };
  }, {});

  const quotesTotalDataWithPct = {
    ...quotesTotalData,
    soldQuotePct: quotesTotalData.soldQuotePct / quotesData.length
  };

  return [[{ stringValue: 'Total' }, ...getQuotesRow(quotesTotalDataWithPct)]];
};

export const getQuotesDataTable = params => {
  const { level, timeframe, quotesData } = params;

  return {
    headers: getQuotesTableHeaders({ level }),
    data: getQuotesTableData({ level, timeframe, quotesData }),
    footer: getQuotesTableFooter({ level, timeframe, quotesData }),
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};

// Specialty Key Metrics Table

const getSpecialtyKeyMetricsTableHeaders = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;
  const linkParams = levelName !== coreLevelName ? { [urlParam]: locNbr } : undefined;

  return [
    { name: `${capitalizeFirstLetter(level.urlLinkLevel)} # ` },
    {
      name: 'Lead Comp',
      link: {
        to: `/${level.levelName}/leads`,
        params: linkParams
      }
    },
    {
      name: `Measure Comp`,
      link: {
        to: `/${level.levelName}/meas`,
        params: linkParams
      }
    },
    { name: `DOTW Standards` }
  ];
};

const getSpecialtyKeyMetricsRowValues = params => {
  const { row, timeframe } = params;

  const leadComp = row[`${timeframe}_${LEADS_VLY_PCT}`];
  const measComp = row[`${timeframe}_${MEAS_VLY_PCT}`];
  const walkComplete = row[`${timeframe}_${WALK_COMPLETE}`];
  const totalWalk = row[`${timeframe}_${TOTAL_WALK}`];
  const dotwStandards = walkComplete / totalWalk;

  return { leadComp, measComp, dotwStandards };
};

const getSpecialtyKeyMetricsRow = params => {
  const { leadComp, measComp, dotwStandards } = params;

  return [
    { pctValue: leadComp, compareValue: true },
    { pctValue: measComp, compareValue: true },
    { pctValue: dotwStandards, compareValue: true }
  ];
};

const getSpecialtyKeyMetricsTableData = params => {
  const { level, timeframe, specialtyKeyMetricsData } = params;
  const subLevelData = specialtyKeyMetricsData.filter(row => row.METRIC_LEVEL === level.subMetric);
  const dataFiltered = subLevelData.filter(row => row[level.propToRender]);

  return dataFiltered.map(row => {
    const specialtyKeyMetricsRowValues = getSpecialtyKeyMetricsRowValues({ row, timeframe });
    const subLevelColumn = getSubLevelColumn({ level, row });

    return [...subLevelColumn, ...getSpecialtyKeyMetricsRow(specialtyKeyMetricsRowValues)];
  });
};

const getSpecialtyKeyMetricsTableFooter = params => {
  const { level, timeframe, specialtyKeyMetricsData } = params;
  const { subLevel } = level;
  const { [LEVEL_NBR]: subLevelNbr } = subLevel;

  const levelData = specialtyKeyMetricsData.find(row => row[subLevelNbr] === null);
  const specialtyKeyMetricsRowValues = getSpecialtyKeyMetricsRowValues({ row: levelData, timeframe });

  return [[{ stringValue: 'Total' }, ...getSpecialtyKeyMetricsRow(specialtyKeyMetricsRowValues)]];
};

const getSpecialtyKeyMetricsStrFirstTableHeaders = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;

  return [
    {
      link: {
        to: `/${levelName}/leads`,
        params: {
          [urlParam]: locNbr
        }
      },
      name: ' Lead Comp'
    },
    {
      link: {
        to: `/${levelName}/meas`,
        params: {
          [urlParam]: locNbr
        }
      },
      name: 'Meas Comp'
    },
    { name: 'DOTW Standards' },
    { name: '1:1 Cmplt' }
  ];
};

const getSpecialtyKeyMetricsStrFirstTableData = params => {
  const { timeframe, specialtyKeyMetricsData } = params;

  return specialtyKeyMetricsData.map(row => {
    const leadComp = row[`${timeframe}_${LEADS_VLY_PCT}`];
    const measComp = row[`${timeframe}_${MEAS_VLY_PCT}`];
    const dotwStandards = row[`${timeframe}_${WALK_COMPLETE_PCT}`];
    const oneToOneCmpl = row[`${timeframe}_${YES_RESP_PCT}`];

    return [
      { pctValue: leadComp, compareValue: true },
      { pctValue: measComp, compareValue: true },
      { pctValue: dotwStandards },
      { pctValue: oneToOneCmpl }
    ];
  });
};

const getSpecialtyKeyMetricsStrSecondTableHeaders = () => [
  { name: 'Spotlight Cmplt' },
  { name: 'CSU Cmplt' },
  { name: '% of Spec. Meeting Sls Goal' }
];

const getSpecialtyKeyMetricsStrSecondTableData = params => {
  const { timeframe, specialtyKeyMetricsData } = params;

  return specialtyKeyMetricsData.map(row => {
    const spotlightCmplt = row[`${timeframe}_${SPOT_CMPLT_PCT}`];
    const csuCmplt = row[`${timeframe}_${CSU_CMPLT_PCT}`];
    const pctOfSpecMeetingSlsGoal = row[`${timeframe}_${SPEC_OVR_GOAL}`];

    return [{ pctValue: spotlightCmplt }, { pctValue: csuCmplt }, { pctValue: pctOfSpecMeetingSlsGoal }];
  });
};

export const getSpecialtyKeyMetricsDataTables = params => {
  const { level, locNbr, timeframe, specialtyKeyMetricsData } = params;
  const { levelName } = level;

  if (levelName === storeLevelName) {
    return [
      {
        headers: getSpecialtyKeyMetricsStrFirstTableHeaders({ level, locNbr }),
        data: getSpecialtyKeyMetricsStrFirstTableData({ timeframe, specialtyKeyMetricsData })
      },
      {
        headers: getSpecialtyKeyMetricsStrSecondTableHeaders(),
        data: getSpecialtyKeyMetricsStrSecondTableData({ timeframe, specialtyKeyMetricsData })
      }
    ];
  }

  return [
    {
      headers: getSpecialtyKeyMetricsTableHeaders({ level, locNbr }),
      data: getSpecialtyKeyMetricsTableData({ level, timeframe, specialtyKeyMetricsData }),
      footer: getSpecialtyKeyMetricsTableFooter({ level, timeframe, specialtyKeyMetricsData }),
      sortable: true,
      sortableDefault: 0
    }
  ];
};

// Specialty Key Metrics Cont.

const getSpecialtyKeyMetricsContTableHeaders = params => {
  const { level } = params;
  const {
    subLevel: { levelName: subLevelName }
  } = level;

  return [
    { name: `${capitalizeFirstLetter(subLevelName)} #` },
    { name: '1:1 Cmplt' },
    { name: 'Spotlight Cmplt' },
    { name: 'CSU Cmplt' },
    { name: '% of Spec. Meeting Sls Goal' }
  ];
};

const getSpecialtyKeyMetricsContRowValues = params => {
  const { row, timeframe } = params;

  const yesResp = row[`${timeframe}_${YES_RESP}`];
  const totResp = row[`${timeframe}_${TOT_RESP}`];
  const cntCmplt = row[`${timeframe}_${CNT_CMPLT}`];
  const cntAssocs = row[`${timeframe}_${CNT_ASSOCS}`];
  const csuCntCmplt = row[`${timeframe}_${CSU_CNT_CMPLT}`];
  const csuCntAssocs = row[`${timeframe}_${CSU_CNT_ASSOCS}`];
  const ovrGoal = row[`${timeframe}_${OVR_GOAL}`];
  const totAssoc = row[`${timeframe}_${TOT_ASSOC}`];

  const oneToOneCmpl = yesResp / totResp;
  const spotlightCmplt = cntCmplt / cntAssocs;
  const csuCmplt = csuCntCmplt / csuCntAssocs;
  const pctOfSpecMeetingSlsGoal = ovrGoal / totAssoc;

  return { oneToOneCmpl, spotlightCmplt, csuCmplt, pctOfSpecMeetingSlsGoal };
};

const getSpecialtyKeyMetricsContRow = params => {
  const { oneToOneCmpl, spotlightCmplt, csuCmplt, pctOfSpecMeetingSlsGoal } = params;

  return [
    { pctValue: oneToOneCmpl },
    { pctValue: spotlightCmplt },
    { pctValue: csuCmplt },
    { pctValue: pctOfSpecMeetingSlsGoal }
  ];
};

const getSpecialtyKeyMetricsContTableData = params => {
  const { level, timeframe, specialtyKeyMetricsContData } = params;
  const { levelName } = level;

  return specialtyKeyMetricsContData.map(row => {
    const { oneToOneCmpl, spotlightCmplt, csuCmplt, pctOfSpecMeetingSlsGoal } = getSpecialtyKeyMetricsContRowValues({
      row,
      timeframe
    });
    const to = `/${SPEC_PRO_RENTAL_PATH}`;

    const firstColumn = buildSublevelLinkColumn({
      level: levelName,
      row,
      to
    });

    return [
      firstColumn,
      ...getSpecialtyKeyMetricsContRow({
        oneToOneCmpl,
        spotlightCmplt,
        csuCmplt,
        pctOfSpecMeetingSlsGoal
      })
    ];
  });
};

const getSpecialtyKeyMetricsContTableFooter = params => {
  const { timeframe, specialtyKeyMetricsContData } = params;

  const { yesResp, totResp, cntCmplt, cntAssocs, csuCntCmplt, csuCntAssocs, ovrGoal, totAssoc } =
    specialtyKeyMetricsContData.reduce((acc, row) => {
      const yesResp = row[`${timeframe}_${YES_RESP}`];
      const totResp = row[`${timeframe}_${TOT_RESP}`];
      const cntCmplt = row[`${timeframe}_${CNT_CMPLT}`];
      const cntAssocs = row[`${timeframe}_${CNT_ASSOCS}`];
      const csuCntCmplt = row[`${timeframe}_${CSU_CNT_CMPLT}`];
      const csuCntAssocs = row[`${timeframe}_${CSU_CNT_ASSOCS}`];
      const ovrGoal = row[`${timeframe}_${OVR_GOAL}`];
      const totAssoc = row[`${timeframe}_${TOT_ASSOC}`];

      return {
        yesResp: (acc.yesResp || 0) + yesResp,
        totResp: (acc.totResp || 0) + totResp,
        cntCmplt: (acc.cntCmplt || 0) + cntCmplt,
        cntAssocs: (acc.cntAssocs || 0) + cntAssocs,
        csuCntCmplt: (acc.csuCntCmplt || 0) + csuCntCmplt,
        csuCntAssocs: (acc.csuCntAssocs || 0) + csuCntAssocs,
        ovrGoal: (acc.ovrGoal || 0) + ovrGoal,
        totAssoc: (acc.totAssoc || 0) + totAssoc
      };
    }, {});
  const specialtyKeyMetricsContTotalData = {
    oneToOneCmpl: yesResp / totResp,
    spotlightCmplt: cntCmplt / cntAssocs,
    csuCmplt: csuCntCmplt / csuCntAssocs,
    pctOfSpecMeetingSlsGoal: ovrGoal / totAssoc
  };

  const specialtyKeyMetricsContTotalDataWithPct = {
    ...specialtyKeyMetricsContTotalData,
    oneToOneCmpl: specialtyKeyMetricsContTotalData.oneToOneCmpl,
    spotlightCmplt: specialtyKeyMetricsContTotalData.spotlightCmplt,
    csuCmplt: specialtyKeyMetricsContTotalData.csuCmplt,
    pctOfSpecMeetingSlsGoal: specialtyKeyMetricsContTotalData.pctOfSpecMeetingSlsGoal
  };

  return [[{ stringValue: 'Total' }, ...getSpecialtyKeyMetricsContRow(specialtyKeyMetricsContTotalDataWithPct)]];
};

export const getSpecialtyKeyMetricsContDataTable = params => {
  const { level, timeframe, specialtyKeyMetricsContData } = params;

  return {
    headers: getSpecialtyKeyMetricsContTableHeaders({ level }),
    data: getSpecialtyKeyMetricsContTableData({ level, timeframe, specialtyKeyMetricsContData }),
    footer: getSpecialtyKeyMetricsContTableFooter({ timeframe, specialtyKeyMetricsContData }),
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};

// Rental Key Metrics Table

const getRentalKeyMetricsTableHeaders = params => {
  const { level } = params;
  const {
    levelName,
    subLevel: { levelName: subLevelName }
  } = level;

  return [
    ...(levelName !== storeLevelName ? [{ name: `${capitalizeFirstLetter(subLevelName)} #` }] : []),
    { name: 'Damage Waiver %' },
    { name: 'Critical Down Tools' },
    { name: 'Critical Down Tools - No Parts' },
    { name: 'LTRA' },
    { name: 'Rental GET' }
  ];
};

const getRentalKeyMetricsRowValues = params => {
  const { row, timeframe } = params;

  const damageWaiverPct = row[`${timeframe}_${DMG_WVR_PCT}`];
  const criticalDownTools = row[`${timeframe}_${TTL_CAD_WO}`];
  const criticalDownToolsNoParts = row[`${timeframe}_${CAD_NO_PARTS}`];
  const ltra = row[`${timeframe}_${LTRA_PCT}`];
  const rentalGet = row[`${timeframe}_${GET_PCT}`];

  return { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet };
};

const getRentalKeyMetricsRow = params => {
  const { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet } = params;

  return [
    { pctValue: damageWaiverPct, orderBy: damageWaiverPct },
    { numValue: criticalDownTools, orderBy: criticalDownTools },
    { numValue: criticalDownToolsNoParts, orderBy: criticalDownToolsNoParts },
    { pctValue: ltra, orderBy: ltra, customStyle: formatColor(ltra, LTRA_GOAL) },
    { pctValue: rentalGet, orderBy: rentalGet, customStyle: formatColor(rentalGet, RENTAL_GET_GOAL) }
  ];
};

const getRentalKeyMetricsTableData = params => {
  const { level, timeframe, rentalKeyMetricsData } = params;

  return rentalKeyMetricsData.map(row => {
    const { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet } = getRentalKeyMetricsRowValues({
      row,
      timeframe
    });
    const subLevelColumn = getSubLevelColumn({ level, row });

    return [
      ...subLevelColumn,
      ...getRentalKeyMetricsRow({ damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet })
    ];
  });
};

const getRentalKeyMetricsTableFooter = params => {
  const { level, timeframe, trOpsDataTotal } = params;
  const { levelName } = level;

  if (levelName === storeLevelName) {
    return [];
  }

  const totalData = trOpsDataTotal[0] || {};
  const data = getRentalKeyMetricsRowValues({ row: totalData, timeframe });

  return [[{ stringValue: 'Total' }, ...getRentalKeyMetricsRow(data)]];
};

export const getRentalKeyMetricsDataTable = params => {
  const { level, timeframe, rentalKeyMetricsData, trOpsDataTotal } = params;

  return {
    headers: getRentalKeyMetricsTableHeaders({ level }),
    data: getRentalKeyMetricsTableData({ level, timeframe, rentalKeyMetricsData }),
    footer: getRentalKeyMetricsTableFooter({ level, timeframe, trOpsDataTotal }),
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};

import { Grid, Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../hooks/useQueryApi';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { DataView } from '../../Common/DataView';
import { Link } from '../../Common/Link';
import { TabMenu } from '../../Common/TabMenu';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { ServicesPrgmPipeline } from '../components/ServicesPrgmPipeline';
import { ServicesPrgmSales } from '../components/ServicesPrgmSales';
import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';

export const SpecialtyProRentalServicesPrgmPage = () => {
  const { currentLevelName, fpToggle, level, locNbr, timeframe, settimeframe, smryPrgmNm } =
    useSpecialtyProRentalServicesContext();
  const queryParams = { locNbr, smryPrgmNm, fpToggle };
  const {
    data: levelData,
    isLoading: isLevelLoading,
    isError: isLevelError
  } = useQueryApi(`queryServicesDtlPrgmByLevel/${level}`, queryParams);
  const { data, isLoading, isError } = useQueryApi(`query/queryServicesDtlPrgmByMetricLevel/${level}`, queryParams);
  const paramByLevel = getParamByLevel(level);

  if (isLoading || isLevelLoading) return <Loader active>Loading...</Loader>;
  if (isError || isLevelError || !data[0] || !levelData[0]) {
    return <Header textAlign='center'>{`Invalid or Unauthorized ${capitalizeFirstLetter(level)} Number`}</Header>;
  }
  const dataFilter = data[0];

  return (
    <>
      <DataView className='mt-4'>
        <DataView.Title textAlign='center'>
          {dataFilter.MER_DEPT_CD}-{dataFilter.SUMMARY_PROGRAM_NAME} - {capitalizeFirstLetter(level)} #{locNbr}
        </DataView.Title>
        <DataView.Controls>
          <Grid>
            <Grid.Column width={8}>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Link to={`/${level}/services?${paramByLevel}=${locNbr}`}>Back to Services</Link>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={8} textAlign='right'>
              Timeframe:
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              &nbsp;
            </Grid.Column>
          </Grid>
        </DataView.Controls>
      </DataView>
      <TabMenu
        className='mb-4'
        color='orange'
        panes={[
          {
            menuItem: 'Sales',
            render: () => <ServicesPrgmSales data={levelData} />
          },
          {
            menuItem: 'Pipeline',
            render: () => <ServicesPrgmPipeline data={levelData} />
          }
        ]}
      />
    </>
  );
};
